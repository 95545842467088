.bc_main_wrapp {
  background: #1f1f1f;
  border-radius: 1rem;
  border: 1px solid rgb(50, 49, 49);
}
.bc_base_wrapp {
  /* height: 300px; */
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bc_img {
  width: 90%;
  height: 184px;
  object-fit: cover;
  border-radius: 12px;
  margin: 10px;
  object-position: top;
}

.bc_text_wrapp {
  padding: 4px;
  /* height: 40%; */
  width: 100%;
  margin-bottom: 0.5rem;
}

.bc_name {
  text-align: left;
  color: rgb(255, 255, 255);
  font-family: var(--font-600);
  word-break: keep-all;
  font-size: 14px;
  line-height: 125%;
  width: 95%;
  margin: 6px auto;
  text-wrap: wrap;
}

.bc_owner_wrapp {
  background: rgb(41, 39, 39);

  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px 4px 4px;
  gap: 6px;
  position: absolute;
  left: 6%;
  top: 42%;
}

.bc_owner_wrapp > img {
  width: 26px;
  height: 26px;
  border-radius: 26px;
  overflow: hidden;
}

.bc_owner_wrapp > p {
  font-family: var(--font-400);
  font-size: 10px;
  color: var(--color-white);
}

.bc_pv_wrapp {
  width: 95%;
  border: 1px solid rgba(78, 78, 78, 0.9);
  border-radius: 12px;
  background: rgba(104, 102, 102, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6px 4px;
  margin: auto;
}

.bc_pv_wrapp > div {
  width: 44%;
}

.bc_pv_wrapp > div > label {
  color: #ffffffb1;
  font-size: 10px;
  font-family: var(--font-300);
  line-height: 110%;
}

.bc_pv_wrapp > div > p:nth-child(2) {
  margin-bottom: 6px;
}

.bc_pv_wrapp > div > p {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-600);
  line-height: 110%;
}

.bc_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  width: 95%;
  border: 1px solid #343434;
  padding: 8px 12px;
  border-radius: 12px;
  margin: auto;
  margin-top: 0.5rem;
}

.bc_btn1 {
  color: #fff;
  font-size: 12px;
  font-family: var(--font-600);
}

.bc_btn2 {
  /* background-color: #000; */
  /* border: 1px solid #343434; */
  /* padding: 6px; */

  /* width: 30px; */
  /* height: 30px; */
  /* border-radius: 30px; */

  display: flex;
  align-items: center;
  transform: rotate(330deg);
  justify-content: center;
  /* position: absolute; */
  /* right: 6%; */
  /* top: 45%; */
}

.bc_btn2:hover {
  transform: rotate(0deg);
  transition: all 0.5s ease-in;
}

.bc_purchase_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  background-color: #289912;
  width: 100%;
  border: 1px solid #bce1b7;
  padding: 8px 12px;
  border-radius: 16px;
}

.bc_purchase_btn > p {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.bc_outofstock_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  background-color: #c11d0e;
  width: 100%;
  border: 1px solid #f7a582;
  padding: 8px 12px;
  border-radius: 16px;
}

.bc_outofstock_btn > p {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
}
