/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrappplsbis {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.nav_screen_nameplsbis {
  color: var(--color-white);
  font-family: var(--font-600) !important;
  font-size: 16px;
  padding-top: 10px;
  text-align: center;
  flex: 11;
  font-weight: bold;
  position: relative;
  right: 15px;
}

.pb_content_p {
  word-break: break-all;
  color: white;
  font-family: var(--font-600);
  font-size: 12px;
  text-align: start;
  word-break: keep-all;
}
.pb_content {
  text-align: justify;
}

.pb_price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pb_amount::placeholder {
  color: gray;
}

.pb_amount {
  border: none;
  margin: 1px;
  border-radius: 17px;
}

.pb_price_p {
  font-family: var(--font-400);
  font-size: 16px;
  color: white;
}

.pb_price_h1 {
  font-family: var(--font-600);
  font-size: 18px;
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}

.pb_input {
  display: flex;
  flex-direction: row;
  position: relative;
  /* margin-bottom: 10px; */
  background: red;
  border-radius: 18px;
  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
}

.pb_titel {
  font-family: var(--font-600) !important;
  font-size: 1rem;
  margin-bottom: 10px;
}

.next_2_new {
  width: 25px;
  right: 2%;
  margin-top: 3px;
  position: absolute;
}

.pb_details {
  border-radius: 25px;
  background-color: var(--color-main);
  padding: 2px;
  margin-bottom: 15px;
}

.pb_details2 {
  background-color: black;
  border-radius: 25px;
  box-shadow: #000 18px 0px 15px 0px;
  padding: 10px;
  position: relative;
}

.pb_details_sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.pb_details_img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}
.pb_details_h1 {
  font-size: var(--font-600);
  position: relative;
  font-size: 14px;
  margin-bottom: 6px;
}
.pb_sub_1 {
  /* width: 12%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pb_sub_2 {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pb_sub_3 {
  /* width: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pb_details_titel {
  font-family: var(--font-700);
  font-size: 10px;
}

.pb_details_date {
  font-family: var(--font-300);
  font-size: 8px;
}

.pb_details_price {
  font-family: var(--font-600);
  position: relative;
  font-size: 14px;
}

.pb_content_p_one {
  /* font-family: var(--font-700); */
  position: relative;
  /* right: 35%; */
  top: 15px;
  color: white;
  font-family: var(--font-600);
  font-size: 12px;
  text-align: start;
  word-break: keep-all;
}
.pb_content_two {
  font-family: var(--font-600);
  position: relative;
  /* right: 35%; */
  top: 20px;
  color: var(--color-main);
  font-size: 12px;
  text-align: start;
  word-break: keep-all;
}

/* page base design end */
