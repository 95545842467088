.input_otp_new_clubmail
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}
.clubmail_otp_input
{
    background-color: gray;
    width: 75px!important;
    height: 65px;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    color: white;
    font-size: 25px;
    text-align: center;
}