.new_proof_identity_btns_wrapp {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
  gap: 10px;
}

.new_proof_identity_btns_wrapp > div {
  width: 100%;
  padding: 20px;
  border: 1px solid transparent; /* Set initial border */
  border-image: linear-gradient(
      116.08deg,
      rgba(255, 255, 255, 0.59) 11.67%,
      rgba(255, 0, 76, 0) 44.03%
    )
    1;
  box-shadow: 0px 4px 14px 0px #9a92d24d inset;
}

.new_proof_identity_btns_wrapp > div > p {
  text-align: left;

  font-size: 14px;
  font-family: var(--font-400);
  font-weight: 400;
  color: var(--color-white);
  width: 44%;
  margin: 0px auto;
}
