.sample_vid_main_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
  position: relative;
  /* border: 1px solid red; */
}
.auth-video-contents-div {
}
.auth-video-text {
  width: 90%;
  margin: 30px auto auto auto;
  text-align: justify;
  font-size: 14px;
  font-family: var(--font-400);
  line-height: 125%;
}
.upload-progress {
  margin-top: 10px;
}
