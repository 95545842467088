body {
    background-color: black;
    font-family: Arial Rounded MT, Helvetica, sans-serif;
    color: white;
}

.Creatednfbs_bg {
    padding: 1rem;
}

.cn_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.imgback {
    width: 2rem;
}

.cn_product {
    padding-top: 1rem;
}

.cn_hr {
    border: 0.5px solid white;
}

.cn_pName {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.cn_p_tb {
    background: transparent;
    border-radius: 2rem;
    border: 1px solid rgb(145, 145, 145);
    height: 2rem;
    color: white;
    padding-left: 10px;
    width: 97%;
}
.cn_sc {
    margin-bottom: 1rem;
}
.cn_scroll {
    display: flex;
    overflow-x: scroll;
}
.cn_selection {
    border: 1px solid rgb(145, 145, 145);
    border-radius: 12px;
    padding: 5px;
    margin: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}
.selection_img {
    width: 50%;
}

.selection_txt {
    font-size: x-small;
}

.cn_doc {
    display: flex;
    align-items: center;
}

.cn_doc_txt {
    font-size: small;
    width: 100%;
}

.cn_doc_input {
    display: flex;
    gap: 15px;
}

.cn_doc_tb {
    background: transparent;
    border-radius: 2rem;
    border: 1px solid rgb(145, 145, 145);
    height: 2rem;
    color: white;
    width: 25%;
    text-align: center;
}

.cn_desc_one {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cn_desc_img {
    width: 5px;
}

.cn_desc_second {
    padding-top: 5px;
}

.cn_desc_txt {
    background: transparent;
    border-radius: 15px;
    border: 1px solid rgb(145, 145, 145);
    color: white;
    width: 94%;
    padding: 10px;
}

.cn_ge {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cn_ge_txt {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cn_radio {
    display: flex;
    gap: 1px;
}

input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 0.8px solid white;
    outline: none;
    transition: background-color 0.3s ease;
    position: relative;
    padding: 2px;
}

input[type="radio"]::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fffcfc;
    opacity: 0;
    transition: opacity 0.3s ease;
}

input[type="radio"]:checked::before {
    opacity: 1;
    background-color: rgb(255, 101, 127);
}

input[type="text"]:focus,
textarea {
    outline: none;
}

input[type="number"]:focus,
input {
    outline: none;
}

.custom_file_upload {
    display: inline-block;
    background-color: transparent;
    color: #555;
    border: 1px dashed rgb(145, 145, 145);
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    margin: 5px;
    height: 25px;
}

.custom_file_upload input[type="file"] {
    display: none;
}

.custom_file_upload span {
    margin-right: 8px;
}

.fu_img_icon {
    width: 15px;
    padding: 5px;
    padding-bottom: 1px;
}

.cn_value {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.min_width {
    width: 30%;
    border-radius: 20px;
}

/* upload */

.all_img_pre {
    /* display: inherit; */
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    /* overflow-x: scroll; */
    margin: 10px;
    /* border: 1px solid; */
}

.createclub_form_upload_img_sec_row {
    display: inline-flex;
    width: 50%;
    height: 50%;
    position: relative;
    right: 10px;
}

.createclub_form_upload_img_sec1 {
    width: 100%;
    /* border: 1px dashed; */
    border-radius: 10px;
    margin: 2px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.multipel_img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}