.view_candw_data_main_wrapp {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.view_candw_img_wrapp {
  width: 100%;
  height: 260px;
  border-radius: 8px;
  overflow: hidden;
}

.view_candw_img_wrapp > img {
  width: 100%;
  height: 100%;
}

.view_candw_reward_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600);
  font-weight: 350;

  width: 100%;
  text-wrap: wrap;
  /* width: 50%; */
  margin-bottom: 6px;
}

.view_candw_userdata_wrapp {
  width: 100%;
  margin: 1rem 0px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.view_candw_name_wrapp {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  margin: 1rem auto;
}

.view_candw_reward_valid_time {
  /* width: 40%; */
  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
  font-weight: 350;
  /* text-align: end; */
  text-wrap: wrap;
}

.view_candw_reward_valid_time > span {
  font-family: var(--font-600);
  color: var(--color-main);
}

.view_candw_addasset_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.view_candw_addasset_btn_wrapp > button {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 12px;
  font-weight: 400;
  margin: 6px 0px;

  padding: 10px 16px;
  border-radius: 20px;

  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );
  box-shadow: 8px 8px 15px 0px #14151d -6px -6px 15px 0px #303240;
}
