.asset_images_main_Wrapp {
  width: 100%;
}

.asset_img_req_single_Card {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-light-gray);
  display: flex;
  align-items: center;
  margin: 10px auto;
  border-radius: 8px;
  gap: 4%;
  height: 160px;
}

.asset_img_req_img_back {
  width: 38%;
  height: 100%;
  /* position: relative; */
  border-radius: 8px;

  overflow: hidden;
}

.asset_img_req_img_gradient-border {
  width: 99%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(
    to right,
    #00000000,
    rgba(218, 14, 65, 0.4),
    rgb(218, 14, 65, 0.6),
    rgb(218, 14, 65, 0.8),
    rgb(218, 14, 65)
  );
  border-image-slice: 1;
  border-radius: 8px;

  overflow: hidden;
}

.asset_img_req_img_gradient-border > img {
  width: 100%;
  height: 100%;
}

.asset_img_req_sec_section {
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.asset_img_req_sec_section_part1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
}

.asset_img_req_sec_section_part1 > img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.asset_img_req_sec_section_part1 > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: -webkit-fill-available;
}

.asset_img_req_sec_section_part1 > div > p {
  font-size: 14px;
  font-family: var(--font-400);
  color: #fff;
}

.asset_img_req_sec_section_part1 > div > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
}

.asset_img_req_sec_section_part1 > div > div > button {
  font-size: 12px;
  font-family: var(--font-400);
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: var(--color-main);
  width: 48%;
  padding: 4px 8px;
}

.asset_img_req_sec_section_part2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.asset_img_req_sec_section_part2 > p {
  font-size: 12px;
  font-family: var(--font-300);
  color: #fff;
}
