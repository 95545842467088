.recevied_products_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  margin: 5px auto;
}

.recevied_sigle_product_wrapp {
  width: 100%;
  height: 100%;
  max-height: 300px;
  /* padding: 10px; */
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.recevied_sigle_product_img {
  width: 100%;
  height: 100%;
  min-height: 240px;
}
.received__user {
  width: 100%;
  padding: 20px;
}

.user_receivednfb_search {
  height: 40px;
  padding: 15px;
}
.recevied_sigle_product_blur {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0px;
  backdrop-filter: blur(18px);
  /* background: url("../../assets/images/bg_au.png"); */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 14px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.recevied_sigle_product_name {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.recevied_sigle_product_text {
  font-size: 10px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.recevied_sigle_product_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 8px;
}

.recevied_sigle_product_btns_wrapp > button {
  font-size: 10px;
  font-family: var(--font-300);
  color: var(--color-white);
  /* border: 1px solid var(--color-white); */
  border-radius: 18px;
  padding: 6px 16px;
  /* background-color: red; */
}

.accept_reject_btn {
  color: white;
  font-family: var(--font-700);
  font-size: 10px;
  padding: 10px;
  border-radius: 5px; 
  position: relative;
}

.accept_reject_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 18px; 
  padding: 1px; 
  background:linear-gradient(100.74deg,rgba(202, 172, 255, 0.21) 0%,  #FF004C 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}