/* page base design start */

.page_spemain_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_spebase_wrapp {
  width: 90%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_spenavbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
/* page base design end */
.benefits_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4%;
}

.benefit_btn {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 10px;
  color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-700);
  margin-top: 20px;
  position: relative;
}
.benefit_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 18px; 
  padding: 1.5px; 
  background:linear-gradient(100.74deg,rgba(202, 172, 255, 0.21) 0%,  #FF004C 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}
.user_benefit_main_wrapp {
  margin: 30px auto;
  background: var(--color-dark-gray);
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 12px;
}

.user_benefit_heading {
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--font-700);
  margin-bottom: 10px;
}

.user_benefits_cards_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  margin-bottom: 10px;
  /* position: relative; */
}

.user_benefits_sigle_card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1.5px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.user_benefits_sigle_card {
  width: 100%;
  padding: 10px;
  background: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  gap: 10px;
  position: relative;
}

.user_benefits_card_img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.user_benefits_card_name {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  text-overflow: inherit;
  max-width: 60%;
}

.user_benefits_card_btn {
  padding: 8px 14px;
  background: #a5c8b2;
  color: var(--color-black);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 4px;
}

/* partner part start */
.partner_sub_slider {
  background-image: url(./../../assets/images/promote_new_be.png);
  background-size: 100% 100%;
  height: 350px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.partner_img_sub {
  width: 100px;
  height: 100px;
}

.partner_benefit_main_wrapp {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sub_part_1 {
  text-align: end;
}

.sub_part_2 {
  text-align: start;
}

.partner_benefit_side1_img {
  width: 80px;
  top: 60px;
  object-fit: contain;
  position: relative;
}

.partner_benefit_side2_img {
  width: 80px;
  object-fit: contain;
  bottom: 0;
  position: absolute;
  transform: rotate(275deg);
}

/* partner part end */

@media (min-width: 940px) {
  .user_benefits_cards_wrapp {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 18px;
  }
}

/* slider */
/* 31-08-2023 */
.slider-image_benefits {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-bottom: 15px;
}
.benefit_slider1 {
  border-radius: 25px;
  position: relative;
  height: 180px;
  width: 50%;
  margin-bottom: 10px;
  border: 1px solid white;
  background-color: rgba(73, 175, 73, 0.332);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.benefit_new_btn {
  background-color: rgba(121, 211, 121, 0.763);
  color: black;
  width: 100px;
  padding: 5px;
  font-size: 15px;
  border-radius: 5px;
  margin-top: 10px;
}
.benefit_slider {
  padding: 20px;
  width: 100%;
}
.benefit_font_nm {
  font-size: 10px;
  margin-top: 5px;
}
