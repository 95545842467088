.newassetcreator_request_cards_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;

  gap: 1rem;
}

.newassetcreator_request_card {
  width: 100%;
  padding: 14px 12px;
  border-radius: 6px;
  background: #ffffff14;
  box-shadow: 0px 1.96px 47.01px 0px #0000000a;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.newassetcreator_request_card_img_wrapp {
  width: 65px;
  height: 65px;
  border-radius: 65px;
  background: linear-gradient(140.71deg, #f5206a 15.3%, #d09ad6 89.21%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5px;
  overflow: hidden;
}

.newassetcreator_request_card_img_wrapp > img {
  width: 100%;
  height: 100%;
  border-radius: 65px;
}

.newassetcreator_request_card_name_wrapp {
  width: 46%;
  /* height: auto; */

  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-wrap: wrap;
}

.newassetcreator_request_card_name_wrapp > p {
  color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-400);
  font-weight: 600;
  line-height: 125%;
}

.newassetcreator_request_card_name_wrapp > span {
  color: #989ca1;
  font-size: 10px;
  font-family: var(--font-300);
  line-height: 125%;
  padding-top: 4px;
}

.newassetcreator_request_card_btn {
  width: 28%;

  color: var(--color-main);
  font-size: 14px;
  font-family: var(--font-600);
  line-height: 125%;
}
