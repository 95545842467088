.cw_leaderbord_main_wrapp {
  width: 90%;
  height: 100%;
  position: relative;
  min-height: 80vh;
  /* border: 1px solid red; */
  margin-top: 70px !important;
  margin: 1rem auto;
  background: linear-gradient(
    196.55deg,
    rgba(255, 0, 76, 0.24) 0%,
    rgba(12, 12, 12, 0) 98.53%
  );
  border: 1px solid;

  border-image-source: linear-gradient(
    204.49deg,
    rgba(12, 12, 12, 0.26) 58.69%,
    rgba(255, 255, 255, 0.0858) 98.91%
  );
}

.cw_leaderbord_head_wrapp {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  transform: translateY(-70px);
}

.cw_leaderbord_price_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  position: relative;
}

.cw_leaderbord_price_wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cw_leaderbord_star_wrapp1 {
  width: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  height: initial;
}

.cw_leaderbord_star_wrapp1 > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.cw_leaderbord_star_wrapp2 {
  width: 20%;
  display: flex;
  align-items: flex-start;
  justify-content: start;

  height: initial;
}

.cw_leaderbord_star_wrapp2 > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.cw_leaderbord_price_ineer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}

.cw_leaderbord_price_ineer > p {
  font-family: var(--font-600);
  font-size: 15px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: center;
  color: #ffb800;
}

.cw_leaderbord_price_ineer > h4 {
  font-family: var(--font-600);
  font-size: 24px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: center;
  color: var(--color-white);
}

/* my section start */

.cw_leaderbord_my_card {
  background: linear-gradient(
    87.97deg,
    rgba(255, 0, 76, 0.42) 1.91%,
    rgba(179, 105, 165, 0.42) 99.62%
  );
  width: 100%;
  padding: 10px 5%;
  min-height: 80px;
  scale: 1.2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: -1rem;
  margin-bottom: 2rem;
}

.cw_leaderbord_my_card > p {
  font-family: var(--font-600);
  font-size: 18px;
  font-weight: 350;
  line-height: 32.5px;
  text-align: center;
  color: var(--color-white);
}

.cw_leaderbord_my_card > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.cw_leaderbord_my_card > div > img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: top;
  border-radius: 60px;
}

.cw_leaderbord_my_card_name_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cw_leaderbord_my_card_name_wrapp > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  /* line-height: 32.5px; */
  text-align: center;
  color: var(--color-white);
}

.cw_leaderbord_my_card_name_wrapp > h5 {
  font-family: var(--font-600);
  font-size: 16px;
  font-weight: 350;
  text-align: center;
  color: var(--color-white);
}

/* my section end */

/* all list start */

.cw_leaderbord_simple_card {
  background: transparent;
  width: 100%;
  padding: 10px 5%;
  min-height: 60px;
  scale: 1.2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* border: 1px solid red; */

  margin: 1rem auto;

  border-bottom: 1px solid #ffffff26;
}

.cw_leaderbord_simple_card > p {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 32.5px;
  text-align: center;
  color: var(--color-white);
}

.cw_leaderbord_simple_card > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.cw_leaderbord_simple_card > div > img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: top;
  border-radius: 50px;
}

.cw_leaderbord_simple_card > div > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 32.5px;
  text-align: center;
  color: var(--color-white);
}

/* all list end */
