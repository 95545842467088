.home_main_wrapp {
  width: 100%;
  height: 100%;
  background: var(--color-black);
}

.home_base_wrapp {
  width: 90%;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}

/* first slider start */

.home_first_slider_wrapp {
  width: 100%;
  /* height: 350px; */
  margin-bottom: 50px;
  margin-top: 70px;
}

.home_first_slide_card {
  width: 100%;
  height: 300px;
  /* background: red; */
  /* border: 1px solid var(--color-main); */
  border-radius: 28px;
  overflow: hidden;
  /* position: relative; */
}

.home_first_slide_card > img,
.home_first_slide_card_img {
  width: 100% !important;
  min-width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  /* overflow: hidden; */
  object-fit: cover !important;
}

/* first slide card start */
.details_of_home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_user_img_details {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  left: 8px;
  top: 12px;
  gap: 8px;
}

.user_name_h1_home {
  font-size: 14px;
  /* position: relative; */
  text-shadow: #000;
  /* top: 3px; */
  font-family: var(--font-600) !important;
  margin: 0px;
}

.home_user_img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: cover;
}

.list_slider {
  background-image: url(../../assets/images/home_slider_shape.png);
  background-size: 100% 100%;
  position: absolute;
  top: -1%;
  height: 9%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_slider > p {
  font-size: 10px;
}

.img_sliders {
  width: 100%;
  /* object-fit: cover; */
  height: 300px;
  border-radius: 25px;
  z-index: -2;
}

.card_overly {
  background: rgba(0, 0, 0, 0.4);
  /* Black overlay with 0.8 opacity */
  position: absolute;
  width: 100%;
  height: 300px;
  border-radius: 25px;
  z-index: -1;
  /* You might need to adjust positioning and other properties */
}

.nfb-img-details_h {
  position: absolute;
  backdrop-filter: blur(25px);
  bottom: 15px;
  width: 90%;
  /* border: 1px solid white; */
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.nfb_home_1 {
  flex-direction: column;
  width: 45%;
  display: flex !important;
  padding-left: 15px;
  align-items: start !important;
  justify-content: center !important;
  /* border-right: 1px solid white; */
}

.nfb_home_line {
  width: 1px;
  height: 40px;
  background: var(--color-white);
}

.home_name_pro {
  position: absolute;
  font-family: var(--font-600) !important;
  top: 57%;
  font-size: 1.2rem;
  width: 90%;
  text-align: center;
}

.bid_home_name {
  font-family: var(--font-800) !important;
  font-size: 10px;
}

.nfb_home_2 {
  flex-direction: column;
  width: 45%;
  display: flex !important;
  align-items: start !important;
  justify-content: center !important;
}

.bid_home_amt {
  font-family: var(--font-600) !important;
  font-size: 14px !important;
}

/* first slide card end */

/* first slider end */

/* sec slider start */
.home_sec_slider_wrapp {
  width: 100%;
  height: 200px;
  /* margin-top: 20px; */
}

.home_sec_slide_card {
  width: 100%;
  height: 160px;
  /* border: 1px solid var(--color-main); */
  /* border-radius: 12px; */
  overflow: hidden;
  position: relative;
}

.home_sec_slide_card > img {
  width: 100%;
  height: 100%;
  /* border: 1px solid var(--color-main); */
  object-fit: fill;
  /* border-radius: 12px; */
}

.home_sec_card_blur_view {
  backdrop-filter: blur(20px);
  padding: 10px;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  bottom: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.home_sec_card_blur_view > p {
  font-size: 12px;
  font-family: var(--font-600) !important;
  margin-bottom: 4px;
}

.home_sec_card_blur_view_first_sec {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home_sec_card_blur_view_first_sec > span {
  font-size: 10px;
  margin: 0px;
  padding: 0px;
  font-family: var(--font-400) !important;
}

.home_sec_card_blur_view_ammount {
  font-size: 12px;
  margin: 0px;
  font-family: var(--font-800) !important;
}

/* sec slider end */

.home_viewmore_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  max-width: var(--max-width-md);
  margin: 10px auto;
}

.home_viewmore_wrapp > h4 {
  font-size: 14px;
  font-family: var(--font-600);
}

.home_viewmore_wrapp > a {
  font-size: 12px;
  font-family: var(--font-400);
  opacity: 0.6;
  cursor: pointer;
}

/* Auction btns start */
.home_auction_btn {
  margin: 20px auto;
  margin-bottom: 30px;
  width: 95%;
  max-width: var(--max-width-md);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 15px; */
}

.home_auction_btn a {
  border: 1px solid var(--color-main);
  padding: 10px 20px;
  color: var(--color-main);
  font-family: var(--font-600);
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 28px;
}

/* Auction btns end */

/* 15-09-2023 */
.home_select_set_new {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  /* padding: 20px; */
}

.home_select_set_new_sub1 {
  width: 14%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home_select_set_new_sub2 {
  width: 80%;
  display: flex;
  overflow-x: scroll;
  gap: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home_select_set_new_sub2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home_select_set_new_sub2 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* 15-09-2023 */

/* 16-09-2023 */
.aadharkyc {
  background-image: linear-gradient(to left, #ffdd0f, #e96c08);
  display: flex;
  gap: 10px;
  padding-left: 10px;
  border-radius: 5px;
  height: 50px;
  justify-items: start;
  align-items: center;
  margin-bottom: 10px;
}

.aadharkyc_alert {
  width: 25px;
}

.nfb_kyc_nm {
  font-family: var(--font-600);
  font-size: 10px;
}

/* 27-09-2023 */
.home_sec_slide_card2 {
  width: 100%;
  height: 160px;
  /* border: 1px solid var(--color-main); */
  /* border-radius: 12px; */
  overflow: hidden;
  position: relative;
}

.home_sec_slide_card_img {
  width: 100%;
  height: 100%;
}

/* all clubs css start */

.toclub_viewmore_wrapp {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 95%;
  max-width: var(--max-width-md);
  margin: 10px auto;
}

.details_top_club {
  position: absolute;
  bottom: 0px;
  backdrop-filter: blur(25px);
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_club_main {
  width: 50%;
  position: relative;
  height: 210px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.top_club_main2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 200px;
  gap: 10px;
}

.top_club_main2_sub {
  position: relative;
  height: 50%;
}

.top_club_img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top_club_img2 {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.home_total_bond_wrapp {
  width: 100%;
  /* padding: 10px; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  /* border: 1px solid var(--color-main); */
  margin-bottom: 10px;
}

.htb_single_sqare {
  /* width: 100%; */
  padding: 4px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 8px;
  /* border: 1px solid var(--color-main); */
  /* margin-bottom: 10px; */
  /* height: 100px; */
  /* gap: 4px; */
  margin-right: 10px;
}

.htb_single_sqare > span {
  color: #fff;
  opacity: 0.8;
  font-size: 12px;
  font-family: var(--font-400);
  padding: 0px;
}

.htb_single_sqare > p {
  color: var(--color-main);
  font-size: 16px;
  font-family: var(--font-700);
  word-wrap: break-word;
}

/* all clubs css end */

/* 27-09-2023 */
/* 27-09-2023 */
/* media quarys start */
@media (min-width: 768px) {
  .home_first_slide_card {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .home_sec_slide_card2,
  .home_sec_slide_card {
    max-width: 45vw;
  }
}

@media (min-width: 1281px) {
  .home_sec_slide_card {
    width: 100%;
    height: 188px;
  }
  .home_sec_slide_card2 {
    width: 100%;
    height: 188px;
  }
}
