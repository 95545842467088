.c_and_w_tabs_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 1rem auto;
}

/* pending bonds section start */

.c_and_w_pending_c_and_w_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.c_and_w_pending_c_and_w_card {
  width: 90%;
  padding: 10px;
  /* min-height: 150px; */
  border-radius: 12px;
  background: #ffffff21;
  border: 0.5px solid #5f5c58bf;
  margin: 10px auto;
}

.c_and_w_pending_c_and_w_card > img {
  width: 100%;
  border-radius: 12px;
  height: 180px;
  object-fit: cover;
  object-position: top;
}

.c_and_w_pending_c_and_w_card > p {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0px;
  border-bottom: 1px solid #fff;
  width: fit-content;
}

.c_and_w_pending_c_and_w_card > button {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 12px;
  font-weight: 400;
  margin: 6px 0px;

  padding: 10px 16px;
  border-radius: 20px;

  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );
  box-shadow: 8px 8px 15px 0px #14151d -6px -6px 15px 0px #303240;
}

/* pending bonds section end */
