.sponsors_carosel_wrapp {
  width: 100%;
  height: 100%;
  margin: 10px auto;
}

.sponsor_single_banner {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
}

.sponsor_single_banner > img {
  width: 100%;
  height: 100%;
}

.sponsors_products_wrapp {
  /* display: flex; Use flex display to enable horizontal scrolling */
  /* overflow-x: auto; Enable horizontal scrolling */
  /* white-space: nowrap; Prevent content from wrapping to new lines */
  /* width: 100%; */
  /* max-width: var(--max-width-md); */
  /* gap: 10px; */
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
  /* margin: 30px auto; */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  margin: 30px auto;
}

.sponsors_products_wrapp::-webkit-scrollbar {
  display: none;
}

.sponsors_single_products {
  min-height: 200px;
  max-height: 200px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  border-radius: 10px;
  text-overflow: ellipsis;
  border-radius: 10px;
  overflow: hidden;
}

.sponsors_single_products > img {
  width: 100%;
  height: 100%;
}

.sponsors_single_products > div {
  width: 82%;
  /* height: 28%; */
  /* background: var(--color-white); */
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  /* page-break-inside: auto; */
  padding: 10px;
  -webkit-backdrop-filter: blur(81px);
  backdrop-filter: blur(30px);
  position: relative;
  bottom: 50%;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
}

.sponsors_product_name {
  color: var(--color-white);
  font-size: 11px;
  font-family: var(--font-600);
  flex: 0.7;
  letter-spacing: 0.4px;
  overflow: auto;
}

.sponsors_product_name::-webkit-scrollbar {
  display: none;
}

.sponsors_product_view_btn {
  /* background-color: #da0e41; */
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  -webkit-mask: linear-gradient(#fff 40 0) content-box,
    linear-gradient(#fff 0 0);
  /* -webkit-mask-composite: xor; */
  border: none;
  font-size: 12px;
  font-family: var(--font-700);
  flex: 0.3 1;
  /* display: flex; */
  align-items: center;
  /* justify-content: flex-end; */
  gap: 10px;
  color: white;
  border-radius: 6px;
  text-align: center;
  width: 92px;
  height: 25px;
}

@media (min-width: 920px) {
  /* .sponsors_single_products {
    min-width: 32%;
    max-width: 32%;
    margin-bottom: 2%;
  } */

  .sponsors_products_wrapp {
    /* flex-wrap: wrap;
    justify-content: space-between;
    gap: 0px; */
  }
}
