.ep_image_main_wrapp {
  width: 150px;
  border-radius: 150px;
  height: 150px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, #310b16 0%, #28232f 100%);
  position: relative;
  margin: 10px auto;
}

.ep_image_logo {
  width: 50px;
  height: 60px;
  object-fit: contain;
}

.ep_image_edit_btn {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  position: absolute;
  bottom: -15px;
  right: -15px;
}

.ep_image_edit_btn > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ep_genral_txt {
  margin-top: 30px;
  width: 100%;
  text-align: left;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
}

.ep_main_wrapp_first_last {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.ep_main_wrapp_txt_first {
  width: 49%;
  border: 1px solid rgba(88, 88, 88, 1);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}

.ep_absolue_txt {
  position: absolute;
  top: -7px;
  left: 10%;
  /* background: #000; */
  /* width: 40%; */
  align-items: center;
  justify-content: center;
}

.ep_text_input_first_last {
  border: 0px;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
}

.ep_absolue_txt_secound {
  position: absolute;
  top: -6px;
  left: 10%;
  background: #000;
  /* width: 40%; */
  align-items: center;
  justify-content: center;
}

.ep_position_two_p {
  color: #ffffff;
  font-size: 10px;
  font-family: var(--font-600);
  font-weight: 350;
  background: #000;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.8;
}

.ep_main_wrapp_txt_username {
  width: 100%;
  border: 2px dashed var(--color-main);
  border-radius: 8px;
  margin: 1rem auto;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.ep_text_input_username {
  width: 80%;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  margin: 6px;
  height: 22px;
  border-radius: 8px;
  padding: 10px;
  border: none;
}

.ep_absolue_txt_username {
  position: absolute;
  top: -7px;
  left: 4%;
  align-items: center;
  justify-content: center;
}

.ep_mobile_flex_wrapp {
  width: 90%;
  display: flex;
  align-items: center;
  /* background: red; */
}

.ep_mobile_edit_img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 10px;
}

.ep_main_wrapp_txt_bio {
  width: 100%;
  border: 2px dashed var(--color-main);
  border-radius: 8px;
  margin: 1rem auto;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* align-items: center; */
  /* justify-content: space-between; */
  position: relative;
}

.ep_text_input_bio {
  width: 90%;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  margin: 0px 6px;
  height: 90%;
  border-radius: 8px;
  padding: 10px;
  border: none;
  align-self: flex-start;
}
.ep_uploded_link_wrapp {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin: 1rem auto;
}

.ep_uploded_link_div_one {
  width: 83%;
  border-bottom: 1px solid rgba(60, 60, 60, 1);
  padding-bottom: 10px;

  text-align: left;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  display: flex;
  word-break: break-all;
  text-wrap: wrap;
}
.ep_uploded_link_div_two {
  width: 10%;
  border-bottom: 1px solid rgba(60, 60, 60, 1);
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.uploaded_image_delete_btn {
  width: 20px;
  height: 20px;
  object-fit: contain;
  /* margin-right: 10px; */
}

.uploaded_link_p_tag {
  text-align: left;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  display: inline-block;
  width: 83%;
}

.ep_record_authentication_video_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  /* margin: 30px auto; */
  width: 100%;
  margin-top: 30px;
}

.ep_record_authentication_video_wrapp_2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem auto;
  gap: 10px;
}

.ep_record_authentication_video_div {
  width: 100%;
  height: 190px;
}
.ep_record_authentication_thumb_div {
  width: 100%;
  height: 190px;
  position: relative;
}
.ep_record_authentication_thumb {
  width: 100%;
  height: 190px;
  border-radius: 12px;
}

.ep_play_thumb_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* background: linear-gradient(180deg, rgba(7, 4, 9, 0.5) 0%, rgba(73, 73, 73, 0) 159.21%); */
  /* -webkit-filter: blur(5px); Safari 6.0 - 9.0 */
  /* filter: blur(0.4px); */

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); /* Adjust the shadow values as needed */
}

.ep_video_play_btn {
  object-fit: contain;
  width: 30px;
  height: 30px;
}

.ep_preview_btn_main_wrapp {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px dashed var(--color-main);
}

.ep_p_tag_preview {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
}

.view_managar_btn_wrapp {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  border: none;
  border-radius: 50px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px rgba(154, 146, 210, 0.3) inset,
    0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px rgba(154, 146, 210, 0.3) inset;
  cursor: pointer;
}
