.feed_screen {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: black;
    height: 100vh;
    overflow: scroll;
}

.feed_titel {
    padding: 1px;
}

h1 {
    font-size: 20px;
}

.feed_img {
    width: 100%;
    border-radius: 25px;
}

/* start feed_header */
.feed_header {
    display: flex;
    flex-direction: row;
    height: 65px;
    width: 100%;
    padding: 5px;
}

.feed_profile {
    width: 25%;
    text-align: center;
}

.feed_user_name {
    width: 75%;
}

.logo_feed {
    width: 50px;
    border: 1px solid #e51a4b;
    border-radius: 25px;
    padding: 5px;
}

/* end feed_header */
/* start feed_footer */
.feed_footer {
    display: flex;
    flex-direction: row;
    height: 65px;
    text-align: center;
    width: 100%;
    padding: 5px;
    bottom: 15px;
    left: 10px;
}

.feed_footer1 {
    width: 34%;
    display: flex;
    flex-direction: row;
}

.feed_footer2 {
    width: 33%;
    display: flex;
    flex-direction: row;
}

.feed_footer3 {
    width: 33%;
    display: flex;
    flex-direction: row;
}

.feed_b_img {
    width: 30px;
    height: 25px;
    margin: 10px;
}

.bid_feed {
    color: #e51a4b;
}

.feed_b_img_a {
    width: 30px;
    margin: 10px;
}

.feed_footer,
span {
    color: white;
    position: relative;
    padding-top: 12px;
}

/*end feed_footer */