.c_and_w_select_bond_wrapper {
  width: 100%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c_and_w_select_bond_wrapper > p {
  max-width: 50%;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  font-weight: 350;
  text-align: left;
}

.c_and_w_select_bond_wrapper > div {
  width: 48%;
  background: linear-gradient(
    91.93deg,
    #ff004c 2.57%,
    rgba(202, 172, 255, 0.21) 97.43%
  );
  padding: 1px;
  border-radius: 8px;
  height: 40px;
}

.c_and_w_select_bond_wrapper > div > input {
  width: 100%;
  height: 100%;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  font-weight: 350;
  background: var(--color-black);
  border-radius: 8px;
  border: none;
}

/* select box satrt */

.c_and_w_select_bond_wrapper > div > select {
  width: 100%;
  height: 100%;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  font-weight: 350;
  background: var(--color-black);
  border-radius: 8px;
  border: none;
}

.c_and_w_select_bond_wrapper > div > select > option {
  width: 100%;
  height: 100px;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  font-weight: 350;
  background: var(--color-black);
  border-radius: 8px;
  border: none;
}

/* select box end */
