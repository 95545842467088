/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mynfb_main {
  width: 100%;
  padding: 0px;
  margin-top: 8%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 5px; */
  justify-content: space-between;
}

.mynfbS_img {
  width: 35px;
}

.mynfb_header {
  width: 48%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  height: 100px;
  margin-bottom: 4%;
  padding-top: 13px;
  position: relative;
}
.mynfb_header::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px; 
  padding: 1.5px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

.h1_mynfbs_details {
  font-family: var(--font-400) !important;
  margin-top: 10px;
  font-size: 15px;
}

/* page base design end */
@media only screen and (max-width: 620px) {
  /* .mynfb_header {
    width: 45%;
    border: 1px solid var(--color-main);
    margin-bottom: 10px;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    height: fit-content;
  } */
}
