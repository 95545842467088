.nfbview_screen {
  display: flex !important;
  flex-direction: column !important;
  background-color: black !important;
  height: 100vh !important;
  overflow: scroll !important;
  /* padding: 20px; */
}

/* header  */

.nfbviews_header {
  padding: 20px;
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
}

.nfbviews_header1 {
  width: 25%;
}

.nfbviews_header2 {
  width: 75%;
  position: relative;
  right: 10%;
  text-align: center;
}

/* header  */
.nfb_img_all {
  width: 100%;
  padding: 20px;
}

/* .slick-slider {
    height: 305px;
} */

.nfb-img-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  bottom: 55px;
  position: relative;
}

.nfb_d_1 {
  width: 40%;
  padding-left: 10px;
}

.nfb_d_2 {
  width: 60%;
  padding-right: 2px;
}

/* nfbview.css */

/* Add this CSS to change the dot color to white */
.slick-dots li button:before {
  color: white;
}

.slick-dots li {
  width: 10px;
}

/* Add this CSS to change the active dot color to white */
.slick-dots li.slick-active button:before {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 25px;
  top: 6px;
  height: 8px;
  border-radius: 25px;
  padding: 0px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: white;
}

.slick-next,
.slick-prev {
  display: none !important;
}

.img_slider {
  width: 100%;
  object-fit: cover;
  height: 300px !important;
  border-radius: 25px;
}

.nfb_list_social {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* padding: 10px; */
  padding: 20px;
}

.nfb_list_social1 {
  width: 30%;
}

.nfb_list_social2 {
  width: 25%;
  /* border: 1px solid white; */
  grid: 52px;
  display: flex;
  flex-direction: row;
}

.nfb_list_social3 {
  width: 45%;
}

.nfb-like {
  margin-left: 5px;
  width: 50%;
  height: 40px;
  border: none;
  /* text-align: center; */
}

.img_like {
  width: 25px;
  height: 25px;
}

.bids-img {
  width: 50px;
  z-index: 999;
  height: 20px;
}

.nfb_bid_nm_btn {
  font-size: 15px;
}

.nfb_btn_bid {
  height: 45px;
  display: flex;
  flex-direction: row;
  width: 85%;
  border-radius: 20px;
  background-image: linear-gradient(to left, #ec1751, #f36f93, #f690ab);
  position: relative;
  left: 15px;
  padding-left: 10px;
  align-items: center;
}

.bid_details-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
}

.pro-story {
  padding: 20px;
  word-break: break-all;
}

.bid_details-list1 {
  width: 49%;
  height: 100px;
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-top: 25px;
  box-shadow: inset #ffffff75 0px 0px 100px 0px;
}

.bid_details-center {
  width: 2%;
}

.bid_details-list2 {
  /* background-color: #ffffff75; */
  box-shadow: inset #ffffff75 0px 0px 150px 0px;
  width: 49%;
  padding-top: 25px;
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.product_intro {
  width: 100%;
  padding: 20px;
  box-shadow: inset 0px 160px 0px 0px #ffffff75;
}

.product_intro {
  text-align: center;
}

.product_intro h1 {
  margin-bottom: 10px;
}

.video_bg {
  width: 100%;
  z-index: 99;
}

video {
  width: 100%;
  max-width: 600px;
  /* Adjust the max-width as needed */
}

.need_help {
  padding: 20px;
}

.need_help_1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-image: linear-gradient(to right, #fec85b, #f67155, #ed1b50);
  height: 50px;
  border-radius: 10px;
}

.needpart1 {
  width: 25%;
  padding: 2px 0px 0px 10px;
}

.need_helpimg {
  width: 45px;
}

.need_helpimg2 {
  width: 25px;
}

.needpart2 {
  padding: 13px 0px 0px 0px;
  width: 50%;
  /* padding-left: 15px; */
}

.needpart3 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
}

.creator {
  width: 100%;
  background-color: white;
  /* height: 420p x; */
}

.clipart {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.clipart_h1 {
  color: black;
}

.clipart-img {
  width: 50px;
}

.pro_nfb-img {
  width: 100%;
}

.product_cre {
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  height: 50px;
  text-align: center;
  bottom: 50px;
  position: relative;
  left: 25%;
}

.pro_cre_h1 {
  color: black;
}

.nfb_insta {
  background-color: black;
}

.img_insta {
  width: 100px;
  padding-top: 10px;
}

.img_insta_all {
  padding: 25px;
}

.media_all {
  width: 100px;
  height: 100px;
  margin-left: 10px;
  border-radius: 15px;
}

.list_of_nfb_pro {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.list_of_nfb_pro1 {
  width: 25%;
}

.list_of_nfb_pro2 {
  padding-top: 10px;
  width: 50%;
}

.list_of_nfb_pro3 {
  padding-top: 15px;
  width: 25%;
}

.nfb_pro_img {
  width: 50px;
  border-radius: 25px;
  height: 50px;
}

.bid_now_nfb_btn {
  padding: 20px;
}

.bid_now_nfb {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to right, #f36f93, #ec1751);
}

.video_play {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(245, 245, 220, 0.44);
  border-radius: 25px;
  bottom: 12%;
  position: relative;
  float: left;
  left: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.video_link {
  height: 100px;
}

.video_player {
  width: 100%;
  height: 360px;
  /* border: 1px solid white; */
}

.video_play1 {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(245, 245, 220, 0.44);
  border-radius: 25px;
  bottom: 12%;
  position: relative;
  float: left;
  left: 10px;
}

.bid_now_nfb {
  display: flex;
  align-items: center;
}

.bid_now_nfb h1 {
  margin-right: 10px;
  /* Adjust as needed */
}

.bid_now_nfb .bids-img {
  height: 20px;
  /* Adjust the height of the image as needed */
}

/* Tablet view */
@media (max-width: 900px) and (min-width: 750px) {
  /* 
        width: 75%;
        padding: 20px;
        align-items: center;
    } */
  /* .img_slider {
        width: 50%;
        object-fit: cover;
        height: 300px !important;
        border-radius: 25px;
    } */
  .nfb_img_all {
    /* width: 100%; */
    /* border: 1px solid #EC1751; */
  }
  .img_slider {
    width: 100%;
    object-fit: scale-down !important;
  }
}

/* Mobile view */
/* Web view */
@media (min-width: 1024px) {
}
