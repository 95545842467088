.club_single_product {
  width: 100%;
  height: 200px;
  /* border: 1px solid #fff; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.club_single_product > img {
  width: 100%;
  height: 100%;
}

.club_product_name_btn_wrapp {
  position: absolute;
  bottom: 0px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px;
  background: rgba(0, 0, 0, 0.4);
}

.club_product_name_btn_wrapp > p {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
}

.club_product_name_btn_wrapp > a {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  background: var(--color-main);
  width: 100%;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}
