.NewLoader_main_wrapp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 222;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent white background */
}

.NewLoader_glass_container {
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px); /* Apply blur effect to the background */
  border-radius: 10px; /* Add border radius for rounded corners */
  padding: 20px; /* Add padding for content */
  box-shadow: 0 6px 90px 0 rgba(31, 38, 135, 0.57);
  backdrop-filter: blur(4px); /* Add blur effect to shadow */
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewLoader_img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  /* animation: loading 1.5s ease-in-out infinite; */
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(-360deg);
  }
}
