.card_main_home {
  border: 1px solid white;
  width: 100%;
  height: 65%;
}

.collection-imgs {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.card_main_home_details {
  position: relative;
  bottom: 32%;
  text-align: left;
  backdrop-filter: blur(10px);
}

.creator-heading {
  font-family: var(--font-600) !important;
  font-size: 12px;
  position: relative;
  left: 5px;
}

.collection-imgs {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.f-card {
  width: 100vw;
  position: relative;
  padding: 20px;
  height: 27vh;
}
.card_main_home {
  position: relative;
  right: 10px;
}
@media only screen and (max-width: 850px) {
}

@media only screen and (max-width: 620px) {
  .collection-imgs {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
}
