.bids_mains {
  display: flex;
  /* flex-direction: row; */
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}

.bids_2 {
  width: 90%;
  max-width: var(--max-width-sm);
  margin: 10px auto;
}

.bidss_user_menu {
  left: 15px;
  position: relative;
}

.bids_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.bids_main_header_notifis {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
}

.bidsmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.bids_main_h1 {
  position: relative;
  right: 25px;
}

.bid_img_details {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bids_club_user {
  width: 100%;
  /* padding: 15px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.bids_club_user_main {
  width: 100%;
  height: 50%;
  position: relative;
  border-radius: 25px;
}

.bid_details_sub1 {
  background-color: #ffffffda;
  border-radius: 8px;
  bottom: 22%;
  position: absolute;
  padding: 15px;
  width: 100%;
}

.bids_club_user_details {
  max-width: 350px;
  width: 100%;
  height: 280px;
  border-radius: 10px;
  border: 1px solid var(--color-main);
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.sub2_button_a {
  background-color: var(--color-main);
  color: white;
  font-family: var(--font-700);
  font-size: 13px;
  padding: 10px;
  border-radius: 10px;
  width: 48%;
}

.bids_h1 {
  font-family: var(--font-700) !important;
  position: relative;
  color: #000;
  font-size: 15px;
}

.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

.bidsmain1 {
  width: 20%;
}

.user_bid_main {
  width: 100%;
  /* padding: 15px; */
}

.user_bid_search {
  height: 40px;
  padding: 15px;
}

.user_bid_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

.bid_details_sub2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  position: absolute;
  padding: 8px;
  bottom: 2px;
}

/* modal bids */
.modal_bids {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  min-width: 240px;
  border: 1px solid #ee1651;
  max-width: var(--max-width-sm);
}

.modal_bids2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 60%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  max-width: var(--max-width-sm);
}

.modal_2_p {
  color: #000;
  margin-bottom: 20px;
  font-family: var(--font-800);
}

.modal_2_a {
  font-family: var(--font-600);
  color: #000;
  line-height: 30px;
  margin-bottom: 10px;
}

.nm_bid_modal {
  left: 0px !important;
}

.close_tag {
  margin: 0;
  font-size: 25px;
  font-family: var(--font-700);
  color: #ee1651;
  left: 0px !important;
}

.bids_close_modal {
  height: 40px;
  width: 75%;
  border-radius: 10px;
  border: 1px solid #ee1651 !important;
}

.bids_close_modal_2 {
  width: 20%;
}

.bids_close_modal_3 {
  width: 20%;
}

#bids_inpput_tag::placeholder {
  color: gray;
}

#bids_inpput_tag {
  border: 1px solid gray;
  box-shadow: inset 0px 0px 8px 0px rgb(213, 209, 209);
  border-radius: 25px;
  height: 40px;
  font-size: 18px;
  margin-top: 10px;
  background-color: white;
  color: gray !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.error-message_bids {
  color: #ee1651;
}

.bids_close_modal {
  color: white;
  font-family: var(--font-700);
}

.bids_btn_new {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  position: absolute;
  padding: 8px;
  bottom: 2px;
  align-items: center;
  justify-content: center;
}

/* modal bids */

/* @media only screen and (max-width: 920px) {
  .modal_bids {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    padding: 10px;
    border-radius: 10px !important;
    width: 90%;
    border: 1px solid #ee1651;
  }
} */
/* 
@media only screen and (max-width: 600px) {
  .modal_bids {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    width: 83%;
    border: 1px solid #ee1651;
    width: 90vw;
  }

  .modal_bids2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
} */
