.Termsandcondition-container {
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}

.Termsandcondition_1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Termsandcondition_2 {
  width: 90%;
  margin: 10px auto;
  max-width: var(--max-width-sm);
  height: 100%;
}

.Termsandcondition_header {
  position: sticky;
  top: 0;
  height: 60px;
  flex-direction: column;
  background-color: #000;
  /* Set your desired background color */
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  /* Ensure the header is on top of notifi_details */
  /* Add any other styles for the header here */
}

.main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div_main1 {

  width: 20%;
}

.div_main2 {

  width: 80%;
  display: flex;
  justify-content: center;
}

.main_h1 {
  position: relative;
  right: 25px;
}

.general_user {
  width: 100%;
}

.user_Termsandcondition_search {
  height: 40px;
  padding: 15px;
}

.user_Termsandcondition_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}


.notifi_details {
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px;
}

.Termsandcondition_img {
  width: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img_Termsandcondition {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid white;
}

.Termsandcondition_msg {
  color: gray;
}

.Termsandcondition_nm {
  width: 80%;
}

.paragraph {
  font-size: 16px;
  font-family: "Gr_light";
  color: #f5f5f5;
  opacity: 0.7;
  margin-bottom: 10px;
  font-family: var(--font-800);
  font-weight: 200;
  margin-bottom: 20px;
  left: 1%;
    width: 98%;
  position: relative;
}

.Termsandcondition_details {
  word-wrap: break-word;
  width: 100%;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  text-align: justify;
}

@media only screen and (max-width: 850px) {
  /* .web_screen_Termsandcondition {
      width: 100%;
      border: none;
      padding: 0px;
  }
  .Termsandcondition_1 {
      width: 55%;
  }

  .Termsandcondition_2 {
      width: 45%;
  } */
}

@media only screen and (max-width: 600px) {
  .Termsandcondition_1 {
      display: none;
  }

  .Termsandconditions_user_menu {
      left: 15px;
      position: relative;
  }

  .Termsandcondition_2 {
      width: 90%;
      display: block;
      flex-direction: column;
      align-items: start;
      justify-content: center;

  }

  .Termsandcondition_header {
      position: sticky;
      top: 0;
      height: 60px;
      flex-direction: column;
      background-color: #000;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
  }

  .main_header_notifi {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }

  .div_main1 {
      width: 20%;
  }

  .div_main2 {
      width: 80%;
      display: flex;
      justify-content: center;
  }

  .main_h1 {
      position: relative;
      right: 25px;
  }

  .paragraph {
      font-size: 16px;
      font-family: "Gr_light";
      color: #f5f5f5;
      opacity: 0.7;
      margin-bottom: 10px;
      font-family: var(--font-800);
      font-weight: 200;
  }

  .Termsandcondition_details {
      word-wrap: break-word;
      width: 100%;
      
  }
}