.accounts_main_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px auto;
  /* position: relative; */
}



.accounts_single_card {
  /* border: 1px solid var(--color-main); */
  margin: 10px auto;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
position: relative;}

.accounts_single_card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px; 
  padding: 1px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

.account_bank_heading {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
  margin-bottom: 10px;
}

.account_bank_des {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-300);
  margin-bottom: 2px;
}

.account_primary_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.account_btn_wrapp {
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.account_btn_wrapp > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
