.Purchase_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 12px;
}
.Purchase_btn_set1 {
  width: 75%;
}
.Purchase_btn_set2 {
  width: 25%;
}
.show_second_img1 {
  width: 20px;
  float: right;
  position: relative;
  right: 10px;
}

/* 25-09-2023 */

.purchesh_new3 {
  background-image: url(./../../assets/images/bluecard_front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: relative;
}
.purchesh_new3_img {
  z-index: -1;
  border-radius: 10px;
  position: relative;
  width: 87%;
  object-fit: cover;
  height: 49%;
  top: 3%;
  left: 19px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.purchase_front_details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 15px;
  height: 40%;
}
.purchesh_new_front-del {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}
.showcard_nm_set {
  font-size: 22px;
  letter-spacing: 4px;
  font-family: var(--font-400);
}

.show3_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardthree {
  font-size: 15px;
  font-family: var(--font-700);
  /* font-weight: 900; */
  letter-spacing: 10px;
  padding-top: 5px;
}

.purchase_del_3 {
  display: flex;
  padding-top: 25px;
  width: 80%;
}

.purchase_del_3_sub1 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.purchase_del_3_sub2 {
  width: 30%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-3-date {
  font-weight: var(--font-700);
  letter-spacing: 8px;
  font-size: 12px;
}

.s-card-3-price {
  font-weight: var(--font-600);
  font-size: 20px;
}

.s-d-3card_qr {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* 25-09-2023 */
