.bp_img_wrapp {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bp_img_wrapp > img {
  width: 95%;
  height: 95%;
  object-fit: contain;
  border-radius: 8px;
  overflow: hidden;
}

.bp_details_wrapp {
  text-align: start;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.bp_details_wrapp > h3 {
  color: #000;
  font-family: var(--font-600);
  font-size: 16px;
  align-self: start;
}

.bp_details_wrapp > p {
  color: #000;
  font-family: var(--font-300);
  font-size: 12px;
  align-self: start;
}

.bp_sizes_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin: 10px auto;
  width: 100%;
}

.bp_single_size {
  padding: 10px;
  background: #ddd;
  color: #000;
  font-size: 12px;
  font-family: var(--font-300);
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.bp_total_wrapp {
  width: 100%;
  /* -webkit-box-shadow: 1px -7px 6px -4px rgba(48, 48, 48, 0.34);
  -moz-box-shadow: 1px -7px 6px -4px rgba(48, 48, 48, 0.34);
  box-shadow: 1px -7px 6px -4px rgba(48, 48, 48, 0.34); */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  padding: 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: #fff;
}

.bp_total_wrapp_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  max-width: var(--max-width-sm);
  width: 90%;
}

.bp_total_wrapp_inner > p {
  color: #000;
  font-size: 16px;
  font-family: var(--font-600);
}

.bp_total_wrapp_inner > button {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-600);
  background: #545454;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.bp_total_wrapp_inner > button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn_qty_active:active {
  color: #fff;
  background: #545454;
}
