/* Style for the container of the words */
.words-display-container {
    display: flex;
    justify-content: center; /* Center the words */
    align-items: center; /* Center the words vertically */
    margin-bottom: 20px; /* Add margin below the container */
    gap: 10px;
  }
  
  /* Style for each word */
  .first_screen_word {
    flex: 1;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: gray;
    margin: 0;
    /* text-transform: uppercase; */
    font-family: var(--font-400);
    /* width: 94px; */
  }
  
  /* Style for the highlighted word */
  .first_screen_word.first_screen_highlighted {
    font-weight: 600;
    color: #da0e41;
    text-transform: uppercase;
    font-size: 18px;
    width: 100px;
    font-family: var(--font-600);
  }