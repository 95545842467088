.back_arrow_wrapp_bank {
  width: 100%;
  margin: 1rem auto;
  padding: 0px 5%;
  padding-top: 25px;
  margin-bottom: 0px;
}
.panbank_single_labal_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.panbank_single_labal_wrapp > p {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
}
.otp_screen_text_container_bank {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0px 5%;
  margin: 1rem auto;
}
.otp_screen_text_container_bank > h4 {
  font-size: 18px;
  color: var(--color-white);
  font-family: var(--font-600);
  line-height: 150%;
  align-self: flex-start;
  margin: 10px 0px;
}
.otp_screen_text_container_bank > p {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 180%;
  align-self: flex-start;
  margin: 24px 0px;
}

.panbank_single_border_wrappdot {
  position: relative; /* To position the arrow icon */
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  outline: none;
  width: 100%;
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: bottom left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panbank_single_border_wrappdot > select {
  font-size: 12px;
  color: white;
  font-family: var(--font-600);
  width: calc(100% - 20px); /* Adjust width to accommodate the arrow icon */
  border: none;
  border-radius: 0px;
  margin: 0px;
  background: none;
  padding: 0.8rem 0px;
  caret-color: var(--color-main);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 1;
}
.panbank_single_border_wrappdot > img {
  position: absolute;
  right: 16px; /* Adjust as needed */
  width: 20px; /* Adjust width of arrow icon */
  height: auto; /* Maintain aspect ratio */
  z-index: 0;
}
.panbank_single_border_wrapp {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  outline: none;
  width: 100%;
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: bottom left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  position: relative;
}
.in_line1 {
  width: 2px;
  height: 21px;
  background: #ff004c80;
  position: absolute;
  left: 12px;
}

/* border: 2px solid #FF004C80 */
.panbank_single_border_wrapp > input {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  width: 70%;
  border: none;
  border-radius: 0px;
  margin: 0px;
  background: none;
  padding: 1rem 0px;
  caret-color: var(--color-main);
  padding-left: 25px;
}
.bank {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
  margin: 36px 0px;
}
.pan_img_in {
  width: 25px;
  height: 25px;
}
.panbank_single_input_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 10px; */
  width: 100%;
  margin: 10px auto;
  margin-bottom: 36px;
}
.bank_ckeck {
  display: flex;
  width: 100%;
  gap: 15px;
  position: relative;
  /* right: 15px; */
  left: 4px;
  /* top: 20px; */
  bottom: 31px;
}
.bank_ckeck > img {
  width: 20px;
  height: 20px;
}
.bank_ckeck > p {
  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
}
