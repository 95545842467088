.otp_screen_text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 0px 5%;
  margin: 1rem auto;
}

.otp_screen_text_container > h4 {
  font-size: 22px;
  color: var(--color-white);
  font-family: var(--font-600);
  line-height: 150%;
  align-self: flex-start;
  margin: 10px 0px;
}

.otp_screen_text_container > p {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 180%;
  align-self: flex-start;
  margin: 10px 0px;
}

.otp_screen_text_container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 1rem auto;
}

.otp_screen_text_container2 > h4 {
  font-size: 22px;
  color: var(--color-white);
  font-family: var(--font-600);
  line-height: 150%;
  align-self: flex-start;
  margin: 10px 0px;
}

.otp_screen_text_container2 > p {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 180%;
  align-self: flex-start;
  margin: 10px 0px;
}

.new_otp_input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5%;
}

.new_otp_input_1st_sec {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  /* padding: 8px; Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 35%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */
}

.new_otp_input_2nd_sec {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  padding: 10px; /* Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 60%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */
}

.new_otp_input_2nd_sec > input {
  border: none;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  font-family: var(--font-600);
  font-weight: 350;
  background-color: transparent;
  color: var(--color-white);
  border-radius: 0px;
}

.new_otp_input_2nd_sec > input:-webkit-autofill {
  background-color: transparent;
  color: var(--color-white);
}

/* CustomDropdown.css */
.new_otp_custom_dropdown {
  position: relative;
  display: inline-block;
  /* border: 1px solid red; */
  background: none;
  display: flex;
  align-items: center;
}

.new_otp_selected_option {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 2px solid transparent; /* Hide default bottom border */
  cursor: pointer;
}

.new_otp_selected_option:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.country-logo {
  width: 28px;
  height: 28px;
  /* margin-right: 8px; */
  object-fit: contain;
}

.new_otp_options {
  position: absolute;
  top: 100%;
  left: 0;
  /* width: 100%; */
  background-color: #858181;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 11;
  height: 157px;
  width: 115px;
  overflow: scroll;
}

.new_otp_contry_option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.new_otp_contry_option:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.new_otp_down_aroow {
  width: 12px;
  height: 12px;
  object-fit: contain;
}

.new_otp_vr {
  width: 1.4px;
  background: var(--color-white);
  height: 20px;
  margin: 0px 6px;
}

.new_otp_mobile_code {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.contry_search_input {
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: var(--color-white);
  font-family: var(--font-400);
}

/* login modal start */

.play_screen_login_modal_wrapp {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(29, 27, 27, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 11;
}

.play_screen_login_inner_wrapp {
  width: 90%;
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.76) 100%
  );
  border-radius: 12px;
  padding: 2px;
  overflow: hidden;
  max-width: var(--max-width-sm);
}

.play_screen_login_inner_2 {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  border-radius: 12px;
  padding: 10px 10%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  text-align: center;
}

.play_screen_login_heading {
  width: 100%;

  font-size: 18px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.play_screen_login_hr {
  width: 100%;
  height: 1px;
  background: #5d5d5d;
  margin: 14px auto;
}

.play_screen_login_hr2 {
  width: 60%;
  height: 1px;
  background: #5d5d5d;
  margin: 10px auto;
  position: relative;
}

.play_screen_login_hr2 > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
}

.play_screen_login_btn {
  width: 100%;

  font-size: 16px;
  font-family: var(--font-400);
  color: var(--color-white);
  background: none;
  border: none;
  margin: 10px auto;
  text-align: center;
}

.play_screen_login_btn:hover {
  color: var(--color-main);
  /* font-family: var(--font-600); */
  transition: ease all 0.5s;
}

/* login modal end */
