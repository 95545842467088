.benefit_category_sub_club {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%!important;
    height: 50px;
    border: 1px solid;
    border-radius: 10px;
    padding: 8px;
    gap: 2px;
}

.benefit_category_sub_club_validation {
    border: 1px solid var(--color-alt);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding: 8px;
    gap: 2px;
}

.benefit_category_club {

    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    gap: 15px;
    margin-top: 15px;
    overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.benefit_category_club::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .benefit_category_club {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }