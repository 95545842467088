.instagram-skeleton {
  width: 100%;
  max-width: 600px; /* Adjust the max-width as needed */
  margin: 0 auto;
  padding: 20px;
  background-color: #f6f8fa; /* Background color for the skeleton loading */
  border-radius: 8px;
}

.header {
  height: 60px; /* Height of the header skeleton */
  background-color: #ddd; /* Background color for header skeleton */
  margin-bottom: 20px;
}

.post {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  width: 50px; /* Size of the avatar skeleton */
  height: 50px; /* Size of the avatar skeleton */
  border-radius: 50%; /* Rounded shape for the avatar skeleton */
  background-color: #ddd; /* Background color for the avatar skeleton */
  margin-right: 15px;
}

.post-content {
  flex: 1;
  height: 100px; /* Height of the post content skeleton */
  background-color: #ddd; /* Background color for post content skeleton */
  border-radius: 8px;
}
