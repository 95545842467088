.verify_email_logo {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.verify_email_logo_wrapp {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  gap: 10px;
}

.verify_email_logo_wrapp > p {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.verify_email_input {
  padding: 0px 10px;
  border-radius: 0px;
}
