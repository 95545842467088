.main_milestone {
  max-width: 430px;
  margin: auto;
}
.myproduct_13_title_section_mile {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-top: 37px;
}
.myproduct_13_back_btn_mile {
  height: 16px;
  width: 8px;
  position: absolute;
  top: 7px;
  left: 22px;
}
.myproduct_13_nav_title_mile {
  font-size: 12px;
  font-family: var(--font-300);
  margin-top: 8px;
}
.cardd {
  margin-top: 32px;
}
.cardback {
  width: 90%;
  height: 270px;
  border-radius: 18px;
  background: #ffffff0f;
  /* border: 1px solid rgb(189, 185, 185); */
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  position: relative;
}
/* .box {
  position: relative;
} */
.cardback::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 18px;
  padding: 1px;
  /* border: 0.47px solid; */

  background: linear-gradient(
    134.04deg,
    #ffffff 2.44%,
    rgba(255, 255, 255, 0) 80.23%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.cardback > img {
  width: 90%;
  height: 204px;
  border-radius: 13px;
  margin-top: 15px;
}
.titlecard {
  font-size: 17px;
  font-weight: 600;
  line-height: 30.55px;
  margin-top: 8px;
}
.under {
  width: 61px;
  height: 1.8px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
}
.para1 {
  font-size: 16px;
  color: white;
  line-height: 30px;
  font-weight: 200;
  width: 90%;
  text-align: justify;
  margin: auto;
  font-family: var(--font-300);
  margin-top: 15px;
  margin-bottom: 45px;
}
.slidetitle {
  font-family: var(--font-600);
  color: var(--color-main);
  text-align: center;
  font-size: 16px;
}

/* slider start */
.slider {
  max-width: 1110px;
  margin: 0 auto;
}

.mile-slider .slick-track {
  padding-top: 53px;
  padding-bottom: 51px;
  /* margin-left: -19px; */
  gap: 23px;
}

.mile-slider .slick-slide {
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.mile-slider .slick-slide:not(.slick-current) {
  filter: brightness(1);
  transform: rotateY(45deg);
}

.mile-slider .slick-slide.slick-current {
  transform: scale(1.35);
  position: relative;
  z-index: 1;
  margin-right: 0;
  width: 222px !important;
}

.mile-slider .slick-slide img {
  width: 100%;
  height: 138px;
  border-radius: 18px;
  /* object-fit: cover; */
}
.mile-slider .slick-slide.slick-center {
  filter: brightness(1);
  margin-right: 0;
  scale: 1.1;
}

.milestoneslide .activeSlide {
  width: 100%;
  margin-left: -16%;
  margin-top: 2px;
  display: inline-block;
  scale: 1.2;
}
/* slider end */
.asset_main {
  width: 90%;
  margin: auto;
}
.asset_title {
  display: flex;
  font-size: 15px;
  font-family: var(--font-400);
  margin-bottom: 14px;
}
.asset_title_left {
  width: 50%;
}
.asset_title_right {
  width: 50%;
  text-align: end;
  color: #ff004c;
}
.asst_content {
  display: flex;
  gap: 7%;
}
.content1 {
  /* margin-right: 6%; */
  width: 100px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 18px;
  position: relative;
}

.content1 > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.content1 > p {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
  text-wrap: wrap;
}
.square {
  position: relative;
  /* height: 110px; */
}
.square > img {
  height: 110px;
}
.square > p {
  position: absolute;
  top: 45px;
  font-size: 10px;
  margin-left: 16%;
  color: var(--color-main);
  font-family: var(--font-600);
}
.btndiv {
  margin-top: 37px;
  margin-bottom: 37px;
  text-align: center;
}

.btnmile {
  width: 90%;
  height: 67px;
  border-radius: 41px;
  box-shadow: inset #ff004cc2 0px 1px 12px 0px, inset #9a92d24d 0px 4px 14px 0px,
    inset #caacff73 0px 39px 99px -49px;
  color: white;
  /* margin-top: 35px; */
  margin: auto;
  font-family: var(--font-400);
}

.asst_content_bonds_wrapp {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 70%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
