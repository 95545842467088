/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.report_div {
  padding-top: 10px;
}

.report_title {
  color: var(--color-main);
  /* margin-top: 10%; */
  position: relative;
}

.report_details {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
  line-height: 30px;
}

.report_details_span {
  position: relative;
  padding-top: 0px;
  left: 15px;
  padding-bottom: 10px;
  font-family: var(--font-600);
  font-weight: 350;
}
.report_btn_new {
  background-color: var(--color-main);
  padding: 10px;
  width: 60%;
  color: white;
  font-family: var(--font-600);
  border-radius: 25px;
  font-weight: bold;
  margin-top: 10%;
}

.show_text_report {
  position: relative;
}
.show_text_report::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 1.5px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}
