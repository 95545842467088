.benefit_category {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  gap: 15px;
  margin-top: 15px;
  overflow-x: scroll;
}

.nm_category_new {
  font-size: 10px;
}
.benefit_nm_new {
  margin-top: 15px;
}

.benefit_category_sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 50px;
  border: 1px solid;
  border-radius: 10px;
  padding: 8px;
  gap: 2px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.benefit_category_sub_img {
  width: 20px;
}

.benefit_contact {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.benefit_contact_sub {
  display: flex;
  width: 50%;
  gap: 15px;
}

.file_benefit_details {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.file_benefit_details_titel {
  font-family: var(--font-700);
}

.benefit_input_2 {
  display: none;
}

.upload_tag_benefit {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed;
  border-radius: 10px;
  height: 100px;
  width: 150px;
}

.benefit_preview {
  width: 152px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid gray;
}
.benefit_preview2 {
  width: 150px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid gray;
}

.custom-file-input {
  width: 40%;
}

.benefit_btn_proceed {
  background-color: var(--color-main);
  color: white;
  font-size: 16px;
  font-family: var(--font-600);
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 25px;
  margin-top: 4%;
}

/* modal_p */
/* AddContactmodal_p.css */

.modal_p-overlay {
  position: fixed;
  padding-top: 15px;
  top: -28px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 11;
}

.modal-close_p {
  width: 30px;
  object-fit: contain;
}

.modal_p-content {
  background-color: black;
  /* margin-top: 10%; */
  border-radius: 8px;
  padding: 20px;
  width: 100vw;
  height: 100vh;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal_p-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal_p-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.modal_p-body {
  /* Add your modal_p body styles here */
}

.add_contact_name {
  width: 100%;
  border-bottom: 1px solid white;
  padding: 10px;
}

.list_of_contact_nm {
  text-align: left;
}

.add_img_nm {
  text-align: center;
  font-size: 12px;
  padding-top: 0px;
  cursor: pointer;
}
.file-preview {
  /* width: 50%; */
  display: flex;
  gap: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.preview-item {
  position: relative;
}
.close-icon1 {
  color: #e51a4b;
  font-size: 12px;
  cursor: pointer;
  width: 18px;
  text-align: center;
  height: 18px;
  position: absolute;
  right: 5px;
  /* font-weight: 900; */
  font-family: var(--font-700);

  padding-top: 0px;
  background: white;
  border-radius: 50px;
  z-index: 9999;
  object-fit: cover;
}
.benefit_category_sub_validation {
  border: 1px solid var(--color-alt);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 50px;
  border-radius: 10px;
  padding: 8px;
  gap: 2px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cb_add_manager_btn {
  /* width: 100%; */
  padding: 10px 18px;
  border-radius: 4px;
  color: #fff;
  font-family: var(--font-600);
  font-size: 14px;
  background-color: var(--color-main);
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

.benefit_select_person_input > input {
  box-shadow: inset #ff004c5c 7px 4px 20px 0px,
    inset #caacff21 -8px -4px 23px 0px;
  border: none;
  height: 45px;
  padding: 15px;
  border-radius: 33px;
}
