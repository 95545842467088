.exclusiveclubnfb_main {
    position: relative;
}

.exclusiveclubnfb_img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.exclusiveclubnfb_nm {
    position: absolute;
    /* background-color: rgba(177, 177, 177, 0.73); */
    backdrop-filter: blur(8px);
    bottom: 0px;
    height: 50px!important;
    width: 100%;
    color: white;
    border-radius: 5px;
    font-family: var(--font-400);
    font-weight: 700;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exclusiveclubnfb_number {
    color: white;
    font-family: var(--font-400);
    font-weight: 700 !important;
    font-size: 15px;
}

.exclusiveclubnfb_main_details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    margin: 30px auto;
}