/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.edit_mobile_no::placeholder {
  color: gray;
}

.edit_btn_mb_new {
  position: absolute;
  margin-top: 1%;
  color: white;
  font-family: var(--font-700);
  background-color: var(--color-main);
  padding: 15px;
  width: 40%;
  border-radius: 25px;
}
@media screen and (max-width: 620px) {
  .edit_btn_mb_new {
    position: absolute;
    bottom: 15px;
    text-align: center;
    color: white;
    font-family: var(--font-700);
    background-color: var(--color-main);
    padding: 15px;
    width: 80%;
    border-radius: 25px;
  }
}
