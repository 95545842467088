.homesearch_new {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.homesearch_2 {
  padding: 10px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

.all_bond,
.tab-container {
  /* text-align: center; */
  padding-top: 5px;
}

.search_home_main {
  text-align: start;
}

.search_home_main_2 {
  display: flex;
  align-items: center;
}
.search_home_main_3 {
  display: flex;
  flex-direction: column;
}
.user_homesearch_search {
  height: 40px;
  padding: 15px;
  background-color: #4f4f4f;
}

.user_homesearch_search::placeholder {
  color: white;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  z-index: 999;
  background-position: 100%;
}

.search_home_main {
  position: relative;
  display: flex;
  align-items: center;
  background: #2b2b2b;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.homesearch_icon {
  width: 50px;
  height: 50px;
  /* background: gray; */
  border-radius: 50px;
  padding: 5px;
}

.search_home_h1 {
  /* position: relative; */
  font-family: var(--font-600);
  margin-bottom: 0px;
  margin-left: 12px;
  font-size: 16px;
}

.search_home_p {
  font-family: var(--font-300);
  margin-left: 12px;
  margin-bottom: 0px;
  font-size: 12px;
  /* text-overflow: clip; */
  /* text-wrap: ; */
  word-wrap: break-word;
}

/* main_home_search */
.main_home_search {
  /* width: 100%; */
}

.box {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  background-color: #000;
  border-radius: 3px;
  overflow: hidden;
}

.tab-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.tab-list::before {
  content: "";
  display: block;
  height: 2px;
  width: 33.333%;
  position: absolute;
  bottom: 0;
  background-color: #aaa;
  transition: 0.3s;
}

.tab-item {
  flex: 1;
  text-align: center;
  transition: 0.3s;
  opacity: 0.5;
}

.tab-toggle {
  display: none;
}

.tab-content {
  display: none;
}

.tab-toggle:nth-child(1):checked ~ .tab-list .tab-item:nth-child(1),
.tab-toggle:nth-child(2):checked ~ .tab-list .tab-item:nth-child(2),
.tab-toggle:nth-child(3):checked ~ .tab-list .tab-item:nth-child(3) {
  opacity: 1;
}

.tab-toggle:nth-child(2):checked ~ .tab-list::before {
  transform: translateX(100%);
}

.tab-toggle:nth-child(3):checked ~ .tab-list::before {
  transform: translateX(200%);
}

.tab-toggle:nth-child(1):checked ~ .tab-container .tab-content:nth-child(1),
.tab-toggle:nth-child(2):checked ~ .tab-container .tab-content:nth-child(2),
.tab-toggle:nth-child(3):checked ~ .tab-container .tab-content:nth-child(3) {
  display: block;
}

.tab-trigger {
  display: block;
  padding: 10px 0;
  color: white;
  font-size: 14px;
}

.tab-container {
  /* padding: 10px 15px; */
}

/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapphome {
  width: 100%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
}

.page_navbar_wrapphome {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  padding: 15px;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.search_creator_name {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 16px;
  margin: 20px auto;
}
/* page base design end */

/* tab btns design start */

.tab_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--trnsp-white);
  /* padding: 10px 20px; */
  border-radius: 28px;
  margin: 10px auto;
}

.tab_single_btn {
  /* width: 30%; */
  flex: 0.3;
  border-radius: 28px;
  /* padding: 10px 20px; */
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.tab_single_btn > p {
  color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-600);
}

.tab_single_btn > img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

/* tab btns design end */
