.footer13_main_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  height: 90px;
  transition: width 0.3s ease-in-out;
  width: 100%;
  padding: 0 10px;
  max-width: var(--max-width-sm);
}

.footer13_main_wrapp.expanded {
  width: 100vw;
  padding: 0;
}

.footer13_base_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 60px;
  height: 60px;
  transition: width 0.3s ease-in-out;
  width: 60px;
  box-shadow: inset #ff004c78 7px 4px 20px 0px,
    inset #caacff1f -8px -4px 23px 0px;
}

.footer13_base_wrapp.expanded {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 28px;
  box-shadow: inset #ff004c78 7px 4px 20px 0px,
    inset #caacff1f -8px -4px 23px 0px;
  width: calc(100vw - 20px);
}

.footer-toggle-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.footer-toggle-icon {
  width: 25px;
  height: 25px;
}

.footer-close-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.footer-close-icon {
  width: 20px;
  height: 20px;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.footer-links > button {
  border: none;
  margin-left: 10px;
}

.footer13_btn_main1 {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.footer13_btn_main1_h {
  width: 20px;
  height: 28px;
  object-fit: contain;
  padding-bottom: 6px;
  border-bottom: 2px solid var(--color-main);
}

.footer13_base_wrapp.expanded > .footer-links > button {
  margin-left: 0;
  margin-right: 0;
}

.footer_close_icon {
  position: absolute;
  right: -11%;
  top: 50%;
  transform: translate(0%, -44%);
  /* width: "16px";
                    height: "16px"; */
}
