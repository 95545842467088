.Topclub_main {
  padding: 2px 25px 25px 25px;
}

.topclub_sub {
  display: flex;
  flex-direction: row;
  height: 35vh;
  gap: 15px;
}

.topclub_sub_details {
  width: 50%;
}

.topclub_sub_details2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.topclub_sub_details_sub {
  height: 50%;
}

.main_img_details {
  width: 100%;
  object-fit: cover;
}

.main_img_details2 {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.top_club_h1 {
  position: relative;
  text-align: center;
  backdrop-filter: blur(10px);
  bottom: 5%;
  font-family: var(--font-600) !important;
}
