/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

/* 04-09-2023 */
.club_m_list_img2 {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  object-fit: cover;
}

.club_m_list {
  position: relative;
}

.club_m2_list_nm {
  font-size: 25px;
  font-family: var(--font-600);
}

.club_member_btn_div {
  display: flex;
  position: fixed;
  left: 0px;
  height: 90px;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  bottom: 0px;
}

.club_member_btn {
  background-color: var(--color-main);
  padding: 10px;
  color: white;
  font-size: 20px;
  font-family: var(--font-600) !important;
  border-radius: 40px;
  width: 60%;
  font-weight: 900;
}

/* club_menu_list */

.club_menu_list {
  position: relative;
  display: flex;
  /* gap: 10px; */
  margin-bottom: 15%;
  width: 100%;
}

.club_menu_list_1 {
  display: flex;
  /* width: 33%; */
  align-items: center;
  margin-top: 5%;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
  flex: 4;
}

.club_menu_img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: #262626;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.club_menu_img1 {
  width: 23px;
  height: 23px;
}

.club_menu_nm {
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  text-align: center;
}

.club_view_membership {
  position: relative;
}

.club_view_membership1 {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  background-color: #262626;
  border-radius: 10px;
  margin-bottom: 25px;
}

.club_member_select {
  width: 30px !important;
  height: 30px !important;
  margin-top: 10px !important;
}

.club_member_select:checked::before {
  opacity: 1;
  background-color: #ffffff !important;
}

.club_member_select::before {
  width: 21px !important;
  top: 4px !important;
  height: 21px !important;
  margin-top: 10px !important;
}

.clun_price_m {
  width: 50%;
  text-align: start;
  font-family: var(--font-600);
}

.club_month {
  width: 30%;
  text-align: end;
}
