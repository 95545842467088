.back_img_all {
  width: 38px;
  height: 25px;
  z-index: 99;
  position: relative;
  border: none;
}
.all_back {
  width: 35px;
}
