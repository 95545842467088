/* .frames_img {
  width: 70px;
  height: 100px;
  border: 1px solid var(--color-main);
  padding: 2px;
} */
/* .set_images_nfb {
  width: 150px;
  height: 100px;
  border: 1px solid var(--color-main);
  padding: 2px;
} */
/* .view_card_nfb {
  margin-top: 15px;
  margin-bottom: 15px;
} */
/* .view_card_nfb2 {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
} */
/* .select_f_view {
  display: flex;
  gap: 10px;
} */
/* .launch_n_benefit {
  background-color: var(--color-main);
  color: white;
  margin-top: 10%;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--font-700);
  width: 75%;
  padding: 15px;
  border-radius: 25px;
} */
.attach_nfbs_nm {
  border: 1px solid var(--color-main);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin: 10px auto;
  width: 100%;
  font-family: var(--font-400);
  font-size: 12px;
}
.attach_nfbs_nm2 {
  border: 1px solid var(--color-main);
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
/* .nm_attach_f {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 6px;
}

 */

/* modal start */

.newattach_modal {
  display: flex;
  /* Align the modal content to the right */
  align-items: flex-end;
  /* Align the modal content to the bottom */
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content1 {
  background-color: #626060d7;
  padding: 20px;
  position: relative;
  border-radius: 50px 50px 0px 0px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100vw;
  min-height: 28vh;
    height: 29vh;
    
  -webkit-border-radius: 50px 50px 0px 0px;
  -moz-border-radius: 50px 50px 0px 0px;
  -ms-border-radius: 50px 50px 0px 0px;
  -o-border-radius: 50px 50px 0px 0px;
}

/* Close button styles */
.modal-content button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.newattach_modal_img2 {
  width: 50px;
  height: 50px;
  /* margin-top: -40px; */
  transform: translateY(-90%);
}

.newattach_modal_input {
  border-radius: 28px;
  background: var(--color-white);
  color: var(--color-black) !important;
  font-size: 14px;
  font-family: var(--font-400);
  margin: 20px auto;
}

.newattach_modal_input::placeholder {
  color: var(--color-gray);
}

.new_attech_modal_btn {
  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
  padding: 10px 20px;
  background-color: var(--color-main);
  border-radius: 28px;
  width: 100px;
}

.newattech_hr {
  width: 100%;
  height: 1px;
  background: var(--color-gray);
  margin: 10px auto;
}

/* modal end */

/* --- new dk --- */

.frames_img {
  width: 70px;
  height: 100px;
  border: 1px solid var(--color-main);
  padding: 2px;
}

.frames_img_border {
  width: 70px;
  height: 100px;
  /* border: 1px solid var(--color-main); */
  padding: 2px;
}

.set_images_nfb {
  width: 150px;
  height: 100px;
  border: 1px solid var(--color-main);
  padding: 2px;
}

.set_images_nfb_border {
  width: 150px;
  height: 100px;
  padding: 2px;
}

.view_card_nfb {
  margin-top: 15px;
  margin-bottom: 33px;
}

.select_f_view {
  display: flex;
  gap: 10px;
}

.launch_n_benefit {
  background-color: var(--color-main);
  color: white;
  margin-top: -1%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  font-family: var(--font-600);
  width: 100%;
  padding: 15px;
  border-radius: 25px;
}

.attach_nfbs_nm {
  border: 1px solid var(--color-main);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.attach_nfbs_nm2 {
  border: 1px solid var(--color-main);
  border-radius: 10px;
  padding: 12px;  
  text-align: left;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.nm_attach_f {
  font-size: 14px;
  margin-bottom: 6px;
}

.createbenefit_btn {
  background-color: white;
  text-align: center;
  color: black;
  padding: 5px 11px;
  font-size: 14px;
  margin-top: 15px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

/* 02-09-2023 */
.view_benefit_main {
  margin-top: 5%;
}

.list_benefit_view {
  border: 1px solid var(--color-main);
  border-radius: 25px;
  height: 150px;
  width: 90%;
  position: relative;
  left: 20px;
  margin: 10px auto;
}

.sub_list_benefits {
  width: 100%;
  height: 135px;
  top: 7px;
  text-align: start;
  position: relative;
  right: 10px;
  padding: 5px;
  background-color: black;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.manage_link {
  color: var(--color-main);
  font-weight: bold;
  position: absolute;
  bottom: 30px;
  left: -15px;
}

.nm_nfbs_list {
  color: white;
  font-weight: bold;
  position: relative;
  right: 15px;
  top: 5px;
  font-size: 20px;
  text-align: center;
}

.nm_nfbs_list1 {
  color: white;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  text-align: center;
}

.part_benefit {
  display: flex;
  gap: 10px;
}

.part_benefit1 {
  width: 20%;
}

.part_benefit2 {
  width: 76%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow-x: scroll;
}

.view_sub_benefit {
  border: 1px solid var(--color-main);
  height: 110px;
  width: fit-content;
  min-width: 94px;
  padding: 5px;
  margin-top: 15px;
  border-radius: 10px;
  background-image: linear-gradient(to left, rgb(0, 0, 0), rgb(232, 0, 0));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_view_1 {
  width: 65px;
  height: 65px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.benefit_nm_sub {
  font-size: 10px;
  font-family: var(--font-400);
  text-align: center;
}

.view_card_nfb2 {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  overflow-x: scroll;

  /* Hide the scrollbar by default */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Define styles for the scrollbar track and thumb */
  /* For Webkit-based browsers (Safari and Chrome) */
  &::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #05050503; /* Color of the thumb */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Color of the track */
  }

  /* For Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent #11101000; /* Color of the thumb */
  }

  /* Show the scrollbar on hover or touch */
  &:hover {
    scrollbar-color: #0e0d0d00 transparent; /* Color of the thumb on hover */
  }
}
