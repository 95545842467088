.homenew_13_main_wrap {
  width: 100%;
  height: 100%;
  position: absolute;
}
.homenew_13_base_wrapp {
  width: 100%;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}
.homenew_13_first_slider_wrapp {
  width: 100%;
  /* height: 350px; */
  margin-bottom: 23px;
  /* margin-top: 44px; */
  /* padding-top: 82px; */
}

.new_marquee_wrapp {
  width: 100%;
  margin: 4px auto 1rem auto;
}
.rfm-marquee-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--width);
  transform: var(--transform);
  background: #ffffff17;
  height: 40px;
}
.homenew_13_auction_btn_home {
  width: 13.13px;
  height: 14px;
  margin-right: -5px;
}

.homenew_13_htb_single_sqare {
  /* width: 100%; */
  padding: 4px;
  height: 29px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 8px;
  margin-right: 10px;
}

.homenew_13_htb_single_sqare > span {
  color: #fff;
  opacity: 0.8;
  font-size: 11px;
  font-family: var(--font-400);
  padding: 0px;
}

.homenew_13_htb_single_sqare > p {
  color: var(--color-main);
  font-size: 11px;
  font-family: var(--font-700);
  word-wrap: break-word;
}
.homenew_13_dot_partn1 {
  margin-left: 15px;
  margin-right: 5px;
  /* margin-top: 6px; */
  padding-bottom: 5px;
}
.homenew_13_dot_partn2 {
  margin-left: 15px;
  margin-right: 5px;
  padding-bottom: 5px;
}
.homenew_13_select_set_new {
  display: flex;
  width: 100%;
  margin-bottom: 23px;
  /* padding: 20px; */
  padding-left: 4%;
}
.homenew_13_select_set_new_sub1 {
  /* width: 18%; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: #342025; */
  height: 35px;
  border-radius: 17.5px;
  /* text-align: center; */
  justify-content: center;
  font-size: 12px;
  color: #ffffff;
  font-weight: 500 !important;
  margin-right: 10px;
}
.my-p {
  color: #ffffff;
  font-family: var(--font-300);
  font-size: 12px;
  font-weight: 500;
  background: #342025;
  height: 35px;
  border-radius: 17.5px;
  padding: 9px 20px;
  border: none;
}

.my-p.custom-border {
  position: relative;
  background-color: transparent;
}
.my-p.custom-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.my-p.custom-border {
  /* border: 1px solid #972137; */
  /* width: 73px; */
}
.homenew_13_select_set_new_sub2 {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.homenew_13_select_set_new_sub2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.homenew_13_select_set_new_sub2 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* ======slider start */
.homenew_13_first_slide_card_home .slick-slide .homenew_13_img_sliders {
  display: block;
  width: 100%;
  height: 100%;
  max-width: var(--max-width-sm);
}
.homenew_13_first_slide_card_home .slick-dots {
  display: none !important;
}

/* --------------------------------- card design start ------------------------------------------ */

.homenew_13_first_slide_card {
  width: 98%;
  border-radius: 18px;
  overflow: hidden;
  /* height: 400px; */
  background-color: #ffffff0d;
  backdrop-filter: blur(10px);
  padding: 14px;
  position: relative;
  display: flex;
  /* gap: 10px; */
  left: 12%;
  margin: 0px 1%;
}

.new_homecard_back_img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.homenew_13_first_slide_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 18px;
  border: 0.5px solid transparent;
  background: linear-gradient(
      134.04deg,
      #ffffff 2.44%,
      rgba(255, 255, 255, 0) 80.23%
    )
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.new_home_card_user_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.new_home_card_user_wrapp > p {
  font-family: var(--font-400);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
}

.new_home_card_user_img {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  overflow: hidden;
  padding: 1px;
  background: linear-gradient(
    138.53deg,
    #ff004c 7.95%,
    rgba(202, 172, 255, 0.67) 92.64%
  );
}

.new_home_card_user_img > img {
  width: 100%;
  height: 100%;
  border-radius: 32px;

  object-fit: cover;
  object-position: top;
}

.new_home_card_img {
  width: 100%;
  height: 180px;
  border-radius: 18px;
  object-fit: cover;
  margin: 1rem auto;
}

.new_home_card_name_wrapp {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 4px; */
  align-items: center;
  margin: 0px auto;
}

.new_home_card_name_wrapp > p {
  font-size: 18px;
  font-family: var(--font-600);
  color: var(--color-white);
  line-height: 150%;
}

.new_home_card_asset_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}

.new_home_card_asset_single {
  width: 48%;
  min-height: 80px;
  border-radius: 18px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;

  box-shadow: 6px 8px 10px 0px #0000001f;
}

.new_home_card_asset_single::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 18px;
  border: 0.5px solid transparent;
  /* background: linear-gradient(45deg, purple, orange) border-box; */
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  background: linear-gradient(
      118.87deg,
      #ffffff 2.35%,
      rgba(255, 255, 255, 0) 60.88%
    )
    border-box;
}

.new_home_card_asset_single > p {
  font-family: var(--font-300);
  color: var(--color-white);
  font-size: 12px;
}

.new_home_card_asset_single > h4 {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 16px;
}

/* ----------------------------------- card design end --------------------------- */

.homenew_13_img_sliders {
  width: 90%;
  /* object-fit: cover; */
  height: 300px;
  border-radius: 25px;
  z-index: -2;
}
.homenew_13_home_name_pro {
  /* position: absolute; */
  font-family: var(--font-600) !important;
  top: 57%;
  font-size: 19px;
  width: 90%;
  text-align: center;
  margin-top: 5px;
}
.homenew_13_u_line {
  width: 17%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
}
.homenew_13_dp_div {
  width: 100%;
  text-align: start;
  padding-left: 6%;
}
.homenew_13_user_img_details {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  left: 14px;
  top: 10px;
  gap: 8px;
}
.homenew_13_user_img {
  width: 31px;
  height: 31px;
  border-radius: 30px;
  object-fit: cover;
  border: 1px solid #8f0735;
}
.homenew_13_user_name_h1_home {
  font-size: 14px;
  /* position: relative; */
  text-shadow: #000;
  /* top: 3px; */
  font-family: var(--font-400) !important;
  margin: 0px;
}
.homenew_13_nfb-img-details_h {
  /* position: absolute; */
  /* backdrop-filter: blur(25px); */
  bottom: 15px;
  width: 96%;
  /* border: 1px solid white; */
  border-radius: 28px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-top: 15px;
}
.homenew_13_nfb_home_1 {
  position: relative;
  flex-direction: column;
  width: 164px;
  height: 65px;
  display: flex !important;
  padding-left: 3px;
  align-items: center;
  justify-content: center !important;
  border-radius: 12px;
  background: #ffffff0f;
  border: 0.5px solid #6f6b6b45;
}

.homenew_13_nfb_home_1::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 0.5px;

  background: linear-gradient(
    118.87deg,
    #ffffff 2.35%,
    rgba(255, 255, 255, 0) 60.88%
  );
  -webkit-mask: linear-gradient(#ffffff0f 0 0) content-box,
    linear-gradient(#ffffff0f 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.homenew_13_bid_home_name {
  font-family: var(--font-300) !important;
  font-size: 12px;
}
.homenew_13_bid_home_amt {
  font-family: var(--font-600) !important;
  font-size: 14px !important;
}
.homenew_13_nfb_home_line {
  width: 1px;
  height: 40px;
  background: var(--color-white);
  display: none;
}
.homenew_13_nfb_home_2 {
  position: relative;
  flex-direction: column;
  width: 164px;
  height: 65px;
  display: flex !important;
  padding-left: 5px;
  align-items: center;
  justify-content: center !important;
  border-radius: 12px;
  background: #ffffff0f;
  border: 0.5px solid #6f6b6b45;
}

.homenew_13_nfb_home_2::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 0.5px;
  background: linear-gradient(
    118.87deg,
    #ffffff 2.35%,
    rgba(255, 255, 255, 0) 60.88%
  );
  -webkit-mask: linear-gradient(#ffffff0f 0 0) content-box,
    linear-gradient(#ffffff0f 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* ======slider end */

/* =======auction btns start */
.homenew_13_auction_btn {
  margin: 20px auto;
  margin-bottom: 30px;
  width: 90%;
  max-width: var(--max-width-md);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 15px; */
  margin-top: 37px;
}

.homenew_13_auction_btn a {
  /* border: 1px solid var(--color-main); */
  padding: 10px 20px;
  color: white;
  font-family: var(--font-400);
  font-size: 16px;
  width: 49%;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* border-radius: 28px; */
  position: relative;
}
.homenew_13_auction_btn a::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 1px;

  background: linear-gradient(
    92.8deg,
    #ff004c 5.05%,
    rgba(202, 172, 255, 0.69) 95.1%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
/* =======auction btns end */

/* =======category start */

.homenew_13_viewmore_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  max-width: var(--max-width-md);
  margin: 10px auto;
  padding: 0px 3%;
}

.homenew_13_viewmore_wrapp > h4 {
  font-size: 14px;
  font-family: var(--font-600);
}

.homenew_13_viewmore_wrapp > a {
  font-size: 12px;
  font-family: var(--font-400);
  opacity: 0.6;
  cursor: pointer;
}
.homenew_13_section_main_row {
  display: flex; /* Use flexbox layout */
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of child elements */
  /* margin-bottom: 66px; */
  padding: 0% 3%;
}
.homenew_13_each_section {
  flex: 0 0 auto;
  padding: 10px;
  width: 150px;
  height: 140px;
  font-size: 14px;
  /* background: #5656ab; */
  justify-content: normal;
  text-align: center;
  margin-right: 10px;
  margin-left: 4px;
  border-radius: 21px;
  /* padding-top: 51px; */
  display: grid;
  align-items: center;
  margin-bottom: 96px;
}
/* =======category end */
@media (max-width: 420px) {
  .homenew_13_first_slide_card_home .slick-slide .homenew_13_img_sliders {
    display: block;
    /* padding: 4%; */
    border-radius: 29px;
    height: 204px;
    width: 93%;
  }

  .new_marquee_wrapp {
    width: 90%;
  }
}

.swiper {
  margin: 10px auto;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
