/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.club_manager_gradinat_wrapp {
  width: 100%;
  padding: 1.5px;
  border-radius: 10px;
  margin-bottom: 10px;

  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
}

.main_manager_club_set {
  display: flex;
  gap: 15px;
  position: relative;
  background-color: #262626;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.club_manager_new {
  /* position: relative; */
  margin: 20px auto;
}

.name_of_manager {
  position: relative;
  left: 10px;
  width: 65%;
  word-break: keep-all;
  font-family: var(--font-600);
  font-weight: 350;
  font-size: 14px;
}

.images_club_manager {
  width: 55px;
  height: 55px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.delete_manager_club {
  width: 25px;
  position: absolute;
  right: 10px;
}

/*  */
.plus_new {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
}
