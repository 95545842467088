.royltyclubnfb_main {
    position: relative;
}

.royltyclubnfb_img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.royltyclubnfb_nm {
    position: absolute;
    /* background-color: rgba(177, 177, 177, 0.73); */
    backdrop-filter: blur(8px);
    bottom: 0px;
    height: 50px !important;
    width: 100%;
    color: white;
    border-radius: 5px;
    font-family: var(--font-400);
    font-weight: 700;
    font-size: 15px;
    display: flex;
    padding: 7px;
}

.royltyclubnfb_nm1 {
    width: 50%;

}

.royltyclubnfb_nm2 {
    width: 50%;
    text-align: end;
    position: relative;
}

.royltyclubnfb_number {
    color: white;
    font-family: var(--font-400);
    font-weight: 700 !important;
    font-size: 12px;
}
.royltyclubnfb_number1{
    color: white;
    font-family: var(--font-400);
    font-weight: 700 !important;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 5px;
}

.royltyclubnfb_main_details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    margin: 30px auto;
}

/* roylty_nav_hd */
.roylty_nav_hd
{
    display: flex;
    width: 100%;
}
.roylty_nav_nm
{
    width: 82%;
    text-align: center;
}
.roylty_nav_img1
{
    width: 10%;
    cursor: pointer;
}
.roylty_nav_img
{
    width: 8%;
}