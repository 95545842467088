.book-container {
  width: 300px;
  height: 400px;
  perspective: 1000px;
}

.book-cover {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-in-out;
}

.cover-front,
.cover-back {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
}

.cover-front {
  background-image: url("../../assets/images/media1.jpg");
}

.cover-back {
  background-image: url("../../assets/images/media2.jpg");
  transform: rotateY(180deg);
}
