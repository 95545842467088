.crad__show13_main_img_front {
  background-image: url(./../../assets/images/card7_hr.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.sc_f_details13 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: -20%;
  position: relative;
}

.card_13_img_front {
  z-index: -1;
  position: absolute;
  width: 100%;
  object-fit: contain;
  height: 65%;
  top: 17%;
  left: 0px;
}

.sc_f_details_purchase13 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 3%;
  position: absolute;
}
