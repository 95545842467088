.new_pv_card_wrapp {
  width: 90%;
  height: 550px;
  margin: 10px auto;
  border: 1px solid red;
}

/* ----- asset value section ----- */
.new_pv_asset_value_wrapp {
  width: 90%;
  min-height: 100px;
  margin: 1rem auto;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1a1a1a;
}

.new_pv_asset_value_stars1 {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.new_pv_asset_value_stars2 {
  width: 60px;
  height: 60px;
  object-fit: contain;
  transform: rotate(180deg);
}

.new_pv_asset_value_div {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.new_pv_asset_value_div > p {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 14px;
  text-align: center;
}

.new_pv_asset_value_div > h4 {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 34px;
  text-align: center;
  line-height: 125%;
}

/* ----- asset name section ----- */

.new_pv_asset_name_wrapp {
  width: 90%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.new_pv_asset_name_wrapp > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.new_pv_asset_name_wrapp > div > h6 {
  font-family: var(--font-600);
  font-size: 18px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: left;
  color: var(--color-white);
  width: 60%;
  text-align: left;
}

.new_pv_asset_name_wrapp > div > p {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: left;
  color: #b4b4b4;
  width: 38%;
  text-align: end;
}

.new_pv_asset_name_wrapp > p {
  font-family: var(--font-400);
  font-size: 14px;
  font-weight: 350;
  line-height: 20.5px;
  text-align: left;
  color: var(--color-white);
  width: 100%;
  text-align: left;
}

.new_pv_likeshare_wrapp {
  width: 90%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_pv_likeshare_btns_wrapp {
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_pv_likeshare_btns_wrapp > button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  opacity: 0.8;
}

.new_pv_likeshare_btns_wrapp > button > p {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  font-weight: 400;
}

/* owner profile start */

.new_pv_ownerprofile_wrapp {
  width: 90%;
  padding: 10px;
  margin: 1rem auto;
  background: #1a1a1a;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_pv_ownerprofile_img_wrapp {
  width: 70px;
  height: 70px;
  border-radius: 70px;

  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new_pv_ownerprofile_img_wrapp > img {
  width: 66px;
  height: 66px;
  border-radius: 66px;
  object-fit: cover;
  object-position: top;
  background: var(--color-black);
}

.new_pv_ownerprofile_wrapp > p {
  width: 70%;
  font-size: 14px;
  font-family: var(--font-600);
  font-weight: 400;
  color: var(--color-white);
}
/* owner profile end */

/* other available rarerez section start */

.new_pv_other_avail_sec_wrapp {
  width: 90%;
  margin: 1rem auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.new_pv_sec_heading {
  width: fit-content;
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
  font-weight: 400;

  border-bottom: 1px solid var(--color-white);
  margin: 10px 0px;
  text-align: left;
}

.new_pv_other_avail_rarerez_cards_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}

.new_pv_avail_rarerez_card {
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* other available rarerez section end */
