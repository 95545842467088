/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 30%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

/* Add additional styles for your modal content here */
.otp_demo_img_div {
  text-align: start;
  width: 100%;
}
.otp_demo_img1 {
  width: 32%;
  /* margin-top: 10%;
  margin-left: 35%; */
}

.otp_demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.otp_demo_title {
  font-family: var(--font-600) !important;
  font-size: 20px;
  margin-top: 1px;
  color: var(--color-main);
  margin-bottom: -9px;
}
.forgot_submit_btn1 {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 42px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
  /* margin-left: 7%; */
}

.otp_demo_p {
  font-family: var(--font-300);
  color: gray !important;
  left: 0px !important;
  text-align: center;
  font-size: 14px !important;
  width: 100% !important;
}
.otp_demo_p_set {
  font-family: var(--font-600) !important;
  color: gray !important;
  left: 0px !important;
  font-size: 15px !important;
  width: 100% !important;
}
.resend_mobile_otp_div {
  width: 100%;
  text-align: end;
}

.demo_otp_btn_new_set {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 3px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
}
.v_a_c {
  font-size: 16px;
  font-family: var(--font-400);
  opacity: 0.8;
}

.input_otp_new {
  display: flex;
  /* gap: 10px; */

  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 0px;
}
.demo_resend_otp {
  color: var(--color-main);
  font-family: var(--font-700);
  text-align: end;
}
.resend_mobile_otpf {
  float: right !important;
  position: relative;
  /* left: 0px !important; */
  color: var(--color-main);
  font-family: var(--font-700) !important;
  border: none;
  right: 1%;
  bottom: 10px;
  top: 2px;
}
/* .in_de_otp {
  background-color: gray;
  width: 60px;
  height: 60px;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  color: white;
  font-size: 25px;
  text-align: center;
} */
.in_de_otp {
  background-color: gray;
  width: 54px;
  /* height: 25%; */
  height: 57px;
  /* border-radius: 5px !important; */
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  /* color: white; */
  font-size: 25px;
  text-align: center;
  border: none;
}
@media only screen and (max-width: 850px) {
  .page_base_wrapp {
    width: 45%;
  }
  .input_otp_new {
    display: flex;
    /* gap: 10px; */
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 26px;
  }
  .in_de_otp {
    background-color: gray;
    width: 18%;
    /* height: 25%; */
    height: 54px;
  }
}
@media only screen and (max-width: 850px) {
  .page_base_wrapp {
    width: 88%;
  }
  .in_de_otp {
    height: 50px;
  }
}
