/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.viewmanagers_search {
  height: 40px;
  padding: 15px;
}

.viewmanagers_search::placeholder {
  color: white;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  color: gray;
  z-index: 999;
  background-position: 100%;
}
.viewmanagers_details {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid white;
}
.viewmanagers_details_img {
  width: 25px;
  cursor: pointer;
  width: 22px;
}
.close_forgot1 {
  color: #fff;
  background-color: #ee1651;
  border-radius: 25px;
  /* width: 100px; */
  font-size: 15px;
  padding: 5px;
  height: 30px;
  width: 36%;
  margin: 0px 22px;
}

.name_of_viewmanagers {
  width: 80%;
  text-align: center;
  font-size: 14px;
}
