.rnfb_nav_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px auto;
  margin: 10px auto;
}

.rnfb_nav_back_btn {
  height: 24px;
  object-fit: contain;
}

.rnfb_nav_screen_name {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.rnfb_products_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  margin: 30px auto;
}

.rngb_single_product {
  width: 100%;
  height: 150px;
  /* min-height: 150px; */
  position: relative;
}

.rngb_single_product > img {
  width: 100%;
  height: 100%;
}

.rnfb_blur_view {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 45px;
  backdrop-filter: blur(10px);
  background-color: #0000007e;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.rnfb_product_name {
  width: 100%;
  color: var(--color-white);
  font-family: var(--font-400);
  font-size: 10px;
}

.rnfb_stock_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rnfb_stock_wrapp > p,
.rnfb_stock_wrapp > span {
  color: var(--color-white);
  font-family: var(--font-400);
  font-size: 10px;
  padding: 0px;
}
