.set_otp_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* padding: 0px 5%; */
  /* margin: 1rem auto; */
}

.new_set_password_inputs_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 5%;
  width: 100%;
}

.new_set_password_single_input_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 10px; */
  width: 100%;
  margin: 10px auto;
}

.new_set_password_single_labal_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.new_set_password_single_labal_wrapp > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.new_set_password_single_labal_wrapp > p {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.new_set_password_single_border_wrapp {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  /* padding: 8px; Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 100%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_set_password_single_border_wrapp > input {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  width: 70%;
  border: none;
  border-radius: 0px;
  margin: 0px;
  background: none;
  padding: 1rem 0px;
  caret-color: var(--color-main);
}

.new_set_password_single_border_wrapp > input::placeholder {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  opacity: 0.4;
}

.new_set_password_single_border_wrapp > img {
  width: 16px;
  height: 16px;
  margin: 5px 8px;
  object-fit: contain;
}
