.main-card-screen {
  width: 90%;
  height: 400;
  margin: 10px auto;
  justify-content: center;
  padding: 14px !important;
  border-radius: 12px !important;
  /* border: 0.5px solid white!important; */
  background: linear-gradient(134.04deg, #191919 2.44%, #191919 80.23%)
    border-box !important;
  mask-composite: exclude !important;
}

.skeletonnew-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: start;
}

.nameofskeletons {
  position: relative;
  top: 5px;
}

.new_lo {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.new_lo h1 {
  width: 50%;
}

.skeleton-img {
  margin-bottom: 10px;
}
