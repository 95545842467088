.play_screen_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.play_screen_banner_container {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.play_screen_banner {
  width: 85%;
  height: 70%;
  object-fit: contain;
}

.play_screen_text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 5%;
}

.play_screen_text_container > h4 {
  font-size: 32px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 125%;
  align-self: flex-start;
}

.play_screen_text_container > h2 {
  font-size: 32px;
  color: var(--color-white);
  font-family: var(--font-600);
  line-height: 150%;
  align-self: flex-start;
  margin-bottom: 10px;
}

.play_screen_text_container > P {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 130%;
  align-self: flex-start;
  margin: 2px 0px;
}

.play_screen_text_container > span {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-300);
  line-height: 125%;
  margin: 10px auto;
  padding-top: 0px;
}

.play_screen_text_container > span > a > b {
  font-size: 12px;
  color: var(--color-main);
  font-family: var(--font-600);
  line-height: 125%;
  margin: 0px 4px;
}
