.bids_main {
  display: flex;
  /* flex-direction: row; */
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.bidss_user_menu {
  left: 15px;
  position: relative;
}

.bids_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.bids_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bidsmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.bids_main_h1 {
  position: relative;
  right: 25px;
}

.bid_img_details {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bid_details_sub1 {
  background-color: #ffffffda;
  border-radius: 8px;
  bottom: 22%;
  position: absolute;
  padding: 15px;
  width: 100%;
}

.sub2_button_a {
  background-color: var(--color-main);
  color: white;
  font-family: var(--font-700);
  font-size: 13px;
  padding: 10px;
  border-radius: 10px;
  width: 48%;
}

.bids_h1 {
  font-family: var(--font-700) !important;
  position: relative;
  color: #000;
  font-size: 15px;
}

.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

.bidsmain1 {
  width: 20%;
}

.user_bid_main {
  width: 100%;
  padding: 15px;
}

.user_bid_search {
  height: 40px;
  padding: 15px;
}

.user_bid_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

.bid_details_sub2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  position: absolute;
  padding: 8px;
  bottom: 2px;
}

/* modal bids */

.modal_2_p {
  color: #000;
  margin-bottom: 20px;
  font-family: var(--font-800);
}

.modal_2_a {
  font-family: var(--font-600);
  color: #000;
  line-height: 30px;
  margin-bottom: 10px;
}

.nm_bid_modal {
  left: 0px !important;
}

.close_tag {
  margin: 0;
  font-size: 25px;
  font-family: var(--font-700);
  color: #ee1651;
  left: 0px !important;
}

.bids_close_modal {
  height: 40px;
  width: 75%;
  border-radius: 10px;
  border: 1px solid #ee1651 !important;
}

.bids_close_modal_2 {
  width: 20%;
}

.bids_close_modal_3 {
  width: 20%;
}

#bids_inpput_tag::placeholder {
  color: gray;
}

.bidlist1 {
  position: fixed;
  background-color: #1f1f1f;
  bottom: 0px;
  height: auto;
  width: 100%;
  padding-bottom: 40px;
  max-width: var(--max-width-sm);
  border-top-right-radius: 28px;
  border-top-left-radius: 28px;
}

#bids_inpput_tag {
  border: 1px solid gray;
  box-shadow: inset 0px 0px 8px 0px rgb(213, 209, 209);
  border-radius: 25px;
  height: 40px;
  font-size: 14px;
  margin-top: 10px;
  background-color: white;
  color: gray !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.list_of_bis_screen {
  /* display: flex; */
  align-items: center;
  height: 30px;
}

.list_of_bis_screen1 {
  /* display: flex; */
  align-items: center;
  height: 30px;
  text-align: end;
}

.error-message_bids {
  color: #ee1651;
}

.bids_close_modal {
  color: white;
  font-family: var(--font-700);
}

.nm_bis_2_bids {
  font: 500;
  font-family: var(--font-600);
  margin-bottom: 10px;
}

/* modal bids */

/* 16-09-2023 */
.nm_bid_list_view_demo {
  display: flex;
  padding: 25px;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.bid_list_nm_set_main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bid_list_nm_set {
  width: 100%;
  font: 500;
  font-family: var(--font-600);
  float: left;
  text-align: start;
}

.bid_list_nm_set2 {
  width: 100%;
  font: 500;
  font-family: var(--font-600);
  float: right;
  width: 100%;
  text-align: end;
}
.company_royaltymain {
  display: flex;
  width: 100%;
}
.company_royalty_sub {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.company_royalty_sub2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
