.nps_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.npasset_heading {
  font-family: var(--font-600);
  font-size: 22px;
  font-weight: 350;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
  margin-top: 10px;
}

.npasset_heading_border {
  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
  width: 35%;
  height: 1px;
  margin: 4px auto;
}

.npasset_inner_page_wrapp {
  width: 100%;
  height: 100%;
  padding: 2rem 0px;
  margin-bottom: 2rem;
  /* display: flex;
  flex-direction: column; */
}

/* ------------ yes section start ----------- */

.npasset_yes_sec_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.npasset_yes_sec_wrapp > p {
  font-family: var(--font-600);
  font-size: 16px;
  font-weight: 350;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
  width: 75%;
}

.npasset_yes_sec_btns_wrapp {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 2rem;
}

.npasset_yes_sec_btns_wrapp > button {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1px;
}

.npasset_yes_sec_btns_wrapp > button > div {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  border-radius: 8px;

  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);

  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------- yes section end ------------- */

/*  ------------------- page change floting button start -------------------  */

.npasset_page_change_btns_wrapp {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 60%;
  padding: 8px;
  max-width: var(--max-width-sm);
  min-width: 300px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.npasset_page_change_btns_wrapp > p {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 1rem;
}

.npasset_page_change_btns_wrapp > button {
  width: 55px;
  height: 55px;
  border-radius: 55px;
}

.npasset_page_change_btns_wrapp > button > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
/*  ------------------- page change floting button end -------------------  */

/* enter detail style start */

.enter_detail_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.creator_name_wrapp {
  width: 100%;
}

.creator_name_wrapp > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

.creator_name_wrapp > div {
  background: linear-gradient(
    91.93deg,
    #ff004c 2.57%,
    rgba(202, 172, 255, 0.21) 97.43%
  );
  width: 100%;
  padding: 1px;
  border-radius: 8px;
  height: 60px;
}

.creator_name_wrapp > div > input {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  border: none;
  border-radius: 8px;

  padding: 10px;
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.media_links_section_wrapp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
}

.media_links_section_wrapp > p {
  font-family: var(--font-400);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

/* enter detail style end */

/* date of creation start */

.datepicker_inputs_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem auto;
  height: 60px;
  gap: 0.5rem;
}

.datepicker_single_input {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    91.93deg,
    #ff004c 2.57%,
    rgba(202, 172, 255, 0.21) 97.43%
  );

  padding: 1px;
}
.datepicker_single_inputmilestone {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    91.93deg,
    #ff004c 2.57%,
    rgba(202, 172, 255, 0.21) 97.43%
  );
  padding: 1px;
  margin-top: 30px;
}

.datepicker_single_input > select {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  border: none;
  border-radius: 8px;
  margin: 0px;
  padding: 10px 14%;

  font-size: 14px;
  color: #969696;
  font-family: var(--font-600);
}
.datepicker_single_inputmilestone > select {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  border: none;
  border-radius: 8px;
  margin: 0px;
  /* padding: 10px 14%; */

  font-size: 14px;
  color: #969696;
  font-family: var(--font-600);
}

.legacy_milestone_single_card_creat_asset {
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-radius: 18px;
  overflow: hidden;
}

.legacy_milestone_single_card_img_create_asset {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.legacy_milestone_cards_wrapp_create_asset {
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}
.legacy_milestone_single_card_blur_wrapp_crate_asset {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.16);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.6px);
  -webkit-backdrop-filter: blur(17.6px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.legacy_milestone_single_card_blur_wrapp_crate_asset > p {
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-400);
}
/* date of creation end */

/* description start */

.des_textarea_wrapp {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    91.93deg,
    #ff004c 2.57%,
    rgba(202, 172, 255, 0.21) 97.43%
  );
  padding: 1px;
  margin-top: 2rem;
}

.des_textarea_wrapp > textarea {
  width: 100%;
  height: 100%;
  border: none;
  background: var(--color-black);
  border-radius: 8px;
  margin: 0px;

  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}
/* description end */

/* creator video start */

.new_creator_video_wrapp {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  border-radius: 10px;
  overflow: hidden;
  padding: 1px;
}

.new_creator_video_upload_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  /* border: 2px dashed #ccc; */
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background: var(--color-black);
  margin: 0px;
  gap: 10px;
}

.new_creator_video_upload_button > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* creator video end */

/* min 1 image start */
.new_min1_img_preview_wrapp {
  width: 100%;
  /* margin: 1rem auto; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 3rem;
}

.border_milestone_wrapp {
  border: 2px solid red;
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  border-radius: 18px;
}

.new_min1_img_preview_single {
  width: 100%;
  height: 100px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 2px;
  /* background: black; */
}

.new_min1_img_preview_single::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: inherit;
  background-image: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
}

.new_min1_img_preview_single > img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #000;
}
/* min 1 image end */

/* attach viddeo start */

.new_attach_video_privew_btn {
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 14px;
  background: var(--color-black);
}

.new_attach_video_privew_btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: inherit;
  background-image: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
}

/* attach viddeo end */

/* new asset value section start */

.new_asset_value_sec_main_wrapp {
  width: 100%;
  /* padding: 10px; */
  height: 100px;
  margin: 1rem auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_asset_value_input_1 {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  /* padding: 8px; Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 25%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */

  text-align: center;
  font-family: var(--font-600);
  font-size: 1.5rem;
  color: var(--color-white);
}

.new_asset_value_input_2 {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  padding: 10px; /* Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 70%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */

  /* text-align: center; */
  font-family: var(--font-600);
  font-size: 1.5rem;
  color: var(--color-white);
}

.new_asset_value_input_2_static_sign {
  font-family: var(--font-600);
  font-size: 1.2rem;
  color: var(--color-white);
  transform: translate(-0%, -5%);
}

/* new asset value section end */

/* auction style start */
.newasset_auction_inputs_main_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 1.5rem auto;
  /* gap: 1rem; */
}

.newasset_auction_inputs_main_wrapp > label {
  font-family: var(--font-400);
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

.newasset_auction_inputs_main_wrapp > div {
  width: 100%;
  background: linear-gradient(to right, #ff004c80, #cbacff37);
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.newasset_auction_inputs_main_wrapp > div > input {
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  /* text-align: center; */
  font-family: var(--font-600);
  font-size: 10px;
  color: var(--color-white);
  margin: 0px;
  padding: 4px;
}

/* auction style end */

/* global massage screen start */

.global_msg_screen_main_wrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.global_msg_screen_main_wrapp > img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin: 10px auto;
}

.global_msg_screen_main_wrapp > p {
  font-family: var(--font-6004);
  font-size: 14px;
  font-weight: 350;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
  margin-bottom: 3rem;
}

.npasset_globel_exe_text {
  font-family: var(--font-400);
  color: var(--color-white);
  font-size: 12px;
  margin: 2rem auto;
  text-align: justify;
}

/* global massage screen end */

/* gift aucemo modal design start */

.newasset_gift_rarere_main_wrapp {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.newasset_gift_rarere_base_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #1f1f1f;
  width: 100%;
  /* height: 200px; */
  align-self: flex-end;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
  padding: 4rem 10px 1rem 10px;
  position: relative;
  min-height: 60vh;
  /* padding: 0 10px; */
}

.gift_rare_fixed_logo_wrapp {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff1a;
  box-shadow: 0px 7px 17px 0px #00000063;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: none;
}

.gift_rare_fixed_logo_wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gift_rare_modal_heading {
  width: 95%;
  font-family: var(--font-600);
  font-size: 14px;
  color: var(--color-white);
  margin: 10px auto;
  margin-bottom: 1rem;
  text-align: center;
}

.gift_rare_modal_inputs_wrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
}

.gift_rare_modal_input {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  /* padding: 8px; Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 100%;

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */

  font-family: var(--font-600);
  font-size: 14px;
  color: var(--color-white);
}

/* gift aucemo modal design end */

.no_option_btns_wrapp {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  justify-self: end;
  margin-top: 2rem;
}

/* ---------------------- rana's code start ------------------------ */

/* protect asset3rd */
.third_option_1_main {
  width: 100%;
}
.third_option_1 {
  display: flex;
  margin: 26px 0px;
  width: 100%;
}
.third_option_1 > img {
  width: 20px;
  height: 20px;
  /* margin-right: 12px; */
}
.third_option_1 > p {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  margin-left: 11px;
}
.input_op1 {
  display: flex;
  /* border-bottom: 1px solid pink; */
  margin-top: 38px;
  width: 100%;
}
.input_op1 > img {
  width: 21px;
  height: 21px;
  margin-top: 7px;
}
.img_email {
  width: 22px !important;
  height: 17px !important;
  margin-top: 9px !important;
}
.input_op1_input {
  border: none;
  margin-left: 2px;
  font-weight: 350;
  font-size: 13px;
  font-family: var(--font-600);
  color: white;
  letter-spacing: 0.3px;
}
.in_line {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
}
.input_op1_input::placeholder {
  font-weight: 350;
  font-size: 12px;
  font-family: var(--font-600);
  /* color: var(--color-white); */
  /* color: red; */
}
/* ---------------------- rana's code end ------------------------ */

/* ---------------------- varna's code start --------------------- */

.new_proasset_inner_page_wrapp {
  width: 100%;
  height: 100%;
  padding: 2rem 0px;
  margin-bottom: 2rem;
}
.new_proasset_inner_div {
  width: 100%;
  height: 100%;
  padding: 2rem 0px;
  margin-bottom: 2rem;
  /* margin-top: 5rem; */
}
.new_proasset_input_icon_div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.new_pro_input_icon {
  width: 21px;
}
.new_pro_select_icon {
  width: 13px;
  /* height: 12px; */
}
.new_proasset_input {
  border: none;
  margin-bottom: 0;
  background: none;
  font-size: 12px;
  font-family: var(--font-roundedbold);
  font-weight: 350;
  /* color: #6a6a6a; */
  line-height: 30px;
}
.new_proasset_heading_border {
  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
  width: 35%;
  height: 1px;
  margin: 4px auto;
}
.npasset_input_border {
  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
  width: 100%;
  height: 2px;
  margin: 4px auto;
  margin-bottom: 3rem;
}
.new_proasset_heading {
  font-family: var(--font-600);
  font-size: 22px;
  font-weight: 350;
  line-height: 150%;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
  margin-top: 10px;
}
.new_proasset_title_div > p {
  font-size: 15px;
  font-family: var(--font-roundedbold);
  font-weight: 350;
  text-align: center;
}
.new_proasset_upload_div {
  margin-top: 35px;
}
.new_proasset_upload_btn {
  background: linear-gradient(
    rgb(255, 0, 76) 0%,
    rgba(202, 172, 255, 0.43) 100%
  );
  opacity: 1;
  min-height: 140px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 1px;
}
.new_proasset_upload_btn > div {
  width: 100%;
  height: 162px;
  background: var(--color-black);
  border-radius: 8px;
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_proasset_upload_btn > div > img {
  width: 21px;
}
.new_proasset_page_change_btns_wrapp {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 60%;
  padding: 8px;
  max-width: var(--max-width-sm);
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
}
.new_proasset_creator_img_wrapp {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem auto;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  border-radius: 10px;
  overflow: hidden;
  padding: 1px;
}
.new_creator_img_upload_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  /* border: 2px dashed #ccc; */
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background: var(--color-black);
  margin: 0px;
  gap: 10px;
}

.new_proasset_page_change_btns_wrapp > button > img {
  width: 70px;
}
.new_proasset_submit_btn_div {
  width: 100%;
}
.new_proasset_submit_btn {
  width: 100%;
  border-radius: 41.5px;
  height: 67px;
  border: 1px solid;
  border-image-source: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset,
    /* First shadow */ 0px 4px 14px 0px #9a92d24d inset,
    /* Second shadow */ 0px 39px 99px -49px #caacff73 inset; /* Third shadow */
  color: #ffffff;
  font-size: 16px;
  font-family: var(--font-roundedbold);
}

/* ---------------------- varna's code end ----------------------- */
