.Partnerbenefits_screen {
  display: flex !important;
  flex-direction: column !important;
  background-color: black !important;
  height: 100vh !important;
  overflow: scroll !important;
  padding: 20px;
}

.Partnerbenefitss_header {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
}

.part-image-1 {
  transform: rotate(0deg);
  float: right;
  width: 75px;
  position: relative;
}

.part-image-2 {
  transform: rotate(275deg);
  width: 75px;
}

.part_details {
  text-align: center;
  padding: 0px 35px 0px 35px;
}

.img_part {
  width: 100px;
}

.part_slid_details {
  background-image: linear-gradient(to left, white, gray);
  height: 100%;
  padding: 10px;
  text-align: center;
  width: 100%;
  border-radius: 30px;
}

/* 22-09-2023 */
/* page base design start */

.part_benemain_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.part_benebase_wrapp {
  width: 90%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.part_set_details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.part_set_main {
  width: 100%;
  height: 280px;
  padding: 1px;
  position: relative;
  border-radius: 10px;
}

.part_set_img {
  width: 100%;
  height: 100%;
  padding: 1px;
  border-radius: 10px;
  object-fit: fill;
}
.part_set_details_sub {
  background-image: linear-gradient(to bottom, #000000, rgb(239, 13, 50));
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.part_set_details_sub_btn {
  background-color: white;
  color: #000000;
  padding: 10px;
  font-family: var(--font-600) !important;
  border-radius: 15px;
  width: fit-content;
  font-size: 10px;
  margin-top: 10px;
  font-weight: 700;
}
.partner_data_re {
  text-align: center;
  margin-top: 15%;
}

/* 16-10-2023 */
.part_date_modal {
  background-color: var(--color-main);
  color: white;
  font-size: 15px;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.date_select_new_prt1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.date_select_new_prt {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.date_select_new_prt3 {
  display: flex;
  width: 100%;
  justify-content: start;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.p_date_inof1 {
  font-size: 25px;
  position: relative;
  bottom: 12px;
}
.p_date_inof {
  font-family: var(--font-600);
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
}
.select_date_part_in {
  width: 15% !important;
  background-color: transparent !important;
  height: 30px;
  text-align: center;
}
.select_nw_modal {
  background-color: #333333ed;
  padding: 20px;
  width: 100%;
  height: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}
/* 16-10-2023 */

/* video modal start */

.benefit_videomodal_wrapp {
  width: 100vw;
  height: 101vh;
  background: var(--color-black);
  position: fixed;
  z-index: 111;
  padding: 20px 10px;
  top: 0px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.recourd_video_wrapp {
  width: 300px;
  height: 500px;
  /* border: 1px solid red; */
  border-radius: 28px;
  overflow: hidden;
  position: relative;
}

.recourd_video_btns {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  /* height: 40px; */
  background-color: rgba(255, 255, 255, 0.5);
  /* z-index: 1112; */
  bottom: 0px;
  left: 5%;
  border-radius: 40px;
  /* gap: 40%; */
  padding: 12px 16px;
  text-align: center;
}

.recourd_text_wrapping {
  position: absolute;

  width: 90%;
  padding: 14px;
  /* height: 40px; */
  background-color: #433f3f70;
  /* z-index: 1112; */
  top: 10px;
  left: 5%;
  border-radius: 8px;
  text-align: center;
  max-height: 16vh;
  overflow-y: scroll;
  padding: 10px auto;
}

.recourd_text_wrapping > p {
  color: white;
  font-family: var(--font-400);
  font-size: 14px;
  text-align: center;
}

.benfit_video_back_btn {
  width: 40px;
  height: 25px;
  object-fit: contain;
  align-self: flex-start;
}

.benefit_video_back_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.recourd_warning_text_wrapping {
  position: absolute;

  width: 90%;
  padding: 14px;
  /* height: 40px; */
  /* background-color: #433f3f70; */
  /* z-index: 1112; */
  top: 6%;
  left: 5%;
  border-radius: 40px;
  text-align: center;
  /* max-height: 20px; */
  overflow-y: scroll;
  padding: 10px auto;
}

.recourd_warning_text_wrapping > p {
  color: var(--color-main);
  font-size: 12px;
  font-family: var(--font-400);
}
/* video modal end */
