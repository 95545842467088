.new_founder_video_main_wrapp {
  width: 100%;
  height: 100%;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
}

.new_founder_video_heading {
  font-family: var(--font-600);
  font-size: 20px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.new_founder_sample_video_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
  position: relative;
}

.new_founder_sample_video {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  aspect-ratio: 4 / 1;
  object-fit: fill;
}

.new_founder_sample_video_play_pause_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  width: 60px;
  height: 60px;
}

.new_founder_sample_video_play_pause_btn > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.7;
}
