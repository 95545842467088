.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_5_main_img_front {
  background-image: url(./../../assets/images/newGoldenCardFront.png);
  background-size: 100% 100%;
  width: 300px;
  height: 500px;
  backface-visibility: hidden;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.crad_5_main_img_back {
  background-image: url(./../../assets/images/newGoldenCardBack.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_5_img_front {
  z-index: -1;
  position: relative;
  width: 94%;
  object-fit: cover;
  height: 87%;
  top: 2%;
  left: 8px;
}

.crad_5_img_back {
  z-index: -1;

  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  left: 8px;
  border-radius: 15px 15px 0px 0px;
}

/* 29-08-2023 */

.card_five_detail_wrapp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 84%;
  margin: 0px auto;
  bottom: 20%;
  position: absolute;
  /* position: relative; */
}
.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}
.nm_of_user_5_ {
  font-size: 14px;
  font-family: var(--font-600);
  text-align: center;
  position: relative;
}
.crad_5_qr_front {
  width: 46px;
  position: relative;
}
.card_five_detail_sec_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
}
.nm_of_cre_titel {
  font-size: 10px;
  font-family: var(--font-300);
  color: var(--color-white);
}
.nm_of_cre_nmr {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.card_five_detail_sec_2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 48%;
}
.sc_img_f {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm {
  font-size: 12px;
}
.sc_price {
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}
.card_five_title_wrapp {
  position: absolute;
  bottom: 30%;
  text-align: center;
  width: 86%;
  margin: 0px auto;
}

.card_five_title {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.crad_5_qr_front_div {
  /* position: absolute; */
  /* bottom: 18%; */
  margin-bottom: 18px;
  text-align: center;
}
/* back  */
.sc_f_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}
.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 70%;
  position: relative;
  left: 6%;
}
.sc_b_details_5 {
  position: absolute;
  bottom: -36px;
  left: 40%;
  text-align: center;
  width: 70%;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}
.sc_img_f_back {
  width: 75px;
  height: 75px;
  background-color: white;
}
.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 22px;
}

.cardfive_back_det_wrapp {
  position: absolute;
  bottom: 20%;
  left: 8%;
  padding: 4px;
  width: 84%;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.cardfive_det_text {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.card_five_logo_front {
  position: absolute;
  right: 10px;
  bottom: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  /* transform: rotate(180deg); */
}

.card_five_logo_back {
  position: absolute;
  left: 10px;
  bottom: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  transform: rotate(180deg);
}
