.Purchase_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 12px;
}
.Purchase_btn_set1 {
  width: 75%;
}
.Purchase_btn_set2 {
  width: 25%;
}
.show_second_img1 {
  width: 20px;
  float: right;
  position: relative;
  right: 10px;
}

/* 25-09-2023 */

.purchesh_new7 {
  background-image: url(./../../assets/images/Card11front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new7_img {
  z-index: -1;
  /* border-radius: 10px; */
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 74%;
  top: 0px;
  left: 0;
}
.purchase_front_details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 15px;
  height: 40%;
}
.purchesh_new_front-del {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}
.showcard_nm_set {
  font-size: 22px;
  letter-spacing: 4px;
  font-family: var(--font-400);
}

.show7_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardseven {
  font-size: 15px;
  font-family: var(--font-700);
  /* font-weight: 900; */
  letter-spacing: 10px;
  padding-top: 5px;
}

.purchase_del_7 {
  display: flex;
  padding-top: 25px;
  width: 80%;
}

.purchase_del_7_sub1 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.purchase_del_7_sub2 {
  width: 30%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-7-date {
  font-weight: var(--font-700);
  letter-spacing: 8px;
  font-size: 12px;
}

.s-card-7-price {
  font-weight: var(--font-600);
  font-size: 20px;
}

.s-d-7card_qr {
  width: 44px;
  height: 44px;
}

/* 25-09-2023 */

.purchase_del_7_front {
  position: absolute;
  justify-content: center;
  bottom: 21%;
  padding: 0 25px;
  line-height: 17px;
  width: 100%;
}
.del7_1 {
  width: 67%;
  text-align: center;
}
.del7_2 {
  width: 33%;
  /* right: -11px; */
  position: relative;
  left: 15px;
  top: 3px;
}
.front_nm7 {
  font-family: var(--font-600);
}
.crad7_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 28%;
  width: 95%;
  padding: 12px;
}
.card7_back_owner {
  position: absolute;
  bottom: 20%;
  text-align: center;
  width: 100%;
  padding: 0 45px;
}
.show_1_details_front7 {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1%;
  left: 3%;
  padding: 5px;
  width: 96%;
  position: relative;
  bottom: 17%;
}
.front_nm_product1_7 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  /* line-height: 30px; */
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.card_1_img_back7p {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 83%;
  left: 0;
  top: 0;
}
.show_1_details_front7 {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0%;
  padding: 5px 25px;
  width: 100%;
  bottom: 11%;
}
.front_card_sub_details_inner7 {
  /* position: relative; */
  /* display: flex; */
  /* flex-direction: row; */
  /* width: 100%; */
  /* margin-top: 15%; */
}
.img_front_card7_1_logo {
  width: 40px;
  position: absolute;
  bottom: 5%;
  height: 40px;
  right: 0;
}
.div_card7_1 {
  width: 100%;
  line-height: 3px;
  position: relative;
}
.front_nm7_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.front_card7_sub_details {
  width: 50%;
  line-height: 15px;
}
.logo_qr_pur_7 {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 5%;
}
.div_card_7_pur {
  width: 20%;
}
.img_front_card_7_logo_pur {
  width: 35px;
  position: relative;
  top: 20%;
  left: 6%;
  height: 35px;
  transform: scaleX(-1);
}
.del7_2_pur {
  width: 80%;
  position: relative;
  left: 68px;
  top: 3px;
}
.s-d-7card_qr_pur {
  width: 43px;
  height: 43px;
}
.ditails_of_back_cs_pur7 {
  position: absolute;
  bottom: 19%;
  padding: 42px 20px;
  line-height: 15px;
  width: 100%;
}
.front_nm_2_7 {
  font-family: var(--font-800) !important;
  font-size: 10px;
}
