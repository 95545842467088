.newtnc_main_wrapp {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1111;
  background: #000;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: scroll;
}

.tandc_backbtn_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem 5%;
}

.tandc_heading {
  width: 100%;
  font-family: var(--font-400);
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
}

.tandc_deswrapp {
  width: 100%;
  display: inline-block;
  padding: 1.5rem 5%;
}

.tandc_deswrapp > p {
  font-family: var(--font-300);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: justify;
  color: var(--color-white);
  margin: 1rem auto;
  display: inline-block;
}
