.benefitsoffered_main {
  display: flex;
  padding: 10px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.benefitsoffered_2 {
  width: 920px;
  margin: 10px auto;
  max-width: var(--max-width-sm);
}

.benefitsoffereds_user_menu {
  left: 15px;
  position: relative;
}

.benefitsoffered_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.benefitsoffered_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.benefitsofferedmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.benefitsoffered_main_h1 {
  position: relative;
  right: 25px;
}

.benefitsoffered_club_user {
  width: 100%;
  padding: 10px;
}

.benefitsoffered_h1 {
  font-family: var(--font-700);
  position: relative;
  right: 10%;
}

.nodatafound {
  font-family: var(--font-600);
  text-align: center;
}

.benefitsofferedmain1 {
  width: 20%;
}

.benefitsoffered_club_user {
  width: 100%;
  padding: 2px;
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: space-between; */
}

.benefits_details_set {
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 350px; */
  background-color: #000;
  /* border: 2px solid var(--color-main); */
  position: relative;
}

.benefits_details_set::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px; 
  padding: 1.5px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}


.benefits_off {
  color: #fff;
  font-family: var(--font-700);
  font-size: 18px;
  margin-bottom: 8px;
}

.benefits_off_p {
  color: #fff;
  font-size: 12px;
  font-family: var(--font-400);
  /* left: 0px !important; */
}
.action_benefits {
  background-color: var(--color-main);
  color: #fff;
  border-radius: 10px;
  font-family: var(--font-600) !important;
  text-align: center;
  float: right;
  margin-top: 10px;
  padding: 10px;
  width: 30%;
  height: fit-content !important;
}

.user_benefit_img {
  width: 100%;
  height: 185px;
  border-radius: 8px;
  overflow: hidden;
}

@media only screen and (max-width: 920px) {
  .benefitsoffered_club_user {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .benefitsoffered_club_user {
    justify-content: center;
  }
}
