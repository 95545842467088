.page_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  position: relative;
}

.resell_div {
  display: flex;
}

.re_title {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

.resell_input_nm {
  width: 25%;
  border: 1px solid gray !important;
  border-radius: 25px;
  float: right;
}

.re_div_hr {
  height: 2px;
  background-color: var(--color-main);
}

.resell_div2 {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.resell_input_title {
  border: 1px solid gray !important;
  border-radius: 25px;
  background-color: gray;
  color: white;
  font-family: var(--font-600);
  height: fit-content;
  padding: 10px;
  margin-top: 10px;
}

.resell_input_title::placeholder,
.resell_input_title3::placeholder {
  color: white;
}
.select_resel_cato {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  overflow-x: scroll;
}
.select_resel_cato_sub {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.select_resel_cato::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.select_resel_cato {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.resellcreateproduct_nm {
  font-size: 10px;
  text-align: center;
}
.img_resel_new {
  background-color: black;
  border-radius: 15px;
  border: 1px solid gray;
  width: fit-content;
  height: 60px;
  padding: 10px;
}
.img_resel_new_border {
  background-color: gray;
  border-radius: 15px;
  border: 1px solid gray;
  width: fit-content;
  height: 60px;
  padding: 10px;
}
.img_of_resell {
  width: 40px;
}
.resell_input_title2 {
  border: 1px solid gray !important;
  border-radius: 25px;
  background-color: gray;
  color: white;
  font-family: var(--font-600);
  height: fit-content;
  padding: 10px;
  width: 60px;
  margin-top: 10px;
  margin-left: 5px;
}
.re_title2 {
  font-family: var(--font-600);
  letter-spacing: 1px;
  font-size: 16px;
}
.resell_div_3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.re_title3 {
  font-family: var(--font-600);
  letter-spacing: 1px;
  font-size: 20px;
  width: 65%;
  margin-top: 10px;
}

.resell_input_title3 {
  border: 1px solid gray !important;
  border-radius: 25px;
  width: 35%;
  background-color: gray;
  color: white;
  font-family: var(--font-600);
  height: fit-content;
  padding: 10px;
  margin-top: 10px;
  margin-left: 5px;
}
.img_of_list {
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;
  overflow-x: scroll;
}
.img_of_resellist {
  width: 75px;
  height: 75px;
}
.img_of_resellist_border {
  width: 75px;
  height: 75px;
  border: 3px solid var(--color-main) !important;
}
.resell_div_4 {
  display: flex;
}
.re_title4 {
  width: 50%;
  font-family: var(--font-600);
  letter-spacing: 1px;
  font-size: 19px;
}
.radio_btn_resel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 50%;
}

.resell_btn_new {
  background-color: var(--color-main);
  color: white;
  font-family: var(--font-600);
  width: 60%;
  padding: 10px;
  border-radius: 18px;
  font-size: 18px;
}
.resell_radio_title {
  border: 2px solid gray; /* Set the border color to gray */
  color: gray;
  /* Add any other styling you want here */
}
