.create_product_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  position: relative;
}

.create_product_base_wrapp {
  max-width: var(--max-width-md);
  width: 90%;
  height: 100%;
  margin: 10px auto;
}

/* navbar start */
.cp_navbar_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px auto;
  width: 100%;
  height: 40px;
}

.nav_back_btn {
  height: 25px;
  object-fit: contain;
}
/* 
.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  margin-left: 22%;
  align-self: center;
  justify-self: center;
} */
/* navbar end */

/* form start */

.product_form_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.form_input_label {
  font-size: 14px;
  margin-bottom: 8px;
  font-family: var(--font-600) !important;
}

.form_input_box {
  border: 1px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-600) !important;
  font-size: 12px;
  min-height: 40px;
}

.form_input_box::placeholder {
  color: #888; /* Change this to your desired color */
}

/* custom input start*/

.custom_radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 10px;
  font-family: var(--font-800);
  font-weight: bold;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 25px;
  width: max-content;
  margin-right: 5%;
}

#custom_radio {
  display: flex;
  /* gap: 1rem; */
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  border-radius: 23px !important;
  background-color: var(--color-main2);
  border: 1.5px solid #ccc;
  border-radius: 3px;
}

.custom_radio:hover input ~ .checkmark {
  background-color: var(--color-main2);
}

.custom_radio input:checked ~ .checkmark {
  background-color: var(--color-main2);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom_radio input:checked ~ .checkmark:after {
  display: block;
}

.custom_radio .checkmark:after {
  left: 18%;
  top: 18%;
  width: 65%;
  height: 65%;
  border-radius: 50%;
  background: var(--color-main);
  /* border: solid white; */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.cbond_form_radio_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 2rem; */
  width: 100%;
}

/* custom input end*/

.image-upload {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  gap: 10px;
  width: 100%;
  margin-top: 10px;
}

.upload-button > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.upload-button {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 70px;
  border: 2px dashed #ccc;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.upload-icon {
  font-size: 32px;
  color: #aaa;
}

.upload-text {
  margin-top: 5px;
  color: #aaa;
}

#file-input {
  display: none;
}

#media_img_vid {
  display: none;
}

#creator_vid {
  display: none;
}

#min_1_img {
  display: none;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* margin-top: 20px; */
}

.image-preview {
  position: relative;
  width: 70px;
  height: 70px;
}

.image-preview img {
  width: 100%;
  height: 100%;
  margin: 0px 10px;
  border-radius: 10px;
  object-fit: contain;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  /* color: white; */
  border: none;
  /* padding: 2px 8px; */
  /* font-size: 12px; */
  cursor: pointer;
  /* border-radius: 4px; */
  width: 20px;
  height: 20px;
}

.delete-button > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* interest start */
.product_interests_wrapp {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.product_interests_wrapp::-webkit-scrollbar {
  /* Hide the default scrollbar for WebKit-based browsers */
  display: none;
}

.interest_sigle_btn {
  min-width: 75px;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  gap: 4px;
  text-overflow: ellipsis;
  padding: 5px;
}

.interest_sigle_icon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.interest_sigle_icon_name {
  font-size: 8px;
  font-family: var(--font-800);
}
/* interest wrapp */

/* glob exclusive start */

.glob_col_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.glob_row_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.glob_row_wrapp > .form_input_label {
  width: 45%;
}

.glob_row_wrapp > #custom_radio {
  width: 45%;
}

.red_err_text {
  font-size: 10px;
  color: red;
  align-self: flex-start;
}

/* glob exclusive end */

/* product value start */
.product_val_input {
  width: 24%;
  border: 1px solid #888;
  font-size: 12px;
  min-height: 30px;
  font-family: var(--font-600) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* product value end */

.share_nfb_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 10px;
  background: var(--color-main);
  color: var(--color-white);
  font-family: var(--font-600) !important;
  border-radius: 10px;
  margin: 10px auto;
}

.product_submit_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: bold;
  min-height: 40px;
  padding: 10px;
  background: var(--color-main);
  color: var(--color-white);
  font-family: var(--font-700) !important;
  border-radius: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
}

/* form end */

/* creator_modal_main_warp */

.creator_modal_main_warp {
  /* display: flex; */
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.creator_modal_base_warp {
  width: 100%;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}
.search {
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

.item-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid white;
  padding: 20px 0;
  width: 100%;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

.item-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px; /* Add some space between the image and label */
}

.item-container div {
  color: white;
}

.available_nfb_modal_input {
  width: 100%;
  padding: 10px;
  background: var(--color-white);
  color: var(--color-black) !important;
  font-size: 12px;
  font-family: var(--font-400);
}

button.available_nfb_modal_input {
  width: 100%;
  padding: 10px;
  background: var(--color-white);
  color: var(--color-black) !important;
  font-size: 12px;
  font-family: var(--font-400);
  border-radius: 28px;
  margin-bottom: 10px;
}

.available_nfb_modal_input::placeholder {
  color: var(--color-gray);
}
.available_nfb_modal_heading {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
  text-align: center;
}

.all_user_modal {
  width: 100vw;
  height: 100vh;
  background: var(--color-black);
  position: fixed;
  z-index: 111;
  border: 1px solid #fff;
  padding: 10px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
}

/* attach video start */

.attach_video_btn_cp {
  width: 80px;
  height: 80px;
  padding: 10px;
  border: 1px solid var(--color-main);
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  text-align: center;
  border-radius: 12px;
  align-self: flex-start;
}

.av_videos_wrapp {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  height: 90vh;
}

.av_single_video {
  width: 48%;
  margin-bottom: 4%;
  /* max-width: 130px; */
  border: 2px dashed var(--color-gray);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  border-radius: 12px;
  position: relative;
}

.av_of_checkbox {
  /* position: absolute !important; */
  /* top: 0px; */
  /* right: 0px; */
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.av_of_checkbox:checked::before {
  content: "✔";
  /*Displayacheckmarkcharacteroranyothersymbolyoulike*/
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /*Setthebackgroundcolorwhenchecked*/
  color: var(--color-white);
  /*Setthetextcolorwhenchecked*/
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.novideo_btn {
  width: 100%;
  border: 1px solid var(--color-main);
  font-size: 14px;
  color: var(--color-main);
  font-family: var(--font-600);
  padding: 10px;
  margin: 20px auto;
  text-align: center;
  border-radius: 12px;
}

/* attach video end */

/* media links start */

.cp_medialink_signle_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
}

.cp_ml_btns_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  gap: 5px;
}

.cp_ml_plus_btn {
  font-size: 16px;
  color: var(--color-white);
  background-color: var(--color-main);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-family: var(--font-600);
  margin: 0px;
  padding: 0px;
}

.cp_ml_mius_btn {
  font-size: 16px;
  color: var(--color-white);
  background-color: var(--trnsp-white);
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-family: var(--font-600);
  margin: 0px;
  padding: 0px;
}

/* media links end */

/* date picker start */

.date-input {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.cp_main_date_picker {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  /* border: 1px solid red; */
}

.datepicker_aaa {
  position: absolute;
}

/* date picker end */

.upload_video_btn_cp {
  width: 100%;
  border: 1px solid var(--color-main);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_video_btn_cp_text {
  font-size: 14px;
  font-family: var(--font-300);
  color: var(--color-white);
}
