.club_Walletscreen {
    display: flex!important;
    flex-direction: column!important;
    padding: 20px!important;
    background-color: black!important;
    height: 100vh!important;
    /* overflow: scroll!important; */
    max-width: var(--max-width-sm);
    margin: 0 auto;
}
.modal-overlay {
    position: fixed!important;
    top: 0!important;
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    background-color: rgba(0, 0, 0, 0.682)!important;
    max-width: var(--max-width-sm);
    margin: 0 auto;
    width: 100%;
}
.modal_w{
    position: fixed!important;
    bottom: 0%!important;
    background-color: #292929!important;
    padding: 20px!important;
    border-radius: 10px 10px 0px 0px!important;
    width: 100%!important;
    height: 40%!important;
    display: grid !important;
    max-width: var(--max-width-sm);
    margin: 0 auto;
}
.modal_img_wallet {
    width: 50px!important;
    position: relative!important;
    bottom: 45px!important;
    left: 45%!important;
}
.w_input {
    background-color: white!important;
    color: #292929 !important;
    height: 40px!important;
}
.footer_center {
    display: flex!important;
    flex-direction: row!important;
    width: 100%!important;
}
.can_1 {
    display: flex!important;
    flex-direction: row!important;
    width: 50%!important;
    display: flex!important;
    justify-content: center!important;
}
.can_1 button {
    background-color: #FFF!important;
    width: 100px!important;
    color: black!important;
    height: 40px!important;
    border-radius: 25px!important;
    padding: 10px!important;
    position: relative!important;
}

.can_2 {
    display: flex!important;
    flex-direction: row!important;
    display: flex!important;
    justify-content: center!important;
    width: 50%!important;
}
.can_2 button {
    background-color: #ee1651!important;
    width: 100px!important;
    color: white!important;
    height: 40px!important;
    border-radius: 25px!important;
    padding: 10px!important;
    position: relative!important;
}

.club_Walletback {
    width: 35px!important;
}

.total_club_Walletrupee {
    color: white!important;
    font-size: 30px!important;
}

.wl_titel {
    color: #726767!important;
}

.club_Walletheader_list {
    /* border: 1px solid white!important; */
    display: flex!important;
    flex-direction: row!important;
    height: 50px!important;
    width: 100%!important;
}

.club_add_with {
    display: flex!important;
    flex-direction: row!important;
    /* height: 50px!important; */
    width: 100%!important;
}

.club_add_with_1 {
    height: 100px!important;
    background-image: linear-gradient(148deg, #7b982a, #000)!important;
    padding-top: 15px!important;
    /* text-align: center!important; */
    align-items: center!important;
    display: flex!important;
    flex-direction: column!important;
    width: 48%!important;
    /* border: 1px solid #7b982a!important; */
    border-radius: 10px!important;
}

.club_Wallettran {
    width: 25px!important;
    position: relative!important;
    top: 10px!important;
    margin-bottom: 15px!important;
}

.add_name {
    font-size: 15px!important;
}

.club_add_with_c {
    width: 4%!important;
}

.club_add_with_2 {
    height: 100px!important;
    background-image: linear-gradient(148deg, #2e3192, #000)!important;
    text-align: center!important;
    width: 48%!important;
    /* border: 1px solid #2e3192!important; */
    padding-top: 15px!important;
    border-radius: 10px!important;
}

.club_Walletheader_list_1 {
    width: 20%!important;
}

.club_Walletheader_list_2 {
    width: 60%!important;
    padding: 15px 0px 0px 10px!important;
}

.club_Walletheader_list_3 {
    width: 20%!important;
}

@media (max-width: 600px) {
    .club_Walletscreen {
        display: flex!important;
        flex-direction: column!important;
        padding: 20px!important;
        background-color: black!important;
        height: 100vh!important;
        overflow: scroll!important;
    }
    .club_Walletscreen_wrap {
        margin: 0 0;
    }
    .club_Walletback {
        width: 35px!important;
    }

    .total_club_Walletrupee {
        color: white!important;
        font-size: 30px!important;
    }

    .wl_titel {
        color: #726767!important;
    }

    .club_Walletheader_list {
        /* border: 1px solid white!important; */
        display: flex!important;
        flex-direction: row!important;
        height: 50px!important;
        width: 100%!important;
    }

    .club_add_with {
        display: flex!important;
        flex-direction: row!important;
        /* height: 50px!important; */
        width: 100%!important;
    }

    .club_Wallettran {
        width: 25px!important;
        position: relative!important;
        top: 10px!important;
        margin-bottom: 15px!important;
    }

    .add_name {
        font-size: 15px!important;
    }

    .club_add_with_c {
        width: 4%!important;
    }

    .club_add_with_2 {
        height: 150px!important;
        background-image: linear-gradient(148deg, #2e3192, #000)!important;
        text-align: center!important;
        width: 48%!important;
        /*border: 1px solid #2e3192!important;
        */
        padding-top: 30px!important;
        border-radius: 10px!important;
        -webkit-border-radius: 10px!important;
        -moz-border-radius: 10px!important;
        -ms-border-radius: 10px!important;
        -o-border-radius: 10px!important;
    }

    .club_Walletheader_list_1 {
        width: 20%!important;
    }

    .club_Walletheader_list_2 {
        width: 60%!important;
        padding: 15px 0px 0px 10px!important;
    }

    .club_Walletheader_list_3 {
        width: 20%!important;
    }

    .all_btn {
        display: flex!important;
        flex-direction: row!important;
        padding-top: 10px!important;
        margin-bottom: 15px!important;
    }

    .h1_new {
        font-size: 15px!important;
    }

    .button_new {
        border: 1px solid white!important;
        color: white!important;
        padding: 5px!important;
        height: 35px!important;
        width: 75px!important;
        margin-left: 10px!important;
        border-radius: 25px!important;
        text-align: center!important;
    }

    hr {
        border: 0.5px solid rgba(136, 136, 136, 0.486)!important;
    }

    .club_all_list {
        display: flex!important;
        flex-direction: row!important;
        width: 100%!important;
        padding: 15px!important;
    }

    .club_all_list1 {

        width: 20%!important;
    }

    .club_all_list2 {
        width: 60%!important;
    }

    .club_all_list3 {
        width: 20%!important;
    }
    .credit_p {
        color: #16ee28!important;
        position: relative!important;
    }
    .debit_p {
        color: #ee1651!important;
        position: relative!important;
    }
    .button_new.active {
        background-color: gray!important;
        border:none!important;
      }
      

    /* modal  */
    /* styles.css */

    .modal_w {
        position: fixed!important;
        bottom: 0%!important;
        background-color: #292929!important;
        padding: 20px!important;
        border-radius: 10px 10px 0px 0px!important;
        width: 100%!important;
        height: 40%!important;
        display: grid !important;
    }

    .modal-overlay {
        position: fixed!important;
        top: 0!important;
        left: 0!important;
        right: 0!important;
        bottom: 0!important;
        background-color: rgba(0, 0, 0, 0.682)!important;
    }

    h3 {
        font-size: 17px!important;
        text-align: center!important;
    }

    .modal_img_wallet {
        width: 50px!important;
        position: relative!important;
        bottom: 45px!important;
        left: 40%!important;
    }

    .footer_center {
        display: flex!important;
        flex-direction: row!important;
        width: 100%!important;
    }

    .can_1 {
        display: flex!important;
        flex-direction: row!important;
        width: 50%!important;
        display: flex!important;
        justify-content: center!important;
    }

    .can_1 button {
        background-color: #FFF!important;
        width: 100px!important;
        color: black!important;
        height: 40px!important;
        border-radius: 25px!important;
        padding: 10px!important;
        position: relative!important;
        
    }
    .can_2 {
        display: flex!important;
        flex-direction: row!important;
        display: flex!important;
        justify-content: center!important;
        width: 50%!important;
    }

    .can_2 button {
        background-color: #ee1651!important;
        width: 100px!important;
        color: white!important;
        height: 40px!important;
        border-radius: 25px!important;
        padding: 10px!important;
        position: relative!important;
    }

    .w_input {
        background-color: white!important;
        color: #292929 !important;
        height: 40px!important;
    }

    .w_input::placeholder {
        color: #292929 !important;
    }

    .close-icon {
        background: none!important;
        border: none!important;
        font-size: 20px!important;
        cursor: pointer!important;
    }

}