.legacy_dashbord_nav_sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 5%;
}

.legacy_dashbord_nav_sec > p {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 1rem;
}

.legacy_dashbord_nav_sec > img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.legacy_dashbord_sec1 {
  width: 90%;
  margin: 1rem 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legacy_dashbord_sec1_create_cumunity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 80px;
}

.legacy_dashbord_sec1_create_cumunity > img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.legacy_dashbord_sec1_create_cumunity > p {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 12px;
}

.legacy_dashbord_sec1_user_wrapp {
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004c5c inset, 0px 4px 14px 0px #caacff21 inset,
    0px 39px 99px -49px #caacff73 inset;

  width: 120px;
  height: 120px;
  border-radius: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.legacy_dashbord_sec1_user_wrapp > a {
  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.legacy_dashbord_sec1_user_wrapp > a > img {
  background: #000;
  width: 94px;
  height: 94px;
  border-radius: 94px;
  object-fit: cover;
  overflow: hidden;
  object-position: top;
}

.legacy_artist_name_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 90%;
  margin: 1rem 5%;
}

.legacy_artist_name_wrapp > h2 {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 1.5rem;
}

.legacy_artist_name_wrapp > p {
  font-family: var(--font-400);
  color: var(--color-white);
  font-size: 14px;
}

/* year slider start */

.year-slider {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar for modern browsers */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  padding: 6px 2rem;
}

.year {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  font-family: var(--font-600);
  color: #555555;
  font-size: 1rem;
}

.year .selected {
  /* text-shadow: 3px 0 #130c0e, -3px 0 #ff004c, 0 3px #ff004c, 0 -3px #130c0e,
    1px 1px #130c0e, -1px -1px #130c0e, 1px -1px #130c0e, -1px 1px #130c0e; */

  text-shadow: 0px -1px #130c0e, -1px -1px 6px #ff004c, 0 3px #ff004c,
    0 -3px #130c0e, 1px 1px #130c0e, -1px -1px #130c0e, 1px -1px #130c0e,
    -1px 1px #130c0e;

  font-family: var(--font-600);
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 10px;
}

.center-box {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  background-color: rgba(0, 0, 0, 0.1); /* Background color for center box */
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.legacy_years_list_wrapp {
  position: relative;
  display: flex;
  align-items: center;
}

.nav-button {
  background: linear-gradient(47deg, black, transparent);

  border: none;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  /* border-radius: 30px; */
  width: 45px;
  height: 45px;

  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.nav-button.left {
  left: -10px;
}

.nav-button.right {
  right: -10px;
}

/* year slider end  */

/* milestone button start */

.legacy_milstone_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  gap: 10px;
}

.legacy_milstone_btn1 {
  /* width: 100%; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  border: none;
  border-radius: 50px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;

  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
  padding: 10px 1.5rem;
}

/* milestone button end */

/* milestone cards start */

.legacy_milestone_cards_wrapp {
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}

.legacy_milestone_single_card {
  width: 100%;
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 18px;
  overflow: hidden;
}

.legacy_milestone_single_card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 16.5px;
  padding: 1.5px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.legacy_milestone_single_card_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 18px; */
}

.legacy_milestone_single_card_blur_wrapp {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.16);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(17.6px);
  -webkit-backdrop-filter: blur(17.6px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.legacy_milestone_single_card_blur_wrapp > p {
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-400);
}

/* milestone cards end */

.year-selector-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.year-selector {
  display: inline-block;
  padding: 10px;
}

.year-item {
  display: inline-block;
  min-width: 100px;
  padding: 10px;
  border: 1px solid black;
  margin: 0 5px;
  cursor: pointer;
}

.year-item.selected {
  background-color: #ccc;
  color: red;
}
