.sm_nav_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}

.sm_nav_wrap > img {
  width: 34px;
  height: 24px;
  object-fit: contain;
  /* border: 1px solid green; */
}

.sm_nav_wrap > p {
  color: var(--color-black);
  font-size: 16px;
  font-family: var(--font-700);
}

.sm_products_wrapp {
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  justify-content: space-between;
}

.sm_single_product {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.sm_single_product > img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
