.splashscreen_main_wrapp {
  display: flex;
  background: var(--color-black);
  width: 100%;
  height: 100%;
}

.splashscreen_base_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  height: 100%;
}

.splash_stardet_btn {
  width: fit-content;
  border-radius: 25px;
  background-color: var(--color-main);
  color: white;
  font-weight: bold;
  font-family: var(--font-700);
  position: absolute;
  bottom: 25px;
  left: 25px;
  padding: 15px;
}

.new_black_splash {
  width: 40%;
  position: absolute;
  top: 20px;
  left: 20px;
}

.new_black_splashh1 {
  word-wrap: break-word;
}

.Splashscreenslider {
  position: relative;
  width: 100vh;
  height: 100vh;
  display: flex;
}

.splash_screen_deltails {
  display: flex;
  position: absolute;
  top: 10%;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.Splashscreensliderimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splash_h1 {
  position: relative;
  left: 15px;
  color: black;
  font-size: 40px;
  line-height: 45px;
  font-family: var(--font-700);
}

.splash_h2 {
  position: relative;
  left: 15px;
  word-wrap: break-word;
  width: 90%;
  color: black;
  font-size: 18px;
  font-family: var(--font-600);
  font-weight: bold;
}

/* .splashscreen_base_wrapp,
.slick-dots {
    bottom: 12px;
} */
