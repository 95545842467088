/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  margin-left: 5%;
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.own_benefit {
  border-bottom: 1px solid var(--color-main);
  padding-bottom: 5px;
  margin-top: 15px;
}

.nm_of_own {
  font-family: var(--font-600);
  display: flex;
  gap: 5px;
}

.info_btn {
  color: var(--color-main);
  border: 1px solid;
  border-radius: 25px;
  width: 14px;
  margin-top: 4px;
  height: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.attach_nm_list {
  display: flex;
  width: 100%;
  /* height: 200px; */
  height: 160px;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 4px;
}
.link_attach {
  width: 120px;
  margin-top: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed;
  border-radius: 10px;
  padding: 10px;
  margin-right: 13px;
}
.link_attach_nm {
  font-size: 14px;
}
.link_attach2 {
  width: 120px;
  left: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  gap: 15px;
}

.details_of_benefit_2 {
  position: relative;
  text-align: center;
  width: 100px;
  /* height: 130px; */
}
.benefits_img_main {
  width: 100px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
}
.benefits_img_1 {
  width: 100px;
  height: 100px;
}
.nm_sub_benefit {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: var(--font-600);
  height: 18px;
  line-height: 12px;
}
.nm_sub_benefit2 {
  font-size: 12px;
  margin-bottom: 5px;
  font-family: var(--font-400);
  color: var(--color-white);
}

/*  */
.attach_partner_1 {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  margin-top: 14px;
}
.attach_partner_main {
  position: relative;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.attach_partner_select {
  position: absolute !important;
  top: 1px;
  right: 1px;
}

/* 05-092023 */
/* Default styling for checkbox */
.details_of_checkbox {
  position: absolute !important;
  top: 0px;
  right: 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.details_of_checkbox:checked::before {
  content: "✔";
  /*Displayacheckmarkcharacteroranyothersymbolyoulike*/
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /*Setthebackgroundcolorwhenchecked*/
  color: var(--color-white);
  /*Setthetextcolorwhenchecked*/
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.attach_partner_select {
  position: absolute !important;
  top: 0px;
  right: 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.attach_partner_select:checked::before {
  content: "✔";
  /*Displayacheckmarkcharacteroranyothersymbolyoulike*/
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /*Setthebackgroundcolorwhenchecked*/
  color: var(--color-white);
  /*Setthetextcolorwhenchecked*/
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.attach_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-600);
  border-radius: 28px;
}
.attache_new_set_div {
  text-align: center;
  margin-top: 10%;
  display: flex;
  justify-content: center;
}

.av_prev_btn {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-main);
}
