.forgot-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.web_screen_forgot {
  width: 100%;
  /* padding: 20px; */
}

.for1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgot-logo {
  width: 100%;
}

.web_img {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.web_img_for {
  width: 80%;
  position: relative;
  left: 5%;
}

.for2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 12px;
}

.hied_forgot {
  position: relative;
  float: right;
  bottom: 39px;
  width: 20px;
  right: 15px;
}

.forgot-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: black;
  height: 100vh;
}

.forgot_main {
  width: 50px;
  position: relative;
  bottom: 45px;
}

.forgot-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.forgot-name {
  font-size: 20px;
  color: #ee1651;
  position: relative;
  /* width: 60%; */
  text-align: left;
  margin-top: 20px;
  font-family: var(--font-700);
}

.forgot_forgot_pass {
  position: relative;
  float: right;
  color: white;
  left: 20px;
}

.forgot_des {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-300);
}

.hr_forgot {
  border: 1px solid gray;
  width: 100%;
  left: 0px;
}

b {
  color: white;
  font: 800;
}

.forgot-logo {
  width: 29%;
  float: left;
}

.forgot-input {
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  color: white;
  background-color: black;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.forgot-footer {
  position: relative;
  bottom: 15px;
  padding: 10px;
}

.forgot-button {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin-top: 40px;
}

.signup-text {
  color: #fff;
}

.signup-text a {
  color: #fff;
  font-weight: 800;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.forgot-container {
  /* Your existing styles for the container */

  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
  height: 100vh;
  transition: height 1s;
}

.modal-content {
  background-color: #333333cc;
  padding: 20px;
  width: 50%;
  height: auto;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close_forgot {
  color: #fff;
  background-color: #ee1651;
  border-radius: 25px;
  width: 100px;
  font-size: 15px;
  padding: 5px;
  height: 30px;
}

.modal_div {
  text-align: center;
}

.modal_div1 {
  text-align: center;
  height: 40px;
}

/* forgate otp design start */

.forgot_otp_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.forgot_submit_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin-top: 30px;
}

/* forgate otp design end */

@media only screen and (max-width: 600px) {
  .web_screen_forgot {
    width: 100%;
    padding: 0px;
  }

  .for1 {
    display: none;
  }

  .for2 {
    width: 100%;
  }

  .hied_forgot {
    position: relative;
    float: right;
    bottom: 39px;
    width: 20px;
    right: 15px;
  }

  .forgot-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: black;
    height: 100vh;
  }

  .forgot_main {
    width: 50px;
    position: relative;
    bottom: 45px;
  }

  .forgot-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .forgot-name {
    font-size: 20px;
    /* margin-bottom: 10px; */
    color: #ee1651;
    /* top: 15px; */
    text-align: left;
    margin-top: 20px;
    /* position: relative; */
    font-family: var(--font-700);
  }

  .forgot_forgot_pass {
    position: relative;
    float: right;
    color: white;
    left: 20px;
  }

  .hr_forgot {
    border: 1px solid white;
    width: 100%;
    left: 0px;
  }

  b {
    color: white;
    font: 800;
  }

  .forgot-logo {
    width: 29%;
    float: left;
  }

  .forgot-input {
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
  }

  input {
    padding: 10px;
    margin-bottom: 14px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    color: white;
    background-color: black;
  }

  .forgot-footer {
    position: absolute;
    bottom: 15px;
    padding: 10px;
  }

  .forgot-button {
    padding: 10px 20px;
    background-color: #ee1651;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    font-size: 17px;
    font-family: var(--font-600);
    border-radius: 28px;
    margin-top: 40px;
  }

  .signup-text {
    color: #fff;
  }

  .signup-text a {
    color: #fff;
    font-weight: 800;
    text-decoration: none;
  }

  .signup-text a:hover {
    text-decoration: underline;
  }

  .forgot-container {
    /* Your existing styles for the container */

    position: relative;
  }

  .modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    background-color: #5f5f5f00;
    display: flex;
    /* width: 100%; */
    align-items: flex-end;
    justify-content: center;
    z-index: 9999;
    transition: height 1s;
    flex-direction: column;
    height: 27vh;
  }

  .modal-content {
    background-color: #333333cc;
    padding: 20px;
    width: 100%;
    height: auto;
    /* max-width: 400px; */
    border-top-left-radius: 15px;
    border-top-right-radius: 10px;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  }

  .close_forgot {
    color: #fff;
    background-color: #ee1651;
    border-radius: 25px;
    width: 100px;
    font-size: 15px;
    padding: 5px;
    height: 30px;
  }

  .modal_div {
    text-align: center;
  }

  .modal_div1 {
    text-align: center;
    height: 40px;
  }
}
