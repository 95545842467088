.cub_select_bond_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cub_select_bond_btns_wrapp > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: left;
  color: var(--color-white);
}

.cub_select_bond_btns_wrapp > button {
  font-family: var(--font-400);
  font-size: 12px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: right;

  color: var(--color-main);
}

.cub_select_bond_cards_wrapp {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.cub_select_bond_single_card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  width: 120px;
  /* margin: 5px; */
  position: relative;
}

.cub_select_bond_single_card > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.cub_select_bond_single_card > p {
  font-family: var(--font-400);
  font-size: 10px;
  color: var(--color-white);
  width: 100%;
  text-align: left;
  text-wrap: nowrap;
}

/* modal start */

.user_benefit_allbonds_modal_wrapp {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 11;
  left: 0px;
  top: 0px;
  padding: 10px 5%;
}

.user_benefit_bond_modal_heding {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px;
  width: 100%;
  gap: 30%;
}

.user_benefit_bond_modal_heding > p {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
}

.user_benefit_modal_allbonds_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  height: 80vh;
  width: 100%;
  /* border: 1px solid red; */
  overflow-x: scroll;
}

.user_benefit_modal_single_bond {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 8px;
}

.user_benefit_modal_bond_hr {
  width: 100%;
  height: 1px;
  background: #ffffff28;
}

.user_benefit_modal_single_bond_inner_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_benefit_modal_single_bond_inner_wrapp > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.user_benefit_modal_single_bond_inner_wrapp > p {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
  width: 55%;
}

.user_benefit_modal_single_bond_inner_wrapp > input[type="checkbox"] {
  /* position: absolute !important; */
  /* top: 0px; */
  /* right: 0px; */
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.user_benefit_modal_single_bond_inner_wrapp
  > input[type="checkbox"]:checked::before {
  content: "✔";
  /*Displayacheckmarkcharacteroranyothersymbolyoulike*/
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /*Setthebackgroundcolorwhenchecked*/
  color: var(--color-white);
  /*Setthetextcolorwhenchecked*/
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* modal end */
