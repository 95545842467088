/* AadhaarInput.css */
.aadhaar-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aadhaar-input {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  padding: 12px 4px;
  outline: none; /* Remove outline on focus */
  max-width: 30px;
  font-size: 12px;
  text-align: center;
  font-family: var(--font-600);
  caret-color: var(--color-main);

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */
}

.aadhaar-input:focus {
  background-image: linear-gradient(
    to right,
    #ff9a9e,
    #fad0c4
  ); /* Adjust focus style if needed */
}

.new_aadhaar_input_wrapp {
  width: 100%;
  margin: 10px auto;
  padding: 5%;
}

.new_aadhaar_input_wrapp > p {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
  margin-bottom: 4px;
}

.new_aadhaar_cpatcha_wrapp {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  /* gap: 10px; */
  width: 100%;
}

.new_aadhaar_cpatcha_wrapp > img {
  width: 40%;
  height: 50px;
}

.new_aadhaar_cpatcha_wrapp > button {
  width: 16%;
  height: 40px;
  /* object-fit: contain; */
}

.new_aadhaar_cpatcha_wrapp > button > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.new_aadhaar_cpatcha_wrapp > input {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  padding: 4px 12px;
  outline: none; /* Remove outline on focus */
  width: 42%;
  font-size: 18px;
  text-align: left;
  font-family: var(--font-600);
  /* caret-color: var(--color-main); */

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */
  margin: 0px;
}

.new_aadhaar_btn_wrapp {
  width: 100%;
  margin-top: 2rem;
  padding: 0px 5%;
}

.new_aadhaar_btn_wrapp > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* margin-bottom: 10px; */
  gap: 10px;
}

.new_aadhaar_btn_wrapp > div > img {
  width: 20px;
  height: 20px;
  /* object-fit: contain; */
}

.new_aadhaar_btn_wrapp > div > p {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
}
