.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_7_main_img_front {
  background-image: url(./../../assets/images/Card11front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_7_main_img_back {
  background-image: url(./../../assets/images/card11back\ .png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_7_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 78%;
  top: 0%;
  left: 0;
}

.crad_7_img_back {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  left: 0;
  border-radius: 15px 15px 0px 0px;
  
}

/* 29-08-2023 */

.sc_f_details_7 {
  position: absolute;
  width: 100%;
  bottom: 22%;
  text-align: center;
}

.nm_of_user_7
{
  font-size: 14px;
  font-family: var(--font-600);
}
.crad_7_qr_front{
  width: 43px;
  position: relative;
  /* top: 7px; */
  height: 43px;
}

.nm_of_cre_nmr
{
  font-size: 15px;
}


.sc_f_details2_7
{
  width: 100%;
  text-align: center;
  position: absolute;
  /* height: 51px; */
  margin: 0 auto;
  /* top: 4px; */
  bottom: 23px;
}
.sc_img_f
{
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm
{
  font-size: 12px;
}
.sc_price
{
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3
{
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}


/* back  */
.ditails_of_back_cs_7 {
  position: absolute;
  bottom: 27%;
  padding: 0px 18px;
}
.sc_f_details1_back_7
{
  position: relative;
  text-align: center;
}
.sc_f_details_back1_7{
  position: relative;
  width: 100%;
  margin: 0 auto;
  bottom: 29%;
}

.sc_b_details_7 {
}

.nm_of_cre_titel_back
{
  font-size: 10px;
}
.nm_of_cre_nmr_back
{
  font-size: 15px;
}
.sc_f_details2_back
{
  width: 40%;
  text-align: center;
}
.sc_img_f_back
{
  width: 75px;
  height: 75px;
  background-color: white;
}
.ditails_of_back_cs7
{
  position: absolute;
  bottom: 27%;
  padding: 23px;
  line-height: 16px;
}
.readmore_card
{
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card_6 {
  font-size: 9px;
}
.sc_f_details_back_5 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 14%;
  position: relative;
}
.nm_of_cre_titel {
  font-size: 12px;
}
.sc_img_f_7 {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_f_details3_front_7{
  width: 13%;
  position: absolute;
  bottom: 22px;
  right: 0px;
}
.sc_b_details_back1_7{
  position: absolute;
  width: 100%;
  bottom: 20%;
}
.sc_f_details_back_7{
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 8%;
  position: absolute;
  padding: 0 29px;
  align-items: center;
  /* justify-content: center; */
  height: 67px;
}
.sc_f7_details2 {
  width: 50%;
  text-align: end;
  position: relative;

}
.sc_b_details2_7{
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: end;
  position: relative;
  right: 11%;
}
.sc_b_details1_7{
  position: relative;
  width: 50%;
  line-height: 15px;
}
.sc_f_details3_back_7{
  width: 13%;
  position: absolute;
  bottom: 22px;

}
.sc_img_b_7{
  width: 40px;
  height: 40px;
  float: left;
  transform: scaleX(-1);
}
.sc_f_details1_7 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  /* left: 9%; */
  
}