.new_login_main_wrapp {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.new_login_screen_text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 10px;
  margin: 1rem auto;
}

.new_login_screen_text_container > h4 {
  font-family: var(--font-600);
  font-size: 22px;
  color: var(--color-white);
  line-height: 125%;
}

.new_login_screen_text_container > p {
  font-family: var(--font-300);
  font-size: 16px;
  color: var(--color-white);
  line-height: 125%;
}

.new_login_inputs_wrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
}

.new_login_forgate_btn {
  width: 100%;
  /* margin: 10px 0px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.new_login_forgate_btn > button {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 12px;
  line-height: 125%;
}
