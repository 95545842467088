.NewModal_main_wrapp {
  width: 100%;
  height: 100%;
  min-width: var(--max-width-sm);
  /* min-height: 40vh; */
  max-height: 80vh;
  background-color: #3c3c3c;
  padding: 1rem;
  border-radius: 12px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  scale: 1.01;
  max-width: var(--max-width-sm);
}

.newmodal_titel {
  font-family: var(--font-600);
  color: #fff;
  font-size: 16px;
  margin: 10px auto;
  max-width: 90%;
}

.newmodal_des {
  font-family: var(--font-400);
  color: #fff;
  font-size: 12px;
  margin: 10px auto;
  text-align: center;
  overflow-y: scroll;
  max-height: 500px;
  min-height: 50px;
  margin-bottom: 10px;
}

.newmodal_btn {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: #fff;
  font-family: var(--font-400);
  background-color: var(--color-main);
  font-size: 14px;
}

.newmodal_des_wrapp {
  /* border: 1px solid red; */
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70%;
  overflow-y: scroll;
}

.newmodal_logo {
  width: 40px;
  object-fit: contain;
  /* margin-top: 20px; */
}

@media (max-width: 720px) {
  .NewModal_main_wrapp {
    min-width: 90vw;
    max-width: 90vw;
    /* min-height: 50vh; */
  }
}
