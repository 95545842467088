.home_main_wrapp {
  background-color: black;
  height: 100vh;
  width: 100vw;
  margin: 20px auto !important;
}

.slick-track {
  display: flex !important;
  gap: 10px;
}

.home_main {
  display: flex;
  justify-content: space-between;
}

.home_screen {
  color: white;
}

.auction_btn_home {
  width: 20px;
  height: 20px;
}
.auction_btn_homec {
  width: 22px;
  height: 22px;
}
/* 16-08-2023 */
.nfb-collection-new {
  display: flex;
  flex-direction: row;
}

.nfb-collection-new1 {
  width: 50%;
  position: relative;
  left: 15px;
  top: 18px;
  font-family: var(--font-600) !important;
  letter-spacing: 2px;
}

.nfb-collection-new2 {
  width: 50%;
  border: none;
  text-align: end;
  position: relative;
  right: 15px;
}

/* 16-08-2023 */

.react-multi-carousel-list {
  padding-bottom: 0rem;
}

.collection-img {
  width: 100%;
  height: 14rem;
  object-fit: cover;
}

.collection-cnt {
  gap: 1rem;
}

.creator-heading {
  font-size: 1.5rem;
  color: #fff;
  /* text-wrap: nowrap; */
}

.creator-para {
  color: #ccc;
}

.creator-div {
  margin: 2% 40%;
}

/* home */
.home_main_slider {
  margin-top: 30%;
  padding: 20px;
  width: 100vw;
}

/* .f-card
{
  width: 50vw!important;
}
.s-card
{
  width: 50vw!important;
}
.top-nfb-card
{
  width: 50vw!important;
}
.top-create-card
{
  width: 50vw!important;
} */

/* home */

@media (min-width: 768px) {
  .slick-track {
    min-width: 100%;
  }
}

@media screen and (max-width: 840px) {
  .creator-heading {
    font-size: 1rem;
  }

  .collection-img {
    height: 6rem;
  }

  .collection-cnt {
    gap: 0.3rem;
  }

  .home_main_slider {
    width: 100vw;
  }

  .list_of_slider {
    width: 100vw;
  }
}
