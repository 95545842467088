* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  text-decoration: none;
  list-style: none;
}
#main_h_1 {
  width: 55%;
}
.main_h_2 {
  /* width: 30%; */
  gap: 20px;
  display: flex;
}
.menu_header_icon {
  height: 30px;
}
.menu_main_titel {
  position: relative;
  left: 15px;
  font-weight: bold;
  padding-bottom: 10px;
}

.header_main_wrapp {
  position: fixed;
  width: 100%;
  padding: 1px;
  z-index: 1;
  height: 64px;
  top: 0px;
  background: var(--color-black);
  /* border: 1px solid white; */
}
.menu_close {
  color: white !important;
  width: 10px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.logo_one {
  width: 35px;
  border: 1px solid #e51a4b;
  border-radius: 25px;
  padding: 1px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.logout {
  position: relative;
  width: 90%;
  border: 2px solid rgb(229, 26, 75);
  padding: 10px;
  color: white;
  left: 15px;
  border-radius: 10px;
}
/* 
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.menu a {
  display: block;
  padding: 7px 15px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.2s all ease-in-out;
  color: #fff;
}

.menu:hover a {
  opacity: 0.4;
}

.menu a:hover {
  opacity: 1;
  color: #fff;
}

.menu-icon {
  display: none;
}

#menu-toggle {
  display: none;
}

#menu-toggle:checked ~ .menu {
  transform: scale(1, 1);
}

.product_titel {
  color: white;
  font-weight: 800;
}
/* 
hr {
  width: 90%;
  position: relative;
  text-align: center;
  left: 10px;
  border: 1px solid red;
} */

/* header menu  */
.header_logo_one {
  position: relative;
  width: 50px;
  height: 50px;
  left: 15px;
  top: 15px;
}

.menu_header_list {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  padding: 5px;
}
.h_1_list {
  width: 25%;
}
.h_2_list {
  width: 65%;
  padding: 15px 0px 0px 10px;
}
.h_3_list {
  width: 10%;
}

/* end header menu  */
@media only screen and (max-width: 950px) {
  .menu {
    flex-direction: column;
    background-color: #000;
    align-items: start;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    overflow-x: scroll;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    height: 100vh;
    padding-bottom: 20px;
  }

  .menu-icon {
    display: block;
    color: #e51a4b;
    font-size: 35px;
    cursor: pointer;
  }
  .close-icon {
    display: block;
    color: #e51a4b;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    /* font-weight: 900; */
    font-family: var(--font-700);
  }
}
