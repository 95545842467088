.bids13_page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
}
.bids13_page_base_wrapp_2 {
  width: 90%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 96vh;
  margin: 10px auto;
  margin-bottom: 105px;
}
.title_section {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 30px;
}
.back_btn {
  height: 16px;
  width: 8px;
  position: absolute;
  top: 7px;
  left: 0px;
}
.nav_title {
  font-size: 1rem;
  font-family: var(--font-600);
  color: var(--color-white);
}

.bids13_user_bid_main {
  padding: 0px;
  width: 97.5%;
  padding-top: 64px;
  position: relative;
}
.bids13_user_bid_search {
  height: 45px;
  padding: 15px;
  border-radius: 33px;
  box-shadow: inset #ff004c5c 7px 4px 20px 0px,
    inset #caacff21 -8px -4px 23px 0px;
  padding-left: 26px;
  border: none;
}
.bids13_user_bid_search::placeholder {
  color: #ffffff80;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
  font-family: var(--font_400);
  font-size: 13px;
}
.search_ic {
  position: absolute;
  /* left: 20%; */
  right: 3.51%;
  bottom: 29px;
  width: 15px;
  height: 15px;
}
@media (max-width: 400px) {
  .search_ic {
    position: absolute;
    /* left: 20%; */
    right: 4.37%;
    bottom: 29px;
  }
}

.bids13_club_user {
  width: 100%;
  /* padding: 15px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 28px;
  gap: 15px;
  justify-content: center;
}
.bids13_club_user_details {
  /* max-width: 350px; */
  width: 97%;
  /* width: 376px; */
  height: 340px;
  margin-bottom: 4px;
  background: #201d23;

  border-radius: 10px;
  /* border: 1px solid var(--color-main); */
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bid13_img_details {
  /* width: 345px; */
  width: 92%;
  height: 182px;
  padding: -7px;
  margin-left: 15px;
  margin-top: 14px;
  border-radius: 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.bid13_details_sub1 {
  border-radius: 8px;
  bottom: 21%;
  /* color: white; */
  position: absolute;
  padding: 15px 21px;
  width: 100%;
  display: flex;
}
.bids13_h11 {
  margin: auto;
  margin-left: 12px;
  font-size: 16px;
  font-family: var(--font-400);
}
.bids13_h1_rs {
  font-family: var(--font-600);
  /* position: relative; */
  color: white;
  font-size: 16px;
  width: 50%;
  margin: auto;
  /* display: flex; */
  text-align: end;
}

.bids13_rs {
  font-size: 16px;
  font-family: var(--font-400);
  color: white;
}
.bids13_profile {
  height: 40px;
  width: 40px;
  border: 1.2px solid #7166f9;
  border-radius: 25px;
}
.bids13_h1 {
  font-family: var(--font-600);
  position: relative;
  color: white;
  font-size: 16px;
  width: 50%;
  margin: auto;
  display: flex;
}

.bids13_btn_new {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 13px;
  position: absolute;
  padding: 8px 17px;
  bottom: 17px;
  align-items: center;
  justify-content: center;
}
.bids13_sub2_button_a {
  position: relative;
  /* border: 1px solid deeppink; */
  color: white;
  font-family: var(--font-400);
  font-size: 16px;
  padding: 10px;
  border-radius: 33px;
  width: 154px;
  height: 42px;
}
.bids13_sub2_button_a::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: transparent;
  border-radius: 50px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.btn_hover {
  border: 1px solid #ee1651 !important;
  width: 49.6%;
  color: white;
  background: linear-gradient(
    97.71deg,
    #ff004c 33.91%,
    rgba(255, 0, 76, 0) 102.16%
  );
  font-family: var(--font-400);
  /* background-color: none; */
  font-size: 16px;
  padding: 10px;
  border-radius: 33px;
  width: 154px;
  height: 42px;
}
/* ---- */
