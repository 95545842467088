.newinterest_heading {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
}

.newinterest_boxes_main_wrapp {
  width: 100%;
  height: 100%;
  min-height: 400px;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  gap: 1px; /* Gap between grid items */
  position: relative;
}

.newinterest_boxes_gradiant {
  position: absolute;
  width: 100%;
  height: 75%;
  top: 1%;
  left: 0px;
  background: radial-gradient(
    60% 60% at 50% 50%,
    rgba(255, 255, 255, 0.22) 43.55%,
    rgba(192, 189, 190, 0.1056) 66.55%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: -1;
}

.newinterest_single_box {
  text-align: center;
  width: 100%;
  height: 150px;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.newinterest_single_box > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.newinterest_single_box > p {
  font-family: var(--font-600);
  font-size: 10px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
