.recr_eletive_position {
  position: relative;
}
.recr_new_wrapp_container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  /* background: var(--color-black); */
}
.recr_back_circule_shadow {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 200vw;
  height: 200vh;
  object-fit: contain;
  border: 1px solid red;
  transform: translate(40%, -44%);
}
.recr_new_container {
  width: 95%;
  margin: 0px auto;
  height: 100%;
  max-width: var(--max-width-sm);
}
.recr_back_arrow_wrapp {
  width: 100%;
  margin: 0px auto;
  padding: 20px 5%;
}
.recr_new_intanationa_invite_main_wrapp {
  width: 100%;
  min-height: 88vh;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
  margin-top: 0px;
}
.recr_new_intanationa_invite_main_wrapp > img {
  width: 226px;
  height: 226px;
  object-fit: contain;
  margin-bottom: 2.7rem;
}
.recr_new_intanationa_invite_main_wrapp > h2 {
  color: var(--color-white);
  font-size: 50px;
  font-family: var(--font-600);
  line-height: 110%;
}
.recr_new_intanationa_invite_main_wrapp > p {
  color: #cccccc;
  font-size: 15px;
  /* font-family: var(--font-300); */
  line-height: 110%;
  font-family: "Gotham_light";
  /* font-family: ; */
  text-align: center;
  line-height: 25px;
  margin-top: 8px;
}
.recr_new_btn_wrapp {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border: none;
  border-radius: 50px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;
}
.recr_mt-3 {
  margin-top: 8.5rem !important;
}
.recr_new_set_password_single_input_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* gap: 10px; */
  width: 100%;
  margin: 10px auto;
  margin-top: 48px;
}
.recr_new_set_password_single_labal_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.recr_new_set_password_single_labal_wrapp > img {
  width: 20px;
  height: 16px;
}
.recr_email {
  font-family: "Gotham_light";
  font-size: 12px;
  font-weight: 400;
  color: snow;
}
.recr_new_set_password_single_border_wrapp {
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  outline: none;
  width: 100%;
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: bottom left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recr_new_set_password_single_border_wrapp > input {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  width: 70%;
  border: none;
  border-radius: 0px;
  margin: 0px;
  background: none;
  padding: 1.2rem 0px;
  caret-color: var(--color-main);
}
/* recievedinvitaion_1 */
.recr_1_new_intanationa_invite_main_wrapp {
  width: 100%;
  min-height: 66vh;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
  margin-top: 0px;
}
.recr_1_new_intanationa_invite_main_wrapp > img {
  width: 226px;
  height: 226px;
  object-fit: contain;
  margin-bottom: 2.7rem;
}
.recr_1_new_intanationa_invite_main_wrapp > h2 {
  color: var(--color-white);
  font-size: 50px;
  font-family: var(--font-600);
  line-height: 110%;
}
.recr_1_new_intanationa_invite_main_wrapp > p {
  color: #cccccc;
  font-size: 15px;
  /* font-family: var(--font-300); */
  line-height: 110%;
  font-family: "Gotham_light";
  /* font-family: ; */
  text-align: center;
  line-height: 25px;
  margin-top: 8px;
  width: 97%;
}
.recr_1_mt-3 {
  margin-top: 2.5rem !important;
}
