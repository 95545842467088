/* Styles.css (or any other CSS file) */

.footer-icons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer-icons .icon {
  color: white;
}
i {
  color: white;
}

.footer_main_wrapp {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.7px solid white;
  position: fixed;
  bottom: 0px;
  background: var(--color-black);
}

.footer_base_wrapp {
  width: 90%;
  max-width: var(--max-width-sm);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.footer_base_wrapp > button {
  border: none;
}
.footer_btn {
  border: 1px solid var(--color-main);
  height: 40px;
  padding: 20px;
  color: var(--color-main);
  font-family: var(--font-600) !important;
  width: 43%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.footer_btn_main1 {
  width: 22px;
}
.footer_btn_main2 {
  width: 20px;
}
.footer_btn_main3 {
  width: 22px;
  border: 1px solid white;
  padding: 3px;
  border-radius: 50px;
}
.footer_btn_main4 {
  width: 25px;
}
.footer_btn_main5 {
  width: 25px;
}
