.pv_img_close_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: var(--color-black);
  margin: 10px auto;
}

.pv_imgs_wrapp {
  margin: 40px auto;
  max-width: var(--max-width-sm);
  /* height: 60vh; */
}

.pv_img_single_img {
  width: 300px;
  max-height: 400px;
  /* object-fit: contain; */
}
.pv_img_single_img_22 {
  object-fit: contain;
  width: 100%;
}
