.wishlist_club_user {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.wishlist_2 {
  width: 920px;
  margin: 10px auto;
  max-width: var(--max-width-sm);
}

.wishlists_user_menu {
  left: 15px;
  position: relative;
}

.wishlist_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 20px;
}

.wishlist_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.wishlistmain2 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wishlistmain1 {
  width: 20%;
}

.Wishlist_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  right: 10%;
}
.wishlist_list2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

/* details card */
.wishlist_list {
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  position: relative;
}

.wishlist_list_first {
  width: 50%;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  height: 120px;
}

.wishlist_list_second {
  width: 50%;
  padding: 10px;
  height: 150px;
}

.wishlist_1_img {
  width: 100%;
  height: 100%;
}

.wishlist_2_img {
  width: 25px;
  bottom: 8%;
  position: absolute;
  right: 10px;
}

.h1_wishlist {
  font-family: var(--font-600) !important;
  font-size: 15px;
  margin-bottom: 5px;
}

.h2_wishlist {
  font-family: var(--font-700) !important;
  font-size: 15px;
  margin-bottom: 5px;
}

.wish_value1 {
  font-family: var(--font-400) !important;
  font-size: 15px;
  left: 0px !important;
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;
}

.wish_value {
  font-family: var(--font-400) !important;
  font-size: 15 px;
  left: 0px !important;
  position: relative;
}

@media only screen and (max-width: 920px) {
  .wishlist_2 {
    width: 90%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 10px auto;
  }

  .wishlist_club_user {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .wishlists_user_menu {
    left: 15px;
    position: relative;
  }

  .wishlist_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .wishlist_club_user {
    justify-content: center;
  }
}
