.Purchase_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 12px;
}
.Purchase_btn_set1 {
  width: 75%;
}
.Purchase_btn_set2 {
  width: 25%;
}
.show_second_img1 {
  width: 20px;
  float: right;
  position: relative;
  right: 10px;
}

/* 25-09-2023 */

.purchesh_new4 {
  background-image: url(./../../assets/images/newBlueCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new4_img {
  z-index: -1;
  border-radius: 10px;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 80%;
  top: 0px;
  left: 0px;
}
.purchase_front_details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 15px;
  height: 40%;
}
.purchesh_new_front-del {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}
.showcard_nm_set {
  font-size: 22px;
  letter-spacing: 4px;
  font-family: var(--font-400);
}

.show4_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardfour {
  font-size: 15px;
  font-family: var(--font-700);
  /* font-weight: 900; */
  letter-spacing: 10px;
  padding-top: 5px;
}

.purchase_del_4 {
  display: flex;
  padding-top: 25px;
  width: 80%;
}

.purchase_del_4_sub1 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.purchase_del_4_sub2 {
  width: 30%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-4-date {
  font-weight: var(--font-700);
  letter-spacing: 8px;
  font-size: 12px;
}

.s-card-4-price {
  font-weight: var(--font-600);
  font-size: 20px;
}

.s-d-4card_qr {
  width: 50px;
  height: 50px;
}

/* 25-09-2023 */

.purchase_del_4_front {
  position: absolute;
  justify-content: center;
  bottom: 9%;
  padding: 0 20px;
  line-height: 16px;
  width: 100%;
  text-align: center;
}
.del4_1 {
  width: 67%;
  text-align: center;
}
.del4_2 {
  width: 100%;
  position: relative;
  bottom: 45px;
  text-align: center;
}
.front_nm4 {
  font-family: var(--font-600);
  font-size: 12px;
}
.crad4_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 28%;
  width: 95%;
  padding: 12px;
}
.card4_back_owner {
  position: absolute;
  bottom: 3%;
  text-align: center;
  width: 100%;
  padding: 0 31px;
}
.show_1_details_front4 {
  /* position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 1%;
  left: 3%;
  padding: 5px;
  width: 96%;
  position: relative;
  bottom: 17%; */
}
.front_nm_product1_4 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  /* line-height: 30px; */
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.card_1_img_back4p {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 95%;
  left: 0;
  top: 0;
}
.show_1_details_front4 {
  position: absolute;
  display: flex;
  flex-direction: row;
  /* left: 3%; */
  padding: 5px 15px;
  padding-left: 19px;
  width: 100%;
  bottom: -4%;
}
.front_card_sub_details_inner4 {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 15%; */
}
.img_front_card4_1_logo {
  width: 40px;
  position: relative;
  bottom: 27%;
  /* left: 30%; */
  height: 40px;
}
.div_card4_1 {
  width: 70%;
  line-height: 3px;
  left: 0px;
  position: relative;
}
.div_card4_2 {
  width: 30%;
}
.front_nm4_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.front_card4_sub_details {
  width: 50%;
  line-height: 15px;
}
.logo_qr_pur_4 {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 2%;
}
.div_card_4_pur {
  width: 20%;
  position: absolute;
}
.img_front_card_4_logo_pur {
  width: 40px;
  position: relative;
  left: 6%;
  height: 40px;
  transform: scaleX(-1);
  bottom: 34px;
}
.del4_2_pur {
  width: 80%;
  /* right: -11px; */
  position: relative;
  left: 70px;
  top: 3px;
}
.s-d-4card_qr_pur {
  width: 41px;
  height: 41px;
}
.ditails_of_back_cs_pur4 {
  position: absolute;
  bottom: 10%;
  padding: 42px 17px;
  line-height: 16px;
  width: 100%;
}
.front_nm_2_4 {
  font-family: var(--font-800) !important;
  font-size: 10px;
}
