.flip-card {
    perspective: 1000px;
    width: 300px;
    height: 500px;
    transition: transform 0.6s;
    border-radius: 50px;
}

.flipped .flip-card-inner_card {
    transform: rotateY(180deg);
}

.card_flip-card-back {
    transform: rotateY(180deg);
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-card-inner_card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
}

.crad_2_main_img_front {
    background-image: url(./../../assets/images/red_card_bg.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
}

.crad_2_main_img_back {
    background-image: url(./../../assets/images/red_card_back_bg.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
}

.crad_2_img_front {
    z-index: -1;
    position: relative;
    width: 95%;
    object-fit: cover;
    height: 97%;
    top: 2%;
    left: 8px;
}

.crad_2_img_back {
    z-index: -1;
    
    position: relative;
    width: 95%;
    object-fit: cover;
    height: 97%;
    left: 8px;
    border-radius: 15px 15px 0px 0px;
    
}

/* 29-08-2023 */

.sc_f_details
{
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 18%;
    position: relative;
}
.sc_f_details1
{
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    left: 6%;
}
.nm_of_user
{
    font-size: 15px;
}
.nm_of_cre_titel
{
    font-size: 10px;
}
.nm_of_cre_nmr
{
    font-size: 15px;
}


.sc_f_details2
{
    display: flex;
    flex-direction: column;
    width: 45%;
    text-align: end;
    position: relative;
    right: 12%;
    margin-top: 20px;
}
.sc_img_f
{
    width: 40px;
    height: 40px;
    float: right;
}
.sc_p_nm
{
    font-size: 12px;
}
.sc_price
{
    font-size: 15px;
    font-weight: 800;
}
.sc_f_details3
{
    width: 5%;
    position: absolute;
    bottom: 10px;
    right: 3px;
}


/* back  */
.sc_f_details_back
{
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 18%;
    position: relative;
}
.sc_f_details1_back
{
    display: flex;
    flex-direction: column;
    width: 60%;
    position: relative;
    left: 6%;
}
.sc_b_details
{
    position: absolute;
    bottom: 10px;
}

.nm_of_cre_titel_back
{
    font-size: 10px;
}
.nm_of_cre_nmr_back
{
    font-size: 15px;
}
.sc_f_details2_back
{
    width: 40%;
    text-align: center;
}
.sc_img_f_back
{
    width: 75px;
    height: 75px;
    background-color: white;
}
.ditails_of_back_cs
{
    position: absolute;
    top: 15%;
    padding: 20px;
}
.readmore_card
{
    color: var(--color-main);
    font-family: var(--font-600);
}
.details_bg_card
{
    font-size: 12px;
}