/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

/* Add additional styles for your modal content here */

.otp_demo_img {
  width: 40%;
  margin-top: 10%;
}

.otp_demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.otp_demo_title {
  font-family: var(--font-700) !important;
  font-size: 25px;
}

.otp_demo_p {
  font-family: var(--font-400);
  color: gray !important;
  left: 0px !important;
  text-align: center;
  font-size: 14px;
  width: 100% !important;
}

.demo_otp_btn_new {
  background-color: var(--color-main);
  padding: 10px;
  width: 30%;
  color: white;
  font-family: var(--font-600);
  border-radius: 25px;
  font-weight: bold;
}

.input_otp_new {
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-top: 25px;
}
.demo_resend_otp {
  color: var(--color-main);
  font-family: var(--font-700);
  text-align: end;
  float: right;
}
.in_de_otp {
  background-color: gray;
  width: 100%;
  height: 60px;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  color: white;
  font-size: 25px;
  text-align: center;
}

.mobile_verify_btn {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.monile_div_club {
  margin-top: 25px;
}
