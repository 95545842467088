.Purchase_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 12px;
}
.Purchase_btn_set1 {
  width: 75%;
}
.Purchase_btn_set2 {
  width: 25%;
}
.show_second_img1 {
  width: 20px;
  float: right;
  position: relative;
  right: 10px;
}

/* 25-09-2023 */

.purchesh_new5 {
  background-image: url(./../../assets/images/newGoldenCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new5_img {
  z-index: -1;
  /* border-radius: 10px; */
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 89%;
  top: 3%;
  left: 0px;
}
.purchase_front_details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 15px;
  height: 40%;
}
.purchesh_new_front-del {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}
.showcard_nm_set {
  font-size: 22px;
  letter-spacing: 4px;
  font-family: var(--font-400);
}

.shownm_cardfive {
  font-size: 15px;
  font-family: var(--font-700);
  /* font-weight: 900; */
  letter-spacing: 10px;
  padding-top: 5px;
}

.purchase_del_5 {
  display: flex;
  padding-top: 25px;
  width: 80%;
}

.purchase_del_5_sub1 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.purchase_del_5_sub2 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 4%;
}

.s-card-5-date {
  font-weight: var(--font-700);
  letter-spacing: 8px;
  font-size: 12px;
}

.s-card-5-price {
  font-weight: var(--font-600);
  font-size: 20px;
}

.s-d-5card_qr {
  width: 50px;
  height: 50px;
}

/* 25-09-2023 */

.show_5_details_front {
  display: flex;
  flex-direction: row;
  bottom: 1%;
  /* left: 3%; */
  padding: 5px;
  width: 100%;
  position: absolute;
  bottom: 17%;
  margin: 0 auto;
  padding: 0 23px;
}
.front5_card_sub_details_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 15%; */
}
.crad5_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 16%;
  width: 95%;
  padding: 12px;
}
.card_back_owner {
  position: absolute;
  bottom: 11%;
  text-align: center;
  width: 100%;
  padding: 0 41px;
}
.card_back_owned_by_text5 {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
}
.card_back_username5 {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

/* 08/11/23 */
.front5_nm {
  font-size: 15px;
  color: white;
  font-family: var(--font-600);
}
.front5_nm_div {
  position: absolute;
  bottom: 30%;
  padding: 0 23px;
  line-height: 16px;
}
.front_card5_sub_details {
  width: 50%;
}
.front_card5_sub_details2 {
  width: 50%;
}
.div_card5_2 {
  width: 12%;
  position: absolute;
  bottom: 25px;
  right: 4px;
}
.img_front_card5_1_logo {
  width: 40px;
  height: 40px;
}
.div_card5_back_2 {
  position: absolute;
  bottom: 4%;
}
.img_back_card5_1_logo {
  width: 40px;
  height: 40px;
  transform: scaleX(-1);
}
.front_nm5_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  /* line-height: 30px; */
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.ditails_of_back_cs_pur5 {
  position: absolute;
  bottom: 13%;
  padding: 41px 27px;
  line-height: 16px;
  width: 100%;
}
.card_5_img_back_pur {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 100%;
  left: 0;
  top: 0;
  /* border-radius: 15px 15px 0px 0px; */
}
.front_nm_2_5 {
  font-family: var(--font-800) !important;
  font-size: 10px;
}
