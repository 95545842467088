.page_main_wrapp_nw {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: var(--color-black); */
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}
.new_side_menu_sec1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width-md);
  margin: 10px auto;
  gap: 15px;
}
.new_side_new_set1 {
  flex: 4 1;
}
.new_side_menu_sec1_profile_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 80px;
}
.sideimgbox > img {
  width: 41px;
  height: 41px;
  border-radius: 50px;
  z-index: 1;
  /* border: 1px solid; */
  /* border-image-source: linear-gradient(140.71deg, #FF004C 15.3%, rgba(255, 0, 76, 0) 89.21%);
    border-image-slice: 1; */
}
.sideimgbox {
  width: 53px;
  height: 53px;
  background: #ffffff1a;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sideimgbox::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  border: none;
  /* padding: 19px; */
  height: 43px;
  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
  width: 43px;
  top: 5px;
  left: 5px;
}
.new_side_menu_close_btn2 {
}
.new_sidemenu_close_box {
  width: 45px;
  height: 45px;
}
.new_sidemenu_close_box > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.new_side_menu_mynfbs_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* border: 1px solid var(--color-main); */
  padding: 10px;
  width: 100%;
  height: 69px;
  margin: 10px auto;
  border-radius: 45.5px;
  background: #0f0f1003;
  box-shadow: 7px 4px 20px 0px #ff004c5c inset,
    -8px -4px 23px 0px #caacff38 inset;
    margin-top: 30px;
    
}
.new_side_menu_mynfbs_btn > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.new_side_menu_mynfbs_btn > p {
  font-size: 18px;
  font-family: var(--font-400);
  color: var(--color-white);
  font-weight: 500;
}
.new_sidemenu_assets_name {
  font-size: 16px;
  font-family: var(--font-roundedbold);
  color: var(--color-white);
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: end;
  font-weight: 350;
}
.new_sidemenu_hr {
  width: 100%;
  max-width: var(--max-width-md);
  /* background: var(--color-main); */
  height: 2px;
  margin-top: 10px;
  margin-bottom: 15px;
  /* border: 2px solid; */
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
  border-image-slice: 1;
}
.new_sidemenu_assets_main_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 14px;
  margin-top: 15px;
}

.new_sidemenu_assets_single_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* margin-bottom: 15px; */
  /* margin: 10px auto; */
}

.new_sidemenu_assets_single_wrapp > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.new_sidemenu_assets_single_wrapp > p {
  font-size: 14px;
  font-weight: var(--font-400);
  color: var(--color-white);
  line-height: 44px;
  font-weight: 500;
}

.new_side_menu_sec1_profile_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 80px;
}

.new_side_menu_profile_name_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 40%; */
  word-wrap: break-word;
}

.new_side_menu_profile_name_wrapp > p {
  font-size: 12px;
  color: var(--color-white);
  opacity: 0.8;
  font-family: var(--font-300);
}

.new_side_menu_profile_name_wrapp > span {
  font-size: 14px;
  color: var(--color-white);
  opacity: 0.8;
  font-family: var(--font-600);
  padding: 0px;
  margin: 0px;
}

.new_side_menu_close_btn {
  color: var(--color-main);
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 10px;
}
.name_user_new_set {
  width: fit-content;
}
.new_side_new_set1 {
  flex: 4;
}

.new_sidelistmain_1 {
  font-family: var(--font-600);
  font-weight: 700;
}
.new_sidelistmain_sub {
  font-family: var(--font-400);
  position: relative;
  left: 10px;
  font-size: 14px !important;
}
.new_sidelistmain_titel {
  font-family: var(--font-400) !important;
  position: relative;
  font-size: 14px !important;
  color: white !important;
  opacity: 1 !important;
}
