.flip-card {
    perspective: 1000px;
    width: 300px;
    height: 500px;
    transition: transform 0.6s;
    border-radius: 50px;
    -webkit-transition: transform 0.6s;
    -moz-transition: transform 0.6s;
    -ms-transition: transform 0.6s;
    -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
    transform: rotateY(180deg);
}

.card_flip-card-back {
    transform: rotateY(180deg);
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flip-card-inner_card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
}

.showcard_front_details {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: 0%;
    align-items: center;
    justify-content: start;
    padding-top: 45px;
    height: 40%;
}

/* 25-09-2023 */
.showcard_nm_set {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 5px;
}

.show3_main_div {
    text-align: start;
    width: 80%;
    padding-left: 5px;
}

.shownm_cardthree {
    font-size: 15px;
    font-family: var(--font-700);
    font-weight: 900;
    letter-spacing: 10px;
    padding-top: 5px;
}

.subcard_del_3 {
    display: flex;
    padding-top: 10px;
    width: 80%;
}

.subcard_del_3_sub1 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.subcard_del_3_sub2 {
    width: 50%;
    display: flex;
    justify-content: end;
    position: relative;
}

.s-card-3-date {
    font-weight: var(--font-700) !important;
    letter-spacing: 8px;
    font-size: 12px;
    font-weight: bold !important;
}

.s-card-3-price {
    font-weight: var(--font-700) !important;
    font-size: 20px;
    font-weight: bold !important;
}

.s-d-3card_qr {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

/* 25-09-2023 */
.showcard_three_new {
    background-image: url(./../../assets/images/bluecard_front.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
    position: relative;
}

.showcardthree_first_img {
    z-index: -1;
    position: relative;
    width: 95%;
    object-fit: cover;
    height: 50%;
    top: 2%;
    left: 8px;
}

.crad__show3_main_img_back {
    background-image: url(./../../assets/images/bluecard_back.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    z-index: 9;
}

.card_show_img_front {
    z-index: -1;
    position: relative;
    width: 95%;
    object-fit: cover;
    height: 97%;
    top: 2%;
    left: 8px;
}

.card_show_img_back {
    z-index: -1;

    position: relative;
    width: 95%;
    object-fit: cover;
    height: 97%;
    left: 8px;
    border-radius: 15px 15px 0px 0px;

}

/* 29-08-2023 */

.sc_f_details {
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 18%;
    position: relative;
}

.sc_f_details1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    left: 6%;
}

.nm_of_user {
    font-size: 15px;
}

.nm_of_cre_titel {
    font-size: 10px;
}

.nm_of_cre_nmr {
    font-size: 15px;
}


.sc_f_details2 {
    display: flex;
    flex-direction: column;
    width: 45%;
    text-align: end;
    position: relative;
    right: 12%;
    margin-top: 20px;
}

.sc_img_f {
    width: 40px;
    height: 40px;
    float: right;
}

.sc_p_nm {
    font-size: 12px;
}

.sc_price {
    font-size: 15px;
    font-weight: 800;
}

.sc_f_details3 {
    width: 5%;
    position: absolute;
    bottom: 10px;
    right: 3px;
}


/* back  */
.sc_f_details_back {
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 18%;
    position: relative;
}

.sc_f_details1_back {
    display: flex;
    flex-direction: column;
    width: 60%;
    position: relative;
    left: 6%;
}

.sc_b_details {
    position: absolute;
    bottom: 10px;
}

.nm_of_cre_titel_back {
    font-size: 10px;
}

.nm_of_cre_nmr_back {
    font-size: 15px;
}

.sc_f_details2_back {
    width: 40%;
    text-align: center;
}

.sc_img_f_back {
    width: 75px;
    height: 75px;
    background-color: white;
}

.ditails_of_back_cs {
    position: absolute;
    top: 15%;
    padding: 20px;
}

.readmore_card {
    color: var(--color-main);
    font-family: var(--font-600);
}

.details_bg_card {
    font-size: 12px;
}


/* 31-08-2023 */
.show_second_img {
    width: 20px;
    float: right;
}

.btn_showcardsecond {
    margin-top: 20px;
    width: 95%;
    display: flex;
    z-index: 9999;
    position: absolute;
}

.btn_showcardsecond_sub1 {
    width: 75%
}

.btn_showcardsecond_sub2 {
    width: 25%
}

.show_second_btn {
    background-color: white;
    font-size: 15px;
    font-weight: bold;
    color: var(--color-main);
    width: -moz-fit-content;
    width: 60px;
    border-radius: 25px;
    padding: 2px 5px 2px 5px;
    text-align: center;
    margin-left: 4px;
}

/* 25-09-2023 */
.showcrad_hr1 {
    height: 3px;
    background-color: var(--color-main);
    margin-top: 5px;
    width: 80%;
}

.showcrad_hr2 {
    height: 2px;
    background-image: linear-gradient(to left, #663548, #da0e41, #663548);
    margin-top: 5px;
    width: 80%;
}