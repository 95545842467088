.side_menu_sec1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-width-md);
  margin: 10px auto;
  gap: 15px;
}

.side_menu_sec1_profile_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 80px;
}

.side_menu_sec1_profile_wrapp > img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.side_menu_profile_name_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* width: 40%; */
  word-wrap: break-word;
}

.side_menu_profile_name_wrapp > p {
  font-size: 12px;
  color: var(--color-white);
  opacity: 0.8;
  font-family: var(--font-300);
}

.side_menu_profile_name_wrapp > span {
  font-size: 14px;
  color: var(--color-white);
  opacity: 0.8;
  font-family: var(--font-600);
  padding: 0px;
  margin: 0px;
}

.side_menu_close_btn {
  color: var(--color-main);
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 10px;
}
.name_user_new_set {
  width: fit-content;
}
.side_new_set1 {
  flex: 4;
}
.side_menu_close_btn2 {
  width: 30px;
  height: 60px;
  position: relative;
  left: 15px;
}
.sidelistmain_1 {
  font-family: var(--font-600);
  font-weight: 700;
}
.sidelistmain_sub {
  font-family: var(--font-400);
  position: relative;
  left: 10px;
  font-size: 14px !important;
}
.sidelistmain_titel {
  font-family: var(--font-400) !important;
  position: relative;
  font-size: 14px !important;
  color: white !important;
  opacity: 1 !important;
}

.side_menu_mynfbs_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--color-main);
  padding: 10px;
  width: 100%;
  margin: 10px auto;
  border-radius: 10px;
}

.side_menu_mynfbs_btn > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.side_menu_mynfbs_btn > p {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.sidemenu_assets_name {
  font-size: 15px;
  font-family: var(--font-700);
  color: var(--color-white);
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sidemenu_assets_main_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 14px;
  margin-top: 20px;
}

.sidemenu_assets_single_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* margin: 10px auto; */
}

.sidemenu_assets_single_wrapp > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.sidemenu_assets_single_wrapp > p {
  font-size: 12px;
  font-weight: var(--font-300);
  color: var(--color-white);
}

.sidemenu_hr {
  width: 100%;
  max-width: var(--max-width-md);
  background: var(--color-main);
  height: 1px;
  margin-top: 10px;
  margin-bottom: 20px;
}
