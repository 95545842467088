/* page base design start */

.page_newmain_wrapp {
  display: flex;
  /* flex-direction: row; */
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
  background-image: url(./../../assets/images/bg_benefits.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.claim_now {
  background: #ffcb5b;
  font-size: 15px;
  color: black;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  width: 30%;
  margin-top: 10px;
  cursor: pointer;
}

.page_newbase_wrapp {
  width: 100%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_newnavbar_wrapp {
  padding: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
/* page base design end */
.benefits_club_user {
  padding: 25px;
  text-align: center;
}
.Collaboratenfb_img1 {
  width: 100%;
  height: 250px;
  border-radius: 10px;
}
.benefits_img {
  width: 100%;
  border-radius: 25px;
}
.benefit_price {
  color: white;
  /* font-weight: 900; */
  font-size: 25px;
  font-family: var(--font-700);
  background-color: #c2c2c257;
  padding: 15px;
  text-align: center;
  width: 80%;
  backdrop-filter: blur(10px);
  border-radius: 25px;
}
.details_of_benefit {
  background-color: #c2c2c257;
  padding: 20px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  max-width: var(--max-width-sm);
}
.sub_benefit {
  background-color: #302d2d;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
}
.benefits_btn_details {
  background-color: #c2c2c257;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: white;
  font-family: var(--font-800);
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 20px;
  width: 100%;
  position: relative;
}
.img_benefits {
  width: 25px;
  position: absolute;
  left: 5%;
}

.select_new_date {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select_new_date_sub {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.select_date_new {
  /* position: absolute !important; */
  top: 0px;
  right: 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main) !important;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
}

.select_date_new:checked::before {
  content: "✔";
  /* /Displayacheckmarkcharacteroranyothersymbolyoulike/ */
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /* /Setthebackgroundcolorwhenchecked/ */
  color: var(--color-white);
  /* /Setthetextcolorwhenchecked/ */
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
}

.date_sel {
  position: relative;
  bottom: 5px;
}

.select_date_btn {
  text-align: center;
}
.select_date_btn_sub {
  background-color: #ee1651;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 35px;
  font-size: 20px;
  font-family: var(--font-800);
  width: 50%;
}

.benefit_video_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
