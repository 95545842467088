.new_user_menu_nav_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px auto;
  margin: 10px auto;
  height: 130px;
}
.new_user_img_menu {
  text-align: center;
  justify-content: space-between;
  display: flex;
  align-items: end;
  /* flex-direction: column; */
}
.new_nav_back_btn {
  width: 45px;
  object-fit: contain;
}
.new_user_sideimgbox {
  width: 103px;
  height: 103px;
  background: #ffffff1a;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.new_user_img_1 {
  width: 86px;
  height: 86px;
  /* border: 1px solid var(--color-main); */
  border-radius: 86px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1;
}
.new_user_sideimgbox::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 86px;
  border: none;
  height: 90px;
  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
  width: 90px;
  top: 7px;
  left: 7px;
}
.new_user_menu_nav_wrapp_qr {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding: 14px auto;
  margin: 10px auto;
}
.new_user_menu_nm_1 {
  font-size: 18px;
  font-family: var(--font-roundedbold);
  line-height: 44px;
  font-weight: 350;
  width: 90%;
}
.new_user_menu_nm_2 {
  font-size: 12px;
  font-family: var(--font-300);
  /* line-height: 44px; */
  font-weight: 500;
  width: 90%;
}
.new_user_qr_btn {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 70px;
}
.new_usermenu_hr {
  width: 100%;
  max-width: var(--max-width-md);
  /* background: var(--color-main); */
  height: 2px;
  margin-top: 25px;
  margin-bottom: 25px;
  /* border: 2px solid; */
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
  border-image-slice: 1;
}
.new_menu_list {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 10px;
  align-items: center;
  /* justify-content: center; */
}
.new_menu_list_p {
  font-size: 14px;
  font-weight: 500;
  color: white;
  position: relative;
  font-family: var(--font-400);
  line-height: 44px;
  left: 13px;
}
.new_user_qr_img {
  object-fit: contain;
  height: 90%;
  width: 90%;
}
.new_user_left_name {
  width: 70%;
}
.new_user_right_qr {
  width: 20;
}
.images_icon_menu_new {
  width: 20px;
  height: 20px;
}
