.fb_main_wrapp {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  margin: 10px auto;
}

.fb_sec_title {
  margin: 10px;
  font-family: var(--font-600);
  font-size: 1rem;
  color: var(--color-main);
}

.fb_banner_wrapp {
  width: 100%;
  height: 280px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-width: 200px;
}
.rsis-image {
  border-radius: 14px;
}

.fb_banner_wrapp > div > p {
  font-size: 1rem;
  font-family: var(--font-600);
  color: #fff;
}

.fb_banner_wrapp > div {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
}

.fb_banner_wrapp > img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  object-fit: cover;
}

.fb_cards_wrapp {
  width: 100%;
  margin-bottom: 1rem;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px; */
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-top: 15px;
}
.image-slider-container {
  width: 100%;
  height: 300px;
  display: flex;
}
.image-slider-container .slick-slide img {
  display: block;
  width: 100% !important;
  /* height: 258px !important; */
  object-fit: cover !important;
  border-radius: 20px;
}
.slider-btn-div {
  position: relative;
  z-index: 1111111;
  left: -6px;
  display: flex;
  /* top: 34%; */
  /* justify-content: center; */
  width: 0%;
  display: flex;
  align-items: center;
}
.slider-btn-div2 {
  position: relative;
  z-index: 1111111;
  right: 17px;
  /* text-align: end; */
  width: 0%;
  /* margin-left: auto; */
  display: flex;
  align-items: center;
}
.free-btn-icon {
  filter: drop-shadow(0px 4px 12px black);
  color: white;
  border-radius: 35px;
  width: 30px;
  height: 30px;
}
.fb_main_wrapp .slick-slider {
  width: 100% !important;
}

.slider-btn-div > button {
  height: 43px;
}
.slider-btn-div2 > button {
  height: 43px;
}

.slider-benefit .slick-track {
  height: 280px !important;
}
.slider-benefit .slick-list {
  border-radius: 6px !important;
}

.slick-slide {
  /* //opacity: .5; */
  transition: 0.3s ease-in-out all;
  /* margin-left: -20px; */
}
.slick-slide.slick-center {
  opacity: 1;
  transform: scale(1);
  margin-right: -80px;
  /* margin-le: -80px; */
}

.free_benefit_confirm_benefit_btn {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 28px;

  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 1rem;
  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );
}
