/* feed navbar start */

.feed_nav_main_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 111;
  background: #000;
}

.feed_nav_base_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 30px;
  background: var(--background-black);
  max-width: var(--max-width-md);
  margin: 10px auto;
  /* position: fixed; */
}

.feed_nav_search_filter_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.feed_nav_name {
  font-size: 16px;
  font-family: var(--font-700);
  color: var(--color-white);
}

.feed_nav_search_btn {
  width: 25px;
  height: 25px;
}

.feed_nav_search_btn > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.feed_catagory_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  /* height: 30px; */
  background: var(--background-black);
  max-width: var(--max-width-md);
  margin: 10px auto;
}

.feed_explore_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid white;
  gap: 6px;
}

.feed_explore_btn > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.feed_explore_btn > p {
  font-size: 10px;
  font-family: var(--font-300);
}

/* feed navbar end */

/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp1 {
  width: 100%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 0px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.images_feed_btn {
  width: 22px;
  display: inline-block;
  margin-right: 5px;
}

.feed_btn_div_like {
  width: 50%;
  color: white;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  font-family: var(--font-400) !important;
  font-size: 18px;
}

.bid_feed_btn {
  font-size: 18px;
  width: 50%;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  color: var(--color-main);
  font-family: var(--font-900) !important;
}

.feed_btn_div {
  display: flex;
  height: 50px;
  gap: 10%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.feedmain {
}

.user_feed_nm {
  font-family: var(--font-400);
  font-weight: bold;
}

.feed_details {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  width: 90%;
  margin: 10px auto;
  align-items: center;
  gap: 10px;
}

.feed_userimg {
  width: 45px;
  height: 45px;
  border-radius: 45px;
}

/* feed_new_hd start */
.feed_new_hd {
  display: flex;
  padding: 20px;
  width: 100%;
}
.nm_of_nfbs_new {
  font-family: var(--font-700);
  font-size: 20px;
}

.feed_user_icon_Wrapp {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  padding: 2px;

  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
}

.feed_user_icon_Wrapp > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--color-black);
}

/* feed_new_hd end */
@media only screen and (max-width: 920px) {
}

@media only screen and (max-width: 620px) {
}
