/* page base design start */
.showcase {
  background-image: url(./../../assets/images/bg_benefits.png);
  background-size: 100% 100%;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_showcase_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}
.show_P {
  max-width: var(--max-width-sm);
  margin: 0 auto;
}
.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.showcase_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  margin-left: 8%;
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

.main_Showcase {
  text-align: end;
}

.Showcase_link_img {
  width: 35px;
  margin-top: 10px;
}

/* .chnages_btn {
  width: auto;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  gap: 10px;
} */

.chnages_img_btn {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  padding: 1px;
  border: 1px solid var(--color-main);
  display: inline-block;
  object-fit: cover;
}
.chnages_img_btn_border {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  padding: 1px;
  /* border: 1px solid var(--color-main); */
  display: inline-block;
  object-fit: cover;
}
