.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
  background-image: url(../../assets/images/login8.png);
  /* background-repeat: no-repeat;
    background-size: 100% 100%; */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.qr_1 {
  display: none;
}

.qr_2 {
  width: 100%;
  display: block;
  max-width: var(--max-width-sm);
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0 auto;
}

.qr_header {
  padding: 20px;
}
.footer_qr_user {
  bottom: 0;
  width: 100%;
  background: #120105;
  border-radius: 25px 23px 0px 0px;
  justify-content: center;
  display: flex;
  height: 85px;
  align-items: center;
  position: fixed;
  padding: 25px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

.qr_details_scan_heading {
  font-size: 20px;
  color: var(--color-white);
  font-family: var(--font-700);
}

.qr_details_scan_un {
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--font-600);
}

.footer_qr_user1 {
  width: 50%;
}

.footer_qr_user2 {
  width: 50%;
}

.footer_qr_user img {
  width: 20px;
}

.footer_qr_user_bottom {
  background-color: #9d9d9d54;
  width: 95%;
  height: 45px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.footer_qr_user_bottom > p {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
}

.qr_header {
  padding: 20px;
}

.qr_details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  /* border: 2px solid #000; */
}

.user_qr_center_wrapp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #000; */
  height: 60vh;
  width: 100%;
}

.qr_details1 {
  background-color: #232228;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  /* width: 75%; */
  align-items: center;
  border-radius: 29px;
  background-image: url("./../../assets/images/ar_code_11.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 25px;
}

.qr_details1_img {
  width: 100%;
  background-color: white;
  height: 250px;
}

@media only screen and (max-width: 850px) {
}

@media only screen and (max-width: 600px) {
  .qr_1 {
    display: none;
  }

  .qrs_user_menu {
    left: 15px;
    position: relative;
  }

  .qr_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .footer_qr_user {
    bottom: 0;
    width: 100%;
    background: #120105;
    border-radius: 25px 23px 0px 0px;
    justify-content: center;
    display: flex;
    height: 85px;
    align-items: center;
    position: fixed;
    padding: 25px;
  }

  .footer_qr_user1 {
    width: 50%;
  }

  .footer_qr_user2 {
    width: 50%;
  }

  .footer_qr_user img {
    width: 20px;
  }

  .footer_qr_user_bottom {
    background-color: #9d9d9d54;
    width: 95%;
    height: 45px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 25px;
    /* font-weight: 900; */
    font-family: var(--font-700);
  }

  .qr_header {
    padding: 20px;
  }

  .qr_details1_img {
    width: 100%;
    background-color: white;
    height: 250px;
  }
}
