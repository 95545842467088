/* page base design start */

.page_qr_main_wrapp {
  display: flex;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  padding: 15px;
  /* min-height: 100vh; */
}

.qrhuntlogin_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}
/* page base design end */
.qr_login {
  color: var(--color-main);
}
.qr_logo_set {
  width: 50px;
  height: 50px;
}
.for_qr_code {
  font-family: var(--font-600);
  text-align: end;
}
.change_pass_show_qr {
  position: relative;
  float: right;
  bottom: 52px;
  width: 20px;
  right: 20px;
}
.qr_login_input {
  height: 45px;
  padding: 10px;
  margin-bottom: 20px;
}
.qr_or_btn_set {
  padding: 10px;
  color: white;
  background-color: var(--color-main);
  font-size: 25px;
  width: 100%;
  border-radius: 25px;
}
