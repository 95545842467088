.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -ms-transition: transform 0.6s;
  -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

/* 25-09-2023 */
.showcard_nm_set {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 5px;
}

.show3_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardseven {
  font-size: 15px;
  font-family: var(--font-700);
  font-weight: 900;
  letter-spacing: 10px;
  padding-top: 5px;
}

.subcard_del_3 {
  display: flex;
  padding-top: 10px;
  width: 80%;
}

.subcard_del_3_sub1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.subcard_del_3_sub2 {
  width: 50%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-3-date {
  font-weight: var(--font-700) !important;
  letter-spacing: 8px;
  font-size: 12px;
  font-weight: bold !important;
}

.s-card-3-price {
  font-weight: var(--font-700) !important;
  font-size: 20px;
  font-weight: bold !important;
}

.s-d-3card_qr {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* 25-09-2023 */
.showcard_seven_new {
  background-image: url(./../../assets/images/Card11front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.showcardseven_first_img {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  top: 0%;
  left: 0;
}

.crad__show7_main_img_back {
  background-image: url(./../../assets/images/card11back\ .png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card7_show_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 71%;
  left: 0px;
  top: 0px;
}

.card7_show_img_back {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 81%;
  left: 0;
  top: 0;
}

/* 29-08-2023 */

.sc_f_details {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}

.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}

.nm_of_user {
  font-size: 15px;
}

.nm_of_cre_titel {
  font-size: 10px;
}

.nm_of_cre_nmr {
  font-size: 15px;
}

.sc_f_details2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: end;
  position: relative;
  right: 12%;
  margin-top: 20px;
}

.sc_img_f {
  width: 40px;
  height: 40px;
  float: right;
}

.sc_p_nm {
  font-size: 12px;
}

.sc_price {
  font-size: 15px;
  font-weight: 800;
}

.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}

/* back  */
.sc_f_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}

.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}

.sc_b_details {
  position: absolute;
  bottom: 10px;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}

.nm_of_cre_nmr_back {
  font-size: 15px;
}

.sc_f_details2_back {
  width: 40%;
  text-align: center;
}

.sc_img_f_back {
  width: 75px;
  height: 75px;
  background-color: white;
}

.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}

.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}

.details_bg_card {
  font-size: 12px;
}

/* 31-08-2023 */
.show_second_img {
  width: 20px;
  float: right;
}

.btn_showcardsecond {
  margin-top: 20px;
  width: 95%;
  display: flex;
  z-index: 9999;
  position: absolute;
}

.btn_showcardsecond_sub1 {
  width: 75%;
}

.btn_showcardsecond_sub2 {
  width: 25%;
}

.show_second_btn {
  background-color: white;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-main);
  width: -moz-fit-content;
  width: 60px;
  border-radius: 25px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  margin-left: 4px;
}

/* 25-09-2023 */
.showcrad_hr1 {
  height: 3px;
  background-color: var(--color-main);
  margin-top: 5px;
  width: 80%;
}

.showcrad_hr2 {
  height: 2px;
  background-image: linear-gradient(to left, #663548, #da0e41, #663548);
  margin-top: 5px;
  width: 80%;
}
/* 09/11/23 */
.showcard7_nm_set {
  font-size: 14px;
  font-family: var(--font-600);
}
.showcard7_front_details {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 22%;
  align-items: center;
}
.show7_main_div_ {
  text-align: left;
  padding: 0 24px;
  /* top: -91%; */
  position: relative;
  line-height: 15px;
}
.qrcard7_div {
  position: absolute;
  width: 100%;
  bottom: 5%;
  margin: 0 auto;
  text-align: center;
}
.s-d-card7_qr {
  width: 42px;
  height: 41px;
  position: relative;
}
.showcard7_front_details2 {
  /* display: flex;
    position: absolute;
    bottom: -5%;
    width: 100%;
    padding: 0 14px; */

  display: flex;
  position: absolute;
  bottom: 11%;
  width: 100%;
  padding: 3px 24px;
}
.front_card7_sub_details {
  width: 50%;
}
.front_card7_sub_details2 {
  position: relative;
  width: 50%;
  text-align: end;
}
.front_card7_sub_details_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 15%; */
}

.div_card_7 {
  width: 12%;
  position: absolute;
  bottom: 22px;
  right: 4px;
}
.img_front_card7_1_logo {
  width: 40px;
  height: 40px;
  float: right;
}
.card7_show_back_owner {
  position: absolute;
  /* left: 90px; */
  text-align: center;
  /* top: 130px; */
  width: 100%;
  bottom: 21%;
  margin: 0 auto;
  padding: 0 37px;
}
.crad7_details_back_ {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 29%;
  width: 100%;
  padding: 0 27px;
}
.details_bg_card_show7 {
  font-size: 12px;
  position: relative;
  /* left: 15px; */
  margin-top: 15px;
  font-family: var(--font-400);
}
.prodct_highlights7 {
  font-family: var(--font-600) !important;
  line-height: 0px;
  position: relative;
}
.owned_by_nm7 {
  font-family: var(--font-400) !important;
  line-height: 25px;
  position: relative;
  font-size: 11px;
}
.back_owned_nm7 {
  font-family: var(--font-600) !important;
  line-height: 14px;
  position: relative;
  font-size: 18px;
}
.img_back_card7_1_logo {
  width: 35px;
  height: 35px;
  float: left;
}
.div_card_7_b {
  width: 12%;
  position: absolute;
  bottom: 25px;
}
.div_card7_1 {
  /* width: 83%; */
}
.div_card_7_b {
  width: 12%;
  position: absolute;
  bottom: 16px;
  left: 10px;
}
.img_back_card7_1_logo {
  width: 40px;
  height: 40px;
  float: left;
  transform: scaleX(-1);
}
.front_nm_product1_7 {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 17px;
  text-align: right;
  font-family: var(--font-600);
}
.front_nm_product_7 {
  font-size: 10px;
  text-align: right;
  font-family: var(--font-400) !important;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}
.card7_back_qrlogo {
}
.ditails_of_back_cs_show7 {
  position: absolute;
  top: 16%;
  padding: 20px;
  line-height: 16px;
}
