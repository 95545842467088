@font-face {
  font-family: "Gotham_Bold";
  src: local("Gotham_Bold"), url(./assets/fonts/Gr_bold.otf) format("opentype");
}
@font-face {
  font-family: "Gotham_medium";
  src: local("Gotham_medium"),
    url(./assets/fonts/Gr_medium.otf) format("opentype");
}
@font-face {
  font-family: "Gotham_light";
  src: local("Gotham_light"),
    url(./assets/fonts/Gr_light.otf) format("opentype");
}
@font-face {
  font-family: "Gotham_italic";
  src: local("Gr_italic"), url(./assets/fonts/Gr_italic.otf) format("opentype");
}
@font-face {
  font-family: "Gotham_XLight";
  src: local("Gr_XLight"),
    url(./assets/fonts/Gotham-XLight.otf) format("opentype");
}
@font-face {
  font-family: "Gr_mitalic";
  src: local("Gr_mitalic"),
    url(./assets/fonts/Gr_mitalic.ttf) format("truetype");
}

:root {
  /* --font-roundedbold: "GothamBoldsecond", sans-serif;
  --font-900: "Gotham_Black", sans-serif;
  --font-700: "Gotham_Bold", sans-serif;
  --font-800: "GothamRoundedLight", sans-serif;
  --font-600: "GothamRounded", sans-serif; */
  --font-roundedbold: "Gotham_medium", sans-serif;
  --font-i: "Gotham_italic", sans-serif;
  --font-700: "Gotham_Bold", sans-serif;
  --font-600: "Gotham_medium", sans-serif;
  --font-600i: "Gr_mitalic", sans-serif;
  --font-400: "Gotham_light", sans-serif;
  --font-300: "Gotham_XLight", sans-serif;
  /* --color-main: #da0e41; */
  --color-main: #ff004c;
  --color-alt: #ff5e89;
  --color-orange: #ff8b00;
  --color-blue: #4397ff;
  --color-dark-blue: #0080ff;
  --color-pink: #d813a5;
  --color-orange2: #ffb103;
  --color-red: red;
  --color-green: green;
  --color-black: #000;
  --color-white: #ffffff;
  --color-gray: #767676;
  --color-bg-gray: #dadada;
  --color-dark-gray: #3f3f3f;
  --color-light-gray: #aaaaaa;
  --max-width: 1170px;
  --max-width-md: 940px;
  --max-width-sm: 420px;
  --fixed-width: 620px;
  --number-step: rgba(26, 42, 52, 0.25);
  --trnsp-white: rgba(255, 255, 255, 0.25);
  --color-gradiant: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-400);
  /* font-family: "Lato", sans-serif !important; */
}

body {
  font-family: var(--font-400);
  background: var(--color-white);
}

figure {
  margin: 0px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 97vh;
  margin: 10px auto;
}

.page_base_wrapp_2 {
  width: 90%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 96vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.navsec_back_arrow {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 30px;
  height: 20px;
}

.navsec_back_arrow > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.new_nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-700) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
/* page base design end */

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background: var(--color-white);
}

.flex_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100vw;
}

.h-100 {
  height: 100vh;
}

.bg_main {
  background: var(--color-main);
}

.ff-alt {
  font-family: var(--font-alt) !important;
}

.nav-margin {
  margin-top: 96px;
}

.font-heading {
  font-size: 38px;
  font-family: var(--font-alt);
  /* font-weight: 700; */
  color: var(--color-white);
  line-height: 130%;
}

.font-bold {
  font-family: var(--font-alt);
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;

  color: var(--color-black);
}

.font-sm {
  font-size: 12px;
  color: var(--color-white);
  font-weight: 100;
}

.font-md {
  font-family: var(--font-400);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--color-white);
}

.font-md-light {
  font-family: var(--font-400);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--color-dark-gray);
  text-transform: uppercase;
}

.font-md2 {
  font-family: var(--font-400);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--color-black);
}

.font-des {
  font-family: var(--font-400);

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;

  color: var(--color-gray);
}

.line {
  margin-top: 10px;
  /* margin-bottom: 15px; */
  background-color: var(--color-golden);
  width: 100%;
  height: 1px;
}

.clr-gray {
  color: var(--color-dark-gray) !important;
}

.clr-green {
  color: var(--color-green) !important;
}

.clr-main-blue {
  color: var(--color-dark-blue) !important;
}

.clr-white {
  color: var(--color-white) !important;
}

.bg-white {
  background: var(--color-bg) !important;
}

.bg-light-blue {
  background: var(--color-light-blue) !important;
}

.mrg-1-v {
  margin: 1rem 0px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: sans-serif; */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.underline {
  text-decoration: underline !important;
}

.underline:hover {
  text-decoration: none !important;
}

strong,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}

.text_normal {
  text-transform: none;
}

.text-input {
  display: flex;
  align-items: center;
  padding: 20px 22px;

  width: 100%;
  /* height: 56px; */

  background: #1e1c24;

  border: 1px solid #49484f;
  border-radius: 24px;
  color: #fff;
}

.text-input::placeholder {
  color: var(--color-gray);
}

input::placeholder {
  color: #6f6f6f;
}

.text {
  font-family: var(--font-400);
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  color: var(--color-white);
}

.text-small {
  font-family: var(--font-400) !important;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 20px;
  margin: 0px;
  color: var(--color-white);
}

.btn-link {
  /* primary/text-med */

  font-family: var(--font-alt) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 16px */
  text-align: center;
  border: none;
  background: transparent;
  /* primary/green */

  /* color: #39e681; */
}

.btn-link:hover {
  border-bottom: 2px solid transparent !important;
  cursor: pointer;
}

/* new design start */

.new_wrapp_container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  /* background: var(--color-black); */
}

.new_container {
  width: 95%;
  margin: 0px auto;
  height: 100%;
  max-width: var(--max-width-sm);
}

.back_circule_shadow {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 200vw;
  height: 200vh;
  object-fit: contain;
  transform: translate(40%, -44%);
}

.back_circule_shadow_white {
  position: fixed;
  top: 50%;
  right: 0%;
  z-index: -1;
  width: 200vw;
  height: 200vh;
  object-fit: contain;
  /* transform: translate(8%, 0%); */
}

.back_circule_shadow_left {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 200vw;
  height: 200vh;
  object-fit: contain;
  transform: translate(8%, -44%);
}

.reletive_position {
  position: relative;
}

.new_black_btn_wrapp {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  border: none;
  border-radius: 50px;
  /* background: var(--color-black); */
}

.new_black_btn_wrapp > p {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 16px;
  font-family: var(--font-600);
  top: 50%;
  left: 50%;
  color: var(--color-white);
}

.new_btn_wrapp {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;

  border: none;
  border-radius: 50px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;
}

.new_btn_wrapp > p {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 16px;
  font-family: var(--font-600);
  top: 50%;
  left: 50%;
  color: var(--color-white);
}

.new_btn_wrapp:active {
  /* scale: 0.9; */
  box-shadow: 
    /* White gradient border */ 0 0 0 3px rgba(255, 0, 76, 0.2),
    /* Pink gradient border */ 0 1px 12px 0 #ff004cc2 inset,
    /* First inner shadow */ 0 4px 14px 0 #9a92d24d inset,
    /* Second inner shadow */ 0 39px 99px -49px #caacff73 inset,
    /* Third inner shadow */ 0 0 10px 5px rgba(255, 255, 255, 0.2) inset,
    /* Additional inner shadow */ 0 0 20px 10px rgba(255, 0, 0, 0.2) inset; /* Additional inner shadow */
}

.new_gradiant_border_btn {
  width: 100%;
  padding: 1px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  height: 50px;
  border-radius: 8px;
}

.new_gradiant_border_btn > div {
  width: 100%;
  height: 100%;
  background: var(--color-black);
  border-radius: 8px;

  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-main);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* new design end */

@media (min-width: 576px) and (max-width: 991px) {
  .container {
    max-width: 93%;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: var(--max-width-md);
  }
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 1200px) {
  .container {
    max-width: var(--max-width);
  }
}

body {
  height: 100%;
  width: 100%;
  font-family: var(--font-400);
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
  color: var(--color-gray-light);
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
  height: 6px; /* Height of the scrollbar
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Make the scrollbar track transparent */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(
    160,
    160,
    160,
    0.7
  ); /* Semi-transparent color for the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid transparent; /* Transparent border around the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(
    115,
    115,
    115,
    0.7
  ); /* Hover color of the scrollbar thumb */
}
/* Additional styling for the scrollbar corner (optional) */
::-webkit-scrollbar-corner {
  background-color: #f4f4f4; /* Color of the scrollbar corner */
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="checkbox"] {
  transform: scale(1.3);
}

::-moz-selection {
  text-shadow: none;
}

::selection {
  text-shadow: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

button[type="submit"] {
  cursor: pointer;
  border: none;
}

textarea {
  resize: none;
}

/* 
img {
  max-width: 100%;
  height: auto;
} */

a {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

/* a:hover {
  
} */

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-600);
  margin-top: 0;
  /* margin-bottom: 0.5rem; */
}

h1,
.h1 {
  /* primary/h1 */

  font-style: normal;
  /* font-weight: 400; */
  font-size: 28px;
  line-height: 130%;
  color: var(--color-white);
  font-family: var(--font-alt);
}

h2,
.h2 {
  font-style: normal;
  /* font-weight: 400; */
  font-size: 24px;
  color: var(--color-white);
  /* margin: 0px !important; */
  line-height: 130%;
  font-family: var(--font-alt);
}

h3,
.h3 {
  /* primary/body-sb */

  font-family: var(--font-alt) !important;
  font-style: normal;
  /* font-weight: 400; */
  font-size: 20px;
  line-height: 130%;
  color: var(--color-white);
  /* text-transform: uppercase; */
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1.1rem;
}

p {
  font-family: var(--font-400);
  font-weight: 400;
  margin-top: 0;
  /* margin-bottom: 0.3em; */
  color: #fff;
}

@media (min-width: 992px) {
  h1,
  .h1 {
    font-style: normal;
    font-size: 24px;
    line-height: 130%;
  }

  h2,
  .h2 {
    font-style: normal;
    font-size: 20px;
    line-height: 130%;
    /* line-height: 130%; */
  }
}

.btn-outline {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 100%;

  /* primary/green */

  border: 1px solid var(--color-light-green);
  /* Shadow/xs */

  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
}

.btn {
  box-sizing: border-box;
  /* Auto layout */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  /* width: 100%; */
  /* background: var(--color-dark-blue); */
  /* color: var(--color-white); */

  font-family: var(--font-alt);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 15px; */
  font-family: var(--font-alt) !important;
  cursor: pointer;
  border: none;
}

.btn:hover {
  color: var(--color-dark-blue);
  background: var(--color-light-blue);
  transition: all ease 0.4s;
}

.btn2 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  /* width: 100%; */
  background: green;
  color: var(--color-white);

  font-family: var(--font-alt);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 15px; */
  font-family: var(--font-alt) !important;
  cursor: pointer;
  border: none;
}

.btn2:hover {
  color: green;
  background: var(--color-light-green);
  transition: all ease 0.4s;
}

.btn-disable {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 100%;

  background: var(--color-light-gray);

  box-shadow: 0px 16px 32px var(--color-light-gray);
  border-radius: 8px;
  color: var(--color-white);

  font-family: var(--font-alt);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.fd-c {
  flex-direction: column;
}

.gap-sm {
  gap: 0.5rem;
}

.gap-md {
  gap: 1rem;
}

.ai-c {
  align-items: center;
}

.ai-fs {
  align-items: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.jc-se {
  justify-content: center;
  gap: 6%;
}

.bg-primary {
  background-color: var(--color-bg);
}

.bg-secondary {
  background-color: var(--color-white);
}

.text-primary {
  color: var(--color-light-main);
}

.text-light {
  color: var(--color-light);
}

.text-white {
  color: var(--color-white);
}

.mx-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.px-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-05 {
  margin-right: 0.5rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.mh-1 {
  margin: 0px 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.px-4 {
  padding: 2rem 0;
}

.ph-1 {
  padding: 0rem 1rem;
}

.text-center {
  text-align: center;
}

.f-xl {
  font-weight: 500 !important;
  font-size: 20px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-14 {
  font-size: 0.975rem;
}

.input_box,
.form-control {
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 32px;
  padding: 5px 10px;
  margin: 0 0 10px;
  /* color: gray; */
}

.form-control::placeholder {
  /* width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  height: 32px;
  padding: 5px 10px;
  margin: 0 0 10px; */
  color: gray;
}

.passwod_box {
  position: relative;
}

.password_eye {
  cursor: pointer;
  position: absolute !important;

  right: 10px;
  top: 10px;
}

.w-100 {
  width: 100%;
}

.font-12 {
  font-size: 12px;
}

.px-lg-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-lg-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.borderBlack {
  border-color: rgba(0, 0, 0, 0.39);
}

/* alert modal style start */

.alert-modal-message {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
}

.alert_modal_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  /* max-height: 300px; */
  max-height: 274px;
  min-height: 60px;
  overflow-y: hidden;
}

/* alert modal style end */
@media (max-width: 991px) {
  .mx-lg-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .mt-lg-4 {
    margin-top: 2.25rem;
  }

  .mx-lg-4 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .pt-lg-4 {
    padding-top: 2.25rem;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-left {
    text-align: left;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }
}

.float {
  position: fixed;
  width: 55px;
  height: 55px;
  bottom: 30px;
  right: 30px;
  background-color: var(--color-black);
  color: #fff;
  border-radius: 55px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.my-float {
  /* margin-top: 16px; */
}

.animate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
  /* 	width: 350px; */
  /* 	font-size:26px; */
  font-family: Helvetica, sans-serif, Arial;
  animation: load 1.2s infinite 0s ease-in-out;
  animation-direction: alternate;
  text-shadow: 0 0 1px rgb(54, 0, 85);
}

.data_loader {
  height: 40vh;
  background-color: #fff;
  color: #471a55;
}

/* 07-10-2023 */
.details_home_icon {
  color: var(--color-main);
  position: relative;
  left: 2px;
  top: 1px;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--font-700);
  cursor: pointer;
}

.ph-color {
  color: #fff;
}

.ph-color::placeholder {
  color: #fff;
}

/* 07-10-2023 */

@keyframes load {
  0% {
    opacity: 0.08;
    /*         font-size: 10px; */
    /* 				font-weight: 400; */
    filter: blur(5px);
    letter-spacing: 3px;
  }

  100% {
    /*         opacity: 1; */
    /*         font-size: 12px; */
    /* 				font-weight:600; */
    /* 				filter: blur(0); */
  }
}

.loader {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 4px solid var(--color-main);
  border-top-color: #f2f2f2;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
  margin: 0 auto;
  /* align-self: center; */
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(360deg);
  }
}
