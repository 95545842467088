.my_order_main_wrapp {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--color-black);
  /* background: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.my_order_base_wrapp {
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-width: var(--max-width-sm);
  background: var(--color-black);
  padding: 10px;
}

.mo_nav_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px auto;
  gap: 30%;
}

.mo_nav_wrap > img {
  width: 34px;
  height: 24px;
  object-fit: contain;
  /* border: 1px solid green; */
}

.mo_nav_wrap > p {
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--font-700);
}

.mo_products_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  margin: 20px auto;
  width: 100%;
}

.mo_single_product {
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* border: 3px solid #dddddd; */
  border-radius: 8px;
  min-height: 100px;
  margin: 10px auto;
  position: relative;
}

.mo_single_product::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1.5px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.mo_sp_part1 {
  width: 30%;
  height: 100%;
  /* border: 1px solid #545454; */
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.mo_sp_part1 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.mo_sp_part2 {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.mo_sp_part2_heading {
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.mo_sp_part2_size_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mo_sp_part2_size_wrapp > p {
  font-size: 12px;
  color: #000;
  font-family: var(--font-400);
}

.mo_sp_part2_size_wrapp > b {
  font-size: 12px;
  color: #000;
  font-family: var(--font-600);
}

.mo_sp_part2_hr {
  width: 100%;
  background: #ddd;
  height: 2px;
  margin: 4px auto;
}

.mo_sp_part2_final_price {
  text-align: end;
  align-self: flex-end;
  color: red;
  font-size: 12px;
  font-family: var(--font-600);
}

.mo_sp_part3_size_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mo_sp_part3_size_wrapp > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.mo_sp_part3_final_price {
  font-size: 10px;
  color: #000;
  font-family: var(--font-600);
}
