.password_validation_main_wrapp {
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: -6px;
}

.password_validation_main_wrapp > p {
  font-size: 10px;
  font-family: var(--font-600);
  margin: 0px;
  padding: 0px;
}
