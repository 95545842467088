.clubAttac_img_resel {
    width: 100px;
    height: 80px;
}

.clubAttac_img_resel2 {
    width: 100px;
    height: 80px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.link_attach2::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.link_attach2 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.attach_partner_1::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.attach_partner_1 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}