.relerfit {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  height: 150px;
}

.relerfit_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("./../../assets/images/new_bg_text.png");
  background-size: 100% 100%;
  border-radius: 10px;
  padding: 10px;
}

.relerfit_1 > p {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.relerfit_details {
  padding: 15px;
  border-radius: 12px;
  /* border: 1px solid var(--color-main); */
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  position: relative;
}
.relerfit_details::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px; 
  padding: 1px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}
.relerfit_2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.relerfit_img {
  width: 100%;
  border-radius: 10px;
}
.relese_attach_titel {
  font-size: 15px;
  font-family: var(--font-700);
  margin-bottom: 10px;
}
.relesattall {
  border: none;
  height: 150px;
  width: 90%;
  position: relative;
  left: 20px;
  margin: 10px auto;
  background: linear-gradient(to left, transparent, var(--color-main));
  padding: 2px;
  border-radius: 13px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
}
.relesattall_sub {
  display: flex;
  border-radius: 13px;
  width: 100%;
  height: 146px;
  background-color: black;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
}
.relesattall_sub1 {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  height: 130px;
  right: 10%;
  position: relative;
  top: 10px;
}
.relesattall_sub2 {
  display: flex;
  flex: 10;
  position: relative;
}
.relesattall_sub2_link {
  position: absolute;
  bottom: 15px;
  right: 15%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: 1px solid white;
  border-radius: 15px;
  background-color: var(--color-main);
  padding: 10px;
  font-size: 15px;
}
