.new_verify_otp_wrapp {
  width: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new_verify_otp_inputs {
  border: none;
  border-bottom: 2px solid transparent; /* Hide default border */
  background-color: transparent; /* Make background transparent */
  padding: 8px; /* Adjust padding as needed */
  outline: none; /* Remove outline on focus */
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-family: var(--font-600);
  caret-color: var(--color-main);

  /* Apply gradient bottom border */
  background-image: linear-gradient(to right, #ff004c80, #cbacff37);
  background-size: 100% 2px; /* Width 100% of input box, height 2px */
  background-repeat: no-repeat;
  background-position: bottom left; /* Start from bottom left */
}

.new_verify_otp_down_sec {
  width: 100%;
}

.new_verify_otp_down_sec > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.new_verify_otp_down_sec > div > p {
  font-size: 14px;
  font-family: var(--font-400);
  color: var(--color-white);
  margin: 0px;
  padding: 0px;
}

.new_verify_otp_down_sec > div > button {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-main);
  margin: 0px 6px;
  padding: 0px;
}

.new_verify_otp_down_sec > div > p > button {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-main);
  margin: 0px;
  padding: 0px;
}
