.save_add_main_wrapp {
  width: 100%;
  margin: 20px auto;
  text-align: left;
}

.save_add_main_wrapp > p {
  color: #000;
  font-size: 14px;
  font-family: var(--font-400);
  margin-bottom: 10px;
}

.save_add_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.save_add_single_btn {
  width: 80px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #000;
  font-size: 12px;
  font-family: var(--font-300);
}

.iboxes_main_wrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin: 20px auto;
  margin-bottom: 60px;
}

.iboxes_first_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  width: 100%;
}

.first_single_ibox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0.45;
  gap: 5px;
}

.first_single_ibox > p {
  color: #000;
  font-size: 14px;
  font-family: var(--font-400);
}

.first_single_ibox > input {
  color: #000 !important;
  font-size: 14px;
  font-family: var(--font-300);
  background: #ddd;
  border-radius: 8px;
  min-height: 40px;
  padding: 8px 12px;
}

.sec_single_ibox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  gap: 5px;
}

.sec_single_ibox > p {
  color: #000;
  font-size: 14px;
  font-family: var(--font-400);
}

.sec_single_ibox > textarea {
  color: #000 !important;
  font-size: 14px;
  font-family: var(--font-300);
  background: #ddd;
  border-radius: 8px;
  min-height: 30px;
  padding: 8px 12px;
}

.sec_single_ibox > input {
  color: #000 !important;
  font-size: 14px;
  font-family: var(--font-300);
  background: #ddd;
  border-radius: 8px;
  min-height: 40px;
  padding: 8px 12px;
}

.add_ship_total_wrapp_inner {
  align-items: flex-start;
  height: -webkit-fill-available;
  padding: 6px 10px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.add_ship_wrapp {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 5px auto;
}

.add_ship_wrapp > p {
  font-size: 14px;
  color: #000;
  font-family: var(--font-400);
}

.add_ship_wrapp > b {
  font-size: 14px;
  color: #000;
  font-family: var(--font-600);
}

.add_ship_wrapp > span {
  font-size: 16px;
  color: red;
  font-family: var(--font-600);
  padding: 0px;
}

.add_ship_hr {
  width: 100%;
  background: #000;
  height: 1px;
  margin: 10px auto;
}

.add_ship_payment_btn {
  width: -webkit-fill-available;
  background: #000;
  font-size: 14px;
  font-family: var(--font-600);
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin: 8px auto;
}

.afpb_other_input {
  width: 60%;
  border: 1px solid #545454;
  padding: 6px;
  min-height: 35px;
  color: #000 !important;
  background: transparent !important;
  border-radius: 8px;
  font-family: var(--font-400);
  font-size: 14px;
  margin: 0px;
}
