.cusModal_main_wrapp {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cusmodal_inner_wrapp {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background: rgba(51, 51, 51);
  width: 100%;
  /* height: 200px; */
  align-self: flex-end;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
  /* padding: 0 10px; */
}

.cm_logo {
  transform: translate(0, -50%);
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.cm_heading {
  font-size: 18px;
  font-family: var(--font-600);
  color: var(--color-white);
  margin-top: -10px;
}

.cm_des_wrapp {
  overflow-y: scroll;
  max-height: 200px;
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  justify-content: start;
}

.cm_des_wrapp::-webkit-scrollbar {
  /* Hide the default scrollbar for WebKit-based browsers */
  display: none;
}

.cm_des {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
  /* height: 50px; */
  margin-top: 10px;
  text-align: justify;
  overflow: scroll;
}

.cm_hr {
  width: 100%;
  border: 1px solid grey;
  margin: 10px auto;
}

.cm_btn {
  align-self: center;
  padding: 8px 16px;
  background: var(--color-main);
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin: 10px auto;
}
