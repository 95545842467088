.userprfile_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userprfile_base_wrapp {
  width: 100%;
  /* max-width: var(--max-width-md); */
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  max-width: var(--max-width-sm);
  margin: 0 auto;

}

.user_profile_first_wrapp {
  width: 100%;
  height: 200px;
  position: relative;
  background: #fff;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  /* display: flex; */
}

/* banner start */

.up_banner_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.up_topbanner_back {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  /* height: 40px; */
}

.up_topbanner_back > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* banner end */

/* USer Profile start */

.up_profile_name_wrapp {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 0 auto;
  /* margin-top: -50px; */
  height: 100px;
  z-index: 11;
  transform: translate(0%, -50%);
  gap: 1rem;
}

.up_profile_img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  /* object-fit: cover; */
  /* border: 2px solid var(--color-white); */
}
.up_profile_qr {
  width: 100%;
  height: 80px;
  /* border-radius: 50%; */
  border: 2px solid var(--color-white);
}

.up_user_name {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  margin-top: 40px;
  width: 70%;
}

/* USer Profile end */

/* Follow start */
.follow_secs_wrapp {
  display: flex;
  width: 90%;
  max-width: var(--max-width-md);
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  margin-top: -40px;
}

.follow_1sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  max-width: 300px;
}

.follow_1sec_sigle_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.follow_1sec_sigle_wrapp > p {
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--font-700);
  /* opacity: 0.6; */
}

.follow_1sec_sigle_wrapp > span {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-300);
  opacity: 0.6;
  padding: 0px;
}

.follow_btn {
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-400);
  padding: 10px 20px;
  background: var(--color-main);
  border-radius: 28px;
}
/* Follow end */

.up_showcase_btn {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  border-radius: 28px;
  font-family: var(--font-600);
  font-size: 12px;
  margin: 0px auto;
  color: var(--color-white);
  padding: 10px;
  letter-spacing: 1px;
  margin: 10px auto;
  position: relative;
}

.up_showcase_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 28px; 
  padding: 1px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

/* my nfbs section start */

.up_first_name {
  font-size: 12px;
  color: var(--color-white);
  opacity: 0.6;
  font-family: var(--font-300);
  align-self: flex-start;
  width: 90%;
  max-width: var(--max-width-md);
  margin: 10px auto;
}

.mynfb_viewall_btn_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.mynfb_viewall_btn_wrapp > p {
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
}

.mynfb_viewall_btn_wrapp > a {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-400);
  opacity: 0.6;
}

.up_mynfbs_products_wrapp {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 90%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 10px auto;
}

.up_mynfbs_products_wrapp::-webkit-scrollbar {
  /* Hide the default scrollbar for WebKit-based browsers */
  display: none;
}

.up_mynfb_single_product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 120px;
  max-width: 120px;
  /* height: 140px; */
  border-radius: 10px;
  text-wrap: wrap;
  /* overflow: hidden; */
}

.up_mynfb_single_product > img {
  width: 100%;
  height: 120px;

  border-radius: 10px;
}

.up_mynfb_single_product > p {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
  margin-top: 4px;
  text-align: left;
}

.up_mynfb_single_product > span {
  font-size: 10px;
  font-family: var(--font-300);
  color: var(--color-white);
  margin-top: 4px;
  opacity: 0.6;
  padding: 0px;
  margin: 0px;
}

/* my nfbs section end */

.up_history_name {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  width: 90%;
  max-width: var(--max-width-md);
}

.up_history_dis {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
  width: 90%;
  max-width: var(--max-width-md);
  margin: 0 auto;
  opacity: 0.8;
}
