.Intrests_main_wrapp {
  width: 100%;
  height: 100%;
  background: var(--color-black);
}

.intrests_base_wrapp {
  width: 90%;
  /* max-width: 920px; */
  margin: 20px auto;
  max-width: var(--max-width-sm);
}

.intrests_nfb_logo {
  /* height: 40px; */
  width: 29%;
  object-fit: contain;
}

.intrests_heading {
  font-size: 28px;
  font-family: var(--font-700);
  width: 200px;
  margin-top: 20px;
  line-height: 30px;
}

.intrests_btn_main_wrapp {
  margin-top: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.intrests_single_btn {
  padding: 8px 16px;
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  line-height: normal;
  border: 1px solid var(--color-main);
  height: fit-content;
  width: fit-content;
  border-radius: 24px;
  color: var(--color-main);
}

.selected_intrest {
  background: var(--color-main);
  color: var(--color-white);
}

.intrests_get_started_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin-top: 20%;
}
