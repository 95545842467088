.new_gst_main_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 95vh;
  /* padding: 0px 5%; */
  margin: 1rem auto;
}

.new_gst_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem auto;
}

.new_skip_btn {
  width: 48%;
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 16px;
  text-align: center;
}
