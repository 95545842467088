.chnage-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #000;
  height: 100vh;
  width: 100%;
}
.chnage_user_1{
  margin-top: 44px;
}

.change_pass_show_new {
  position: relative;
  float: right;
  bottom: 49px;
  width: 20px;
  right: 14px;
    /* top: -34px; */
    /* bottom: 49px; */
}

.chnage_1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chnage_2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.chnages_user_menu {
  left: 15px;
  position: relative;
}

.chnage_2 {
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.chnage_header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.chnage_header1 {
  width: 20%;
}
.new_nav_screen_name {
  font-size: 15px;
  color: var(--color-white);
  font-family: var(--font-700) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chnage_header2 {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chnage_header3 {
  width: 20%;
}

.chnages_input {
  padding: 15px;
  border: 1px solid #837e7ebb;
  margin-bottom: 16px;
    margin-top: 5px;
}

.chnages_input::placeholder {
  color: gray;
}

.submit_chnages_password {
  background-color: #ee1651;
  /* height: 50px; */
  color: white;
  width: 100%;
  position: relative;
  bottom: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 28px;
  font-size: 16px;
  font-family: var(--font-600);
  padding: 10px 20px;
  /* width: 123%; */
}

.footer_chnages_pass {
  /* position: absolute; */
  background-color: #ee165100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 84px;
  width: 100%;
  margin-top: 5%; 
}

.modal_c {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background-color: #5f5f5f00;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 999;
  transition: height 1s;
  flex-direction: column;
  /* height: 27vh; */
}

.modal_c-content {
  background-color: #333333cc;
  padding: 20px;
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

.modal_c-message {
  color: white;
  font-size: 13px;
}

.close_forgot {
  color: #fff;
  background-color: #ee1651;
  border-radius: 25px;
  width: 100px;
  font-size: 15px;
  padding: 5px;
  height: 30px;
}

.modal_c_div {
  text-align: center;
}

.modal_c_div1 {
  text-align: center;
  height: 40px;
}

@media only screen and (max-width: 850px) {
}

@media only screen and (max-width: 600px) {
  /* .footer_chnages_pass {
    position: fixed;
    background-color: #ee165100;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 84px;
    width: 90%;
    left: 0px;
  } */
}
