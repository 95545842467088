.pro_new_sec_main {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}

.prosetnm1 {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.prosetnm {
  font-size: 20px;
  font-weight: bold;
}

.pro_sen_new_del {
  display: flex;
  width: 80%;
  gap: 10px;
  flex-direction: row;
  margin-top: 10%;
  padding-left: 15px;
}

.pro_sen_new_del1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pro_sen_new_del2 {
  width: 20%;
}

.proset_nm {
  font-family: var(--font-600);
  font-size: 15px;
}

.proset_nm_img {
  width: 70px;
  float: right;
}

.proset_nm_div {
  text-align: start;
}

.pr_d {
  height: 3px;
  background-color: var(--color-main);
  margin-top: 15px;
  width: 80%;
}

.img_pr_new {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 25%;
  left: 12px;
}

.back_pro_nm1 {
  text-align: center;
  width: 80%;
  padding-left: 5px;
}

.back_pro_nm {
  word-spacing: 10px;
  font-size: 25px;
}

.purchesh_new_front-del1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 32%;
  line-height: 30px;
}
.pro_new_sec_main {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}

.prosetnm1 {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.prosetnm {
  font-size: 20px;
  font-weight: bold;
}

.pro_sen_new_del {
  display: flex;
  width: 80%;
  gap: 10px;
  flex-direction: row;
  margin-top: 10%;
  padding-left: 15px;
}

.pro_sen_new_del1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pro_sen_new_del2 {
  width: 20%;
}

.proset_nm {
  font-family: var(--font-600);
  font-size: 15px;
}

.proset_nm_img {
  width: 70px;
  float: right;
}

.proset_nm_div {
  text-align: start;
}

.pr_d {
  height: 3px;
  background-color: var(--color-main);
  margin-top: 15px;
  width: 80%;
}

.img_pr_new {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 25%;
  left: 12px;
}

.back_pro_nm1 {
  text-align: center;
  width: 80%;
  padding-left: 5px;
}

.back_pro_nm {
  word-spacing: 10px;
  font-size: 25px;
}

.purchesh_new_front-del1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 32%;
  line-height: 30px;
}
