.releasednfb-container {
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}

.releasednfb_2 {
  width: 90%;
  margin: 10px auto;
  max-width: var(--max-width-sm);
}

.releasednfb_header {
  top: 0;
  flex-direction: column;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.releasednfb_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.released_main1 {
  width: 20%;
}

.released_main2 {
  width: 80%;
  display: flex;
  justify-content: center;
}

.releasednfb_main_h1 {
  position: relative;
  right: 25px;
}

.released__user {
  width: 100%;
  margin: 10px auto;
}

.user_releasednfb_search {
  height: 40px;
  padding: 15px;
}

.user_releasednfb_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

/* 18-08-2023 */
.nfb_releas {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin: 10px auto;
}

.releasednfb_main {
  padding: 2px;
  /* max-width: 350px; */
  width: 95%;
  margin: 0px auto;
  background: linear-gradient(180deg, #ff004c 0%, #caacff36 100%);
  border-radius: 14px;
}

.releasednfb_base {
  width: 100%;
  background: #16171d;
  border-radius: 14px;
}

.releasednfb_img {
  width: 100%;
  border-radius: 14px;
  height: 200px;
}

.reles_details {
  padding: 10px;
}

.reles_details_sub {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.reles_details_h1 {
  font-family: var(--font-600) !important;
  font-size: 15px;
  position: relative;
}

.reles_details_h2 {
  font-family: var(--font-300) !important;
  font-size: 10px;
  margin-top: 2px;
  opacity: 0.6;
}

.releas_btn2 {
  display: flex;

  flex-direction: row;
  gap: 15px;
}

.releas_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.releas_btn2_img {
  width: 25px;
  height: 25px;
  position: relative;
  left: 15px;
  top: 15px;
}

.edit_benefit {
  font-family: var(--font-600);
  border: 1px solid var(--color-main) !important;
  padding: 10px;
  color: white;
  height: fit-content;
  background-color: #000;
  /* width: 48%; */
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 15px;
  width: fit-content;
}

.stock_main {
  font-size: 15px;
  border: 1px solid var(--color-main) !important;
  font-family: var(--font-600) !important;
  padding: 10px;
  color: white;
  height: fit-content;
  background-color: #000;
  width: 48%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.view_bids {
  font-family: var(--font-600) !important;
  border: 1px solid var(--color-main) !important;
  padding: 15px;
  background-color: var(--color-main);
  border: 1px solid white !important;
  height: fit-content;
  width: 75%;
  border-radius: 8px;
  color: white;
  font-size: 15px;
  margin-bottom: 20px;
}

/* relese benefits style start */

.reles_details_benefits_wrapp {
  max-width: "100%";
  overflow-x: auto;
  margin: 10px auto;
}

.reles_details_single_benefit {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
}
/* relese benefits style end */

.released_nodata_text {
  text-align: center;
  color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-400);
  opacity: 0.8;
  margin-bottom: 10px;
}

/* 18-08-2023 */

@media only screen and (max-width: 920px) {
  .nfb_releas {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .nfb_releas {
    justify-content: center;
  }
}
