.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -ms-transition: transform 0.6s;
  -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_seven_img_front {
  background-image: url(./../../assets/images/Card11front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_seven_img_back {
  background-image: url(./../../assets/images/card11back\ .png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card7_show_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 68%;
  top: 9px;
  left: 0px;
}

.card7_show_img_back {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 81%;
  left: 0;
}

/* 29-08-2023 */

.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}

.nm_of_cre_titel {
  font-size: 10px;
}
.nm_of_cre_nmr {
  font-size: 15px;
}

.sc_f7_details2 {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: end;
  position: relative;
  right: 2%;
}
.sc_img_f7 {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm {
  font-size: 12px;
}
.sc_price {
  font-size: 15px;
  font-weight: 800;
}
.sc_f7_details3 {
  width: 5%;
  position: absolute;
  bottom: 18px;
  right: 3px;
}

/* back  */

.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}

.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card {
  font-size: 12px;
}

/* 31-08-2023 */
.show_second_img {
  width: 20px;
  float: right;
}
.btn_showcardsecond {
  margin-top: 20px;
  width: 95%;
}
.show_second_btn {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-main);
  /* width: -moz-fit-content; */
  /* width: 60px; */
  border-radius: 28px;
  padding: 3px 8px;
  text-align: center;
  margin-left: 4px;
  font-family: var(--font-600);
}
/* new 06-11-2023 */
.sc_f7_details2_back {
  width: 100%;
  bottom: 22%;
  position: absolute;
  padding: 0 22px;
}
.sc_img_f4_back {
  width: 43px;
  height: 42px;
}

.nm_of_user_div7 {
  position: relative;
  width: 100%;
  line-height: 16px;
}
/* .sc_f5_details {
    display: flex;
    flex-direction: row;
    width: 100%;
    bottom: 46%;
    position: relative;
    padding: 0 12px
  } */
.sc_b7_details {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 16px;
  bottom: 23%;
  padding: 0 46px;
}
.sc_f7_details_front {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 12%;
  position: absolute;
  padding: 0 22px;
}
.sc_f7_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 0%;
  position: absolute;
}
.nm_of_user7 {
  font-size: 15px;
  font-family: var(--font-600);
}
.qr_of_user_div7 {
  width: 33%;
  left: 30%;
  position: relative;
  bottom: 22px;
}
.sc_b_details3 {
  width: 13%;
  position: relative;
  bottom: 7px;
  left: 7px;
}
.sc_b_img_f4 {
  width: 40px;
  height: 40px;
  float: right;
  transform: scaleX(-1);
}
.ditails_of_back_cs_7_nfb {
  position: absolute;
  bottom: 31%;
  padding: 0 18px;
  width: 100%;
}
.sc_f7_details1 {
  width: 50%;
}
