.resellnfb_main {
  display: flex;
  /* flex-direction: row; */
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}
.resellnfb_btn_details {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.resellnfb_2 {
  width: 920px;
  margin: 10px auto;
}

.resellnfbs_user_menu {
  left: 15px;
  position: relative;
}

.resellnfb_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.resellnfb_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.resellnfbmain2 {
  width: 80%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.resellnfb_main_h1 {
  position: relative;
  right: 25px;
}

.resellnfb_club_user {
  width: 100%;
  padding: 15px;
}

.user_resellnfb_search {
  height: 40px;
  margin-top: 10px;
  /* padding: 15px; */
}

.user_resellnfb_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

.resellnfb_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  /* right: 10%; */
}

.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

.resell_btn_list {
  display: flex;
  gap: 15px;
  /* padding: 15px; */
  margin: 10px auto;
}

.resellnfb_btn {
  font-family: var(--font-600);
  color: white;
  width: 100%;
  border-radius: 25px;
  /* background-color: #342025; */
  height: fit-content;
  padding: 10px;
  /* border: 1px solid red; */
  position: relative;
}

.resellnfb_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 1px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.resellnfb_btn_2 {
  font-family: var(--font-600);
  color: white;
  width: 100%;
  border-radius: 20px;
  /* background-color: gray; */
  height: fit-content;
  padding: 10px;
  position: relative;
}

.resellnfb_btn_2::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 1px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

/* resellnfb_btn_details_main  start*/
.resellnfb_btn_details_main {
  /* border: 1px solid var(--color-main); */
  /* margin-top: 1%; */
  /* margin-bottom: 1%; */
  border-radius: 25px;
  max-width: 350px;
  width: 100%;
  position: relative;
}
.resellnfb_btn_details_main::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  padding: 1.5px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.details_main_img_div {
  height: 100%;
}

.details_main_img {
  width: 100%;
  border-radius: 25px;
  height: 250px;
}
.details_main_img_round {
  width: 100%;
  border-radius: 25px;
  height: 3000px;
}

.details_main_img_2 {
  width: 100%;
  height: 250px;
  border-radius: 25px;
}

.details_of_resell {
  padding: 20px;
}

.details_of_resell_h1 {
  font-family: var(--font-600);
  font-size: 20px;
  margin-bottom: 10px;
  line-height: normal;
}

.details_of_resell_p {
  margin-bottom: 10px;
  font-size: 12px;
  font-family: var(--font-300);
}
.action_of_resell_btn {
  /* background-color: var(--color-main); */
  color: white;
  font-family: var(--font-700);
  font-size: 10px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.action_of_resell_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 1px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.action_of_resell {
  height: 35px;
}

.launch_nfb_white_img {
  width: 8%;
  float: right;
}

.horizontal-div-warp {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.horizontal-div-warp::-webkit-scrollbar {
  /* Hide the default scrollbar for WebKit-based browsers */
  display: none;
}

/* resellnfb_btn_details_main  end*/
@media only screen and (max-width: 920px) {
  .resellnfb_2 {
    width: 90%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 10px auto;
  }
  .resellnfb_btn_details {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .resellnfb_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .resellnfb_btn_details {
    justify-content: center;
  }
}
