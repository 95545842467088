.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ylw_flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  /* width: 300px;
    height: 500px; */
}
.ylw_card_flip-card-front,
.ylw_card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}
.ylw_card_flip-card-back {
  bottom: 0px;
}

.ylw_flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .ylw_flip-card-inner_card {
  transform: rotateY(180deg);
}
.ylw_card_flip-card-back {
  transform: rotateY(180deg);
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.ylw_card_flip-card-back {
  transform: rotateY(180deg);
}

.yellowcard_1_main_img_front {
  background-image: url(./../../assets/images/yellow_card_bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  /* position: relative; */
}

.yellowcard_1_main_img_back {
  background-image: url(./../../assets/images/yellow_card_back_bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.yellowcard_1_img_front {
  z-index: -1;
  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  top: 2%;
  left: 8px;
}

.yellowcard1_details_front {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 4%;
  position: absolute;
}
.ylw_sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 40%;
  position: relative;
  left: 6%;
  gap: 5px;
  margin-bottom: 15px;
}
.ylw_sc_f_details2 {
  display: flex;
  flex-direction: column;
  width: 46%;
  text-align: end;
  position: relative;
  right: 6px;
  justify-content: end;
}
.name_of_user {
  font-size: 17px;
  /* margin-bottom: 9px; */
  line-height: 20px;
}
.name_of_cre_titel {
  font-size: 10px;
  /* padding-bottom: 5px; */
}
.name_of_cre_name {
  font-size: 17px;
  line-height: 18px;
}
.ylw_sc_f_details3 {
  width: 14%;
  position: absolute;
  bottom: 0;
  right: 2px;
}
.ylw_qr_img_f {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_product_val {
  font-size: 10px;
  line-height: 11px;
}
.sc_price_ {
  font-size: 14px;
  font-weight: 800;
}
.yellowcard1_details_back {
  display: flex;
  flex-direction: row;
  width: 99%;
  bottom: 16%;
  position: relative;
}
.details_bg_ylw_card {
  font-size: 12px;
}
.ylwcard_logo_div {
  width: 15%;
}
.card_ylw_logo_back {
  position: absolute;
  left: 2px;
  bottom: 11px;
  width: 40px;
  height: 40px;
  object-fit: contain;
  transform: rotate(180deg);
}
.ylw_sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 54%;
  position: relative;
}
.ylw_sc_f_details2_back {
  width: 30%;
  text-align: center;
}
.ylw_des_name {
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
  padding-top: 6px;
}
.ylw_card_readmore_btn {
  color: var(--color-main);
  font-size: 12px;
  font-family: var(--font-600);
  border: none;
  background-color: none;
  margin-bottom: 4px;
}
.ylw_sc_img_f_back {
  width: 64px;
  height: 64px;
  background-color: white;
}

.ylw_nm_of_cre_nme_back {
  font-size: 18px;
}
.ylw_sc_b_details {
  position: absolute;
  bottom: 10px;
}
.name_of_owned_at_back {
  font-size: 10px;
}
.yellowcard_1_img_back {
  z-index: -1;
  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  left: 8px;
  border-radius: 15px 15px 0px 0px;
}
.ylw_details_of_back_cs_ {
  position: absolute;
  bottom: 21%;
  padding: 0 20px;
  width: 100%;
}
