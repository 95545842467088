.Purchaseone_btn_set
{
    width: 100%;
    display: flex;
    position: absolute;
    margin-top: 20px;
}
.Purchaseone_btn_set1{
    width: 75%;
}
.Purchaseone_btn_set2{
    width: 25%;
}
.setone_pur_del_1{
    position: relative;
    left: 15px;
    top: 10px;
    width: 95%;
    word-break: keep-all;
}