.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_4_main_img_front {
  background-image: url(./../../assets/images/newBlueCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.crad_4_main_img_back {
  background-image: url(./../../assets/images/newPinkCardBack.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_4_img_front {
  z-index: -1;
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 97%;
  top: 0%;
  left: 0px;
}

.crad_4_img_back {
  z-index: -1;
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 95%;
  left: 0px;
  top: 3px;
  border-radius: 15px 15px 0px 0px;
}

/* 29-08-2023 */

.sc_f_details_6 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 10%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.nm_of_user_4_div {
  position: absolute;
  bottom: 10%;
  text-align: center;
}
.nm_of_user_4_ {
  font-size: 14px;
  font-family: var(--font-600);
}
.crad_4_qr_front_div {
  position: absolute;
  text-align: center;
  bottom: 17%;
}
.crad_4_qr_front {
  width: 45px;
}
.sc_f_details_4_ {
  display: flex;
  flex-direction: row;
  width: 98%;
  /* bottom: 22%; */
  /* position: relative; */
  /* border: 1px solid red;  */
}
.sc_f_details_4_sec1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 42%;
  /* bottom: 25%; */
  position: relative;
  /* border: 1px solid red; */
}
.sc_f_details_4_sec2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 42%;
  /* bottom: 25%; */
  position: relative;
}

.nm_of_cre_nmr {
  font-size: 15px;
}

.sc_f_details2_6 {
  width: 33%;
  text-align: end;
  position: relative;
  right: 13%;
}
.sc_img_f {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm {
  font-size: 12px;
}
.sc_price {
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}

/* back  */
.ditails_of_back_cs_6 {
  position: absolute;
  top: 15%;
  padding: 0px 39px;
  /* margin-left: 17px; */
}
.sc_f_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}
.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}
.sc_b_details_4 {
  position: absolute;
  /* left: 90px; */
  text-align: center;
  /* top: 130px; */
  width: 100%;
  bottom: 3%;
  margin: 0 auto;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}
.sc_img_f_back {
  width: 75px;
  height: 75px;
  background-color: white;
}
.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card_6 {
  font-size: 9px;
}
.sc_f_details_back_5 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 14%;
  position: relative;
}
.nm_of_cre_titel {
  font-size: 12px;
}
.sc_img_f {
  width: 30px;
  height: 30px;
  float: right;
}

.card_four_logo_back {
  position: absolute;
  left: 10px;
  bottom: 30px;
  transform: rotate(180deg);
}
