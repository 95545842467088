.nav13_main_wrapp {
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  width: 100%;
}
.nav13_base_wrapp {
  max-width: var(--max-width-sm);
  width: 90%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 1rem 0%;
  /* margin-top: 8px; */
}

.nav13_profile_wrapp {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  /* border: 1px solid var(--color-main); */
  overflow: hidden;
  background: #ffffff1a;
  padding: 6px;
}

.nav13_profile_wrapp > div {
  padding: 2px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: linear-gradient(
    140.71deg,
    #ff004c 15.3%,
    rgba(255, 0, 76, 0) 89.21%
  );
}
.nav13_img_of_usermenu {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  position: relative;
}

/* ==== */
.nav13_btns_wrapp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.nav13_btn {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
}
.nav13_btn > img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}
