.dashed_input_box {
  border: 2px dashed transparent; /* Set the initial border style */
  border-radius: 15px; /* Set the border radius */
  /* padding: 10px; */
  transition: border-color 0.3s; /* Add transition effect for border color */
  margin: 0px;
}

/* Set gradient border */
.dashed_input_box:focus {
  border-image: linear-gradient(
    to right,
    #ff8a00,
    #e52e71
  ); /* Set the gradient */
  border-image-slice: 1; /* Ensure the whole border is covered by the gradient */
}
