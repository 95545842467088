.crad__show13_main_img_front {
  background-image: url(./../../assets/images/card7_hr.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.show_card_13_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: contain;
  height: 67%;
  top: 16%;
  left: 0px;
}

/* .crad__show13_main_img_front {
  background-image: url(./../../assets/images/card7_hr.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
} */
