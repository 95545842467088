.show_preview_main {
  width: 100%;
  position: relative;
  height: 250px;
}
.show_preview_i {
  width: 100%;
  border-radius: 10px;
  height: 250px;
}
.del_ofshow {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1px;
  width: 100%;
  gap: 10px;
  background-image: linear-gradient(180deg, #0000, #da0e41);
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
}
.del_ofshow1 {
  display: flex;
  width: 50%;
  flex-direction: column;
  position: relative;
}
.del_ofshow2 {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: end;
  position: absolute;
  right: 20px;
  bottom: 10px;
}

/* keyur  css start*/
.del_pro_nm_vi {
  font-family: var(--font-600);
}
.del_pro_nm_vi2 {
  font-family: var(--font-300);
}
.del_pro_nm_vi3 {
  font-family: var(--font-300);
}
.view_like_pro_2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
}
/* keyur css end */

.pv2_productvalue_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.pv2_productvalue_single_card {
  display: flex;
  width: 48%;
  height: 100px;
  background-image: url("../../assets/images/bg_amount.png");
  object-fit: contain;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
}

.pv2_productvalue_value {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.pv2_productvalue_name {
  font-size: 10px;
  font-family: var(--font-300);
  color: var(--color-white);
}
