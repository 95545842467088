.login-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.login_1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-logo {
  width: 100px;
}

.web_img {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.web_img_1 {
  width: 80%;
  position: relative;
  left: 5%;
}

.login_2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.login-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: left;
  align-items: start;
}

.login-name {
  /* display: none; */
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  color: #ee1651;
  width: 60%;
  text-align: start;
  padding-top: 10px;
  font-family: var(--font-700);
}

/* .forgot_login_pass {
  position: relative;
  float: right;
  color: white;
  z-index: 99;
} */

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 100%;
  color: white;
  background-color: black;
}

.login-button {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 44px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
}

.signup-text-login {
  color: #fff;
  text-align: center;
}

.signup-text-login a {
  font-weight: 800;
  color: #fff;
  text-decoration: none;
}

.signup-text-login b {
  color: #ee1651;
  font-size: 14px;
  font-weight: 800;
}

.signup-text-login a:hover {
  text-decoration: underline;
}

.hied_login {
  position: relative;
  float: right;
  width: 20px;
  right: 12px;
  bottom: 44px;
}

.login-footer {
  position: relative;
  /* padding-top: 40%; */
}

.sign_login {
  color: #ee1651;
}
.signup-text-login {
  color: #fff;
  font-size: 14px;
}

.signup-text-login a {
  color: #fff;
  font-weight: 800;
  text-decoration: none;
}

.signup-text-login a:hover {
  text-decoration: underline;
}

.web_screen_login {
  width: 90%;
  /* border: 1px solid white; */
  padding: 20px;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}

.forgot_login_pass {
  position: relative;
  float: right;
  color: white;
  /* text-align: end; */
  left: 18px;
  font-size: 14px;
  font-family: var(--font-600);
  z-index: 1;
}

/* .modal-content_login {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
} */

.modal_login {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background-color: #5f5f5f00;
  display: flex;
  /* width: 100%; */
  align-items: flex-end;
  justify-content: end;
  z-index: 9999;
  transition: height 1s;
  flex-direction: column;
  height: 30vh;
  width: 90%;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}

.modal-content_login {
  /* background-color: #333333cc; */
  background-color: #333333;
  padding: 20px;
  width: 100%;
  height: auto;
  /* max-width: 400px; */
  border-top-left-radius: 15px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar {
  display: none;
}
/* .forgot_div{
  width: 100%;
} */

@media only screen and (max-width: 850px) {
  .modal_login {
    width: 100%;
  }

  .web_screen_login {
    width: 100%;
    border: none;
    padding: 0px;
  }

  .login_1 {
    width: 55%;
  }

  .login_2 {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .signup-text-login b {
    color: #ee1651;
    font: 800 !important;
  }

  .web_screen_login {
    width: 100%;
    border: none;
    padding: 0px;
  }

  .login_1 {
    display: none;
  }

  .login_2 {
    width: 100%;
    padding: 0px;
  }

  .hied_login {
    position: relative;
    float: right;
    bottom: 44px;
    width: 20px;
    right: 15px;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: black;
    height: 100vh;
  }

  .login-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: start;
  }

  .login-name {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: var(--font-700);
    color: #ee1651;
    top: 15px;
    position: relative;
    width: 0px;
    text-align: end;
    padding-top: 10px;
  }

  .login-logo {
    width: 100px !important;
    float: left;
    display: block;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
  }

  input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    color: white;
    background-color: black;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .login-footer {
    position: relative;
    /* padding: 10px; */
    margin-top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .signup-text-login {
    color: #fff;
    border: none;
    padding: 0px;
    text-align: start;
    font-size: 14px;
  }

  .signup-text-login a {
    color: #fff;
    font-weight: 800;
    text-decoration: none;
  }

  .signup-text-login a:hover {
    text-decoration: underline;
  }
  .login-button {
    font-size: 16px;
    font-family: var(--font-600);
    margin-top: 0px;
  }
  .forgot_login_pass {
    position: relative;

    margin-left: 45%;
  }

  /* modal login  */
  /* .modal_login {
    position: fixed;
    top: 0;
    bottom: 0pc;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* Additional styling as needed */
}
