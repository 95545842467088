.esa_first_wrapp {
  margin: 10px auto;
}

.esa_first_wrapp > p {
  font-size: 16px;
  color: #fff;
  font-family: var(--font-600);
}

.esa_hr {
  width: 100%;
  background: #fff;
  opacity: 0.6;
  height: 1px;
  margin-top: 6px;
}

.esa_third_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  padding: 6px auto;
}

.esa_name {
  font-family: var(--font-600);
  color: var(--color-main);
  font-size: 14px;
  width: 74%;
  text-align: start;
}

.esa_pvd {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 14px;
  width: 22%;
  text-align: end;
}

.esa_pv {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 14px;
  /* width: 22%; */
  text-align: start;
  margin: 10px auto;
}

.esa_share_btn {
  width: 100%;
  background: var(--color-main);
  font-size: 16px;
  font-family: var(--font-400);
  color: var(--color-white);
  border-radius: 8px;
  padding: 8px 16px;
}

.esa_save_btn {
  width: 80%;
  background: var(--color-main);
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
  border-radius: 28px;
  padding: 8px 16px;
  margin: 20px 10%;
  align-self: center;
}
