/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

/* 04-09-2023 */
.club_m_list {
  position: relative;
}

.club_m_list_view {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #262626;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.club_m_list_img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  object-fit: cover;
  border: 1px solid #fff;
}

.club_m_list_nm {
  font-size: 14px;
  font-family: var(--font-300);
}
