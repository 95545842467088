.mynfbcclubnfb_main {
    position: relative;
}

.mynfbcclubnfb_main::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1.5px;
    background: linear-gradient(
      100.74deg,
      #ff004c 0%,
      #9a92d24d 98.82%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    /* border: 1px solid; */
  }

.mynfbcclubnfb_img {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.mynfbcclubnfb_nm {
    position: absolute;
    /* background-color: rgba(177, 177, 177, 0.73); */
    backdrop-filter: blur(8px);
    bottom: 0px;
    height: 50px !important;
    width: 100%;
    color: white;
    border-radius: 5px;
    font-family: var(--font-400);
    font-weight: 700;
    font-size: 15px;
    display: flex;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mynfbcclubnfb_nm1 {
    width: 50%;

}

.mynfbcclubnfb_nm2 {
    width: 50%;
    text-align: end;
    position: relative;
}

.mynfbcclubnfb_number {
    color: white;
    font-family: var(--font-400);
    font-weight: 700 !important;
    font-size: 12px;
}
.mynfbcclubnfb_number1{
    color: white;
    font-family: var(--font-400);
    font-weight: 700 !important;
    font-size: 12px;
}

.mynfbcclubnfb_main_details {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
    margin: 30px auto;
}

/* mynfbc_nav_hd */
.mynfbc_nav_hd
{
    display: flex;
    width: 100%;
}
.mynfbc_nav_nm
{
    width: 82%;
    text-align: center;
    font-family: var(--font-600);

}
.mynfbc_nav_img1
{
    width: 10%;
    cursor: pointer;
}
.mynfbc_nav_img
{
    width: 25px;
    height: 25px;
    /* width: 8%; */
}