/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.b_list_hr {
  background-color: var(--color-main);
  height: 2px;
  width: 50%;
}
.mybidslist_list {
  width: 100%;
  border-radius: 10px;
  background-image: linear-gradient(to top, #000000, #7a7575);
  padding: 5px;
  margin-bottom: 20px;
  /* -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px; */
  position: relative;

}

.mybidslist_list::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px; 
  padding: 1px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */



}

.mybidslist_sub {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  padding: 10px;
}
.my_list_bis_img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid var(--color-main);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.firstname_mybidslist {
  flex: 8;
}
.btn_sub_list_my_img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  /* border: 1px solid green; */
}
.btn_sub_list_my {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
