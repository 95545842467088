.approval_main {
  display: flex;
  /* flex-direction: row; */
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.approval_2 {
  width: 920px;
  margin: 10px auto;
}

.approvals_user_menu {
  left: 15px;
  position: relative;
}

.approval_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.approval_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.approvalmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.approval_main_h1 {
  position: relative;
  right: 25px;
}

.approval_club_user {
  width: 100%;
  /* padding: 10px; */
  margin: 10px auto;
  margin-top: 20px;
}

.user_approval_search {
  height: 40px;
  padding: 15px;
}

.user_approval_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

.approval_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  right: 10%;
}

/* 17-08-2023 */
.main_approval_btn {
  display: flex;
  gap: 15px;
  width: 100%;
}

.Requests_btn {
  color: white;
  font-family: var(--font-600);
  font-size: 15px;
  width: 33%;
  background-color: gray;
  padding: 10px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.Accepted_btn {
  color: white;
  font-family: var(--font-600);
  font-size: 15px;
  width: 33%;
  /* background-color: gray; */
  padding: 10px;
  border-radius: 25px;
  position: relative;
}

.Accepted_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px; 
  padding: 1px; 
  background:linear-gradient(100.74deg,rgba(202, 172, 255, 0.21) 0%,  #FF004C 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}
.Requests_btn_details {
  width: 100%;
}

.Accepted_btn_details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 30px;
  margin: 10px auto;
  width: 100%;
  margin-top: 20px;
}

.accepted_sub {
  width: 100%;
}

.Rejected_btn_details {
  width: 100%;
}

.main_approval_btn button.active {
  background-color: var(--color-main);
}

/* approval_btn_details_main  end*/
/* 17-08-2023 */
.accepted_single_card {
  width: 98%;
  height: 100%;
  max-height: 280px;
  position: relative;
  /* border-radius: 25px; */
  overflow: hidden;
  margin: 0 1%;
}

.accepted_single_card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  padding: 1px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  /* border: 1px solid; */
}

.accepted_details_sub_1 {
  backdrop-filter: blur(10px);
  background-color: #ffffff7e;
  /* width: 18%; */
  min-width: 140px;
  position: absolute;
  top: 10px;
  padding: 8px 16px;
  border-radius: 28px;
  left: 10px;
}

.accepted_details_sub_2 {
  backdrop-filter: blur(10px);
  background-color: #ffffff7e;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 15px;
  border-radius: 15px 15px 25px 25px;
  /* height: 100px; */
}

.accepted_details_sub_3 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.accepted_details_img {
  width: 100%;
  height: 100%;
  min-height: 275px;
  border-radius: 25px;
}

.accepted_details_h1 {
  font-size: 10px;
  line-height: 15px;
  font-family: var(--font-600);
  color: #000;
}

.profile_accepted_details {
  width: 35px;
  height: 35px;
  border-radius: 35px;
}

/* 18-08-2023 */
.accepted_details_nm {
  font-family: var(--font-600);
  font-size: 12px;
  color: #000;
}

.accepted_details_pro {
  font-size: 10px;
  line-height: 15px;
  font-family: var(--font-400);
  color: #000;
}

.accepted_request {
  font-family: var(--font-600);
  font-size: 10px;
  color: #000;
  margin-bottom: 5px;
}

.accepted_nm {
  font-family: var(--font-700);
  font-size: 14px;
  color: #000;
}

.accepted_text {
  font-family: var(--font-600);
  font-size: 10px;
  color: #000;
  margin-top: 4px;
}

.assetapprov_nodata_text {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 18px;
  margin: 30px auto;
  align-self: center;
  width: fit-content;
}

.new_btn_wrapp_for_assetaproval {
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;

  border: none;
  border-radius: 50px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(72, 67, 68, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 17px #ff004cc2 inset, 0px 4px 14px 5px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;
}

.new_btn_wrapp_for_assetaproval > p {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 14px;
  font-family: var(--font-600);
  top: 50%;
  left: 50%;
  color: var(--color-white);
  width: 80%;
}

/* 18-08-2023 */

/* 17-08-2023 */
