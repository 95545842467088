.back_arrow_wrapp {
  width: 100%;
  margin: 1rem auto;
  padding: 0px 5%;
}

.new_intanationa_invite_main_wrapp {
  width: 100%;
  min-height: 90vh;
  padding: 1rem 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 1rem; */
}

.new_intanationa_invite_main_wrapp > img {
  width: 180px;
  height: 180px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.new_intanationa_invite_main_wrapp > h2 {
  color: var(--color-white);
  font-size: 70px;
  font-family: var(--font-600);
  line-height: 110%;
}

.new_intanationa_invite_main_wrapp > p {
  color: var(--color-white);
  font-size: 18px;
  font-family: var(--font-600);
  margin-bottom: 1rem;
}
