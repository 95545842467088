.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_8_main_img_front {
  background-image: url(./../../assets/images/newredlineCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}
.crad_14_main_img_front {
  background-image: url(./../../assets/images/card14front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}
.crad_15_main_img_front {
  background-image: url(./../../assets/images/card15front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_8_main_img_back {
  background-image: url(./../../assets/images/newredlineCardBack.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}
.crad_14_main_img_back {
  background-image: url(./../../assets/images/card14back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}
.crad_15_main_img_back {
  background-image: url(./../../assets/images/card15back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_8_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  top: 0%;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
}

.crad_8_img_back {
  z-index: -1;
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 98%;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
  top: 4px;
}

/* 29-08-2023 */

.sc_f_details_5 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 40%;
  position: relative;
}
.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}
.nm_of_user_5 {
  font-size: 18px;
  text-align: center;
  position: relative;
  bottom: 39%;
  left: 18%;
}

.sc_f_details1_5 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 9%;
  margin-top: 20px;
}
.nm_of_cre_titel {
  font-size: 10px;
}
.nm_of_cre_nmr {
  font-size: 15px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.sc_f_details2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: end;
  position: relative;
  right: 12%;
  margin-top: 20px;
}
.sc_img_f {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm {
  font-size: 12px;
}
.sc_price {
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3_8 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 21px;
}
.sc_img_f_8 {
  width: 35px;
  height: 35px;
  float: right;
}
.sc_f_blur_8 {
  position: absolute;
  bottom: 22%;
  left: 5%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  border-radius: 28px;
  backdrop-filter: blur(13px);
  padding: 8px;
}
.sc_qr_div {
  width: 20%;
  height: 45px;
  background-color: white;
  border-radius: 25px;
  align-items: center;
  text-align: center;
}
.crad_8_qr_front {
  width: 30px;
  position: relative;
  top: 15%;
}
.nm_of_user_8_div {
  text-align: left;
  margin: 0 auto;
  width: 80%;
  padding: 0 20px;
}
.nm_of_user_8 {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  line-height: normal;
}
/* back  */
.sc_f_details_back_8 {
  width: 100%;
  bottom: 13%;
  position: relative;
  text-align: center;
}
.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 70%;
  position: relative;
  left: 6%;
}
.sc_p_nm_8 {
  font-size: 11px;
  color: var(--color-white);
  font-family: var(--font-300);
}
.sc_f_details_8 {
  position: absolute;
  bottom: 12px;
  text-align: center;
  width: 100%;
}
.sc_price_8 {
  font-size: 12px;
  font-family: var(--font-600);
}

.nm_of_cre_titel_back {
  font-size: 10px;
  font-family: var(--font-300);
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}
.sc_img_f_back {
  width: 75px;
  height: 75px;
  background-color: white;
}
.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-300);
}

.card_7_logo_front {
  position: absolute;
  bottom: 30px;
  right: 20px;
  width: 30px;
  height: 30px;
}

.card_8_logo_back {
  position: absolute;
  bottom: 30px;
  left: 20px;
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
}
