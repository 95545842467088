.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.cardfront_img_feed {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.card_1_main_img_front {
  background-image: url(./../../assets/images/yellow_card_bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  /* position: relative; */
}

.card_1_main_img_back {
  background-image: url(./../../assets/images/yellow_card_back_bg.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_1_img_front {
  z-index: -1;
  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  top: 2%;
  left: 8px;
}

.crad1_details_front {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 4%;
  width: 95%;
}

.crad1_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 4%;
  width: 95%;
}

.prodct_highlights {
  font-family: var(--font-600) !important;
  line-height: 0px;
  position: relative;
  left: 15px;
}

.pro_nm_card {
  font-family: var(--font-800) !important;
  line-height: 10px;
  position: relative;
  left: 15px;
}

.card_qr {
  width: 70px;
  background: white;
  position: relative;
  right: 12%;
  bottom: 14%;
  float: right;
}

.front_card_sub_details {
  width: 50%;
}

.front_card_sub_details2 {
  width: 50%;
}

.front_card_sub_details_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15%;
}

.div_card_1 {
  width: 60%;
}

.div_card_2 {
  width: 40%;
}

.front_nm {
  font-family: var(--font-600) !important;
  margin-bottom: 8%;
}

.front_nm_product {
  font-family: var(--font-800) !important;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}

.front_nm_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  left: 0px !important;
  position: relative !important;
}

.img_front_card_1_logo {
  width: 40px;
  position: relative;
  top: 20%;
  left: 30%;
}

.front_nm_2 {
  font-family: var(--font-800) !important;
}

.front_nm_3 {
  font-family: var(--font-600) !important;
  margin-bottom: 5%;
}

.card_1_img_back {
  z-index: -1;
  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  left: 8px;
  border-radius: 15px 15px 0px 0px;
}
.ditails_of_back_cs_ {
  position: absolute;
  bottom: 15%;
  padding: 0 20px;
  width: 100%;
}
