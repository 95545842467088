.Verifyscreen-container {
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}
.input_otp_new1 {
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 74%;
  padding: 10px;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Verifyscreen_1 {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.web_user_menu {
  margin-left: 0.5%;
}
/* .mobile_input_modal {
  width: 35%;
  margin-left: 19.5%;
} */

.Verifyscreen_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: normal;
  margin-top: 19px;
}
.otp_demo1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0%;
  width: 74%;
  gap: 10px;
}
.verify_edit {
  width: 21px;
  margin-top: 11px;
}

.Verifyscreen_header {
  top: 0;
  flex-direction: column;
  background-color: #000;
  padding: 20px;
  display: flex;
  width: 16%;
  margin-left: 17%;
}
.in_de_otp1 {
  background-color: gray;
  width: 73px;
  height: 70px;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  color: white;
  font-size: 25px;
  text-align: center;
  /* margin-left: 29px; */
}
.web_user_menu1 {
  margin-left: 21.5%;
}

.mobile_verify {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.mobile_verify_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 44px;
  width: 34.4%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin-left: 20%;
}
.resend_mobile_otp {
  float: right !important;
  position: relative;
  left: 0px !important;
  color: var(--color-main);
  font-family: var(--font-700) !important;
  border: none;
}

.mobile_verify_number {
  color: var(--color-main);
  font-size: 15px;
  margin-top: 3%;
  left: 0px !important;
  cursor: pointer;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(114, 114, 114, 0.466);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9;
}

.modal_content_mobile {
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

/* opt screen  */
.mobile_modal_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 20px auto;
}

@media only screen and (max-width: 920px) {
  .Verifyscreen_1 {
    display: none;
  }

  .Verifyscreens_user_menu {
    left: 15px;
    position: relative;
  }

  .Verifyscreen_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .Verifyscreen_header {
    top: 0;
    flex-direction: column;
    background-color: #000;
    padding: 20px;
    display: flex;
  }
}

@media only screen and (max-width: 620px) {
  .Verifyscreen_1 {
    display: none;
  }

  .Verifyscreens_user_menu {
    left: 15px;
    position: relative;
  }

  .Verifyscreen_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .Verifyscreen_header {
    margin-left: 4%;
  }

  .Verifyscreen_header {
    top: 0;
    flex-direction: column;
    background-color: #000;
    padding: 20px;
    display: flex;
  }

  .new_verify {
    width: 97px;
  }
  .otp_demo1 {
    width: 100%;
  }

  .email_div {
    width: 92%;
    margin: 0 auto;
  }

  .mobile_verify_h1 {
    font-family: var(--font-700) !important;
    font-size: 25px;
  }

  .mobile_verify_p {
    font-family: var(--font-400);
    color: gray !important;
    left: 0px !important;
    text-align: center;
    font-size: 15px !important;
    width: 100% !important;
  }

  .email_verify_p {
    font-family: var(--font-600) !important;
    width: 100% !important;
    color: gray !important;
    text-align: center;
    font-size: 15px !important;
    left: 0px !important;
    word-break: break-all;
  }

  .verify_edit {
    width: 15px;
  }

  .mobile_verify_btn {
    padding: 10px 20px;
    background-color: #ee1651;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 44px;
    width: 100%;
    font-size: 16px;
    font-family: var(--font-600);
    border-radius: 28px;
    width: 100%;
    margin-left: 0%;
  }

  .email_verify_btn {
    position: relative;
    height: fit-content;
    padding: 15px;
    background-color: var(--color-main);
    font-family: var(--font-900) !important;
    width: 30%;
    margin-top: 10%;
  }

  .resend_mobile_otp {
    float: right !important;
    position: relative;
    left: 0px !important;
    color: var(--color-main);
    font-family: var(--font-700) !important;
    border: none;
  }

  .monile_div {
    padding: 20px;
    padding-top: 0px;
  }

  /* OTP input container */
  .otp_div {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  /* OTP input box style */
  .otp_input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }

  .mobile_input {
    width: 45px;
    height: 45px;
    text-align: center;
    font-size: 18px;
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: gray;
    column-rule: white;
    outline: none;
  }

  /* verifyscreen.css */

  /* Add styles for the modal overlay */

  .mobile_input_modal,
  .email_input_modal {
    width: 80%;
    height: 45px;
    font-family: var(--font-700) !important;
    color: white;
  }

  .mobile_input_modal::placeholder {
    font-family: var(--font-bold);
  }

  .email_input_modal::placeholder {
    font-family: var(--font-bold);
  }

  /* Add styles for the modal content */

  /* Add styles for the mobile number section */
  /* .mobile_verify_number {
    display: inline-block;
    margin-top: 2px;
    text-decoration: none;
    cursor: pointer;
  } */

  /* Rest of your existing styles... */
  .input_otp_new1 {
    gap: 10px;
    flex-direction: row;
    width: 95%;
    padding: 10px;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    gap: 19px;
    margin-left: 2.5%;
  }
  .in_de_otp1 {
    width: 24%;
    height: 60px;

    text-align: center;
    /* margin-left: 29px; */
  }
  /* .web_user_menu {
    margin-left: 0%;
  } */
  .web_user_menu1 {
    margin-left: 0%;
  }
}
