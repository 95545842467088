.oc_secound_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin: 16px auto;
}

.oc_secound_wrapp > img {
  width: 90%;
  height: 140px;
  object-fit: contain;
}

.oc_secound_wrapp > p {
  color: #000;
  font-size: 16px;
  font-family: var(--font-600);
  text-align: center;
}

.oc_view_product_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  width: 100%;
}

.oc_view_product_wrapp > div {
  width: 58%;
  height: 1px;
  background: #000;
}

.oc_view_product_wrapp > button {
  width: 38%;
  font-size: 14px;
  color: red;
  font-family: var(--font-600);
  text-align: end;
}

.oc_add_address_btn {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  background: #545454;
  border-radius: 8px;
}

.oc_add_address_btn > p {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-600);
}

.oc_total_main_wrapp {
  width: 100%;
  padding: 10px;
  margin: 16px auto;
  border: 3px solid #ddd;
  border-radius: 8px;

  margin-bottom: 80px;
}

.pac_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
}

.pac_wrapp > p:nth-child(1) {
  color: #000;
  font-size: 16px;
  font-family: var(--font-300);
}

.pac_wrapp > p:nth-child(2) {
  color: #000;
  font-size: 16px;
  font-family: var(--font-600);
}

.pac__total_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
}

.pac__total_wrapp > p:nth-child(1) {
  color: #000;
  font-size: 18px;
  font-family: var(--font-400);
}

.pac__total_wrapp > p:nth-child(2) {
  color: #000;
  font-size: 18px;
  font-family: var(--font-700);
}

.oc_co_btn {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-600);
  color: #fff;
  font-size: 14px;

  background: #000;
  width: -webkit-fill-available;
}

.oc_addresses_wrapp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin: 10px auto;
  margin-bottom: 30px;
}

.oc_sigle_add {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  min-height: 70px;
  /* margin: 10px auto; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.oc_sigle_add > input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid black;
  background-color: white;
  margin: 0px;
}

.oc_sigle_add > input[type="radio"]:checked::before {
  background-color: #000;
  width: 20px;
  height: 20px;
}

.oc_sigle_add_inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
}

.oc_sigle_add_inner > label {
  font-size: 14px;
  color: #000 !important;
  font-family: var(--font-600);
  margin: 0px;
  text-align: left;
}

.oc_sigle_add_inner > p {
  font-size: 12px;
  color: #545454;
  font-family: var(--font-300);
}

.empty_cart_btn_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.empty_cart_btn {
  padding: 12px 18px;
  color: #fff;
  background: #545454;
  font-family: var(--font-600);
  font-size: 16px;
  border-radius: 8px;
}
