.usermenu_header {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
}

.user_img_menu {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.user_img_1 {
  width: 100px;
  height: 100px;
  border: 1px solid var(--color-main);
  border-radius: 100px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.menu_list {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  gap: 10px;
  align-items: center;
  /* justify-content: center; */
}

.images_icon_menu {
  width: 20px;
  height: 20px;
}
.notifi_details_tag {
  position: relative;
  left: 11px;
}
.menu_list_p {
  font-size: 12px;
  color: white;
  position: relative;
  left: 13px;
}

/* mpin modal start */

.mpin_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
}

.mpin_yn_btn {
  width: 40%;
  padding: 8px 16px;
  background-color: var(--color-main);
  font-size: 14px;
  font-family: var(--font-300);
  color: var(--color-white);
  border-radius: 28px;
}

/* mpin modal end */

@media only screen and (max-width: 850px) {
  /* .web_screen_usermenu {
        width: 100%;
        border: none;
        padding: 0px;
    }
    .usermenu_1 {
        width: 55%;
    }

    .usermenu_2 {
        width: 45%;
    } */
}

@media only screen and (max-width: 600px) {
  .usermenu_1 {
    display: none;
  }

  .notifications_user_menu {
    left: 10px !important;
    bottom: 2px;
    position: relative;
    margin-bottom: 0px;
    font-family: var(--font-600);
  }

  .usermenu_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .user_menu_nm {
    font-family: var(--font-600);
    font-size: 16px;
  }

  .user_img_menu {
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 33vh;
  }

  .user_img_1 {
    width: 100px;
    border-radius: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .images_icon_menu {
    width: 20px;
    height: 20px;
  }

  .images_icon_menu > p {
    position: relative;
    left: 15px;
    font-size: 14px;
    color: white;
  }

  /* modal start css  */
  .mpin-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.166);
    /* Set the overlay background color and opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    /* Ensure the modal overlay is displayed on top of other elements */
  }

  .mpin-modal {
    background-color: #fff;
    /*Setthemodalbackgroundcolor*/
    padding: 20px;
    border-radius: 8px;
    width: fit-content;
    height: auto;
  }

  .mpin_modal-buttons {
    text-align: end;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: end;
    gap: 20px;
  }

  .modal_mpin_btn {
    font-family: var(--font-bold);
    color: black;
  }

  .mpinbtn_all {
  }

  .modal_h1_mpin1 {
    font-size: 18px;
    color: black;
    font-family: var(--font-bold);
  }
  .modal_h1_mpin {
    font-size: 18px;
    color: black;
    font-family: var(--font-800) !important;
  }

  /* modal end css  */
}

/* -------------------------------- */
