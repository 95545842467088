.new_show_user_single_input_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  padding: 0px 5%;
  margin: 1rem auto;
}
