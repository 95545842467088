.all_family_member_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 10px;
  margin: 30px auto;
  width: 100%;
}

.single_family_member_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
  margin-bottom: 8px;
}

.single_family_member_card > img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: top;
}

.single_family_member_card > p {
  width: 100%;
  font-size: 12px;
  color: white;
  text-align: left;
  font-family: var(--font-600);
  margin: 0px;
}

.single_family_member_card > span {
  width: 100%;
  font-size: 10px;
  color: white;
  text-align: left;
  opacity: 0.6;
  font-family: var(--font-600);
  padding: 0px;
}

.single_family_member_card > button {
  width: 100%;
  font-size: 10px;
  color: var(--color-main);
  text-align: left;
  font-family: var(--font-600);
  padding: 0px;
}
