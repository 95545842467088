.launche_nfb {
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  background-color: black !important;
  height: 100vh !important;
  overflow: scroll !important;
}

/* header */
.launche_nfb_header {
  display: flex;
  flex-direction: row !important;
  height: 50px !important;
  width: 100% !important;
  margin-bottom: 10px;
}

.launche_nfb_header1 {
  width: 10%;
}

.launche_nfb_header2 {
  width: 90%;
  text-align: center;
}

.nfb_h1 {
  position: relative;
  right: 5px;
}

/* end header */

.nfb_details {
  border: 2px solid #ee1651;
  position: relative;
  border-radius: 20px;
  margin-bottom: 10px;
  height: 65%;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.nfb_img {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-radius: 20px;
}

.nfb_details_sub {
  padding: 15px;
  height: 100px;
}

.launche_btn {
  position: relative;
  width: 100px;
  background: #ee1651;
  height: 30px;
  border-radius: 5px;
  padding: 5px;
}

@media (max-width: 600px) {
  .launche_nfb {
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
    background-color: black !important;
    height: 100vh !important;
    overflow: scroll !important;
  }

  /* header */
  .launche_nfb_header {
    display: flex;
    flex-direction: row !important;
    height: 50px !important;
    width: 100% !important;
    margin-bottom: 10px;
  }

  .launche_nfb_header1 {
    width: 10%;
  }

  .launche_nfb_header2 {
    width: 90%;
    text-align: center;
  }

  .nfb_h1 {
    position: relative;
    right: 5px;
  }

  /* end header */
  .nfb_details {
    border: 2px solid #ee1651;
    position: relative;
    border-radius: 20px;
    margin-bottom: 10px;
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .nfb_img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 20px;
  }

  .nfb_details_sub {
    padding: 15px;
    height: 100px;
  }

  .launche_btn {
    position: relative;
    width: 40%;
    font-size: 12px;
    font-family: var(--font-600) !important;
    background: #ee1651;
    height: fit-content;
    border-radius: 5px;
    padding: 8px;
  }
}
