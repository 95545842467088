.total_wallet_rupee {
  color: white;
  font-size: 25px;
  margin-top: 20px;
  font-family: var(--font-400);
  word-break: break-all;
}

.wl_titel {
  color: gray;
  font-family: var(--font-400);
  font-size: 14px;
}

.wallet_entry_wrapp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
  /* height: 50px; */
  width: 100%;
}

.all_list1 {
  width: 60px;
  height: 60px;
}

.all_list1 > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.add_with_1 {
  height: 120px;
  background-image: linear-gradient(148deg, #7b982a, #000);
  padding-top: 15px;
  /* text-align: center; */
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 48%;
  /* border: 1px solid #7b982a; */
  border-radius: 10px;
}

.wallet_tran {
  width: 25px;
  position: relative;
  top: 10px;
  margin-bottom: 15px;
}

.add_name {
  font-size: 15px;
}

.add_with_c {
  width: 4%;
}

.add_with_2 {
  height: 120px;
  background-image: linear-gradient(148deg, #2e3192, #000);
  text-align: center;
  width: 48%;
  /* border: 1px solid #2e3192; */
  padding-top: 15px;
  border-radius: 10px;
}

.Transactions_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px auto;
}

.Transactions_text {
  font-family: var(--font-700);
  font-size: 16px;
}
.download_text {
  font-family: var(--font-700);
  font-size: 14px;
  color: var(--color-red);
}

.container_bank {
  display: flex;
  align-items: center;
  margin: 30px auto;
  width: 100%;
}

.manage_account_btn {
  /* border: 2px solid #e51a4b; */
  background-color: black; /* Changed button color to black */
  color: #fff;
  font-family: var(--font-400);
  text-align: center;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%; /* Changed button width to 100% */
  align-self: flex-start;
  font-size: 14px;
  position: relative;
}

.manage_account_btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 10px; 
  padding: 2px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

.wallet_tab_btn {
  /* border: 1px solid gray; */
  color: gray;
  padding: 4px 16px;
  /* width: 75px; */
  border-radius: 25px;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-600);
  position: relative;
}

.wallet_tab_btn.active::before  {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px; 
  padding: 1.5px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}


.add_with {
  display: flex;
  flex-direction: row;
  /* height: 50px; */
  width: 100%;
}

.wallet_tab_btn.active {
  /* background-color: gray; */
  /* border: 1px solid gray; */
  color: white;
  position: relative;
}

.wallet_tab_btns_wrapp {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  gap: 16px;
}

.wallet_entry_line {
  width: 100%;
  height: 1px;
  background: var(--color-white);
  max-width: var(--max-width-md);
  margin: 10px auto;
}

.Account-name {
  color: var(--color-green);
  font-size: 14px;
  font-family: var(--font-400);
}

.credit_active {
  color: var(--color-red);
  font-size: 14px;
  font-family: var(--font-300);
}

.wallet-recharged {
  color: var(--color-white);
  font-size: 10px;
  font-family: var(--font-300);
}

.by-text {
  color: var(--color-white);
  font-size: 14px;
  font-family: var(--font-400);
}

.all_list2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* @media (max-width: 600px) {
  .Wallet_screen {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: black;
    height: 100vh;
    overflow: scroll;
  }

  .wallett_back {
    width: 35px;
  }

  .total_wallet_rupee {
    color: white;
    font-size: 30px;
  }

  .wl_titel {
    color: #726767;
  }

  .add_with_1 {
    height: 150px;
    background-image: linear-gradient(148deg, #7b982a, #000);
    padding-top: 30px;
    text-align: center;
    width: 48%;
    border-radius: 10px;
  }

  .wallet_tran {
    width: 25px;
    position: relative;
    top: 10px;
    margin-bottom: 15px;
  }

  .add_name {
    font-size: 15px;
  }

  .add_with_c {
    width: 4%;
  }

  .add_with_2 {
    height: 150px;
    background-image: linear-gradient(148deg, #2e3192, #000);
    text-align: center;
    width: 48%;
    padding-top: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .h1_new {
    font-size: 15px;
  }

  hr {
    border: 0.5px solid rgba(136, 136, 136, 0.486);
  }

  .wallet_entry_wrapp {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px;
  }

  .all_list1 {
    width: 20%;
  }

  .all_list2 {
    width: 80%;
  }

  .all_list3 {
    width: 20%;
  }
  .credit_p {
    color: var(--color-green) !important;
    position: relative;
  }
  .credit_active {
    color: var(--color-red) !important;
    position: relative;
    font-size: 14px;
    font-family: var(--font-400);
  }
  .debit_p {
    color: #ee1651 !important;
    position: relative;
  }

  .Account-name {
    color: var(--color-green) !important;
    font-size: 14px;
    font-family: var(--font-400);
  }
  .by-text {
    font-size: 14px;
    font-family: var(--font-400);
  }
  .wallet-recharged {
    margin-top: 2px;
    font-size: 10px;
    font-family: var(--font-400);
  }

  .modal_w {
    position: fixed;
    bottom: 0%;
    background-color: #292929;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 40%;
    display: grid !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.682);
  }

  h3 {
    font-size: 17px;
    text-align: center;
  }

  .modal_img_wallet {
    width: 50px;
    position: relative;
    bottom: 45px;
    left: 40%;
  }

  .footer_center {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .can_1 {
    display: flex;
    flex-direction: row;
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .can_1 button {
    background-color: #fff;
    width: 100px;
    color: black;
    height: 40px;
    border-radius: 25px;
    padding: 10px;
    position: relative;
  }
  .can_2 {
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .can_2 button {
    background-color: #ee1651;
    width: 100px;
    color: white;
    height: 40px;
    border-radius: 25px;
    padding: 10px;
    position: relative;
  }

  .w_input {
    background-color: white;
    color: #292929 !important;
    height: 40px;
  }

  .w_input::placeholder {
    color: #292929 !important;
  }

  .close-icon {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
} */
