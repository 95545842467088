/* 04-09-2023 */
.Productview_list {
    position: relative;
}

.Productview_list_view {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #262626;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 10px;
    width: 100%;
}

.Productview_list_img {
    width: 45px;
    height: 45px;
    border-radius: 50px;
}

.Productview_list_nm {
    font-size: 15px;
    font-family: var(--font-400);
    width: 50%;
}
.product_number_list
{
    width: 5%;
}
.product_price_list
{
    width:20%;
}