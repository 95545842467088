/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

.selectmanagers_details {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 10px;
}
.select_managers {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}
.selected_nm_managers {
  border-bottom: 1px solid white;
  width: 100%;
  padding-bottom: 9px;
}
.selected_nm_managers_green {
  border-bottom: 1px solid green;
  width: 100%;
  padding-bottom: 9px;
}

.lablewhite {
  color: white;
  font-family: var(--font-600) !important;
}
.lablegreen {
  color: green;
  font-family: var(--font-600) !important;
}
