.notification-container {
  display: flex;
  flex-direction: row;
  /* padding: 20px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}

.notification_1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notification_2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.notification_header {
  position: sticky;
  top: 0;
  flex-direction: column;
  background-color: #000;
  /* Set your desired background color */
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  /* Ensure the header is on top of notifi_details */
  /* Add any other styles for the header here */
}
.main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div_main1 {
  width: 20%;
}
.div_main2 {
  width: 80%;
  display: flex;
  justify-content: center;
}
.main_h1 {
  position: relative;
  right: 25px;
}

.general_user {
  width: 100%;
}
.user_notification_search {
  height: 40px;
  padding: 15px;
}
.user_notification_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

.notifi_details {
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 0px;
}

.notification_img {
  width: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img_notification {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid white;
}

.notification_msg {
  color: gray;
}

.notification_nm {
  width: 80%;
}

.user_notification_tabs_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px auto;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.user_notification_single_tab {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-600);
  /* border: 1px solid #fff; */
  padding: 6px 14px;
  border-radius: 28px;
  width: -webkit-fill-available;
  position: relative;
}

.user_notification_single_tab::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 28px; 
  padding: 1.5px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

@media only screen and (max-width: 850px) {
  /* .web_screen_notification {
        width: 100%;
        border: none;
        padding: 0px;
    }
    .notification_1 {
        width: 55%;
    }

    .notification_2 {
        width: 45%;
    } */
}

@media only screen and (max-width: 600px) {
  .notification_1 {
    display: none;
  }

  .notifications_user_menu {
    left: 15px;
    position: relative;
  }
  .notification_2 {
    width: 100%;
    display: block;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .notification_header {
    position: sticky;
    top: 0;
    flex-direction: column;
    background-color: #000;
    /* Set your desired background color */
    /* padding: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    /* Ensure the header is on top of notifi_details */
    /* Add any other styles for the header here */
  }
  .main_header_notifi {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .div_main1 {
    width: 20%;
  }
  .div_main2 {
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .main_h1 {
    position: relative;
    right: 25px;
  }

  .general_user {
    width: 100%;
  }
  .user_notification_search {
    height: 40px;
    padding: 15px;
  }
  .user_notification_search::placeholder {
    color: gray;
    background-image: url(../../assets/images/search.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 100%;
  }

  .notification_img {
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .img_notification {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid white;
  }

  .notification_msg {
    color: gray;
  }

  .notification_nm {
    width: 80%;
  }
}
