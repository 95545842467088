.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.cardfront_img_feed {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.crad_3_main_img_front_feed {
  background-image: url(./../../assets/images/bluecard_front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_3_main_img_front {
  background-image: url(./../../assets/images/bluecard_front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_3_main_img_back_feed {
  background-image: url(./../../assets/images/bluecard_back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_3_main_img_back {
  background-image: url(./../../assets/images/bluecard_back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_3_img_front {
  z-index: -1;
  position: relative;
  width: 87%;
  object-fit: cover;
  height: 50%;
  top: 3%;
  left: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.card_3_main_details {
  text-align: left;
  margin-top: 15%;
}

.card_h3 {
  font-family: var(--font-600) !important;
  position: relative;
  left: 11%;
  font-size: 20px;
  margin-top: 18% !important;
  margin-bottom: 0px;
}

.crad1_details_front {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 4%;
  width: 95%;
}

.crad1_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 4%;
  width: 95%;
}

.prodct_highlights {
  font-family: var(--font-600);
  line-height: 0px;
  position: relative;
  left: 15px;
}

.back_owned_nm {
  font-family: var(--font-600);
  line-height: 10px;
  position: relative;
  left: 15px;
  font-size: 16px;
}

.pro_nm_card {
  font-family: var(--font-800) !important;
  line-height: 10px;
  position: relative;
  left: 15px;
}

.card_qr {
  width: 70px;
  background: white;
  position: relative;
  right: 8%;
  bottom: 14%;
  float: right;
}

.owned_by_nm {
  font-family: var(--font-300);
  /* line-height: 35px; */
  position: relative;
  left: 15px;
  font-size: 10px;
}

.front_card_sub_details {
  width: 50%;
}

.front_card_sub_details2 {
  width: 50%;
}

.front_card_sub_details_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15%;
}

.div_crad_3 {
  width: 60%;
}

.div_card_3 {
  width: 40%;
}

.front_nm {
  font-family: var(--font-600) !important;
  margin-bottom: 8%;
}

.front_nm_product {
  font-family: var(--font-800) !important;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}

.front_nm_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  left: 0px !important;
  position: relative !important;
}

.img_front_crad_3_logo {
  width: 40px;
  position: relative;
  top: 20%;
  left: 30%;
}

.front_nm_3 {
  font-family: var(--font-800) !important;
}

.front_nm_3 {
  font-family: var(--font-600) !important;
  margin-bottom: 5%;
}

.crad_3_img_back {
  z-index: -1;
  position: relative;
  width: 87%;
  object-fit: cover;
  height: 55%;
  left: 20px;
}

/* hr */
.card_3_hr {
  top: 6px;
  border: 1.7px solid var(--color-main) !important;
  width: 80%;
  left: 9%;
  position: relative;
}

.card_3_subh1 {
  font-family: var(--font-600) !important;
  position: relative;
  left: 11%;
  font-size: 15px;
  margin-top: 5% !important;
  margin-bottom: 0px;
}

.back_del_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  font-size: 25px;
  margin-top: 5% !important;
  margin-bottom: 20px;
}

.price_3_card {
  font-family: var(--font-600) !important;
  position: relative;
  left: 11%;
  font-size: 22px;
  margin-bottom: 0px;
}

.card_3_white {
  position: relative;
  width: 35px;
  left: 5%;
  top: 0px;
  transform: rotate(180deg);
}

.card_qr_3 {
  width: 60px;
  background: white;
  position: relative;
  right: 13%;
  float: right;
  z-index: 9;
  top: 5px;
}

/* back Detsils */
.crad_3_back_del {
  width: 80%;
  left: 10%;
  position: relative;
  text-align: center;
  top: 8%;
}

.back_hr1 {
  border: 1.5px solid var(--color-main) !important;
  width: 100%;
  left: 0%;
  margin-bottom: 10px;
}

.back_hr2 {
  border: 1.5px solid transparent !important;
  /* Set initial border color as transparent */
  background: #663548;
  width: 100%;
  left: 0%;
  height: 1px;
}

/* ----- */
.ditails_of_back_cs_feed {
  position: absolute;
  bottom: 15%;
  padding: 20px;
}
