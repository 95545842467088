/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp_b {
  width: 100%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

/* page base design end */
.bidlist {
  position: fixed;
  background-color: #1f1f1f;
  bottom: 0px;
  height: auto;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 40px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}

.bidlist_img {
  width: 50px;
  position: relative;
  bottom: 25px;
}
.bid_total {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  padding: 20px;
}
.list_bid_btn {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}
.list_bid_btn1 {
  width: 40%;
  padding: 10px;
  color: white;
  background-color: var(--color-main);
  border-radius: 20px;
  font-family: var(--font-700);
}
.nm_bid_total {
  width: 50%;
}
.amnt_bid_list {
  width: 50%;
  text-align: end;
}
.nm_bis_1 {
  font: 500;
  font-family: var(--font-600);
  margin-bottom: 10px;
}
.nm_bis_2 {
  font: 500;
  font-family: var(--font-600);
  margin-bottom: 10px;
}
.total_nm_list {
  font: 500;
  font-family: var(--font-600);
}

/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp_b {
  width: 100%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

/* page base design end */

.bid_total {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  padding: 20px;
}

.list_bid_btn {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.list_bid_btn1 {
  width: 40%;
  padding: 10px;
  color: white;
  background-color: var(--color-main);
  border-radius: 20px;
  font-family: var(--font-700);
}

.nm_bid_total {
  width: 50%;
}

.amnt_bid_list {
  width: 50%;
  text-align: end;
}

.nm_bis_1 {
  font: 500;
  font-family: var(--font-600);
  margin-bottom: 10px;
}

.nm_bis_2 {
  font: 500;
  font-family: var(--font-600);
  margin-bottom: 10px;
}

.total_nm_list {
  font: 500;
  font-family: var(--font-600);
}

/* modal */
/* Add these CSS styles to your bidlist.css file or a separate modal.css file */

/* Modal container styles */

/* CSS styles for the bottom modal */

.modal_bottom {
  display: flex;
  /* Align the modal content to the right */
  align-items: flex-end;
  /* Align the modal content to the bottom */
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content1 {
  background-color: #626060d7;
  padding: 20px;
  position: relative;
  border-radius: 50px 50px 0px 0px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100vw;
  min-height: 45vh;
  -webkit-border-radius: 50px 50px 0px 0px;
  -moz-border-radius: 50px 50px 0px 0px;
  -ms-border-radius: 50px 50px 0px 0px;
  -o-border-radius: 50px 50px 0px 0px;
  max-width: var(--max-width-sm);
  margin: 0 auto;
}

/* Close button styles */
.modal-content button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* Style the "Place bid" button separately if needed */

/* When the modal is open, make it visible */
.modal.active {
  display: flex;
}

.bidlist_img2 {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -7%;
  left: 44%;
}
.list_bid_btn3 {
  width: 40%;
  padding: 10px;
  color: black;
  background-color: white;
  border-radius: 20px;
  font-family: var(--font-700);
}
.list_bid_btn2 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 15%;
  justify-content: center;
  align-items: center;
}
.bidslist_new_nm {
  font-family: var(--font-700);
  padding-top: 25px;
}
