.Purchasesecond_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 20px;
}

.Purchasesecond_btn_set1 {
  width: 75%;
}

.Purchasesecond_btn_set2 {
  width: 25%;
}

.par_del_set {
  width: 50%;
  padding: 15px;
}

.par_del_set2 {
  width: 50%;
  position: relative;
}

.par_del_set_sub {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 15px;
}
.set_pur_del_1 {
  position: relative;
  left: 15px;
  top: 10px;
}

/* ----------- */

.card_front_name {
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.card_front_creator_text {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.card_front_product_text {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.card_front_username {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.card_back_username {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  margin-left: 5%;
}

.card_front_product_text {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
  text-align: right;
}

.card_front_product_value {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  text-align: right;
}

.card_back_ps_text {
  font-size: 16px;
  font-family: var(--font-700);
  color: var(--color-white);
  margin-left: 5%;
}

.card_back_product_name {
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
  margin-left: 5%;
}

.card_back_owned_by_text {
  font-size: 12px;
  font-family: var(--font-300);
  color: var(--color-white);
  margin-left: 5%;
  margin-bottom: 4px;
  margin-top: 6px;
}

.card_down_btn {
  background-color: var(--color-main);
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  padding: 6px 10px;
  border-radius: 12px;
}
