/* page base design start */
.page_main_wrapp_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrappbox {
  width: 100%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.bondbox_main {
  position: absolute;
  background-color: #262626;
  border-radius: 25px 25px 0px 0px;
  bottom: 0px;
  padding-bottom: 2%;
  width: 100%;
  max-width: var(--max-width-sm);
}

.images_bondbox {
  width: 50px;
  position: relative;
  bottom: 20px;
}

.bondbox_checkbox {
  display: flex;
  width: 50%;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* gap: 15px; */
  gap: 6px;
}

/* Default styling for checkbox */
.check_box_new {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  margin-top: 23px;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
}

/* Style the checkbox when it's checked */
.check_box_new:checked::before {
  content: "✔";
  /* Display a checkmark character or any other symbol you like */
  display: block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  /* Set the background color when checked */
  color: white;
  /* Set the text color when checked */
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
  position: relative;
  bottom: 10px;
  right: 10px;
}

.details_bondbox {
  padding: 10px;
  font-size: 12px;
  font-family: var(--font-400);
}

.btn_bondbox {
  margin-top: 5%;
  align-self: center;
  padding: 8px 16px;
  background: var(--color-main);
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin: 10px auto;
}
.bond_hr {
  width: 90%;
  height: 2px;
  background: var(--color-gray);
  margin: 10px auto;
}
@media (max-width: 600px) {
  .bondbox_main {
    padding-bottom: 4%;
  }
}
