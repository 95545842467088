/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  align-self: center;
  right: 15px;
  position: relative;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

/* 29-08-2023 */
.cre_titel {
  color: var(--color-gray);
  font-size: 12px;
  font-family: var(--font-600);
  margin-top: 10px;
}

.bondisseu {
  width: 100%;
  border-radius: 25px;
  margin-top: 10px;
}

.nm_bondise {
  color: var(--color-main);
  font-size: 16px;
  font-family: var(--font-700);
  margin: 10px auto;
}

.bondise_price {
  font-family: var(--font-600);
  font-size: 16px;
  line-height: normal;
}

.bondissue_details {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bondissue_details1 {
  width: 65%;
}
.bondissue_arrow {
  font-size: 35px;
  color: gray;
  font-family: var(--font-300);
  position: relative;
  padding-top: 0px;
  margin-bottom: 8px;
}
.bondissue_details2 {
  width: 35%;
  text-align: end;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.input_bondissue::placeholder {
  color: gray;
  font-size: 25px;
  font-family: var(--font-400);
}
.input_bondissue {
  width: 60px;
  font-size: 25px;
  height: 50px;
  text-align: center;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-400);
}

.bondissue_date {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}
.bondissue_date1 {
  width: 50%;
}
.bondissue_date2 {
  width: 50%;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bondissue_minimum {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.bondissue_minimum1 {
  width: 50%;
}
.bondissue_minimum2 {
  width: 50%;
  text-align: end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.minimum_bin_input {
  color: white;
  height: 40px;
  margin-left: 10px;
  width: 100px;
}
.date_part {
  width: 50px;
}
.bond_issue_btn {
  display: flex;
  justify-content: center;
}
.bond_issue_btn_sub {
  background-color: var(--color-main);
  font-family: var(--font-700);
  font-size: 15px;
  color: white;
  width: 60%;
  text-align: center;
  padding: 9px;
  border-radius: 25px;
}

.Collaboratenfb_img {
  width: 100%;
  height: 300px;
  border-radius: 30px;
}

.react-datepicker__close-icon {
  height: 75%;
}
