.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -ms-transition: transform 0.6s;
  -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_four_img_front {
  background-image: url(./../../assets/images/newBlueCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_four_img_back {
  background-image: url(./../../assets/images/newPinkCardBack.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card4_show_img_front {
  z-index: -1;
  position: relative;
  width: 95%;
  object-fit: cover;
  height: 97%;
  top: 2%;
  left: 8px;
}

.card4_show_img_back {
  z-index: -1;
  position: relative;
  width: 99%;
  object-fit: cover;
  height: 95%;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
}

/* 29-08-2023 */

.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}
.nm_of_user {
  font-size: 15px;
}
.nm_of_cre_titel {
  font-size: 10px;
}
.nm_of_cre_nmr {
  font-size: 15px;
}

.sc_f4_details2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: end;
  position: relative;
  right: 12%;
}
.sc_img_f4 {
  width: 35px;
  height: 35px;
  float: right;
}
.sc_p_nm {
  font-size: 12px;
}
.sc_price {
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}

/* back  */

.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}

.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card {
  font-size: 12px;
}

/* 31-08-2023 */
.show_second_img {
  width: 20px;
  float: right;
}
.btn_showcardsecond {
  margin-top: 20px;
  width: 95%;
}
.show_second_btn {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-main);
  /* width: -moz-fit-content; */
  /* width: 60px; */
  border-radius: 28px;
  padding: 3px 8px;
  text-align: center;
  margin-left: 4px;
  font-family: var(--font-600);
}
/* new 06-11-2023 */
.sc_f4_details2_back {
  width: 100%;
  text-align: center;
  bottom: 5%;
  position: relative;
}
.sc_img_f4_back {
  width: 50px;
  height: 50px;
}

.nm_of_user_div4 {
  position: relative;
  bottom: 0%;
  text-align: center;
}
.sc_f4_details {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: -2%;
  position: absolute;
}
.sc_b4_details {
  position: relative;
  width: 100%;
  text-align: center;
}
.sc_f4_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 8%;
  position: relative;
}
.card4_show_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 76%;
  top: 1%;
  left: 0px;
}
.ditails_of_back_cs_4_nfb {
  position: absolute;
  bottom: 12%;
  padding: 20px;
}
