.new_auther_video_text {
  font-family: var(--font-400);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
  margin: 1rem auto;
}

.new_auther_video_wrapp {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem auto;
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.43) 100%
  );
  border-radius: 10px;
  overflow: hidden;
  padding: 1px;
}

.new_auther_video_upload_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  /* border: 2px dashed #ccc; */
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background: var(--color-black);
  margin: 0px;
  gap: 10px;
}

.new_auther_video_upload_button > img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.new_auther_video_upload_button > p {
  font-family: var(--font-600);
  font-size: 14px;
  font-weight: 350;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #9c9c9c;
}

/* progress bar style start */

.upload-progress {
  margin-top: 10px;
}

.progress-wrapper {
  position: relative;
  width: 100%;
  margin-top: 1rem;
}

.progress-bar-container {
  position: relative;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Adjust the color as needed */
  font-weight: 400;
  font-size: 12px;
  font-family: var(--font-600);
  width: 100%;
}

/* Style the progress bar */
.progress-bar-container progress {
  width: 100%;
  height: 30px;
  appearance: none;
  border: none;
}

/* Style the fill color of the progress bar */
.progress-bar-container progress::-webkit-progress-value {
  background-color: var(
    --color-main
  ); /* Change this color to your desired fill color */
}

/* progress bar style end */
