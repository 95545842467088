.myproduct_13_page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
}
.myproduct_13_page_base_wrapp_2 {
  width: 90%;
  max-width: var(--max-width-sm);
  height: 100%;
  min-height: 96vh;
  margin: 10px auto;
  margin-bottom: 105px;
}
.myproduct_13_title_section {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  margin-top: 20px;
}
.myproduct_13_back_btn {
  height: 16px;
  width: 8px;
  position: absolute;
  top: 7px;
  left: 0px;
}
.myproduct_13_nav_title {
  font-size: 22px;
  font-family: var(--font-600);
  margin-top: 46px;
}
.underline_asset {
  width: 115px;
  height: 1px;
  background: linear-gradient(90deg, #ff004c 0.43%, #caacff 98.7%);
  margin: auto;
  margin-top: 7px;
}
/* .myproduct_13_product-cnt {
    border: 1px solid rgb(173, 3, 3);
    border-radius: 10px;
    padding: 14px 14px;
    margin: 2rem auto;
    height: 208px;
    width: 94%;
} */

.myproduct_13_product-cnt_wrapp {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  background: linear-gradient(
    100.74deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.21) 98.82%
  );
  padding: 1px;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myproduct_13_product-cnt {
  padding: 14px;
  margin: 2rem auto;
  /* min-height: 208px; */
  /* text-align: center; */
  width: 100%;
  height: 198px;
  background: black;
  border-radius: 6px;
}
.myproduct_13_product-cnt p {
  margin-bottom: 2rem;
  font-size: 1rem;
  font-family: var(--font-300);
  color: #969696;
  line-height: 150%;
}
.myproduct_13_create_product_btn {
  background: #fff;
  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );
  /* padding: 6px 20px; */
  width: 140px;
  height: 38px;
  border-radius: 18px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
  font-family: var(--font-400);
  color: white;
  margin-bottom: 13px;
}
.myproduct_13_user_bid_main {
  padding: 0px;
  width: 100%;
  padding-top: 2px;
  margin: auto;
  position: relative;
  margin-bottom: 19px;
}
.myproduct_13_user_bid_search {
  height: 45px;
  padding: 15px;
  border-radius: 33px;
  box-shadow: inset #ff004c5c 7px 4px 20px 0px,
    inset #caacff21 -8px -4px 23px 0px;
  padding-left: 26px;
  border: none;

  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 14px;
}
.myproduct_13_user_bid_search::placeholder {
  color: #ffffff80;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
  font-family: var(--font-400);
  font-size: 14px;
}
.myproduct_13_search_ic {
  position: absolute;
  /* left: 20%; */
  right: 16px;
  bottom: 30px;
  width: 15px;
  height: 15px;
}
@media (max-width: 400px) {
  .myproduct_13_search_ic {
    position: absolute;
    /* left: 20%; */
    right: 4.37%;
    bottom: 29px;
  }
}
.myproduct_13_button-container {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-md);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.myproduct_13_filter-title-text {
  /* font-family: var(--font-300) !important;4 */
  font-family: var(--font-300);
  font-weight: 300;
  font-size: 12px;
  color: var(--color-white);
  text-align: center;
}
.myproduct_13_my-p {
  color: #ffffff;
  font-family: var(--font-300);
  font-size: 12px;
  font-weight: 500;
  background: #342025;
  height: 35px;
  border-radius: 17.5px;
  /* padding: 9px 26px; */
  border: none;
  min-width: 33%;
}
@media (max-width: 478px) {
  .myproduct_13_my-p {
    color: #ffffff;
    font-family: var(--font-300);
    font-size: 12px;
    font-weight: 500;
    background: #342025;
    height: 35px;
    border-radius: 17.5px;
    /* padding: 9px 25px; */
    border: none;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .myproduct_13_my-p {
    color: #ffffff;
    font-family: var(--font-300);
    font-size: 12px;
    font-weight: 500;
    background: #342025;
    height: 35px;
    border-radius: 17.5px;
    /* padding: 9px 14px; */
    border: none;
  }
}
.myproduct_13_my-p.custom-border {
  position: relative;
  background-color: transparent;
}
.myproduct_13_my-p.custom-border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 1.5px;
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}

/* card */
.myproduct_13_club_user_details {
  /* max-width: 350px; */
  width: 97%;
  /* width: 376px; */
  height: 340px;
  margin-bottom: 4px;
  background: #201d23;

  border-radius: 10px;
  /* border: 1px solid var(--color-main); */
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.myproduct_13_img_details {
  /* width: 345px; */
  width: 92%;
  height: 182px;
  padding: -7px;
  margin-left: 15px;
  margin-top: 14px;
  border-radius: 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.myproduct_13_club_user_details {
  /* max-width: 350px; */
  width: 97%;
  /* width: 376px; */
  /* height: 340px; */
  height: auto;
  margin-bottom: 4px;
  background: #201d23;

  border-radius: 10px;
  /* border: 1px solid var(--color-main); */
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.myproduct_13_club_user_details_2 {
  /* max-width: 350px; */
  width: 97%;
  /* width: 376px; */
  height: 380px;
  margin-bottom: 4px;
  background: #201d23;

  border-radius: 10px;
  /* border: 1px solid var(--color-main); */
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.myproduct_13_img_details {
  /* width: 345px; */
  width: 92%;
  height: 182px;
  padding: -7px;
  margin-left: 15px;
  margin-top: 14px;
  border-radius: 10px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.myproduct_13_card-content {
  padding: 13px;
  /* padding-left: 22px; */
  color: white;
  /* position: absolute; */
  bottom: 50px;
  left: 0px;
  /* background: var(--number-step); */
  -webkit-backdrop-filter: blur(10px);
  /* backdrop-filter: blur(10px); */
  width: 100%;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.myproduct_13_card-title-text {
  font-size: 18px;
  font-family: var(--font-500) !important;
}
.myproduct_13_applied-title {
  font-size: 14px;
  color: #f4f4f4 !important;
  margin: 6px 0px;
}
.myproduct_13_btn_new {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px 0px;
  justify-content: start;
}
.myproduct_13_sub2_button_a {
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  padding: 1px;
  border-radius: 28px;
  min-width: 25%;
  overflow: hidden;
  min-height: 35px;
  width: 30%;
}

.myproduct_13_sub2_button_a > div {
  padding: 6px 12px;
  background: #201d23;
  width: 99%;
  height: 99%;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.myproduct_13_sub2_button_a_hover {
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  padding: 1px;
  border-radius: 28px;
  min-width: 25%;
  overflow: hidden;
  min-height: 35px;
  width: 34%;
}

.myproduct_13_sub2_button_a_hover > div {
  background: linear-gradient(
    180deg,
    #ff004c 0%,
    rgba(202, 172, 255, 0.69) 100%
  );
  padding: 6px 12px;
  width: 99%;
  height: 99%;
  color: var(--color-white);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 28px;
  margin: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.myproduct_13_card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 28px;
  margin-left: 1.5%;
  width: 100%;
}
.myproduct_13_card-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
  gap: 12px;
  flex-wrap: wrap;
  /* background-color: red; */
}
