/* page base design start */

.page_editmobileotp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.editmobileotp_nav {
  display: flex;
  padding: 20px;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */
.mobile_new_details2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.mobile_otp_new {
  color: gray;
  width: 75%;
  text-align: center;
  margin-bottom: 5%;
}

.mobile_submit_new {
  background-color: transparent !important;
  color: white;
  font: 300;
  font-size: 20px;
  border: 1px solid;
  border-radius: 25px;
  width: 50%;
  padding: 10px;
}

.mobile_int_otp2 {
  background-color: gray;
  color: white;
  width: 25%;
  height: 50px;
  border-radius: 5px;
  font-size: 25px;
  border: none;
  text-align: center;
}
.otp_input_container {
  display: flex;
  flex-direction: row;
  gap: 10%;
  margin-top: 20%;
}
