.v2modal_main_wrapp {
  background: #00000096;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 1rem 5%;
  z-index: 111;
}

.v2modal_inner_wrapp {
  width: 100vw;
  max-height: 70vh;
  min-height: 300px;
  max-width: var(--max-width-sm);
  background: #1f1f1f;
  border-radius: 30px;
  position: relative;
  padding: 3.4rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v2modal_fixed_logo_wrapp {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff1a;
  box-shadow: 0px 7px 17px 0px #00000063;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: none;
}

.v2modal_fixed_logo_wrapp > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.v2modal_close_img {
  position: absolute;
  top: 2%;
  right: 2%;
  transform: translate(-50%, 50%);
  width: 18px;
  height: 18px;
}

.v2modal_close_img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.v2modal_fixed_heading {
  font-size: 18px;
  font-family: var(--font-600);
  color: var(--color-white);
  line-height: 150%;
  width: 90%;
  margin: 10px auto;
  text-align: center;
}

.v2modal_fixed_text_wrapp {
  width: 100%;
  height: 76%;
  margin-top: 10px;
  margin-bottom: 1rem;
  /* border: 1px solid red; */
  text-align: center;
  overflow-x: scroll;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
}

.v2modal_fixed_text_wrapp > p {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
  line-height: 150%;
}

.v2modal_fixed_btns_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4%;
}

.v2modal_btn1 {
  width: 100%;
  height: 50px;
  background: transparent;
  padding: 8px;
  font-size: 14px;
  color: var(--color-white);
  max-width: 150px;
  border-radius: 50px;
  font-family: var(--font-600);
}

.v2modal_btn2 {
  width: 100%;
  height: 50px;
  /* background: transparent; */
  padding: 8px;
  font-size: 14px;
  color: var(--color-white);
  max-width: 150px;
  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );
  font-family: var(--font-600);

  border-radius: 50px;
}

.v2modal_btn2:active {
  /* scale: 0.9; */
  box-shadow: 0 0 0 3px rgba(255, 0, 76, 0.2), 0 1px 12px 0 #ff004cc2 inset,
    0 4px 14px 0 #9a92d24d inset, 0 39px 99px -49px #caacff73 inset;
}
