.mymemberships_main {
  display: flex;
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.mymemberships_2 {
  width: 920px;
  margin: 10px auto;
  max-width: var(--max-width-sm);
}

.mymembershipss_user_menu {
  left: 15px;
  position: relative;
}

.mymemberships_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.mymemberships_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.mymembershipsmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mymemberships_main_h1 {
  position: relative;
  right: 25px;
}

.mymemberships_h1 {
  font-family: var(--font-700);
  position: relative;
  right: 10%;
}

.nodatafound {
  font-family: var(--font-600);
  text-align: center;
}

.mymembershipsmain1 {
  width: 20%;
}

.mymemberships_club_user {
  width: 100%;
  /* padding: 25px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.mymemberships_details {
  width: 100%;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px auto;
  background: #ffffff0f;
}

.mymemberships_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.mymemberships_details > div > p {
  width: 70%;
  font-size: 14px;
  color: var(--color-white);
  font-family: var(--font-600);
  font-weight: 350;
}

.mymemberships_details_img1 {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.mymemberships_details_img2 {
  width: 55px;
  border-radius: 55px;
  height: 55px;
  object-fit: cover;
  transform: translateY(-25px);
}

.benefits_details {
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  max-width: 350px;
  background-color: white;
}

.benefits_off {
  color: #000;
  font-family: var(--font-700);
}

.benefits_off_p {
  color: #000;
  font-size: 13px;
  font-family: var(--font-800);
  left: 0px !important;
}
.action_benefits {
  background-color: #50d285;
  color: #000;
  border-radius: 10px;
  text-align: center;
  float: right;
  margin-top: 10px;
  padding: 10px;
  width: 30%;
}
@media only screen and (max-width: 920px) {
  .mymemberships_club_user {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .mymemberships_club_user {
    justify-content: center;
  }
}
