/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.addhar_headind {
  font-size: 16px;
  color: #fff;
  font-family: var(--font-700);
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

.aadharcard_div {
  width: 100%;
  display: flex;
  position: relative;
}

.aadharcard_img {
  width: 100%;
}
.aadharcard_input::placeholder {
  color: gray;
}
.aadharcard_input {
  position: absolute;
  bottom: 23%;
  height: 35px;
  right: 10%;
  border: 0px solid #80808000;
  color: gray !important;
  background-color: transparent;
  border-radius: 3px;
  width: 61%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.submit_aadhaar_btn {
  width: 100%;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  font-family: var(--font-600);
  margin-top: 15%;
  border: 1px solid white;
  padding: 12px;
  font-size: 16px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

.addhar_capcha_main_wrapp {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin: 10px auto;
  width: 92%;
  height: 100px;
  /* margin-top: 30px; */
}

.addhar_no_main_wrapp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  margin: 10px auto;
  /* width: 100%; */
  width: 93%;
  /* height: 100px; */
  /* margin-top: 30px; */
}

.aadhar_capcha_img {
  width: 62%;
  height: 66px;
  object-fit: contain;
  margin-bottom: 3px;
}

.aadhaar_capcha_wrapp {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 4px;
}

.aadhaar_smt_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 44px;
  width: 93%;
  margin-top: 88px;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
}

@media (max-width: 420px) {
  .aadharcard_input {
    bottom: 17%;
  }
  .aadhar_capcha_img {
    width: 62%;
    height: 66px;
    object-fit: contain;
    margin-bottom: -1px;
  }
}
