/* Info Icon */

.cn_info {
  color: red;
  /* border: 1px solid red; */
  border-radius: 100%;
  width: 10px;
  height: 10px;
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.6px;
}

/* File Upload  */

.custom_file_upload {
  display: inline-block;
  background-image: url("../../../assets/images/gallery_icon.png");
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: center;
  color: #555;
  border: 1px dashed rgb(145, 145, 145);
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  margin: 5px;
  width: 25px;
  height: 35px;
}

.custom_file_upload input[type="file"] {
  display: none;
}

.custom_file_upload span {
  margin-right: 8px;
}

.fu_img_icon {
  width: 15px;
  padding: 5px;
  padding-bottom: 1px;
}

/* save and protect */

.save_protect {
  margin: 15px;
  text-align: center;
}

.btn_save {
  padding: 8px 35px;
  background-color: #f75498;
  font-size: medium;
  color: white;
  border: 1px solid #f75498;
  border-radius: 20px;
}
