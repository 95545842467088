/* followers start */
.cp_follower_wrapp {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  margin-top: -30px;
  min-width: 250px;
  min-height: 50px;
}

.cp_follower_vr {
  background: var(--color-white);
  height: 40px;
  width: 1px;
}

.cp_clubowner_name {
  font-size: 12px;
  color: var(--color-white);
  opacity: 0.6;
  font-family: var(--font-300);
  align-self: flex-start;
  width: 90%;
  margin: 10px auto;
  text-align: justify;
}

/* followers end */

/* follow btn start */

.cp_follow_btn {
  width: 90%;
  padding: 10px;
  /* background: var(--color-main); */
  background: linear-gradient(
    97.08deg,
    #d2003f 10.33%,
    rgba(202, 172, 255, 0.21) 95.72%
  );

  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 12px;
  border-radius: 28px;
  margin: 4px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
/* follow btn end */

/* founder start */

.founder_heading {
  width: 90%;
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  align-self: flex-start;
  margin: 10px auto;
}

.cp_founder_wrapp {
  width: 95%;
  margin: 10px auto;
  /* border: 1px solid red; */
  /* padding: 10px; */
}

.cp_founder_single_card {
  width: 100%;
  padding: 10px;
  background: var(--color-dark-gray);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.cp_founder_single_card > img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  object-fit: contain;
  border: 2px solid var(--color-white);
}

.cp_founder_single_card > p {
  font-size: 12px;
  font-family: var(--font-400);
  color: var(--color-white);
}
/* founder end */

/* all nfbs start */
.all_nfbs_main_wrapp {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
  margin-top: 20px;
}

.all_nfbs_main_wrapp > p {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.all_nfbs_main_wrapp > a {
  font-size: 11px;
  font-family: var(--font-300);
  color: var(--color-white);
  opacity: 0.8;
}
/* all nfbs end */

/* cp four box start */

.cp_fourbox_wrapp {
  width: 90%;
  height: 160px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cp_fourbox_sec_1 {
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cp_fourbox_sec_2 {
  width: 49%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.cp_fourbox_singlebox_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 48%;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
}

.cp_fourbox_singlebox_2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 48%;
  height: 100%;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
}

.cp_fourbox_singlebox_1 > p,
.cp_fourbox_singlebox_2 > p {
  font-size: 14px;
  font-family: var(--font-700);
  color: var(--color-white);
}

.cp_fourbox_singlebox_1 > img,
.cp_fourbox_singlebox_2 > img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

/* cp four box end */

/* cp gallery start */

.cp_gallery_img {
  min-width: 120px;
  height: 120px;
}

.cp_gallery_single_img {
  min-width: 80%;
  max-width: var(--max-width-md);
  max-height: 160px;
}

.cp_social_wrapp {
  background: var(--color-dark-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: var(--max-width-md);
  margin: 10px auto;
  padding: 14px;
  border-radius: 10px;
}

.cp_social_img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
/* cp gallery end */

.cp_history_des {
  width: 90%;
  margin: 10px auto;
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-300);
  text-align: justify;
}

.club_receivednfb_wrapp {
  width: 90%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 8px;
  /* border: 1px solid var(--color-main); */
  margin: 10px auto;
  position: relative;
}

.club_receivednfb_wrapp::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px; 
  padding: 1px; 
  background:linear-gradient(100.74deg, #FF004C 0%, rgba(202, 172, 255, 0.21) 98.82%);
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
          /* border: 1px solid; */
}

@media (min-width: 620px) {
  .cp_gallery_single_img {
    min-width: 300px;
  }
}
