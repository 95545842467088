.card_13_main_img_front {
  background-image: url(./../../assets/images/card7_hr.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  /* position: relative; */
}

.card13_show_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  /* object-fit: cover; */
  height: 62%;
  top: 83px;
  left: 0px;
  object-fit: contain;
}

.sc_f_details13 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: -20%;
  position: relative;
}
