.createclub_main {
  display: flex;
  /* flex-direction: row; */
  padding: 20px;
  background-color: black;
  height: 100vh;
  width: 100%;
}

.createclub_2 {
  width: 100%;
  /* margin: 10px auto; */
}

.createclubs_user_menu {
  left: 15px;
  position: relative;
}

.createclub_header {
  top: 0;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.createclub_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.createclubmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.createclub_main_h1 {
  position: relative;
  right: 25px;
}

.createclub_club_user {
  margin-top: 20px;
}

.createclub_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  right: 10%;
}

.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

.createclubmain1 {
  width: 20%;
}

.createclub__user {
  width: 100%;
  padding: 20px;
}

.user_createclubnfb_search {
  height: 40px;
  padding: 15px;
}

.user_createclubnfb_search::placeholder {
  color: gray;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 100%;
}

/* 18-08-2023 */
.user_club_join {
  display: flex;
  /* margin-bottom: 15px; */
}

.character-count {
  text-align: end;
  position: relative;
  bottom: 10px;
}

textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 100%;
  color: white;
  background-color: black;
}

.createclub_club_user input {
  margin-bottom: 25px;
  font-family: var(--font-600);
  margin-top: 5px;
}

.createclub_club_user textarea {
  font-family: var(--font-600);
  margin-top: 5px;
}

.media_all_link input {
  margin-bottom: 10px;
}

.media_all_link label {
  margin-bottom: 10px;
  padding-top: 10px;
}

.media_all_link {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.createclub_club_user input[type="checkbox"] {
  background-color: #000;
  position: relative;
  top: 5px;
  height: 15px;
  cursor: pointer;
}

.createclub_club_user input::placeholder {
  color: gray;
}

option {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  color: white;
  background-color: black;
}

.profile_div {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.logo_div {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cover_div {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 15px;
  margin-bottom: 15px;
}

.multi_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}

.club_imges_details {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.new30 {
  margin-bottom: 15px;
}
.upload30new {
  margin-bottom: 15px;
}
.note_paid_club {
  color: var(--color-main);
  font-family: var(--font-700);
  font-size: 14px;
  left: 0px !important;
  word-break: break-word;
  margin-bottom: 20px;
}

/* 18-08-2023 */
/* 19-08-2023 */
.sub_btn_club {
  background-color: var(--color-main);
  color: white;
  font-family: var(--font-600) !important;
  width: 100%;
  font-size: 16px;
  align-self: center;
  border-radius: 28px;
  margin: 18px auto;
  padding: 8px 22px;
}

.div_club1 {
  width: 40%;
  padding: 8px 0px 0px 2px;
}

.div_club2 {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.div_club2 label {
  cursor: pointer;
  margin-top: 8px;
  font-family: var(--font-300);
  font-size: 14px;
}

.user_can {
  font-size: 16px;
  font-family: var(--font-600);
}

.logo_up {
  text-align: center;
}

.preview_container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.preview {
  max-width: 100%;
  max-height: 100%;
  margin: 0 10px;
  z-index: 1;
}

/* profile_up */
/* Add your other styles here */

.file_input {
  display: none;
}

.file_label {
  cursor: pointer;
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 11px;
  text-align: center;
  position: absolute !important;
}

.profile_up,
.logo_up,
.cover_up {
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 100%;
}

.multi_up {
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 50%;
  margin-top: 15px;
}

.multi_up_2 {
  /* border: 1px dashed white; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image_preview {
  position: relative;
  width: 50%;
  height: 100px;
  border: 1px solid;
}

.preview_multi {
  width: 100%;
  height: 49px;
}

.remove_button {
  background-color: red;
  color: white;
  font-family: var(--font-700);
  border-radius: 50px;
  width: 25px;
  position: absolute;
  right: 0px;
  top: 5px;
}

#profileInput,
#logoInput {
  /* width: 10px; */
}

/* 19-08-2023 */

/* YourComponent.css */

#freeCheckbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

#paidCheckbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

#freeCheckbox {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 35px;
  height: 18px !important;
  margin-right: 15px;
  padding: 5px;
  border-radius: 0px;
  outline: none;
  cursor: pointer;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

#paidCheckbox {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 35px;
  height: 18px !important;
  margin-right: 15px;
  padding: 5px;
  border-radius: 0px;
  outline: none;
  cursor: pointer;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

#freeCheckbox::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 2px;
  left: 7px;
  opacity: 0;
  transition: opacity 0.2s;
}

#paidCheckbox::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 2px;
  left: 7px;
  opacity: 0;
  transition: opacity 0.2s;
}

#freeCheckbox:checked::before {
  opacity: 1;
}

#paidCheckbox:checked::before {
  opacity: 1;
}

.ownerBox {
  width: 100%;
  padding: 10px;
  color: var(--color-gray);
  font-family: var(--font-600);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--color-gray);
  border-radius: 28px;
  gap: 4px;
  margin: 10px auto;
}

@media only screen and (max-width: 920px) {
  .createclub_club_user {
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .createclub_club_user {
    justify-content: center;
  }

  .createclub_club_user input[type="checkbox"] {
    background-color: #000;
    height: 12px;
  }
}
