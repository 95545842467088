/* .new_cirtificate_slider {
  width: 95%;
  height: 400px;
  margin: 1rem auto;
} */

.new_certificate_slider {
  position: relative;
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content {
  width: 250px;
  height: 250px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-caption {
  position: absolute;
  bottom: -25px;
  text-align: center;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
}

.slider-caption > p {
  font-family: var(--font-600);
  font-weight: 500;
  color: white;
  font-size: 1rem;
}

.new_asset_creator_btns_wrapp {
  margin: 3rem auto;
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  width: 100%;
}

.new_asset_creator_user_wrapp {
  width: 70%;
  /* height: ; */

  display: flex;
  align-items: center;
  gap: 6px;
}

.new_asset_creator_user_wrapp > div {
  width: 40px !important;
  height: 40px !important;
  border-radius: 40px !important;
}

@media (max-width: 500px) {
  .slide-content {
    margin-left: -10px;
  }
}
