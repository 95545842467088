.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_6_main_img_front {
  background-image: url(./../../assets/images/card10front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_6_main_img_back {
  background-image: url(./../../assets/images/card10back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.crad_6_img_front {
  z-index: -1;
  position: relative;
  width: 80%;
  object-fit: cover;
  height: 67%;
  top: 4%;
  left: 28px;
}

.crad_6_img_back {
  z-index: -1;
  position: relative;
  width: 80%;
  object-fit: cover;
  height: 75%;
  left: 28px;
  top: 21px;
  
}

/* 29-08-2023 */

.sc_f_details_6 {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 15%;
  position: absolute;
  align-items: baseline;
  justify-content: center;
  margin: 0 auto;
  line-height: 14px;
}

.nm_of_user_6
{
  font-size: 13px;
  text-align: center;
  position: relative;
  left: 0;
  font-family: var(--font-600);
}
.crad_6_qr_front{
  width: 42px;
  height: 42px;
  position: relative;
  top: -3px;
  left: 1px;
}
.sc_f_details1_6 {
  width: 100%;
  position: relative;
  text-align: left;
  padding: 0 20px;
}
.sc_b_details1_6 {
  width: 50%;
  position: relative;
  text-align: start;
}
.nm_of_cre_nmr
{
  font-size: 15px;
}


.sc_f_details2_6
{
  width: 33%;
  text-align: end;
  position: absolute;
  right: 43%;
  bottom: 3px;
}
.sc_img_f6
{
  width: 40px;
  height: 40px;
  float: left;
  transform: scaleX(-1);
}
.sc_p_nm
{
  font-size: 12px;
}
.sc_price
{
  font-size: 15px;
  font-weight: 800;
}
.sc_f_details3
{
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}


/* back  */
.details_of_back_cs_6 {
  position: absolute;
  bottom: 31%;
  padding: 0px 40px;
}
.sc_f_details_back_6
{
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 0%;
  position: absolute;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  height: 67px;
}
.sc_f_details1_back
{
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}
.sc_b_details_6 {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  padding: 0 27px;
  margin: 0 auto;
}

.nm_of_cre_titel_back
{
  font-size: 10px;
}
.nm_of_cre_nmr_back
{
  font-size: 15px;
}
.sc_f_details2_back
{
  width: 40%;
  text-align: center;
}
.sc_img_f_back
{
  width: 75px;
  height: 75px;
  background-color: white;
}
.ditails_of_back_cs
{
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card
{
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card_6 {
  font-size: 9px;
}
.nm_of_cre_titel {
  font-size: 12px;
}
.sc_img_f {
  width: 30px;
  height: 30px;
  float: right;
}
.sc_b_details2_6 {
  width: 45%;
  text-align: end;
  position: relative;
  right: 7%;
}
.sc_f_details3_6 {
  width: 5%;
  position: absolute;
  bottom: 14px;
  left: 2px;
}
.sc_b_details3_6 {
  width: 5%;
  position: absolute;
  bottom: 27px;
  right: 0px;
}
.sc_img_f6_b {
  width: 40px;
  height: 40px;
  float: right;

}