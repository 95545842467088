.Topcreators_main_home {
  border: 1px solid white;
  width: 100%;
  height: 65%;
}

.react-multi-carousel-dot--active button {
  background: #da0e41 !important;
}
.react-multi-carousel-dot button {
  background: #080808;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
}

.secondcollection-img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.Topcreators_main_home_details {
  position: relative;
  bottom: 25%;
  backdrop-filter: blur(10px);
  text-align: center;
  height: 30px;
  padding-top: 8px;
}
.react-multi-carousel-item {
  width: 100vw;
  height: 25vh;
}
.react-multi-carousel-track {
  gap: 20px !important;
  display: flex;
}
.top_cre_h6 {
  font-family: var(--font-600) !important;
  font-size: 12px;
  position: relative;
}

.top-create-card {
  width: 100vw;
  position: relative;
  padding: 20px;
  height: 27vh;
}
.Topcreators_main_home {
  position: relative;
  right: 10px;
}

@media only screen and (max-width: 850px) {
}

@media only screen and (max-width: 620px) {
  .secondcollection-img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
  .secondcreator-para {
    text-align: start;
    left: 5px !important;
    position: relative !important;
    font-family: var(--font-800) !important;
    font-size: 10px;
  }
}
