.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -ms-transition: transform 0.6s;
  -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

/* 25-09-2023 */
.showcard_nm_set {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 5px;
}

.show3_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardsix {
  font-size: 15px;
  font-family: var(--font-700);
  font-weight: 900;
  letter-spacing: 10px;
  padding-top: 5px;
}

.subcard_del_3 {
  display: flex;
  padding-top: 10px;
  width: 80%;
}

.subcard_del_3_sub1 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.subcard_del_3_sub2 {
  width: 50%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-3-date {
  font-weight: var(--font-700) !important;
  letter-spacing: 8px;
  font-size: 12px;
  font-weight: bold !important;
}

.s-card-3-price {
  font-weight: var(--font-700) !important;
  font-size: 20px;
  font-weight: bold !important;
}

.s-d-3card_qr {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* 25-09-2023 */
.showcard_six_new {
  background-image: url(./../../assets/images/card10front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.showcardsix_first_img {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  top: 0%;
  left: 0;
}

.crad__show6_main_img_back {
  background-image: url(./../../assets/images/card10back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card6_show_img_front {
  z-index: -1;
  position: relative;
  width: 80%;
  object-fit: cover;
  height: 68%;
  left: 28px;
  top: 20px;
}

.card6_show_img_back {
  z-index: -1;
  position: relative;
  width: 80%;
  object-fit: cover;
  height: 74%;
  left: 28px;
  top: 23px;
}

/* 29-08-2023 */

.sc_f_details {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}

.sc_f_details1 {
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  left: 6%;
}

.nm_of_user {
  font-size: 15px;
}

.nm_of_cre_titel {
  font-size: 10px;
}

.nm_of_cre_nmr {
  font-size: 15px;
}

.sc_f_details2 {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: end;
  position: relative;
  right: 12%;
  margin-top: 20px;
}

.sc_img_f {
  width: 40px;
  height: 40px;
  float: right;
}

.sc_p_nm {
  font-size: 12px;
}

.sc_price {
  font-size: 15px;
  font-weight: 800;
}

.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}

/* back  */
.sc_f_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 18%;
  position: relative;
}

.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}

.sc_b_details {
  position: absolute;
  bottom: 10px;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}

.nm_of_cre_nmr_back {
  font-size: 15px;
}

.sc_f_details2_back {
  width: 40%;
  text-align: center;
}

.sc_img_f_back {
  width: 75px;
  height: 75px;
  background-color: white;
}

.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}

.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}

.details_bg_card {
  font-size: 12px;
}

/* 31-08-2023 */
.show_second_img {
  width: 20px;
  float: right;
}

.btn_showcardsecond {
  margin-top: 20px;
  width: 95%;
  display: flex;
  z-index: 9999;
  position: absolute;
}

.btn_showcardsecond_sub1 {
  width: 75%;
}

.btn_showcardsecond_sub2 {
  width: 25%;
}

.show_second_btn {
  background-color: white;
  font-size: 15px;
  font-weight: bold;
  color: var(--color-main);
  width: -moz-fit-content;
  width: 60px;
  border-radius: 25px;
  padding: 2px 5px 2px 5px;
  text-align: center;
  margin-left: 4px;
}

/* 25-09-2023 */
.showcrad_hr1 {
  height: 3px;
  background-color: var(--color-main);
  margin-top: 5px;
  width: 80%;
}

.showcrad_hr2 {
  height: 2px;
  background-image: linear-gradient(to left, #663548, #da0e41, #663548);
  margin-top: 5px;
  width: 80%;
}
/* 09/11/23 */
.showcard6_nm_set {
  font-size: 14px;
  font-family: var(--font-600);
}
.showcard6_front_details {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 13%;
  align-items: center;
}
.show6_main_div_ {
  text-align: left;
  padding: 0 14px;
  /* top: -91%; */
  position: relative;
  line-height: 14px;
}
.qrcard6_div {
  position: relative;
  width: 32%;
  top: 65px;
  margin: 0 auto;
  text-align: center;
}
.s-d-card6_qr {
  width: 42px;
  height: 41px;
  position: relative;
}
.showcard6_front_details2 {
  /* display: flex;
    position: absolute;
    bottom: -5%;
    width: 100%;
    padding: 0 14px; */

  display: flex;
  position: absolute;
  bottom: 1%;
  width: 100%;
  padding: 3px 14px;
}
.front_card6_sub_details {
  width: 50%;
}
.front_card6_sub_details2 {
  position: relative;
  width: 50%;
  right: -6px;
}
.front_card6_sub_details_inner {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* margin-top: 15%; */
}
.front_nm_product {
  font-size: 10px;
  text-align: right;
  font-family: var(--font-800) !important;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
}
.div_card_6 {
  width: 12%;
  position: absolute;
  bottom: 14px;
  right: 4px;
}
.img_front_card6_1_logo {
  width: 40px;
  height: 40px;
  float: right;
}
.card6_show_back_owner {
  position: absolute;
  /* left: 90px; */
  text-align: center;
  /* top: 130px; */
  width: 100%;
  bottom: 23%;
  margin: 0 auto;
  padding: 0 37px;
}
.crad6_details_back_ {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 29%;
  width: 100%;
  padding: 0 37px;
}
.details_bg_card_show6 {
  font-size: 12px;
  position: relative;
  /* left: 15px; */
  margin-top: 15px;
  font-family: var(--font-400);
}
.prodct_highlights6 {
  font-family: var(--font-600) !important;
  line-height: 0px;
  position: relative;
}
.owned_by_nm6 {
  font-family: var(--font-800) !important;
  line-height: 25px;
  position: relative;
  font-size: 11px;
}
.back_owned_nm6 {
  font-family: var(--font-600) !important;
  line-height: 14px;
  position: relative;
  font-size: 15px;
}
.img_back_card6_1_logo {
  width: 35px;
  height: 35px;
  float: left;
}
.div_card_6_b {
  width: 12%;
  position: absolute;
  bottom: 25px;
}
.div_card6_1 {
  width: 83%;
}
.div_card_6_b {
  width: 12%;
  position: absolute;
  bottom: 12px;
}
.img_back_card6_1_logo {
  width: 40px;
  height: 40px;
  float: left;
  transform: scaleX(-1);
}
.front_nm_product1_6 {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 17px;
}

.ditails_of_back_cs_show6 {
  position: absolute;
  bottom: 32%;
  padding: 0 44px;
  line-height: 15px;
}
