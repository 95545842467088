.auction_products_wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  margin: 30px auto;
}

.auction_single_product_wrapp {
  width: 100%;
  height: 100%;
  /* border: 1px solid var(--color-main); */
  /* min-height: 100px; */
  padding: 10px;
}

.auction_product_username_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.auction_product_username_wrapp > img {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  /* object-fit: contain; */
  overflow: hidden;
  margin-right: 10px;
}

.auction_product_username_wrapp > p {
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
}

.auction_product_image_wrapp {
  width: 100%;
  min-height: 200px;
  /* height: 100%; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auction_product_img {
  width: 90%;
  min-height: 200px;
  max-height: 260px;
  margin: 10px auto 0px auto;
}

.auction_product_blur_view {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0px;
  backdrop-filter: blur(18px);
  /* background: url("../../assets/images/bg_au.png"); */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px 14px;
  background: #000000a5;
}

.auction_product_name {
  font-size: 14px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.auction_product_des {
  font-size: 11px;
  font-family: var(--font-300);
  color: var(--color-white);
}

.auction_bid_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}

.auction_bid_price_wrapp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.auction_bid_price_wrapp > p {
  color: var(--color-red);
  font-size: 12px;
  font-family: var(--font-600);
}

.auction_bid_price_wrapp > span {
  color: var(--color-red);
  font-size: 14px;
  font-family: var(--font-600);
  margin: 0px;
  padding: 0px;
}

.auction_bid_btn {
  padding: 8px 18px;
  background: var(--color-white);
  color: var(--color-black);
  font-size: 12px;
  font-family: var(--font-600);
  border-radius: 18px;
}

.auction_stock_cube_wrapp {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px;
  /* gap: 4px; */
  border: 1px solid var(--color-main);
  background: var(--color-black);
  border-radius: 4px;
  top: 20px;
  right: 30px;
}

.auction_stock_cube_wrapp > p {
  color: var(--color-white);
  font-size: 10px;
  font-family: var(--font-600);
  padding: 0px;
}

.auction_nodata_text {
  font-family: var(--font-600);
  color: var(--color-white);
  font-size: 14px;
  align-self: center;
  margin: 30px auto;
  width: fit-content;
  text-align: center;
  grid-template-columns: 1 -1;
}
  