.bidsall_screen {
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  background-color: black !important;
  height: 100vh !important;
  overflow: scroll !important;
}

/* start header */

.bidsallheader {
  display: flex;
  flex-direction: row !important;
  height: 50px !important;
  width: 100% !important;
  margin-bottom: 10px;
}

.bidsallheader1 {
  width: 10%;
}

.bidsallheader2 {
  width: 90%;
  text-align: center;
}

.bidsall_h1 {
  position: relative;
  right: 15px;
}

/* end header */

/* alllist */
.alllist {
  width: 100%;
  height: 75px;
  border-radius: 10px;
  position: relative;
  background: linear-gradient(#565656, #000);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  justify-content: space-between;
}

.alllist_part1 {
  width: 15%;
}

.alllist_part1_img {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  object-fit: cover;
  border: 1px solid var(--color-main);
}

.alllist_part2 {
  width: 55%;
  padding-left: 15px;
  padding-top: 5px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  gap: 5px;
}

.alllist_part2 > p {
  font-size: 12px;
  font-family: var(--font-400);
  line-height: 14px;
}

.alllist_part3 {
  width: 30%;
  padding: 5px;
}

.bidsbtn_all {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.bidsbtn_all1 {
  width: 48%;
}

.button_all1 {
  height: 30px !important;
  border: 2px solid green !important;
  border-radius: 25px !important;
  width: 30px;
  position: relative;
  left: 4px;
}

.button_img {
  width: 14px;
  color: green;
  height: 8px;
  border-bottom: solid 2px currentColor;
  border-left: solid 2px currentColor;
  transform: rotate(-45deg);
  left: 6px;
  position: relative;
  bottom: 2px;
}
.button_img2_new {
  font-size: 20px;
  line-height: 0px;
  color: red;
  margin-top: -4px;
}
.msg_bids {
  color: white !important;
  position: relative;
  left: 25px;
  top: 5px;
}
.bidsbtn_all2 {
  width: 42%;
}
.button_all2_new {
  height: 30px;
  border: 2px solid red;
  border-radius: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_allp_new {
  font-size: 10px;
  font-family: var(--font-300);
  color: green;
}

.button_allp_new_r {
  font-size: 10px;
  font-family: var(--font-300);
  color: red;
}

/* search start */

.req_search input {
  border: none;
  /* background-color: #ee1651; */
  /* padding-top: 10px; */
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 0px;
}

.req_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* padding: 4px; */
  border-radius: 50px;
  width: 100%;
  background: none;
  border: 1px solid #888;
  padding-left: 5px;
  padding-right: 5px;
  
}

.req_search-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* search end */
