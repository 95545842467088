.cluballnfb_main {
    position: relative;
}

.cluballnfb_nm {
    position: absolute;
    left: 12px;
    top: 10px;
    background-color: rgba(177, 177, 177, 0.73);
    padding: 0px 10px 10px 10px;
    color: white;
    border-radius: 5px;
    font-family: var(--font-400);
    font-weight: 700;
    font-size: 15px;
    height: 25px;
    width: fit-content;
}
.cluballnfb_img
{
    width: 100%;
    height: 150px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.cluballnfb_number
{
    position: absolute;
    bottom: 18px;
    right: 10px;
    background-color: rgba(177, 177, 177, 0.73);
    padding: 2px 10px 10px 10px;
    color: white;
    border-radius: 50px;
    text-align: center;
    font-family: var(--font-400);
    font-weight: 700!important;
    font-size: 15px;
    height: 25px;
    width: fit-content;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.cluballnfb_main_details {
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  margin: 30px auto;
  }
  