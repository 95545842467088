.cmr_list_wrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  margin: 10px auto;
  width: 100%;
}

.cmr_single_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 70px; */
  padding: 10px;
  border-radius: 8px;
  content: "";
  background: url("../../assets/images/bg_amount.png");
  object-fit: contain;
}

.cmr_single_item_img {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  object-fit: cover;
  object-position: top;
}

.cmr_single_item_name {
  color: #fff;
  font-size: 14px;
  font-family: var(--font-600);
}

.cmr_single_item > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cmr_no_data_text {
  align-self: center;
  text-align: center;
  font-size: 16px;
  font-family: var(--font-600);
  color: #fff;
  margin: 40px auto;
  width: 100%;
}
