.qr_user_div {
  background-color: rgba(255, 0, 0, 0.981);
  padding: 10px;
  border-radius: 25px;
  height: 50px;
}

.av_videos_wrapp_2 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  max-height: 90vh;
}

.qr_user_code {
  width: 30px;
}

.update_profie_img2 {
  width: 50px;
  border-radius: 52px;
  border: 1px solid white;
  background: black;
  padding: 5px;
  bottom: 30px;
  position: relative;
}

.user_profile_img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.up_img {
  display: flex;
  justify-content: center;
  border-radius: 80px;
  border: 2px dashed white;
  padding: 3px;
}

.update_profie_img {
  width: 120px;
  height: 120px;
  border-radius: 52px;
  object-fit: cover;
}

.user_bank_account {
  border: 2px solid red;
  border-radius: 25px;
  text-align: center;
  padding: 5px;
}

/* 29-08-2023 */
/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  /* border: 1px solid var(--color-main); */
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

.edit_user_nm {
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  height: 80px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.edit_user_nm1 {
  width: 20%;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.edit_user_nm2 {
  width: 80%;
  padding: 0px 0px 0px 8px;
  margin-top: 15px;
}
.edit_user_nm_text {
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  height: 150px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}
.edit_user_nm1_text {
  width: 20%;
  border-right: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.edit_user_nm2_text {
  width: 80%;
  padding: 0px 0px 0px 8px;
  margin-top: 15px;
}

.edit_nm_tag {
  font-size: 10px;
  font-family: var(--font-300);
  opacity: 0.8;
  margin-left: 8px;
}

.input_edit {
  width: 100%;
  border: none;
  padding: 4px 8px;
}

.user_icon_edit {
  width: 16px;
}

.myupdate_profile {
  background-color: var(--color-main);
  color: white;
  padding: 15px;
  width: 80%;
  border-radius: 25px;
  font-family: var(--font-700);
}
.view_managers_main {
  border: 1px solid white;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

/* crop image modal style start */

.crop_modal_main_wrapp {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #000;
  z-index: 111;
  overflow: hidden;
}

.crop_modal_base_wrapp {
  width: "100%";
  overflow: hidden;
  max-width: var(--max-width-md);
  height: "100%";
  display: flex;
  flex-direction: column;
}

.crop-container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 80px;
}

.crop_img_yes_no_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
  max-width: var(--max-width-sm);
  height: 9vh;
}

/* crop image modal style end */

.image-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 300px;
  height: 500px;
}

.image-container {
  position: relative;
  width: 252px;
  height: 405px;
  overflow: hidden;
}

.fixed-image,
.selected-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selected-image {
  object-fit: contain;
  pointer-events: none; /* Allow clicks to go through the selected image */
}
/* 
input {
  margin-top: 10px;
}

button {
  margin-top: 10px;
} */
