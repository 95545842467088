/* page base design start */

.page_main_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.page_base_wrapp {
  width: 90%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  margin: 10px auto;
}

.page_navbar_wrapp {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px auto;
}

.nav_back_btn {
  height: 20px;
  object-fit: contain;
}

.nav_back_wrapp {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_name {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
/* page base design end */
.Collaboratenfb_img {
  width: 100%;
  height: 300px;
  border-radius: 30px;
}
.collaborate_titel {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 16px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.collaborate_btn {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-600);
  border-radius: 28px;
}
.collaborate_option {
  padding: 5px;
  height: 100px;
  color: white;
}

.radio_new_club {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.my_club_select {
  position: relative;
  top: 5px;
}

.collaborate_search_option_btn {
  padding: 8px 14px;
  margin-top: 10px;
  border-radius: 28px;
  border: 1px solid #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collaborate_search_option_btn > p {
  color: #fff;
  font-family: var(--font-300);
  font-size: 12px;
}

.collaborate_search_option_btn > img {
  width: 12px;
  height: 20px;
  object-fit: contain;
}
