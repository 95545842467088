.mobile_success_main_wrapp {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-black);
  padding: 10px;
}

.success_heading {
  font-size: 30px;
  color: var(--color-white);
  font-family: var(--font-600);
  margin-bottom: 10px;
}

.product_success_heading {
  font-size: 18px;
  color: var(--color-white);
  font-family: var(--font-600);
  line-height: 135%;
  text-align: center;
  margin-bottom: 1rem;
}

.product_des_text {
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--font-300);
  text-align: center;
  margin-bottom: 2rem;
  line-height: 135%;
}

.success_des_text {
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--font-300);
  text-align: center;
}

.success_img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin: 1rem auto;
}
