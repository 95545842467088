.Purchase_btn_set {
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 12px;
}
.Purchase_btn_set1 {
  width: 75%;
}
.Purchase_btn_set2 {
  width: 25%;
}
.show_second_img1 {
  width: 20px;
  float: right;
  position: relative;
  right: 10px;
}

/* 25-09-2023 */

.purchesh_new8 {
  background-image: url(./../../assets/images/newredlineCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new14 {
  background-image: url(./../../assets/images/card14front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new15 {
  background-image: url(./../../assets/images/card15front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
  position: static;
}
.purchesh_new8_img {
  z-index: -1;
  /* border-radius: 10px; */
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 78%;
  top: 0px;
  left: 0;
  border-radius: 14px 14px 0 0;
}
.purchase_front_details {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 15px;
  height: 40%;
}
.purchesh_new_front-del {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  bottom: 0%;
  align-items: center;
  justify-content: start;
  padding-top: 2px;
  height: 40%;
}
.showcard_nm_set {
  font-size: 22px;
  letter-spacing: 4px;
  font-family: var(--font-400);
}

.show8_main_div {
  text-align: start;
  width: 80%;
  padding-left: 5px;
}

.shownm_cardeight {
  font-size: 15px;
  font-family: var(--font-700);
  /* font-weight: 900; */
  letter-spacing: 10px;
  padding-top: 5px;
}

.purchase_del_8 {
  display: flex;
  padding-top: 25px;
  width: 80%;
}

.purchase_del_8_sub1 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.purchase_del_8_sub2 {
  width: 30%;
  display: flex;
  justify-content: end;
  position: relative;
}

.s-card-8-date {
  font-weight: var(--font-700);
  letter-spacing: 8px;
  font-size: 12px;
}

.s-card-8-price {
  font-weight: var(--font-600);
  font-size: 20px;
}

.s-d-8card_qr {
  width: 44px;
  height: 44px;
}

/* 25-09-2023 */

.purchase_del_8_front {
  text-align: left;
  margin: 0 auto;
  width: 80%;
  line-height: 15px;
  padding: 0 10px;
}
.del8_1 {
  width: 67%;
  text-align: center;
}
.del8_2 {
  width: 33%;
  /* right: -11px; */
  position: relative;
  left: 15px;
  top: 3px;
}
.front_nm8 {
  font-family: var(--font-600);
}
.crad8_details_back {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 28%;
  width: 95%;
  padding: 12px;
}
.card8_back_owner {
  position: absolute;
  bottom: 5%;
  text-align: center;
  width: 100%;
  padding: 0 31px;
}

.front_nm_product1_8 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  /* line-height: 30px; */
  text-align: right;
  left: 0px !important;
  position: relative !important;
}
.card_1_img_back8p {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 14px;
}
.show_1_details_front8 {
  position: absolute;
  left: 0%;
  padding: 5px 25px;
  width: 100%;
  bottom: 3%;
}
.front_card_sub_details_inner8 {
  position: relative;
  /* display: flex; */
  /* flex-direction: row; */
  width: 100%;
  /* margin-top: 15%; */
}
.img_front_card8_1_logo {
  width: 40px;
  position: absolute;
  bottom: 4%;
  height: 40px;
  right: 6px;
}
.div_card8_1 {
  width: 100%;
  line-height: 3px;
  position: relative;
}
.front_nm8_product1 {
  font-family: var(--font-600) !important;
  font-size: 14px;
  line-height: 16px;
  /* text-align: right; */
  left: 0px !important;
  position: relative !important;
}
.front_nm8_product {
  font-size: 10px;
  font-family: var(--font-800) !important;
  font-size: 12px;
  line-height: 16px;
}
.front_card8_sub_details {
  width: 100%;
  line-height: 15px;
}
.logo_qr_pur_8 {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  bottom: 5%;
}
.div_card_8_pur {
  width: 20%;
}
.img_front_card_8_logo_pur {
  width: 40px;
  position: relative;
  top: 27%;
  left: 6%;
  height: 40px;
  transform: scaleX(-1);
}
.del8_2_pur {
  width: 20%;
  height: 45px;
  background-color: white;
  border-radius: 25px;
  align-items: center;
  text-align: center;
}
.s-d-8card_qr_pur {
  width: 34px;
  height: 34px;
  position: relative;
  top: 12%;
}
.ditails_of_back_cs_pur8 {
  position: absolute;
  bottom: 12%;
  padding: 42px 15px;
  line-height: 15px;
  width: 100%;
}
.sc_f_blur_8 {
  position: absolute;
  bottom: 24%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  padding: 8px;
  left: 0;
  right: 0;
}
.front_card8_sub_details2_pur {
  position: relative;
  width: 100%;
  text-align: center;
  /* padding-top: 11px; */
}
