.create_club_inputs_wrapp {
  width: 90%;
  margin: 0px auto;
}

.create_club_userjoin_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create_club_userjoin_wrapp > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
  width: 45%;
}

.create_club_userjoin_wrapp > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* width: 50%; */
}

.create_club_userjoin_wrapp > button > img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.create_club_userjoin_wrapp > button > p {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

.create_club_profileandlogo_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.new_create_clubs_selected_ownername_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  background: var(--color-black);
}

.new_create_clubs_selected_ownername_wrapp > p {
  color: var(--color-main);
  font-size: 14px;
  font-family: var(--font-600);
  font-weight: 350;
}

#profile_img {
  display: none;
}

#logo_img {
  display: none;
}

#cover_img {
  display: none;
}
