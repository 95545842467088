.productview_main {
  display: flex;
  flex-direction: row;
  /* padding: 15px; */
  background-color: black;
  height: 100vh;
  width: 100%;
}

.productview_2 {
  width: 90%;
  margin: 10px auto;
  max-width: var(--max-width-sm);
}

.productviews_user_menu {
  left: 15px;
  position: relative;
}

.productview_header {
  top: 0;
  padding: 15px;
  flex-direction: row;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.productview_main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.productviewmain2 {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.productview_main_h1 {
  position: relative;
  right: 25px;
}

.productview_club_user {
  width: 100%;
  padding: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  /* justify-content: space-between; */
}

.productview_h1 {
  font-family: var(--font-600) !important;
  position: relative;
  right: 10%;
}

.nodatafound {
  font-family: var(--font-600) !important;
  text-align: center;
}

.productviewmain1 {
  width: 20%;
}

/* 20-08-2023 */
.product_user_nm {
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-align: start;
  width: 100%;
}

.product_user_nm_img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.product_user_nm_p {
  font-family: var(--font-600);
  font-size: 20px;
  left: 10px;
  position: relative;
  /* top: 8px; */
}

.card_new_sub_main {
  /* position: relative; */
  display: flex !important;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  /* left: 20px; */
}

.detsil_product_nm {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.detsil_product_nm2 {
  width: 100%;
  background-color: rgb(73, 72, 72);
  border-radius: 25px;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.product_del_part1 {
  text-align: center;
  width: 50%;
  border-right: 1px solid white;
}

.product_del_part2 {
  text-align: center;
  width: 50%;
}

.product_del_part_sub1 {
  color: white;
  font-size: 10px;
  font-family: var(--font-300);
}

.product_del_part_sub2 {
  color: white;
  font-size: 16px;
  font-family: var(--font-600);
}

.product_story {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
}

.product_story_del {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 25px;
  width: 100%;
  word-break: break-all;
  margin-bottom: 20px;
}

.product_story1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
  text-align: end;
}

.pv_titel {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 20px;
  /* font-weight: 900 !important; */
  float: right;
  width: fit-content;
}

.hashtags_list {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

/* pv_video_show */
.pv_video_shows {
  position: relative;
  text-align: center;
  height: 380px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
}

.video_bg_pv {
  width: 100%;
  height: 100%;
  z-index: 99;
}

.video_play1_pv {
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(245, 245, 220, 0.44);
  border-radius: 25px;
  bottom: 0%;
  position: absolute;
  float: left;
  left: 10px;
}

.video_player_pv {
  width: 100%;
  height: 360px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
}

.video_plays {
  z-index: 999;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: rgba(245, 245, 220, 0.44);
  border-radius: 25px;
  bottom: 4%;
  position: absolute;
  float: left;
  left: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.pv_video_show_img {
  width: 100px;
}

/* pv_video_show */
/* creator_details */
.creator_details {
  height: fit-content;
  /* padding: 25px; */
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  margin-bottom: 25px;
}

.instagram_img_ins {
  width: 50%;
}

.instgarm {
  background-color: #000;
  width: 100%;
  padding: 25px;
  text-align: center;
}

.instagram_img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-top: 10px;
}

.need_pv_help {
  width: 100%;
}

.need_pv_help_1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-image: linear-gradient(to right, #fec85b, #f67155, #ed1b50);
  height: 50px;
  border-radius: 10px;
}

.need_pvpart1 {
  width: 25%;
  padding: 2px 0px 0px 10px;
}

.need_pv_helpimg {
  width: 45px;
}

.need_pv_helpimg2 {
  width: 45px;
}

.need_pvpart2 {
  width: 50%;
  text-align: center;
  font-family: var(--font-600);
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 15px; */
}

.need_pvpart3 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
}

.creator_details_sub {
  box-shadow: 0px 0px 10px 0px #888888;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  bottom: 30px;
  background-color: white;
  position: relative;
  z-index: 99;
}

.hasttagnew_pro {
  width: 100%;
}

.p_v_details {
  font-family: var(--font-400);
  word-break: keep-all;
  width: 100%;
  text-align: justify;
  font-size: 14px;
}

.creator_details_sub_h1 {
  font-family: var(--font-600);
  font-weight: 600 !important;
  color: #000;
}

.creator_details_sub_p {
  font-family: var(--font-800);
  color: #000;
  left: 0px !important;
}

.creator_details_img {
  width: 100%;
  object-fit: cover;
  height: 250px;
  background-image: url("../../assets/new_images/user_background_logo_image.png");
  background-size: contain; /* Ensure the background covers the element */
  background-position: center; /* Center the background image */
}

/* creator_details */
.resell_product_list {
  width: 100%;
  background-color: rgb(73, 72, 72);
  border-radius: 25px;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.list_number_pv {
  position: relative;
  top: 7px;
  padding-top: 0px;
  font-size: 12px;
  font-family: var(--font-600);
}

.list_of_pv1 {
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.list_of_pv2 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.list_of_pv3 {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.list_img_of_resell {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.list-nm {
  font-family: var(--font-600) !important;
  font-weight: 600;
}

.list-price {
  font-family: var(--font-800);
}

.product_view_hr {
  background-color: #ed1b50;
  height: 2px;
  width: 100%;
}

.sub_product1 {
  width: 50%;
  text-align: start;
}

.sub_product2 {
  width: 50%;
  text-align: end;
}

.sub_h_1 {
  font-family: var(--font-700);
  font-size: 16px;
  /* line-height: 33px; */
}

.sub_h_p {
  font-size: 12px;
  font-family: var(--font-300);
  left: 0px;
}

.product-nfb_nm {
  font-size: 12px;
  /* line-height: 25px; */
  font-family: var(--font-300);
  color: var(--color-main);
}

.product-nfb_price {
  font-family: var(--font-600);
  color: var(--color-main);
  font-size: 18px;
}

/* pd_share start 28-08-2023 */
.pd_share {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.pd_share_img {
  width: 25px;
}

.pd_nm {
  font-family: var(--font-400);
  font-size: 10px;
}

.pd_share_1 {
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pd_share_2 {
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pd_share_3 {
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pd_share_4 {
  width: 40%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.bid_d {
  background: linear-gradient(to left, #ed1b50, #d7758e);
  /* padding: 10px; */
  /* width: fit-content; */
  border-radius: 45px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 45px;
}

.bid_d2 {
  background: linear-gradient(to left, #ed1b50, #d7758e);
  padding: 10px;
  width: 95%;
  /* width: fit-content; */
  border-radius: 25px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
  gap: 10px;
  position: relative;
}

.bid_d_img {
  width: 35px;
  height: 25px;
  position: relative;
  /* left: 10px; */
}

.bid_nm {
  font-family: var(--font-700);
  font-size: 13px;
  color: var(--color-white);
}

/* ---- dk 14-09-2023 start ---- */
.nm_of_user_benefit {
  position: relative;
  right: 15px;
  font-family: var(--font-700);
  font-size: 23px;
}

.partner_b_set {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partner_b_set_img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.partner_b_set_details {
  background-color: #4f4d4da8;
  width: 75%;
  position: absolute;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20%;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.partner_b_set_view {
  width: 25%;
  position: absolute;
  bottom: 15px;
  background-color: yellow;
  text-align: center;
  color: black;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.nm_pr_set1 {
  font-size: 10px;
  font-family: var(--font-600);
}

.nm_pr_set2 {
  font-size: 20px;
  font-family: var(--font-600);
}

/* user_benefit_new */
.user_benefit_new {
  width: 100%;
  background-color: #262626;
  border-radius: 10px;
  display: flex;
  padding: 15px;
}

.user_benefit_new_sub1 {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_benefit_new_sub2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.user_benefit_img {
  background-image: url("./../../assets/images/profile_star.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefit_hr_set {
  height: 1px;
  background: white;
}

.user_benefit_img_sub {
  width: 80%;
  height: 80%;
  border-radius: 50px;
}

.view_of_pr_benefit {
  background-color: white;
  color: var(--color-main);
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  width: 25%;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* ---- dk 14-09-2023 end ---- */
/* new_set_product_details */
.new_set_product_details {
  display: flex;
}

/* new_set_product_details */

/* pd_share end*/
/* 20-08-2023 */

/* 27-09-2023 */
.ser_p_produuct {
  color: #fff;
  font-family: var(--font-400);
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

/* trade history start */

.trad_hist_siglecard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--trnsp-white);
  padding: 10px;
  border-radius: 12px;
}

.th_img_wrapp {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 5px;
}

.th_buyer_name {
  font-size: 14px;
  font-family: var(--font-600);
  font-weight: 350;
  color: var(--color-white);
  text-wrap: wrap;
  /* width: 80%; */
  text-align: center;
}

.th_price {
  font-size: 12px;
  font-family: var(--font-600);
  color: var(--color-white);
  width: -webkit-fill-avilable;
}
/* trade history end */

/* media links start */

.ml_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.ml_link_text {
  /* text-align: left; */
  width: 70%;
  /* text-wrap: wrap; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ml_link_text > p {
  word-wrap: break-word;
  font-size: 14px;
  font-family: var(--font-600);
  color: var(--color-white);
  width: 100%;
}

.ml_copy_btn {
  font-size: 12px;
  color: var(--color-white);
  font-family: var(--font-600);
  background-color: var(--color-main);
  padding: 10px;
  border-radius: 8px;
}

.creator_details_details {
  color: black;
  word-wrap: break-word;
  width: 370px;
  margin-bottom: 10px;
  text-align: justify;
  width: 90%;
  margin: 10px auto;
}

/* media links end */

/* 27-09-2023 */

@media only screen and (max-width: 600px) {
  .productview_club_user {
    /* justify-content: center; */
  }

  .video_bg_pv {
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  .video_play1_pv {
    width: 40px;
    height: 40px;
    padding: 10px;
    background-color: rgba(245, 245, 220, 0.44);
    border-radius: 25px;
    bottom: 0%;
    position: absolute;
    float: left;
    left: 10px;
  }

  .video_player_pv {
    width: 100%;
    height: 360px;
    /* border: 1px solid white; */
  }

  .pv_video_show_img {
    width: 100px;
  }

  /* pv_video_show */
}

/* 2/12/2023 */

.box_02 {
  border: 2px solid red;
  position: relative;
  border-radius: 5px;
  padding: 15px;
  height: 90px;
  width: 80%;
}

.box_img2 {
  position: absolute;
  bottom: -10px;
  top: 10px;
  left: -10px;
  box-shadow: 10px -7px 15px 0px red;
  width: 100%;
  border-radius: 5px !important;
  height: 75px;
  object-fit: cover;
}
.box_img3 {
  position: absolute;
  bottom: -10px;
  top: 10px;
  right: -10px;
  box-shadow: -5px 5px 15px 5px red;
  width: 100%;
  border-radius: 5px !important;
  height: 75px;
  object-fit: cover;
}
.box_img4 {
  position: absolute;
  bottom: -10px;
  top: 10px;
  right: -10px;
  box-shadow: -5px -5px 15px 0px red;
  width: 100%;
  border-radius: 5px !important;
  height: 75px;
  object-fit: cover;
}
.box_img5 {
  position: absolute;
  bottom: -10px;
  top: 10px;
  right: -10px;
  box-shadow: 5px 5px 15px 5px red;
  width: 100%;
  border-radius: 5px !important;
  height: 75px;
  object-fit: cover;
}

.box_p {
  color: white;
}
.box_main_con {
  display: flex;
  /* Use flex display to enable horizontal scrolling */
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent content from wrapping to new lines */
  gap: 0px;
  justify-content: start;
  position: relative;
  top: 10px;
  width: 100%;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.box_details {
  width: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 10px;
}

/* -------------- milestone -------------------- */

/* -------------- phisical asset section start ------- */

.product_view_phisical_sec_wrapp {
  width: 100%;
  margin: 10px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product_view_gradinat_text_heading {
  font-size: 20px;
  background-image: linear-gradient(
    180deg,
    #ff004c,
    #ff004c,
    #ff004c,
    #caacff,
    #caacff,
    #caacff,
    #caacff
  );
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 10px;
  position: relative;
  font-family: var(--font-600);
}

.product_view_gradinat_text_heading::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  margin: 6px auto 0;
}

.phisical_asset_box_gradient_wrapp {
  background: linear-gradient(180deg, rgba(255, 0, 76, 0.5) 0%, #caacff 100%);
  width: 100%;
  height: 100%;
  padding: 1px;
  border-radius: 26px;
}

.phisical_asset_box_inner_wrapp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 10px;
  border-radius: 26px;
  background: #000;
}

.phisical_asset_box_inner_wrapp > img {
  width: 100%;
  height: 200px;
  border-radius: 16px;
  object-fit: cover;

  /* margin-bottom: 10px; */
}

.phisical_asset_owner_name_wrapp {
  border: 1px solid #818181;
  width: 100%;
  border-radius: 24px;
  padding: 6px;
  background: #212121;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  /* margin: 6px 0px;  */
}

.phisical_asset_owner_name_wrapp > div {
  width: 34px;
  height: 34px;
  background: #595959;
  border-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.phisical_asset_owner_name_wrapp > div > img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.phisical_asset_owner_name_wrapp > p {
  font-family: var(--font-600);
  font-size: 10px;
  font-weight: 350;
  line-height: 15.5px;
  text-align: left;

  width: 85%;
  color: #b4b4b4;
}

.phisical_asset_story_wrapp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.phisical_asset_story_heading {
  font-family: var(--font-600);
  font-size: 16px;
  font-weight: 350;
  line-height: 30.3px;
  text-align: left;
  color: var(--color-white);
  border-bottom: 1px solid var(--color-white);

  width: fit-content;
}

/* -------------- phisical asset section end ------- */

.productview_milestone_wrapp {
  width: 100%;
  height: 240px;
  position: relative;
}

.productview_milestone_wrapp > img {
  width: 100%;
  height: 92%;
  border-radius: 24px;
  overflow: hidden;
}

.productview_milestone_wrapp > div {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: max-content;
  padding: 1px;
  max-width: 90%;

  z-index: 11;
  background: linear-gradient(180deg, rgba(255, 0, 76, 0.5) 0%, #caacff 100%);
  border-radius: 24px;
}

.productview_milestone_wrapp > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #202020;
  padding: 10px 16px;
  border-radius: 24px;
}

.productview_milestone_wrapp > div > div > p {
  font-family: var(--font-600);
  color: var(--color-white);
  font-weight: 300;
  font-size: 14px;
}

/* -------------- milestone -------------------- */

@media only screen and (max-width: 600px) {
  /* .box_details {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  } */
  /* .box_main_con {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    margin-bottom: 10px;
    width: 100%;
  } */
  .box_02 {
    border: 2px solid red;
    position: relative;
    border-radius: 5px;
    padding: 15px;
    height: 62px;
    width: 80%;
  }
  .box_img2,
  .box_img3,
  .box_img4,
  .box_img5 {
    height: 50px;
  }
  .box_img2 {
    border-radius: 0;
  }
}
