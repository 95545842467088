.family_req_tabs_sec {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  /* border: 1px solid red; */
  margin-top: 10px;
}

.family_req_tabs_sec_details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family_req_tabs_sec_hr {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 76, 0.5) 0%,
    rgba(202, 172, 255, 0.21) 98.46%
  );
}

.family_req_tabs_sec_details > button {
  color: var(--color-white);
  font-size: 1rem;
  font-family: var(--font-600);
  width: 46%;
  background: none;
  border: none;
  opacity: 0.5;
}

.family_req_tabs_sec_details > div {
  background: var(--color-white);
  font-size: 1rem;
  font-family: var(--font-600);
  width: 2px;
  height: 20px;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 76, 0.5) 50%,
    rgba(202, 172, 255, 0.21) 21%
  );
}

.family_req_receved_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  margin: 1rem auto;
  /* height: 65vh; */
  /* overflow-y: scroll; */
}

.family_req_receved_single_card {
  width: 100%;
  padding: 10px;
  box-shadow: 0px 1.9585492610931396px 47.00518035888672px 0px #0000000a;
  background: #ffffff14;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 18px;
}

.family_req_receved_single_card > img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 4px solid var(--color-white);
  object-fit: cover;
}

.family_req_receved_single_card_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48%;
}

.family_req_receved_single_card_name > h4 {
  font-family: var(--font-400);
  font-size: 14px;
  font-weight: 600;
  line-height: 16.45px;
  letter-spacing: 0.9792746305465698px;
  text-align: left;
  color: var(--color-white);
}

.family_req_receved_single_card_name > p {
  font-family: var(--font-400);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.45px;
  letter-spacing: 0.9792746305465698px;
  text-align: left;
  color: #989ca1;
}

.family_req_receved_single_card_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.family_req_receved_single_card_btn {
  width: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  border: none;
  border-radius: 40px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;
}

.family_req_receved_single_card_lrg_btn {
  /* width: 40px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

  border: none;
  border-radius: 28px;
  border-image: linear-gradient(
    116.08deg,
    rgba(255, 255, 255, 0.59) 11.67%,
    rgba(255, 0, 76, 0) 44.03%
  );
  border-image-slice: 1;
  box-shadow: 0px 1px 12px 0px #ff004cc2 inset, 0px 4px 14px 0px #9a92d24d inset,
    0px 39px 99px -49px #caacff73 inset;
  cursor: pointer;
  padding: 12px;
}

.family_req_receved_single_card_lrg_btn > p {
  color: var(--color-white);
  font-family: var(--font-600);
  font-size: 14px;
}

.req_main_tabs_wrapp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
}