/* page base design start */

.benefitsmain_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #191820;
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
}

.benefitsbase_wrapp {
  width: 100%;
  max-width: var(--max-width-md);
  height: 100%;
  min-height: 100vh;
  /* margin: 10px auto; */
}

.benefitsnavbar_wrapp {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 10px auto;
}

.nav_back_btns {
  height: 20px;
  object-fit: contain;
}

.benefits_navs {
  flex: 0.4;
  display: flex;
  align-items: flex-start;
}

.nav_screen_names {
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-600) !important;
  /* margin-left: 22%; */
  align-self: center;
  justify-self: center;
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* page base design end */

.benefitshow_main {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 15px;
}

.nm_start_benefit {
  font-size: 13px;
  font-weight: bold;
  position: relative;
  left: 25px;
  width: 55%;
}

.benefit_star {
  width: 50px;
}

.line_start {
  width: 45%;
  height: 1px;
  background-color: white;
}

.benefitshow_1 {
  background-image: url("./../../assets/images/6ob.gif");
  width: 60%;
  border: 1px solid;
  margin-bottom: 10px;
  height: 300px;
  padding-top: 15px;
}

.benefitshow_second {
  width: 60%;
  border: 1px solid;
  text-align: start;
  padding: 10px;
}

.price_nm_box {
  font-family: var(--font-600);
  font-size: 14px;
}

.benefitshow_images {
  width: 50%;
  height: 70%;
  object-fit: cover;
}

.btn_with_benefit {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-top: 25px;
  align-items: center;
}

.contiune_with {
  font-family: var(--font-600);
  background-color: var(--color-main);
  color: white;
  font-size: 18px;
  /* height: 50px; */
  padding: 10px;
  border-radius: 25px;
  width: 80%;
}
