.av_videos_wrapp {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  height: 90vh;
}

.av_single_video {
  width: 48%;
  /* max-width: 130px; */
  border: 2px dashed var(--color-gray);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  border-radius: 12px;
  position: relative;
}

.av_of_checkbox {
  /* position: absolute !important; */
  /* top: 0px; */
  /* right: 0px; */
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--color-main);
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  transform: scale(0.8) !important;
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -ms-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

/* Style the checkbox when it's checked */
.av_of_checkbox:checked::before {
  content: "✔";
  /*Displayacheckmarkcharacteroranyothersymbolyoulike*/
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--color-main);
  /*Setthebackgroundcolorwhenchecked*/
  color: var(--color-white);
  /*Setthetextcolorwhenchecked*/
  text-align: center;
  line-height: 20px;
  border-radius: 25px;
  position: relative;
  bottom: 10px;
  right: 10px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
