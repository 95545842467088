.nav2_main_wrapp {
  position: fixed;
  top: 0px;
  width: 100%;
  background: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  left: 0px;
}
.img_of_usermenu {
  width: 38px;
  height: 38px;
  border-radius: 38px;
  padding: 1px;
  /* padding-top: 2px; */
  padding-bottom: 0;
}

.nav2_base_wrapp {
  width: 90%;
  max-width: var(--max-width-sm);
  padding: 10px 0px;
  margin: 0px auto;
  min-height: 50px;
  /* border: 1px solid #ffd; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.nav_profile_wrapp {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid var(--color-main);
  overflow: hidden;
}

.nav_btn {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
}

.nav_profile_wrapp > img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}

.nav_btn > img {
  width: 80%;
  height: 80%;
  overflow: hidden;
  object-fit: contain !important;
}

.nav_btns_wrapp {
  display: flex;
  align-items: center;
  gap: 10px;
}
