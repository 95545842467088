.blue-card-main-wrapp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flip-container {
  perspective: 1000px;
  width: 300px;
  height: 580px;
}

.flipper {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-container.flipped .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}

/* img {
  width: 100%;
  height: 100%;
} */
.frame-img {
  position: relative;
  width: 100%;
  height: 100%;
}
.inner-img {
  position: absolute;
  left: 25px;
  width: 84%;
  height: 405px;
  top: 27px;
  z-index: -1;
  object-fit: fill;
}

/* for only fifth card */
.inner-img_5 {
  position: absolute;
  left: 26px;
  width: 83%;
  height: 397px;
  top: 38px;
  z-index: -1;
  object-fit: fill;
}

.front-card-del-qr {
  display: flex;
  position: absolute;
  bottom: 4px;
  width: 100%;
}
.front-card-del-div {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.front-card-del {
  text-align: center;
}
.front-card-del > p {
  font-size: 12px;
}
.front-card-qr {
  width: 35%;
}
.qr-circle {
  height: 74px;
  width: 74px;
  border-radius: 73px;
  background: white;
  margin-left: 8px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qr-circle > img {
  width: 67%;
  height: 67%;
}
.back-card-del-div {
  position: absolute;
  bottom: 35%;
  width: 80%;
  left: 30px;
}
.fram_read_btn {
  color: var(--color-main);
  font-family: var(--font-600);
  font-size: 12px;
}
.des-name {
  font-size: 1rem;
  font-family: var(--font-600);
  color: var(--color-white);
  line-height: 150%;
}
.des-content {
  font-size: 12px;
  line-height: 130%;
  font-family: var(--font-300);
  color: var(--color-white);
}
.owner-name-div {
  position: absolute;
  bottom: 27%;
  width: 83%;
  text-align: center;
  margin: auto;
  left: 26px;
}
.owned-by {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1.6px;
  font-family: var(--font-400);
  color: var(--color-white);
  opacity: 0.9;
}
.owner-name {
  font-size: 18px;
  line-height: 130%;
  font-family: var(--font-600);
  color: var(--color-white);
}
.back-card-del-qr {
  display: flex;
  position: absolute;
  bottom: 4px;
  width: 100%;
}
.back-card-del-div2 {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-card-del {
  text-align: center;
}
.back-card-del > p {
  font-size: 12px;
}
.back-card-qr {
  width: 35%;
}

/* modal */
.modal-b {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content-b {
  background-color: #3c3c3c;
  margin: auto;
  padding: 20px;
  border: none;
  max-height: 80vh;
  width: 80%;
  max-width: 500px;
  border-radius: 12px;
  position: relative;
  /* color: black; */
  top: 50%;
  text-align: center;
}
.modal-content-b > p {
  /* color: black; */
}
.close-b {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #aaa;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.close-b:hover,
.close-b:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-content-b > button {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: #fff;
  font-family: var(--font-400);
  background-color: var(--color-main);
  font-size: 14px;
  margin-top: 10px;
}
