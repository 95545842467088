.flip-card {
  perspective: 1000px;
  width: 300px;
  height: 500px;
  transition: transform 0.6s;
  border-radius: 50px;
  -webkit-transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -ms-transition: transform 0.6s;
  -o-transition: transform 0.6s;
}

.flipped .flip-card-inner_card {
  transform: rotateY(180deg);
}

.card_flip-card-back {
  transform: rotateY(180deg);
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-inner_card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card_flip-card-front,
.card_flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_front {
  background-image: url(./../../assets/images/newredlineCardFront.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_front_14 {
  background-image: url(./../../assets/images/card14front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_front_15 {
  background-image: url(./../../assets/images/card15front.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_back {
  background-image: url(./../../assets/images/newredlineCardBack.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_back_14 {
  background-image: url(./../../assets/images/card14back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card_nfb_eight_img_back_15 {
  background-image: url(./../../assets/images/card15back.png);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  z-index: 9;
}

.card8_show_img_front {
  z-index: -1;
  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  top: 0%;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
}

.card8_show_img_back {
  z-index: -1;

  position: relative;
  width: 100%;
  object-fit: cover;
  height: 97%;
  left: 0px;
  border-radius: 15px 15px 0px 0px;
}

/* 29-08-2023 */

.nm_of_user {
  font-size: 14px;
}
.nm_of_cre_titel {
  font-size: 10px;
}
.nm_of_cre_nmr {
  font-size: 14px;
}

.sc_img_f8 {
  width: 40px;
  height: 40px;
  float: right;
}
.sc_p_nm {
  font-size: 10px;
}
.sc_price {
  font-size: 14px;
  font-weight: 800;
}
.sc_f_details3 {
  width: 5%;
  position: absolute;
  bottom: 10px;
  right: 3px;
}

/* back  */

.sc_f_details1_back {
  display: flex;
  flex-direction: column;
  width: 60%;
  position: relative;
  left: 6%;
}

.nm_of_cre_titel_back {
  font-size: 10px;
}
.nm_of_cre_nmr_back {
  font-size: 15px;
}
.sc_f_details2_back {
  width: 40%;
  text-align: center;
}

.ditails_of_back_cs {
  position: absolute;
  top: 15%;
  padding: 20px;
}
.readmore_card {
  color: var(--color-main);
  font-family: var(--font-600);
}
.details_bg_card {
  font-size: 12px;
}

/* 31-08-2023 */
.show_second_img {
  width: 20px;
  float: right;
}
.btn_showcardsecond {
  margin-top: 20px;
  width: 95%;
}
.show_second_btn {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-main);
  /* width: -moz-fit-content; */
  /* width: 60px; */
  border-radius: 28px;
  padding: 3px 8px;
  text-align: center;
  margin-left: 4px;
  font-family: var(--font-600);
}
/* new 06-11-2023 */
.sc_f8_details2_front {
  position: absolute;
  bottom: 24%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  padding: 8px;
  left: 0;
  right: 0;
}
.sc_img_f8_back {
  width: 40px;
  height: 40px;
  position: relative;
  transform: scaleX(-1);
  /* left: 10px; */
}

/* .nm_of_user_div5{
    position: relative;
    bottom: 51%;
    text-align: center;
    padding: 0 20px;
} */
.sc_f8_details {
  text-align: center;
  width: 100%;
  bottom: 17%;
  position: relative;
  padding: 0 12px;
}
.sc_f8_details2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* text-align: end; */
  position: relative;
  line-height: 13px;
  padding-top: 15px;
}
.sc_b6_details {
  position: relative;
  width: 50%;
  /* text-align: center; */
}
.sc_f8_details_back {
  display: flex;
  flex-direction: row;
  width: 100%;
  bottom: 11%;
  position: relative;
}
.qr_of_user_div8 {
  width: 65px;
  height: 50px;
  background-color: white;
  border-radius: 25px;
  align-items: center;
  text-align: center;
}
.sc_f8_details1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  text-align: center;
  line-height: 13px;
}
.nm_of_user_div8 {
  position: relative;
  width: 100%;
  line-height: 16px;
  text-align: left;
  padding-left: 11px;
}
.sc_b8_details {
  position: relative;
  width: 100%;
  text-align: center;
}
.sc_b8_details3 {
  width: 30%;
  position: absolute;
  bottom: 10px;
  left: 3px;
}
.sc_img_f8_front {
  width: 34px;
  height: 35px;
  position: relative;
  top: 12%;
}
.ditails_of_back_cs_8_nfb {
  position: absolute;
  bottom: 14%;
  padding: 0 18px;
  width: 100%;
}
