.nav_back_wrapp_pur {
  flex: 0.3;
  display: flex;
  align-items: flex-start;
}
.chnages_btn::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chnages_btn {
  display: flex; /* Use flex display to enable horizontal scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping to new lines */
  width: 100%;
  max-width: var(--max-width-sm);
  gap: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  justify-content: flex-start;
  padding: 10px;
  margin-bottom: 2rem;
  margin: 0px auto;
}
.show_p {
  margin: 0 auto;
  max-width: var(--max-width-sm);
}
