.new_pan_number_heading {
  font-family: var(--font-600);
  font-size: 12px;
  font-weight: 350;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-top: 1rem;
  padding: 0px 5%;
}
