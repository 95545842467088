.signup-container {
  display: flex;
  flex-direction: row;
  margin: 20px auto;
  background-color: black;
  /* height: 100vh; */
  align-items: center;
  justify-content: center;
}

.signup1 {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}

.login-logo {
  width: 40%;
}

.web_img {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.signup_img_1 {
  width: 80%;
  position: relative;
  left: 5%;
}

.signup2 {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width-sm);
  margin: 0px auto;
}

.gender-nm {
  padding: 0px;
  /* bottom: 1px; */
  font-size: 16px;
  font-family: var(--font-600);
  color: var(--color-white);
}

/* Styles.css (or any other CSS file) */
.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: -10px; */
  max-height: 50px;
}

.mv_terms_new {
  color: #ee1651;
  border: none;
}

.checkbox-label {
  position: relative;
  display: flex;
  align-items: center;
  left: 10px;
}

.mb_term {
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
  position: relative;
  left: 15px;
  font-size: 12px;
}

.checkbox-label.checked .checkmark {
  background-color: #ee1651;
}

.checkbox-inline {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-label.checked .checkmark:after {
  display: block;
}

.checkbox-label.checked .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.gender-input {
  display: flex;
  flex-direction: row;
  /* height: 60px; */
  width: 100%;
  padding-bottom: 7px;
  align-items: center;
  gap: 25px;
}

.gender_single_wrapp {
  display: flex;
  align-items: center;
  gap: 12px;
  /* justify-content: center; */
}
.hied_signup {
  position: relative;
  float: right;
  bottom: 43px;
  width: 18px;
  right: 17px;
}

.signup-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.check_new {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.signup-name {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ee1651;
  top: 15px;
  position: relative;
  font-family: var(--font-700);
}
.accept-buttont {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
}
.login-buttons {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 12px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-600);
  border-radius: 28px;
}

.signup-logo {
  /* width: 29%; */
  width: 120px;
  object-fit: contain;
  /* float: left; */
  margin-top: 10px;
  align-self: flex-start;
}

.signup-input {
}

.Termsandcondition_header1 {
  position: fixed;
  height: 60px;
  top: -2px;
  left: 0px;
  background-color: #000;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
  width: 100%;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  color: white;
  background-color: black;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  color: white;
  background-color: black;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

option {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  color: white;
  background-color: black;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.signup-footer {
  /* position: absolute; */
  bottom: 15px;
  padding: 14px 0px;
}

.signup-button {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  height: 35px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}
.accept-button {
  padding: 10px 20px;
  background-color: #ee1651;
  color: #fff;
  border: none;
  height: 35px;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
}

.signup-text {
  color: #fff;
  font-size: 14px;
}

.signup-text a {
  color: #fff;
  font-weight: 800;
  text-decoration: none;
}

.signup-text a:hover {
  text-decoration: underline;
}

.styled-select {
  width: 100px;
  height: 17px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  /* background: url(images/downarrow_blue.png) no-repeat right white; */
  border: 2px double red;
  display: inline-block;
  position: relative;
}

.styled-select select {
  background: transparent;
  -webkit-appearance: none;
  width: 100px;
  font-size: 11px;
  border: 0;
  height: 17px;
  position: absolute;
  left: 0;
  top: 0;
}

/* dropdown search start */

.contry_select_modal {
  border: 1px solid gray;
  width: 90%;
  position: absolute;
  top: 45px;
  padding: 10px;
  width: 100%;
  max-height: 200px;
  /* overflow-y: scroll; */
  background: #000;
  z-index: 111;
}

.state_select_modal {
  border: 1px solid gray;
  width: 90%;
  position: absolute;
  top: 45px;
  padding: 10px;
  width: 100%;
  max-height: 200px;
  /* overflow-y: scroll; */
  background: #000;
  z-index: 111;
}

.contry_modal_option_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-height: 130px;
  flex-direction: column;
  overflow-y: scroll;
}

.select_down_arrow {
  position: absolute;
  right: 20px;
  bottom: 22px;
  width: 12px;
  height: 22px;
  object-fit: contain;
}

/* dropdown search end */

.radio_btn1 {
  margin-left: 30px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.radio_btn2 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.radio_btn3 {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}
::-webkit-scrollbar {
  display: none;
}
.radio_btn_name {
  font-size: 12px;
  color: var(--color-white);
  bottom: 0px;
  padding: 0px;
  /* align-items: center; */
  font-family: var(--font-400);
  top: 1px;
}
.phone-country {
  top: 8px;
}
.bondbox_checkbox1 {
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 15px;
}

.all_gender_radio_wrapp {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 74%;
}

.main_header_notifi {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.verify_btn_otp {
  width: 100%;
  padding: 10px;
  background: var(--color-main);
  color: var(--color-white);
  font-size: 16px;
  font-family: var(--font-600);
  text-align: center;
  border-radius: 28px;
}

@media only screen and (max-width: 600px) {
  .web_screen_signup {
    width: 100%;
    border: none;
    padding: 0px;
  }

  .signup1 {
    display: none;
  }

  .web_signup {
    width: 100%;
  }

  .mv_terms_new {
    color: #ee1651;
  }

  .checkbox-label {
    position: relative;
    display: flex;
    align-items: center;
    left: 10px;
  }

  .mb_term {
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
    position: relative;
    left: 15px;
    font-size: 12px;
  }

  .checkbox-label.checked .checkmark {
    background-color: #ee1651;
  }

  .checkbox-inline {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: #fff;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-label.checked .checkmark:after {
    display: block;
  }

  .checkbox-label.checked .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .gender-input {
    display: flex;
    flex-direction: row;
    /* height: 60px; */
    width: 100%;
    padding-bottom: 7px;
    align-items: center;
    gap: 15px;
  }

  .hied_signup {
    position: relative;
    float: right;
    bottom: 43px;
    width: 20px;
    right: 15px;
  }

  .signup-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .check_new {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  .signup-name {
    font-size: 20px;
    margin-bottom: 10px;
    color: #ee1651;
    top: 15px;
    position: relative;
    font-family: var(--font-700);
  }

  .signup-input {
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: white;
    width: 100%;
  }

  .gender-nm {
    padding: 0px;
    /* bottom: 1px; */
    font-size: 16px;
    font-family: var(--font-600);
    color: var(--color-white);
  }

  .radio_btn_name {
    font-size: 12px;
    color: var(--color-white);
    bottom: 0px;
    padding: 0px;
    /* align-items: center; */
    font-family: var(--font-400);
  }

  input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    color: white;
    background-color: black;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  select {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    color: white;
    background-color: black;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  option {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    color: white;
    background-color: black;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .signup-footer {
    /* position: absolute; */
    bottom: 15px;
    padding: 14px 0px;
  }

  .signup-button {
    padding: 10px 20px;
    background-color: #ee1651;
    color: #fff;
    border: none;
    height: 35px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .signup-text {
    color: #fff;
    font-size: 15px;
    font-family: var(--font-300);
  }

  .signup-text a {
    color: #fff;
    font-weight: 800;
    text-decoration: none;
  }

  .signup-text a:hover {
    text-decoration: underline;
  }

  .styled-select {
    width: 100px;
    height: 17px;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    /* background: url(images/downarrow_blue.png) no-repeat right white; */
    border: 2px double red;
    display: inline-block;
    position: relative;
  }

  .styled-select select {
    background: transparent;
    -webkit-appearance: none;
    width: 100px;
    font-size: 11px;
    border: 0;
    height: 17px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .Termsandcondition-container {
    display: flex;
    flex-direction: row;
    /* padding: 20px; */
    background-color: black;
    height: 100vh;
    width: 100%;
    position: absolute;
    flex: 1;
    top: 0;
    left: 0;
    /* overflow: hidden; */
  }

  .Termsandcondition_1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Termsandcondition_2 {
    width: 90%;
    margin: 0px auto;
    max-width: var(--max-width-sm);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .div_main1 {
    width: 20%;
  }

  .main_h1 {
    position: relative;
    right: 25px;
    color: #f5f5f5;
  }

  .general_user {
    width: 100%;
  }
  .user_Termsandcondition_search {
    height: 40px;
    padding: 15px;
  }
  .user_Termsandcondition_search::placeholder {
    color: gray;
    background-image: url(../../assets/images/search.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 100%;
  }

  .notifi_details {
    border-bottom: 1px solid white;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px;
  }

  .Termsandcondition_img {
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .img_Termsandcondition {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid white;
  }

  .Termsandcondition_msg {
    color: gray;
  }

  .Termsandcondition_nm {
    width: 80%;
  }
  .phone-country {
    top: 9px;
  }
  @media only screen and (max-width: 850px) {
    /* .web_screen_Termsandcondition {
        width: 100%;
        border: none;
        padding: 0px;
    }
    .Termsandcondition_1 {
        width: 55%;
    }

    .Termsandcondition_2 {
        width: 45%;
    } */
  }

  @media only screen and (max-width: 600px) {
    .Termsandcondition_1 {
      display: none;
    }

    .Termsandconditions_user_menu {
      left: 15px;
      position: relative;
    }
    /* .Termsandcondition_2 {
      width: 100%;
      display: block;
      flex-direction: column;
      align-items: start;
      justify-content: center;
    } */

    .div_main1 {
      width: 20%;
    }

    .main_h1 {
      position: relative;
      right: 25px;
    }
    .paragraph {
      font-size: 16px;
      font-family: "Gr_light";
      color: #f5f5f5;
      opacity: 0.7;
      margin-bottom: 10px;
    }
    .Termsandcondition_details {
      word-wrap: break-word;
      width: 90%;
      padding: 2px;
    }
    .back_img_all {
      width: 45px;
      height: 25px;
      z-index: 99;
      position: relative;
      border: none;
    }
    .all_back {
      width: 35px;
    }

    .gender_single_wrapp {
      gap: 6px;
    }
  }
}
