.reles_details_club {
  width: 100%;
  font-family: var(--font-600) !important;
  font-size: 15px;
}

.releas_btnclub {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 10px;
}

.benefit_category::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.benefit_category {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
