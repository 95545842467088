.product-section {
  margin: 1% 5%;
}

.product-title {
  text-align: center;
}

.product-cnt p {
  margin-bottom: 0.8rem;
  font-size: 14px;
  font-family: var(--font-300);
}

.search input {
  border: none;
  /* background-color: #ee1651; */
  /* padding-top: 10px; */
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 0px;
}

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  /* padding: 4px; */
  border-radius: 50px;
  width: 100%;
  background: none;
  border: 1px solid #888;
  padding-left: 5px;
  padding-right: 5px;
}

.search-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.product-cnt {
  border: 1px solid rgb(173, 3, 3);
  border-radius: 10px;
  padding: 14px 10px;
  margin: 2rem auto;
  /* text-align: center; */
}

.create_product_btn {
  background: #fff;
  padding: 6px 20px;
  border-radius: 18px;
  font-size: 11px;
  cursor: pointer;
  align-self: flex-start;
  font-family: var(--font-700);
  color: var(--color-black);
  /* transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out; */
}

.card-image {
  width: 99.8%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
  margin-left: 10px;
}

.card {
  width: 100%;
  max-width: 400px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.card-content {
  padding: 16px;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: var(--number-step);
  backdrop-filter: blur(10px);
  width: 100%;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-title {
  margin-bottom: 10px;
}

.card-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
  gap: 10px;
  /* background-color: red; */
}

.filter_btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card-title-text {
  font-size: 17px;
  font-family: var(--font-700) !important;
}

.applied-title {
  font-size: 12px;
}
.card-button {
  background-color: transparent;
  border: 1px solid white; /* Add white border */
  border-radius: 4px; /* Add border radius */
  padding: 8px 16px; /* Add padding inside the button */
  color: white; /* Set the text color to white */
  cursor: pointer;
  border-radius: 20px;
  font-size: 11.5px;
}

.filter-title-text {
  font-family: var(--font-600) !important;
  font-size: 14px;
}

/* cusmodal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
  height: 100vh;
  transition: height 1s;
}

.modal-content {
  background-color: #333333cc;
  padding: 20px;
  width: 50%;
  height: auto;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-message {
  color: white;
  font-size: 12px;
  font-family: var(--font-400);
}

.modal_two_buton {
  padding-bottom: 40px;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-around;
  /* flex-direction: row; */
}
.close_forgot {
  color: #fff;
  background-color: #ee1651;
  border-radius: 25px;
  width: 100px;
  font-size: 15px;
  padding: 5px;
  height: 30px;
}

/* ResponsiveComponent.css */

/* ResponsiveComponent.css */

.button-container {
  display: flex;
  justify-content: space-between; /* Distribute buttons along the row */
  margin-top: 15px;
}

.filter_btn {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 10px;
  font-family: "Gr_bold";
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 0 5px;
}

.active-button {
  background-color: #e51a4b;
}

.inactive-button {
  background-color: #898989;
}

.my_pro_Search::placeholder {
  color: rgba(128, 128, 128, 0.773) !important;
  font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
  .card {
    max-width: 100%;
  }
  .filter-title-text {
    font-size: 11px;
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 6px;
  }
}
